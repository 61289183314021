import { Checkbox, Form, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/es/select';
import axios from 'axios';
import { isDefined } from 'class-validator';
import { t } from 'i18next';
import fileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import FormComponent from 'src/app/components/FormComponent/FormComponent';
import SelectAll from 'src/app/components/SelectWithAllComponent/SelectAll';
import { exportSC3DataFilter, getNSearches } from 'src/app/forms/ExportData/export-data.config';
import { exportFileSendMail } from 'src/app/forms/ExportInterestForm/export-interest-form.config';
import { IFormProps } from 'src/app/forms/form.config';
import { useAppSelector } from 'src/app/redux/hook';
import * as userReduxType from 'src/app/redux/user/user.redux.type';
import { useGetUsersQuery } from 'src/app/redux/user/userSlice';
import { lookup } from 'src/app/services/customer/customer.service';
import { urlsConfig } from 'src/configs';
import { LAYOUTVIEW } from 'src/configs/system.config';
import { OperationType, ReportTypes } from 'src/enums/logger-export.enum';
import { LayoutViewType } from 'src/enums/user.enum';

const ExportDataForm: React.FC<IFormProps> = ({ form, saveButtonDisabled }) => {
  const userState = useAppSelector((state) => state.user);

  const [selectOptions, setSelectOptions] = useState<DefaultOptionType[]>([]);
  const [usersToMail, setUsersToMail] = useState<{ label: string; value: string; key: number }[]>([]);
  const { customerId }: userReduxType.StateType = useAppSelector((state) => state.user);

  const mailList = useWatch('mails', form);
  const selectedReportTypes = useWatch('reportTypes', form);
  const pdfExport = useWatch('pdfExport', form);
  const sendAttachment = useWatch('sendAttachment', form);
  const isNSearchSelected = selectedReportTypes?.includes(ReportTypes.Nsearch);

  const { data: userList } = useGetUsersQuery({
    customerIds: customerId ?? undefined,
  });

  const getCustomers = async () => {
    try {
      const { data } = await lookup(true);
      const tempOptions: DefaultOptionType[] = [];
      if (data) {
        data.rows.forEach((customer) => {
          tempOptions?.push({
            label: customer.enterpriseIdentified[0].company.name,
            value: customer.id,
          });
        });
        setSelectOptions(tempOptions);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (customerId) {
      getCustomers();
    }
  }, [customerId]);

  useEffect(() => {
    if (isDefined(userList)) {
      const mailsAndNames = userList.rows
        .filter((user) => user?.email)
        .map((user, _) => ({
          label: user?.fullname,
          value: user?.email,
          key: _,
        }));
      setUsersToMail(mailsAndNames);
    }
  }, [userList]);

  const onFinish = async (values: any) => {
    if (userState.token !== undefined) {
      try {
        const { data } = await axios.post(
          urlsConfig.data.exportData,
          {
            ...(values?.customerId && { customerIds: values?.customerId }),
            reportTypes: values.reportTypes,
            operationType: OperationType.excel,
          },
          {
            baseURL: urlsConfig.loggerServerBaseURL.replace('https', 'http'),
            responseType: 'blob',
          },
        );

        if (data) {
          fileDownload(data, 'Reports.xlsx');
        }
        if (pdfExport) {
          getNSearches({ mailList, customerIds: values?.customerId, excel: data, isSendAttachment: sendAttachment });
        } else if (mailList) {
          exportFileSendMail({
            emails: mailList,
            type: 'xlsx',
            ...(sendAttachment && { excel: data }),
          });
        }
      } catch (error: any) {
        console.log('error: ', error);
      }
    }
  };
  return (
    <FormComponent
      options={{
        form,
        onFinish,
      }}
      hasSaveButton
      saveButtonText="Export"
      saveButtonDisabled={saveButtonDisabled}
    >
      {selectOptions?.length > 0 && (
        <Form.Item label={t('forms.exportDataForm.fields.customers')} name="customerId" rules={[{ required: true }]}>
          <SelectAll
            variant="borderless"
            mode="multiple"
            allowClear
            showSearch
            maxTagCount="responsive"
            optionFilterProp="label"
            style={{ width: '100%' }}
            formOptions={{ form, name: 'customerId' }}
            options={selectOptions}
          />
        </Form.Item>
      )}

      <Form.Item label={t('forms.exportDataForm.fields.reportTypes')} name="reportTypes" rules={[{ required: true }]}>
        <SelectAll
          variant="borderless"
          mode="multiple"
          allowClear
          showSearch
          maxTagCount="responsive"
          formOptions={{ form, name: 'reportTypes' }}
          options={
            LAYOUTVIEW === LayoutViewType.LAYOUT_1
              ? exportSC3DataFilter
              : exportSC3DataFilter.filter((sc3Data) => {
                  const excludeFromSamaAndSecurete = [
                    ReportTypes.Customer_Info,
                    ReportTypes.Company_Info,
                    ReportTypes.Subscription,
                  ];
                  return !excludeFromSamaAndSecurete.includes(sc3Data?.value);
                })
          }
          optionFilterProp="label"
          style={{ width: '100%' }}
        />
      </Form.Item>
      {isNSearchSelected && (
        <Form.Item label="Email Users" name="mails">
          <Select
            style={{ background: 'transparent !important' }}
            mode="multiple"
            variant="borderless"
            options={usersToMail}
            placeholder="Select Users to Mail"
          />
        </Form.Item>
      )}
      {isNSearchSelected && (
        <Form.Item label="Get PDF for NSearch" valuePropName="checked" name="pdfExport">
          <Checkbox style={{ marginLeft: '0.75em' }}>{pdfExport ? ' Yes' : ' No'}</Checkbox>
        </Form.Item>
      )}
      {isNSearchSelected && (
        <Form.Item label="Send Attachments" valuePropName="checked" name="sendAttachment">
          <Checkbox style={{ marginLeft: '0.75em' }}>{sendAttachment ? ' Yes' : ' No'}</Checkbox>
        </Form.Item>
      )}
    </FormComponent>
  );
};

export default ExportDataForm;
