import { Button } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { ICustomTabs } from 'src/app/components/TabLayoutComponent/tab-layout.config';
import TabLayout from 'src/app/components/TabLayoutComponent/TabLayout';
import { getPermissionKey } from 'src/app/helpers/permission.helper';
import { useGetModulesAndMachinesQuery } from 'src/app/redux/controlPanel/controlPanelSlice';
import { routes } from 'src/configs/permissions.dictionary';

const LazyRunningModulesComponent = React.lazy(() => import('./RunningModulesPage/RunningModules'));

const LazyMachinesComponent = React.lazy(() => import('./MachinesPage/Machines'));

const ControlPanelComponent = () => {
  const { data: modulesAndMachines } = useGetModulesAndMachinesQuery(
    { name: 'modules and Machines' },
    {
      pollingInterval: 5000,
    },
  );

  const modules: any = [];
  const machines: any = [];

  if (modulesAndMachines) {
    const moduleAndMachineNames: string[] = Object.keys(modulesAndMachines);
    moduleAndMachineNames.forEach((name) => {
      if (name === 'machine') {
        machines.push(...modulesAndMachines[name]);
      } else {
        modules.push(modulesAndMachines[name]);
      }
    });
  }

  const tabItems: ICustomTabs[] = [
    {
      key: '1',
      label: <Button>{t('settingsPage.controlPanel.runningModules.tabName')}</Button>,
      children: <LazyRunningModulesComponent data={modules} />,
      permission: getPermissionKey([routes['/Portal'], 'ControlPanel', 'RunningModules']),
      // extendedContents: extraTabs(rmForm),
    },
    {
      key: '2',
      label: <Button>{t('settingsPage.controlPanel.machines.tabName')}</Button>,
      children: <LazyMachinesComponent data={machines} />,
      permission: getPermissionKey([routes['/Portal'], 'ControlPanel', 'Machines']),
    },
  ];

  return <TabLayout paramName="subTab" items={tabItems} className="tabs-with-border-container" />;
};

export default ControlPanelComponent;
