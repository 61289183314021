import { AxiosPromise } from 'axios';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';
import * as servicesCustomerRegisterTypes from 'src/app/services/customerAndUserInvitations/customerAndUserInvitations.service.type';

// LookUp
export const lookupCustomerInvitations = (): // params?: servicesCustomerRegisterTypes.LookUpTroubleTicketDataType
AxiosPromise<servicesCustomerRegisterTypes.CustomerInvitationsLookUpResultType> => {
  return axios.get(urlsConfig.customerAndUserInvitations.customerInvitations);
};

export default {
  lookupCustomerInvitations,
};
