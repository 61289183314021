import styled from 'styled-components';

export const FormContainer = styled.span`
  .buttonFormItem {
    button {
      backgorund-color: red !important;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.border};
      border-top: 0.5px solid ${({ theme }) => theme.colors.secondaryText};
      border-bottom: 0.5px solid ${({ theme }) => theme.colors.secondaryText};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 30px;
      font-weight: bold;
      &:hover {
        background: transparent;
      }
    }
    .saveBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0;
        width: 95px;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
       .formSaveIcon {
            display:flex !important;
        }
    }
    .cancelBtn {
        margin-right: 20px;
        border-left: none;
        border-right: none;
    }
    }
}
.pageButton {

  .pageBtnIcon {
    background: ${({ theme }) => theme.colors.quoteBgc} ;
  }
}



`;
