import React from 'react';

import { iconType } from 'src/types';

const EditIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={
        onClick
          ? { cursor: 'pointer', display: 'flex', alignItems: 'center' }
          : { display: 'flex', alignItems: 'center' }
      }
    >
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill={color}
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
      </svg>
    </div>
  );
};

EditIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '12px' },
  color: 'var(--primary_text_color)',
  onClick: undefined,
  className: undefined,
};

export default EditIcon;
