import { Interest } from 'src/app/services/interest/interest.service.type';
import { InterestsTypes } from 'src/enums/interests.enum';
import { VolumeEnum } from 'src/enums/sc3-type.enum';
import { LookupDataType } from 'src/types/query.type';
import { User } from 'src/types/user.type';

export enum TipTierEnum {
  Tier_1 = 0,
  Tier_2 = 1,
  Tier_2_Watch_List = 2,
  Tier_3 = 3,
  Special_Case = 4,
}

export interface LocationRecognizedDataType extends Interest {
  countryId: number;
  name: string;
  alphaCode: string;
  prevalenceIndexRank: number | undefined;
  modernSlaveryScore: number | undefined;
  governmentResponse: number | undefined;
  tier: TipTierEnum | undefined;
  overallRiskLevel: VolumeEnum;
  interest: InterestsTypes;
}

export type LocationRecognizedResultType = {
  rows: LocationRecognizedDataType[];
  count: number;
};

export interface LocationIdentifiedDataType extends Interest {
  name: string;
  description?: string;
  lat: number;
  lng: number;
  createdById: string;
  createdBy: User;
  updatedById: string;
  updatedBy: User;
  address: string;
  aliases: string[];
  locationRecognizedId: string;
  locationRecognized: { id: string; name: string };

  isInterested: boolean;

  isIdentified: boolean;
}

export type LocationIdentifiedResultType = {
  rows: LocationIdentifiedDataType[];
  count: number;
};

export type LocationIdentifiedLookupDataType = LookupDataType & {
  isInterested?: boolean;
  isIdentified?: boolean;
};

export type LocationIdentifiedCreateDataType = {
  name: string;
  description?: string;
  lat: number;
  lng: number;
  address: string;
  aliases: string[];
  pictures: Blob[];
  locationRecognizedId: string;
};

export type LocationIdentifiedUpdateDataType = Partial<LocationIdentifiedCreateDataType> & {
  id: string;
  deletedPictures: string[];
};
