import styled from 'styled-components';

export const Table = styled.table`
  thead {
    th {
      border-top: 1px solid ${({ theme }) => theme.colors.quoteBgc} !important;
      border-bottom: 1px solid ${({ theme }) => theme.colors.quoteBgc} !important;
    }

    tr:not(:first-child) {
      th {
        border-top: none !important;
        border-bottom: 1px solid ${({ theme }) => theme.colors.quoteBgc} !important;
      }
    }
  }
  tbody {
    // tr:first-child {
    //   td {
    //     border-top: 1px solid ${({ theme }) => theme.colors.quoteBgc} !important;
    //     padding: 0 !important;
    //   }
    // }

    // td {
    //   border-bottom: 1px solid ${({ theme }) => theme.colors.quoteBgc} !important;
    // }

    // tr:last-child {
    //   td {
    //     border-bottom: 1px solid ${({ theme }) => theme.colors.quoteBgc} !important;
    //   }
    // }
  }
`;
