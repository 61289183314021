export enum RoutesEnum {
  '/' = '0',
  '/Network' = '1',
  '/Topic' = '2',
  '/Location' = '3',
  '/Industry' = '4',
  '/Notifications' = '5',
  '/Reports' = '6',
  '/Resources' = '7',
  '/Profile' = '8',
  '/Settings' = '9',
  '/Authentication' = '11',
  '/GoogleAuthenticationVerification' = '12',
  '/Login' = '13',
  '/ForgotPasswordDone' = '14',
  '/Students' = '17',
  '/SAQForm' = '19',
  '/HelpAndSupport' = '20',
  '/CustomerRegister' = '21',
  '/AccountType' = '22',
  '/CustomerRegisterWithCompanyDetails' = '23',
  '/Register' = '24',
  '/CompanySettings' = '25',
  '/Administration' = '27',
  '/Portal' = '28',
  '/ImportData' = '29',
  '/ExportData' = '30',
  '/Interests' = '31',
  '/NSearch' = '32',
  '/FileUpload' = '33',
}
