import { AxiosPromise } from 'axios';
import { urlHelper } from 'src/app/helpers';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';
import {
  NoteLookupResultType,
  GetNoteDataQueryType,
  TakeNoteDataType,
} from 'src/app/services/notes/notes.service.type';

export const lookup = (customerId?: string): AxiosPromise<NoteLookupResultType> => {
  const query: GetNoteDataQueryType = {};
  if (customerId) {
    query.customerId = customerId;
  }
  return axios.get(urlHelper.addQueryParameters(query, urlsConfig.notes.lookup));
};

export const create = (data: TakeNoteDataType): AxiosPromise<NoteLookupResultType> => {
  return axios.post(urlsConfig.notes.create, data);
};

// export const update = (data: updateNoteDataType): AxiosPromise<noteLookupResultType> => {
//     return axios.patch(urlsConfig.notes.create,data)
// }
export default {
  lookup,
  create,
  // update
};
