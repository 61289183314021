type LocalStorageKeysType =
  | 'token'
  | 'landingPageActiveKey'
  | 'engagementMatrixActiveKey'
  | 'notificationsMuted'
  | 'interestDetailList'
  | 'user'
  | 'theme'
  | 'all-themes'
  | 'lastLoginDate'
  | 'language'
  | 'dashboard'
  | 'users'
  | 'logo';

export const setValueWithKey = (key: LocalStorageKeysType, value: string) => {
  localStorage.removeItem(key);
  localStorage.setItem(key, value);
};

export const getValueWithKey = (key: LocalStorageKeysType) => {
  return localStorage.getItem(key);
};

export const removeKeyPair = (key: LocalStorageKeysType) => {
  localStorage.removeItem(key);
};

export const reset = () => {
  localStorage.clear();
};

export const loginReset = () => {
  removeKeyPair('token');
  removeKeyPair('user');
};
