import {
  getFontFamily,
  getInterestWhitelist,
  getMediaTypesWhitelist,
  getSensorCategoryTypesWhitelist,
  getSensorTypesWhitelist,
  isConfigDisabled,
} from 'src/app/helpers/config.helper';
import { InterestsTypes } from 'src/enums/interests.enum';
import { SensorCategoryType, SensorType } from 'src/enums/nginr-type.enum';
import { MediaType } from 'src/enums/surfer.enums';
import { LayoutViewType } from 'src/enums/user.enum';

import ConfigDataFile from 'src/configs/system-config.json';
import urlsConfig from 'src/configs/url.config';

export type SystemConfigType = {
  sensorTypes: {
    [key in keyof typeof SensorType]: boolean;
  };
  mediaTypes: {
    [key in keyof typeof MediaType]: boolean;
  };
  interests: {
    [key in keyof typeof InterestsTypes]: boolean;
  };
  webFrontendDesign: {
    Layout: LayoutViewType;
  };
  // system: {
  //   type: SystemType;
  // };
  sensorCategoryTypes: {
    [key in keyof typeof SensorCategoryType]: boolean;
  };
  sensorUrls: {
    webSiteUrls: string[];
    newsSiteUrls: string[];
    blacklistUrls: string[];
    courtRecordUrls: string[];
    socialMediaUrls: string[];
  };
  mapURL: {
    landURL: string;
    satelliteURL: string;
  };

  systemLogin: {
    active: boolean;
  };
};

const isBuildRun = window.EXECUTABLE_TYPE && window.EXECUTABLE_TYPE !== '{{EXECUTABLE_TYPE}}';
const getSystemConfig = (): SystemConfigType => {
  if (isBuildRun) {
    return {
      sensorTypes: {
        Camera: !isConfigDisabled(window.ST_CAMERA),
        Card_Reader: !isConfigDisabled(window.ST_CARD_READER),
        CB_Manual: !isConfigDisabled(window.ST_CB_MANUAL),
        Web_Manual: !isConfigDisabled(window.ST_WEB_MANUAL),
        Other: !isConfigDisabled(window.ST_OTHER),
        Web_Site: !isConfigDisabled(window.ST_WEB_SITE),
        News_Site: !isConfigDisabled(window.ST_NEWS_SITE),
        Blacklist: !isConfigDisabled(window.ST_BLACKLIST),
        Court_Record: !isConfigDisabled(window.ST_COURT_RECORD),
        Social_Media: !isConfigDisabled(window.ST_SOCIAL_MEDIA),
        Fence_Node: !isConfigDisabled(window.ST_FENCE_NODE),
      },

      sensorCategoryTypes: {
        OSS: !isConfigDisabled(window.SCT_OSS),
        File_Upload: !isConfigDisabled(window.SCT_FILE_UPLOAD),
        Streaming: !isConfigDisabled(window.SCT_STREAMING),
      },

      mediaTypes: {
        Video: !isConfigDisabled(window.MT_VIDEO),
        Image: !isConfigDisabled(window.MT_IMAGE),
        Zipped: !isConfigDisabled(window.MT_ZIPPED),
        Ones: !isConfigDisabled(window.MT_ONES),
        Siem: !isConfigDisabled(window.MT_SIEM),
        Talk: !isConfigDisabled(window.MT_TALK),
        Document: !isConfigDisabled(window.MT_DOCUMENT),
        Unknown: !isConfigDisabled(window.MT_UNKNOWN),
        Fence_Data: !isConfigDisabled(window.MT_FENCE_DATA),
        Keypad: !isConfigDisabled(window.MT_KEYPAD),
      },

      interests: {
        Communication: !isConfigDisabled(window.INT_COMMUNICATON),
        Incident: !isConfigDisabled(window.INT_INCIDENT),
        Location: !isConfigDisabled(window.INT_LOCATION),
        Object: !isConfigDisabled(window.INT_OBJECT),
        Person: !isConfigDisabled(window.INT_PERSON),
        Sensor: !isConfigDisabled(window.INT_SENSOR),
        Vehicle: !isConfigDisabled(window.INT_VEHICLE),
        Functional_Area: !isConfigDisabled(window.INT_FUNCTIONAL_AREA),
        Relation: !isConfigDisabled(window.INT_RELATION),
        Enterprise: !isConfigDisabled(window.INT_ENTERPRISE),
        Industry: !isConfigDisabled(window.INT_INDUSTRY),
        Topic: !isConfigDisabled(window.INT_TOPIC),
        Agent: !isConfigDisabled(window.INT_AGENT),
      },

      webFrontendDesign: {
        Layout: LayoutViewType[window.LAY_WEB_LAYOUT as keyof typeof LayoutViewType] ?? LayoutViewType.FULL,
      },
      // system: {
      //   type: SystemType[window.SYS_TYPE as keyof typeof SystemType] ?? SystemType.RED,
      // },

      sensorUrls: {
        blacklistUrls: [...window.BLACKLIST_URLS.split(',')],
        courtRecordUrls: [...window.COURT_RECORD_URLS.split(',')],
        newsSiteUrls: [...window.NEWS_SITE_URLS.split(',')],
        socialMediaUrls: [...window.SOCIAL_MEDIA_URLS.split(',')],
        webSiteUrls: [...window.WEB_SITE_URLS.split(',')],
      },
      mapURL: {
        landURL: window.LAND_MAP_URL,
        satelliteURL: window.SATELLITE_MAP_URL,
      },
      systemLogin: {
        active: !isConfigDisabled(window.SYS_LOGIN_ACTIVE),
      },
    };
  }
  return ConfigDataFile as SystemConfigType;
};

const systemConfig = {
  ...getSystemConfig(),
};

export const INTERESTS_WHITELIST = getInterestWhitelist(systemConfig.interests);
export const MEDIA_TYPE_WHITELIST = getMediaTypesWhitelist(systemConfig.mediaTypes);
export const SENSOR_TYPE_WHITELIST = getSensorTypesWhitelist(systemConfig.sensorTypes);
export const SENSOR_CATEGORY_TYPE_WHITELIST = getSensorCategoryTypesWhitelist(systemConfig.sensorCategoryTypes);
export const LAYOUTVIEW: LayoutViewType = systemConfig.webFrontendDesign.Layout;

export const SENSOR_URL_LIST = systemConfig.sensorUrls;

export const LAND_MAP_URL = systemConfig.mapURL.landURL;
export const SATELLITE_MAP_URL = systemConfig.mapURL.satelliteURL;
export const LOGIN_URL = systemConfig.systemLogin.active ? `${urlsConfig.systemLoginURL}/Login` : '/Login';

export const FONT_FAMILY = getFontFamily(LAYOUTVIEW);

export default systemConfig;
