import { urlHelper } from 'src/app/helpers';

import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  TopicIdentifiedCreateDataType,
  TopicIdentifiedDataType,
  TopicIdentifiedLookupDataType,
  TopicIdentifiedLookupResultType,
  TopicIdentifiedUpdateDataType,
} from 'src/app/redux/topics/topic-slice.type';
import { urlsConfig } from 'src/configs';

export const topicIdentifiedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTopicsIdentified: builder.query<TopicIdentifiedLookupResultType, TopicIdentifiedLookupDataType | void>({
      query: (params?: TopicIdentifiedLookupDataType) => ({
        url: urlsConfig.interest.identified.topic.lookup,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'TopicsIdentified' as const,
                id,
              })),
              { type: 'TopicsIdentified', id: 'LIST' },
            ]
          : [{ type: 'TopicsIdentified', id: 'LIST' }],
    }),
    createTopic: builder.mutation<TopicIdentifiedDataType, TopicIdentifiedCreateDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.topic.create,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'TopicsIdentified', id: 'LIST' }],
    }),
    updateTopic: builder.mutation<TopicIdentifiedDataType, TopicIdentifiedUpdateDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.topic.update,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, data) => [{ type: 'TopicsIdentified', id: data.id }],
    }),
    deleteTopic: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `${urlsConfig.interest.identified.topic.delete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'TopicsIdentified', id }],
    }),
    exportTopicIdentifiedExcell: builder.mutation<ArrayBuffer, { ids?: string[] }>({
      query: (data) => ({
        url: urlsConfig.exports.topic,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetTopicsIdentifiedQuery,
  useLazyGetTopicsIdentifiedQuery,
  useCreateTopicMutation,
  useUpdateTopicMutation,
  useDeleteTopicMutation,
  useExportTopicIdentifiedExcellMutation,
} = topicIdentifiedSlice;
