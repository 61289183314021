import axios, { AxiosPromise } from 'axios';
import { forIn } from 'lodash';
import { urlsConfig } from 'src/configs';
import { TLayer, TLayerForm } from 'src/app/services/layersService/layers-server.service.config';

const axiosInstance = axios.create({
  baseURL: urlsConfig.layersServerBaseURL,
});

const getFormData = (layer: TLayerForm) => {
  let media;
  let rest;

  if (layer.layerType === 'geojson') {
    ({ media, ...rest } = layer);
  } else {
    rest = layer;
  }

  const formData = new FormData();

  forIn(rest, (value, key) => {
    if (value !== undefined) {
      if (typeof value === 'boolean') {
        formData.append(key, value ? 'true' : 'false');
      } else if (key === 'geometryColor') {
        // @ts-ignore
        formData.append(key, value.hex || value);
      } else {
        formData.append(key, value);
      }
    }
  });

  if (media) {
    formData.append('media', media[0]?.originFileObj);
  }

  return formData;
};

export const getLayers = (): AxiosPromise<TLayer[]> => {
  return axiosInstance.get(urlsConfig.layersServer);
};

export const createLayer = (layer: TLayerForm): AxiosPromise<TLayer> => {
  const formData = getFormData(layer);
  return axiosInstance.post(urlsConfig.layersServer, formData);
};

export const updateLayer = (layer: TLayerForm): AxiosPromise<TLayer> => {
  const formData = getFormData(layer);
  return axiosInstance.patch(urlsConfig.layersServer, formData);
};

export const deleteLayer = (name: string): AxiosPromise<TLayer[]> => {
  return axiosInstance.delete(`${urlsConfig.layersServer}/${name}`);
};
