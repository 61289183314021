import React from 'react';

import { iconType } from 'src/types';

const DownloadIconPlain: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex' }}
    >
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill={color || 'var(--primary_text_color)'}
      >
        <g>
          <rect fill="none" height="24" width="24" />
        </g>
        <g>
          <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
        </g>
      </svg>
    </div>
  );
};

DownloadIconPlain.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '12px' },
  // color: 'var(--primary-color)',
  onClick: undefined,
  className: undefined,
};

export default DownloadIconPlain;
