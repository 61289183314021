import { AxiosPromise } from 'axios';
import { urlHelper } from 'src/app/helpers';
import { RegisteredStatusType } from 'src/enums/sc3-type.enum';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';
import * as serviceCustomerTypes from 'src/app/services/customer/customer-service.type';

export const register = (
  customerRegisterInformation: serviceCustomerTypes.CustomerRegisterDataType,
  options: { blockErrorHandler: boolean },
): AxiosPromise<serviceCustomerTypes.CustomerRegisterResultType> => {
  return axios.post(urlsConfig.customer.register, customerRegisterInformation, options);
};

export const customerRegister = (
  customerRegisterInformation: serviceCustomerTypes.RegisterDataType,
  options: { blockErrorHandler: boolean },
): AxiosPromise<serviceCustomerTypes.CustomerRegisterResultType> => {
  return axios.post(urlsConfig.customer.customerRegister, customerRegisterInformation, options);
};

export const customerMultipleAccountRegister = (
  customerRegisterInformation: serviceCustomerTypes.CustomerMultipleAccountRegisterDataType,
  options: { blockErrorHandler: boolean },
): AxiosPromise<serviceCustomerTypes.CustomerMultipleAccuontRegisterResultType> => {
  return axios.post(urlsConfig.customer.customerMultipleAccountRegister, customerRegisterInformation, options);
};

export const registerInvitation = (
  customerRegisterInvitationInformation: serviceCustomerTypes.CustomerRegisterInvitationDataType,
  options: { blockErrorHandler: boolean },
): AxiosPromise<serviceCustomerTypes.CustomerRegisterInvitationResultType> => {
  return axios.post(urlsConfig.customer.registerInvitation, customerRegisterInvitationInformation, options);
};

export const lookup = (
  isFirstCompany?: boolean,
  ids?: string,
): AxiosPromise<serviceCustomerTypes.CustomerLookupResultType> => {
  const query: serviceCustomerTypes.GetCustomersDataQueryType = {};
  if (isFirstCompany) {
    query.isFirstCompany = isFirstCompany ? 'true' : 'false';
  }
  if (ids) {
    query.ids = ids;
  }
  return axios.get(urlHelper.addQueryParameters(query, urlsConfig.customer.lookup));
};

export const customersOfUser = (
  data: serviceCustomerTypes.CustomersOfUserDataType,
  options?: { blockErrorHandler: boolean },
): AxiosPromise<serviceCustomerTypes.CustomersOfUserResultType> => {
  return axios.post(urlsConfig.customer.customersOfUser, data, options);
};

export const customerAccountTypesLookup = (options: {
  blockSpinner: boolean;
}): AxiosPromise<serviceCustomerTypes.CustomerAccountTypesLookupResultType> => {
  return axios.get(urlsConfig.customer.customerAccountTypes, options);
};

export const verifyRegisterSignature = (data: { token: string }): AxiosPromise<RegisteredStatusType> => {
  return axios.post(urlsConfig.customer.verifyRegisterToken, data);
};

export const updateCustomer = (
  data: serviceCustomerTypes.UpdateCustomerDataType,
): AxiosPromise<serviceCustomerTypes.CustomerResultType> => {
  return axios.patch(urlsConfig.customer.update, data);
};

export const deleteCustomer = (id: string): AxiosPromise<any> => {
  return axios.delete(`${urlsConfig.customer.delete}/${id}`);
};
export default {
  registerInvitation,
  lookup,
  updateCustomer,
  deleteCustomer,
};
