import { t } from 'i18next';
import moment from 'moment';
import { LanguageType } from 'src/types/language.type';

export const formatDate = (date: Date | string | number | undefined): string => {
  if (!date) return '';
  const d = new Date(date);

  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  let hours = d.getHours().toString();
  let minutes = d.getMinutes().toString();

  if (hours.length < 2) hours = `0${hours}`;
  if (minutes.length < 2) minutes = `0${minutes}`;

  return `${day}/${month}/${year} - ${hours}:${minutes}`;
};

export const convertTimeToCalendarTexts = (time?: string) => {
  const calendarKeys: {
    [key in keyof LanguageType['momentCalendar']]: string;
  } = {
    sameDay: '[sameDay]',
    lastDay: '[lastDay]',
  };

  const momentCalendarKey = moment(time).calendar(calendarKeys) as keyof LanguageType['momentCalendar'];
  // const momentCalendarKey = moment(time).calendar(calendarKeys);

  if (momentCalendarKey === 'lastDay' || momentCalendarKey === 'sameDay') {
    return t(`momentCalendar.${momentCalendarKey}`);
  }

  return moment(time).format('DD/MM/YYYY');
};

export const compareDates = (first: string, second: string) => {
  if (!first) {
    return -1;
  }
  if (!second) {
    return 1;
  }
  // return moment(first, "DD/MM/YYYY HH:mm").valueOf() - moment(second, "DD/MM/YYYY HH:mm").valueOf();

  return moment(first, 'DD/MM/YYYY HH:mm').diff(moment(second, 'DD/MM/YYYY HH:mm'));
};
