import { urlHelper } from 'src/app/helpers';
import { getFormData } from 'src/app/helpers/form.helper';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  PersonIdentifiedCreateDataType,
  PersonIdentifiedData,
  PersonIdentifiedLookupDataType,
  PersonIdentifiedLookupResultType,
  PersonIdentifiedUpdateDataType,
  PersonImportDataType,
} from 'src/app/redux/interests/persons/person-slice.type';
import { urlsConfig } from 'src/configs';
import { MutationType } from 'src/enums/operation.enum';

export const personIdentifiedslice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPersonsIdentified: builder.query<PersonIdentifiedLookupResultType, PersonIdentifiedLookupDataType | void>({
      query: (params?: PersonIdentifiedLookupDataType) => ({
        url: urlsConfig.interest.identified.person.lookup,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'PersonIdentified' as const,
                id,
              })),
              { type: 'PersonIdentified', id: 'LIST' },
            ]
          : [{ type: 'PersonIdentified', id: 'LIST' }],
    }),
    createPerson: builder.mutation<PersonIdentifiedData, PersonIdentifiedCreateDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.person.create,
        method: 'POST',
        body: getFormData(data, ['pictures'], MutationType.CREATE),
      }),
      invalidatesTags: [
        { type: 'PersonIdentified', id: 'LIST' },
        { type: 'Interests', id: 'LIST' },
      ],
    }),
    updatePerson: builder.mutation<PersonIdentifiedData, PersonIdentifiedUpdateDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.person.update,
        body: getFormData(data, ['pictures']),
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, data) => [{ type: 'PersonIdentified', id: data.id }],
    }),
    deletePerson: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `${urlsConfig.interest.identified.person.delete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: 'PersonIdentified', id },
        { type: 'Interests', id: 'LIST' },
      ],
    }),
    exportPersonIdentifiedExcell: builder.mutation<ArrayBuffer, { ids?: string[] }>({
      query: (data) => ({
        url: urlsConfig.exports.person,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
    importPerson: builder.mutation<void, PersonImportDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.person.import,
        method: 'POST',
        body: getFormData(data, ['file']),
      }),
      invalidatesTags: [{ type: 'PersonIdentified', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetPersonsIdentifiedQuery,
  useCreatePersonMutation,
  useDeletePersonMutation,
  useUpdatePersonMutation,
  useExportPersonIdentifiedExcellMutation,
  useLazyGetPersonsIdentifiedQuery,
  useImportPersonMutation,
} = personIdentifiedslice;
