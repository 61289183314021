import { urlHelper } from 'src/app/helpers';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  AccountTypeLookupDataType,
  AccountTypeLookupResultType,
  CustomerDataType,
  CustomerLookupDataType,
  CustomerUpdateAccountType,
} from 'src/app/redux/customers/customers-slice.type';
import { CustomerLookupResultType } from 'src/app/services/customer/customer-service.type';
import { urlsConfig } from 'src/configs';

export const customersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<CustomerLookupResultType, CustomerLookupDataType | void>({
      query: (params?: CustomerLookupDataType) => ({
        url: urlsConfig.customer.lookup,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'Customers' as const,
                id,
              })),
              { type: 'Customers', id: 'LIST' },
            ]
          : [{ type: 'Customers', id: 'LIST' }],
    }),
    getAccountTypes: builder.query<AccountTypeLookupResultType, AccountTypeLookupDataType | void>({
      query: (params?: AccountTypeLookupDataType) => ({
        url: urlsConfig.customerAccountTypes.lookup,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'AccountTypes' as const,
                id,
              })),
              { type: 'AccountTypes', id: 'LIST' },
            ]
          : [{ type: 'AccountTypes', id: 'LIST' }],
    }),
    updateAccountType: builder.mutation<CustomerDataType, CustomerUpdateAccountType>({
      query: (data) => ({
        url: urlsConfig.customer.update,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [{ type: 'Customers', id: data.id }],
    }),
    updateCustomer: builder.mutation<any, any>({
      query: (data) => ({
        url: urlsConfig.customer.update,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [{ type: 'Customers', id: data.id }],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useGetAccountTypesQuery,
  useUpdateAccountTypeMutation,
  useUpdateCustomerMutation,
} = customersSlice;
