import { getEnumSelectOptions } from 'src/app/helpers/enum.helper';
import { DeliveryStatusType, RegisteredStatusType } from 'src/enums/sc3-type.enum';

export const enumToFilterObj = (enumObj: any) => {
  const filterObj = getEnumSelectOptions(enumObj)?.map((field) => ({
    text: field?.label as string,
    value: field?.label as string,
  }));
  return filterObj;
};

export const deliveryStatusFilterArr = enumToFilterObj(DeliveryStatusType);
export const registerStatusFilterArr = enumToFilterObj(RegisteredStatusType);
