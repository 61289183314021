import { Checkbox, Tooltip } from 'antd';
import { t } from 'i18next';
import moment from 'moment';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/redux/hook';

import {
  checkNotification,
  resetCheckedNotifications,
  setSelectedNotification,
  uncheckNotification,
  updateNotifications,
} from 'src/app/redux/notifications/notificationsSlice';
import { NotificationResultType } from 'src/app/services/notifications/notifications-service.type';
import { NotificationReadStatusEnum, NotificationStarStatusEnum } from 'src/enums/notifications.enum';
import LabelImportantIcon from 'src/icons/LabelImportantIcon';
import StarFillIcon from 'src/icons/StarFillIcon';
import StarIcon from 'src/icons/StarIcon';
import { LabelType } from 'src/types/labels.type';
import { convertShowSettingsLabel } from 'src/app/pages/SettingsPage/NotificationSettingsMain/NotificationSettings/notification-settings.config';
import NotificationThreeDot from 'src/app/pages/NotificationsPage/Dropdowns/NotificationThreeDot';
import { getIconColorAndTextWithPriorityNumber } from 'src/app/pages/NotificationsPage/notification-list.config';
import styles from 'src/app/pages/NotificationsPage/NotificationsPage.module.scss';

interface INotificationContentProps {
  item: NotificationResultType;
  isStatic?: boolean;
  labels: LabelType[];
}

export const NotificationContent: React.FC<INotificationContentProps> = ({ item, isStatic, labels }) => {
  const dispatch = useAppDispatch();

  const { checkedNotifications, selectedNotification } = useAppSelector((state) => state.notificationsState);

  const { userLabelShow, systemLabelShow } = useAppSelector((state) => state.user);

  const onRowClick = async (value: NotificationResultType) => {
    if (value.readStatus === NotificationReadStatusEnum.Unread) {
      await dispatch(
        updateNotifications({
          ids: [value.id],
          readStatus: NotificationReadStatusEnum.Read,
        }),
      ).unwrap();
    }
    dispatch(setSelectedNotification(value));
    dispatch(resetCheckedNotifications());
    dispatch(checkNotification(value.id));
  };

  return (
    <div
      className={styles.notificationRow}
      style={
        selectedNotification?.id === item.id && !isStatic
          ? { backgroundColor: 'var(--notification_mail_static_bg)' }
          : item.readStatus
            ? { backgroundColor: 'var(--page_content_bg)' }
            : undefined
      }
      onClick={() => onRowClick(item)}
      key={item.id}
    >
      <div className={styles.checkNotification}>
        {!isStatic ? (
          <Checkbox
            checked={checkedNotifications.includes(item.id)}
            onChange={(e) => {
              const { checked } = e.target;
              if (checked) dispatch(checkNotification(item.id));
              else dispatch(uncheckNotification(item.id));
            }}
            onClick={(e) => e.stopPropagation()}
          />
        ) : null}
        <p className={styles.notificationTime}>{moment(item.createdAt).format('HH:mm')}</p>
      </div>
      <div className={styles.rowIcons}>
        <Tooltip title={getIconColorAndTextWithPriorityNumber(item.label, labels).title}>
          <div className={styles.iconContainer}>
            <LabelImportantIcon
              className={styles.labelImportantIcon}
              color={getIconColorAndTextWithPriorityNumber(item.label, labels).color}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </Tooltip>
        <Tooltip
          title={
            item.starStatus === NotificationStarStatusEnum.Star
              ? t('notificationsPage.markUnStarTitle')
              : t('notificationsPage.markStarTitle')
          }
        >
          <div
            className={styles.iconContainer}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                updateNotifications({
                  ids: [item.id],
                  starStatus: Number(!item.starStatus),
                }),
              );
            }}
          >
            {item.starStatus === NotificationStarStatusEnum.Star ? (
              <StarFillIcon color="#ffcd3c" className={styles.starIcon} />
            ) : (
              <StarIcon className={styles.starIcon} />
            )}
          </div>
        </Tooltip>
      </div>
      <div className={styles.notificationContent}>
        <div className={styles.subjectContainer} style={item.readStatus ? { color: 'rgb(128, 128, 128)' } : undefined}>
          <div className={styles.title}>{item.subject}</div>
          <div className={styles.newsTitle}>{item.content}</div>
        </div>

        {convertShowSettingsLabel({
          list: item.label,
          systemLabelShow,
          userLabelShow,
        }).map((obj) => {
          return (
            <Tooltip title={obj.name} key={obj.id}>
              <div className={styles.labelTag} style={{ backgroundColor: obj.backgroundColor }}>
                {obj.name.charAt(0)}
              </div>
            </Tooltip>
          );
        })}
      </div>

      <NotificationThreeDot notification={item} />
    </div>
  );
};
