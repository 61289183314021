import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { storageHelper } from 'src/app/helpers';
import { ContentStateType } from 'src/app/redux/configContent/configContent.type';
import { light } from 'src/theme/Theme.styled';
import { ThemeType } from 'src/types/configContent.type';

export type ConfigContentStateType = {
  // buraya daha sonra language eklenecek
  theme: ThemeType;
  contentState?: ContentStateType;
  logo: string;
};

const localStorageTheme = storageHelper.getValueWithKey('theme');
const theme = localStorageTheme ? JSON.parse(localStorageTheme) : light;
const logo = storageHelper.getValueWithKey('logo') || '';

const currentTheme: ThemeType = theme; // findTheme(theme);

const initialState: ConfigContentStateType = {
  theme: currentTheme || light,
  logo,
};

export const configContentSlice = createSlice({
  name: 'configContent',
  initialState,
  reducers: {
    getTheme: (state: ConfigContentStateType) => {
      state.theme = currentTheme;
    },
    reset: (state: ConfigContentStateType) => {
      state.theme = light;
    },
    changeLogo: (state, action: PayloadAction<string>) => {
      state.logo = action.payload;
    },
    changeTheme: (state: ConfigContentStateType, action: PayloadAction<Omit<ThemeType, 'language'>>) => {
      state.theme.colors = action.payload.colors;
      state.theme.name = action.payload.name;
    },
    // changeLanguage: (state, action: PayloadAction<LanguagePreferenceEnum>) => {
    //   state.theme.language = action.payload;
    // },
  },
});
export const { reset, changeTheme, changeLogo } = configContentSlice.actions;

export default configContentSlice.reducer;
