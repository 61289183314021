import React from 'react';

import { iconType } from 'src/types';

const SearchIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick && { cursor: 'pointer' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
        {circleColor && (
          <circle id="Ellipse_56" data-name="Ellipse 56" cx="15.5" cy="15.5" r="15.5" fill={circleColor} />
        )}
        <g id="Group_39501" data-name="Group 39501" transform="translate(9.654 4.827)">
          <g id="Group_39500" data-name="Group 39500">
            <path
              id="Path_15287"
              data-name="Path 15287"
              d="M176.332,87.137a6.1,6.1,0,0,0-4.344-1.8,1.316,1.316,0,1,0,0,2.633,3.51,3.51,0,0,1,3.511,3.51,1.316,1.316,0,0,0,1.316,1.316h0a1.316,1.316,0,0,0,1.316-1.316A6.1,6.1,0,0,0,176.332,87.137Z"
              transform="translate(-170.671 -85.338)"
              fill={color || '#92aabb'}
            />
          </g>
        </g>
        <g id="Group_39503" data-name="Group 39503">
          <g id="Group_39502" data-name="Group 39502">
            <path
              id="Path_15288"
              data-name="Path 15288"
              d="M28.575,26.713,19.6,17.737A10.965,10.965,0,0,0,10.97,0h0a10.97,10.97,0,0,0,0,21.94A10.916,10.916,0,0,0,17.737,19.6l8.976,8.976a1.316,1.316,0,0,0,1.862-1.862Zm-17.6-7.405a8.337,8.337,0,1,1,8.338-8.337A8.347,8.347,0,0,1,10.97,19.308Z"
              transform="translate(0)"
              fill={color || '#92aabb'}
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

SearchIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default SearchIcon;
