import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { setAnyPendingRequest } from 'src/app/redux/api/apiSlice';
import { axiosInstance, dfmAxios } from 'src/app/utils/axios.instance';

declare module 'axios' {
  export interface AxiosRequestConfig {
    blockSpinner?: boolean;
    blockErrorHandler?: boolean;
  }
}

const { CancelToken } = axios;

/** Configures axios for progress spinner and global error handling */
// const configureInterceptors = (
//   setAnyPendingRequest: (value: boolean) => void,
//   handleResponseErrors: (error: AxiosError) => boolean
// ) => {
//   let pendingRequestsNumber = 0;

//   axiosInstance.interceptors.request.use(
//     (config: InternalAxiosRequestConfig & { blockSpinner?: boolean }) => {
//       pendingRequestsNumber += 1;
//       if (!config.blockSpinner) {
//         setAnyPendingRequest(true);
//       }

//       return config;
//     },
//     (error: AxiosError) => {
//       return Promise.reject(error);
//     }
//   );

//   axiosInstance.interceptors.response.use(
//     (response: AxiosResponse) => {
//       pendingRequestsNumber -= 1;
//       if (pendingRequestsNumber === 0) setAnyPendingRequest(false);
//       console.log("response", response);
//       return response;
//     },
//     (error: AxiosError) => {
//       pendingRequestsNumber -= 1;
//       if (pendingRequestsNumber === 0) setAnyPendingRequest(false);
//       const handled = handleResponseErrors(error);

//       // if (handled) {
//       //   return Promise.reject(
//       //     errorHelper.handleResponseErrors("errorHandled", error, "errorHandled", "errorHandled")
//       //   );
//       // }
//       return Promise.reject(error);
//     }
//   );
// };

const configureInterceptors = (
  // setAnyPendingRequest: (value: boolean) => void,
  dispatch: any,
  handleResponseErrors: (error: AxiosError) => boolean,
) => {
  [axiosInstance, dfmAxios].forEach((instance) => {
    instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        // if (!config.flag) {
        //   pendingRequestsNumber += 1;
        // }

        // if (!config.blockSpinner) {
        //   setAnyPendingRequest(true);
        // }
        if (!config.blockSpinner) dispatch(setAnyPendingRequest(true));
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      },
    );

    instance.interceptors.response.use(
      (response: AxiosResponse) => {
        dispatch(setAnyPendingRequest(false));

        return response;
        // if (!response.config) {
        //   pendingRequestsNumber -= 1;
        //   if (pendingRequestsNumber === 0) setAnyPendingRequest(false);
        // }
        // setAnyPendingRequest(false);
        // return response;
      },
      (error: AxiosError) => {
        dispatch(setAnyPendingRequest(false));
        // pendingRequestsNumber -= 1;
        // if (pendingRequestsNumber === 0) setAnyPendingRequest(false);
        const handled = handleResponseErrors(error);

        if (handled) return Promise.reject(new Error('Error Handled'));

        return Promise.reject(error);
      },
    );
  });
};

export default {
  configureInterceptors,
  CancelToken,
};
