import { Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styles from 'src/app/pages/PortalPage/CustomerAndUserPage/TakeNoteModalContent/TakeNoteModalContent.module.scss';
import notesService from 'src/app/services/notes/notes.service';
import { NoteType } from 'src/types/notes.type';

const { TextArea } = Input;
type TakeNoteProps = {
  customerId: string;
  sendNote: (e: any, defaultNote: string) => void;
};
const TakeNoteModalContent: React.FC<TakeNoteProps> = ({ customerId, sendNote }) => {
  const [note, setNote] = useState<NoteType>({
    id: '',
    customerId: '',
    note: '',
  });

  const defaultNoteRef = useRef('');
  const getNote = async () => {
    try {
      const { data } = await notesService.lookup(customerId);
      if (data.rows[0]) {
        setNote(data.rows[0]);
        defaultNoteRef.current = data.rows[0].note;
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    sendNote(note, defaultNoteRef.current);
  }, [note]);

  useEffect(() => {
    getNote();
  }, []);

  return (
    <div className={styles.companyIcons}>
      <div className={styles.textArea}>
        <TextArea
          style={{ height: '30rem', resize: 'none' }}
          placeholder="Type something..."
          maxLength={2000}
          variant="borderless"
          showCount
          value={note?.note}
          onChange={(e) => {
            setNote((prev) => ({ ...prev, note: e.target.value }));
          }}
        />
      </div>
    </div>
  );
};

export default TakeNoteModalContent;
