import React, { useEffect, useState } from 'react';
import TableComponent from 'src/app/components/TableComponent/TableComponent';
import {
  SubscriptionInfoTableDataType,
  convertSubsJSONtoTableValue,
} from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyDetailsModal/SubscriptionInfoModalContent/subscriptionInfoModalContent.config';
import { SubscriptionFromLogger } from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyDetailsModal/companyDetails-modal.config';

type SubscriptionInfoProps = {
  subscriptionInfo: SubscriptionFromLogger;
};
const SubscriptionInfoModalContent: React.FC<SubscriptionInfoProps> = ({ subscriptionInfo }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionFormInfoList, setSubscriptionFormInfoList] = useState<SubscriptionInfoTableDataType[]>([]);
  const getLogs = async () => {
    try {
      const resultData = convertSubsJSONtoTableValue(subscriptionInfo);
      setLoading(false);
      if (resultData) {
        setSubscriptionFormInfoList(resultData);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    getLogs();
  }, []);

  const columns = [
    {
      title: 'From Subscription Type Level',
      dataIndex: 'fromSubscriptionType',
      key: 'fromSubscriptionType',
    },
    {
      title: 'To Subscription Type Level(Current)',
      dataIndex: 'toSubscriptionType',
      key: 'toSubscriptionType',
    },
    {
      title: 'Operation Type',
      dataIndex: 'operationType',
      key: 'operationType',
      width: '25%',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '25%',
    },
  ];

  return (
    <TableComponent
      loading={loading}
      dataSource={subscriptionFormInfoList}
      columns={columns}
      extraContentWidth={400}
      scroll={{ y: 'calc(100vh - 48rem)' }}
      isModalTable
    />
  );
};

export default SubscriptionInfoModalContent;
