export enum MediaType {
  Video = 0,
  Image = 1,
  Zipped = 2,
  Ones = 3,
  Siem = 4,
  Talk = 5,
  Document = 6,
  Unknown = 7,
  Fence_Data = 8,
  Keypad = 9,
}

export enum NetworkQuery {
  Individual = 'individual',
  Social = 'social',

  Augmented = 'augmented',
}

export enum BlockStatisticStatus {
  POSITIVE,
  NEGATIVE,
  IRRELEVANT,
}
export enum Degrees {
  First = 'first',
  Second = 'second',
  Third = 'third',
}
