import React from 'react';

import { iconType } from 'src/types';

const SaveIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <path
          id="Rectangle_14939"
          data-name="Rectangle 14939"
          d="M5,0H30a0,0,0,0,1,0,0V30a0,0,0,0,1,0,0H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
          fill={color || '#92aabb'}
        />
        <g id="diskette" transform="translate(4.342 4.268)">
          <g id="Group_40567" data-name="Group 40567" transform="translate(0 0)">
            <g id="Group_40566" data-name="Group 40566">
              <path
                id="Path_37352"
                data-name="Path 37352"
                d="M151.637,303.273h7.64a.637.637,0,0,0,0-1.273h-7.64a.637.637,0,1,0,0,1.273Z"
                transform="translate(-144.591 -289.181)"
                fill="#fff"
              />
              <path
                id="Path_37353"
                data-name="Path 37353"
                d="M151.637,363.273h7.64a.637.637,0,0,0,0-1.273h-7.64a.637.637,0,1,0,0,1.273Z"
                transform="translate(-144.591 -346.635)"
                fill="#fff"
              />
              <path
                id="Path_37354"
                data-name="Path 37354"
                d="M21.546,3.582l-3.4-3.4A.636.636,0,0,0,17.7,0H.637A.637.637,0,0,0,0,.637V21.1a.637.637,0,0,0,.637.637H21.1a.637.637,0,0,0,.637-.637V4.032A.637.637,0,0,0,21.546,3.582ZM5.136,1.273H14.05V5.518H5.136ZM16.6,20.459H5.136V11.545H16.6Zm3.863,0H17.87v-9.55a.637.637,0,0,0-.637-.637H4.5a.637.637,0,0,0-.637.637v9.55H1.273V1.273H3.863V6.155a.637.637,0,0,0,.637.637H14.686a.637.637,0,0,0,.637-.637V1.273h2.113L20.459,4.3Z"
                transform="translate(0 0)"
                fill="#fff"
              />
              <path
                id="Path_37355"
                data-name="Path 37355"
                d="M151.637,423.273h7.64a.637.637,0,1,0,0-1.273h-7.64a.637.637,0,1,0,0,1.273Z"
                transform="translate(-144.591 -404.088)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

SaveIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default SaveIcon;
