import React from 'react';

import { iconType } from 'src/types';

const BlockEnterpriseIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <span
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10.5" cy="11.2471" r="10.5" fill={color} />
        <path d="M9.51367 11.522H11.3041V12.133H9.51367V11.522Z" fill="white" />
        <path
          d="M13.5945 12.1014H11.9085V12.3893C11.9085 12.5483 11.7744 12.6771 11.6087 12.6771H9.2109C9.04527 12.6771 8.91117 12.5483 8.91117 12.3893V12.1014H7.22511C6.83757 12.1014 6.49483 11.8642 6.37213 11.5112L5.29443 8.40625V14.6917C5.29443 15.1678 5.69782 15.5551 6.19362 15.5551H14.626C15.1218 15.5551 15.5252 15.1678 15.5252 14.6917V8.40647L14.4474 11.5112C14.3248 11.8642 13.9821 12.1014 13.5945 12.1014Z"
          fill="white"
        />
        <path
          d="M11.6104 6.93945H9.21045C8.7142 6.93945 8.31046 7.32492 8.31046 7.79869V8.0851H5.80664L6.93812 11.3262C6.97913 11.4434 7.09366 11.522 7.22288 11.522H8.91045V11.2356C8.91045 11.0774 9.04467 10.9492 9.21045 10.9492H11.6104C11.7762 10.9492 11.9104 11.0774 11.9104 11.2356V11.522H13.598C13.7272 11.522 13.8418 11.4434 13.8828 11.3262L15.0143 8.0851H12.5104V7.79869C12.5104 7.32492 12.1067 6.93945 11.6104 6.93945ZM8.91045 8.0851V7.79869C8.91045 7.64064 9.0449 7.51228 9.21045 7.51228H11.6104C11.776 7.51228 11.9104 7.64064 11.9104 7.79869V8.0851H8.91045Z"
          fill="white"
        />
      </svg>
    </span>
  );
};

BlockEnterpriseIcon.defaultProps = {
  style: { width: '30px', height: '30px', marginRight: '8px' },
  color: 'var(--primary-color)',
  onClick: undefined,
  className: undefined,
};

export default BlockEnterpriseIcon;
