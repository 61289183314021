import dayjs from 'dayjs';
import { isEqual, memoize } from 'lodash';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as VariableList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { timeHelper } from 'src/app/helpers';
import { NotificationContent } from 'src/app/pages/NotificationsPage/NotificationContent';
import styles from 'src/app/pages/NotificationsPage/NotificationsPage.module.scss';
import { defaultPageSize } from 'src/app/pages/NotificationsPage/notification-list.config';
import { useAppDispatch, useAppSelector } from 'src/app/redux/hook';
import { useGetLabelsQuery } from 'src/app/redux/labels/labelSlice';
import {
  fetchNotifications,
  NotificationMetadata,
  setSelectedNotification,
} from 'src/app/redux/notifications/notificationsSlice';
import { NotificationResultType } from 'src/app/services/notifications/notifications-service.type';
import NoDataIcon from 'src/icons/NoDataIcon';

const NotificationRow = memo(({ index, style, data }: any) => {
  const item = data.items[index];
  if (!item) return null;

  if (typeof item === 'string')
    return (
      <div style={style}>
        <div className={styles.notificationsContainer}>
          <span className={styles.notificationTitle}>{timeHelper.convertTimeToCalendarTexts(item)}</span>
          <div className={styles.titleBorder} />
        </div>
      </div>
    );
  return (
    <div style={style}>
      <NotificationContent key={item.id} item={item} isStatic={data?.props?.isStatic} labels={data?.props?.labels} />
    </div>
  );
}, isEqual);

NotificationRow.displayName = 'NotificationRow';

interface INotificationsProps {
  dataSource: Partial<NotificationMetadata> & {
    data: NotificationResultType[];
  };
  isStatic?: boolean;
}
const Notifications: React.FC<INotificationsProps> = ({ dataSource, isStatic }) => {
  const listRef = useRef<any>();
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector((state) => state.configContent);

  const { data: labelList } = useGetLabelsQuery();

  const notificationsWithDates = useMemo(() => {
    const result: (string | NotificationResultType)[] = [];
    const sortedData = dataSource.data
      .slice()
      .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf());

    sortedData.forEach((not, idx) => {
      const date = dayjs(not.createdAt).format('YYYY-MM-DD');

      if (idx === 0) {
        result.push(date);
        result.push(not);
      } else {
        const lastDate = dayjs(sortedData[idx - 1].createdAt).format('YYYY-MM-DD');

        if (lastDate !== date) {
          result.push(date);
        }
        result.push(not);
      }
    });

    return result;
  }, [dataSource.data]);

  useEffect(() => {
    return () => {
      if (!isStatic) dispatch(setSelectedNotification(undefined));
    };
  }, []);

  useEffect(() => {
    if (listRef.current) listRef.current?._listRef.resetAfterIndex(0);
  }, [listRef, notificationsWithDates]);

  if (!dataSource.data.length)
    return (
      <div className={styles.noDataIcon}>
        <NoDataIcon color={theme.colors.quoteBgc} />
      </div>
    );

  const isItemLoaded = (idx: number) => {
    return !dataSource.hasMore || notificationsWithDates.length - 1 > idx;
  };

  const getItemSize = (idx: number) => {
    const foundData = notificationsWithDates[idx];
    const nextData = notificationsWithDates[idx + 1];

    if (!foundData) return 50;

    if (typeof foundData === 'string') return 30;

    if (typeof nextData === 'string') return 60;
    return 50;
  };

  const createItemData = memoize((items, props) => ({
    items,
    props,
  }));

  const itemData = createItemData(notificationsWithDates, { isStatic, labels: labelList?.rows || [] });

  return (
    <AutoSizer>
      {({ height, width }: any) => {
        return (
          <InfiniteLoader
            ref={listRef}
            isItemLoaded={isItemLoaded}
            itemCount={dataSource.hasMore ? notificationsWithDates.length + 1 : notificationsWithDates.length}
            loadMoreItems={() => {
              if (dataSource.query)
                dispatch(
                  fetchNotifications({
                    ...dataSource.query,
                    offset: dataSource.query.offset + defaultPageSize,
                  }),
                );
            }}
            threshold={1}
          >
            {({ onItemsRendered, ref }) => (
              <VariableList
                height={isStatic ? height : height - 45}
                itemCount={dataSource.hasMore ? notificationsWithDates.length + 1 : notificationsWithDates.length}
                width={width}
                ref={ref}
                itemSize={getItemSize}
                // itemData={notificationsWithDates}
                itemData={itemData}
                onItemsRendered={onItemsRendered}
              >
                {NotificationRow}
              </VariableList>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
};

export default React.memo(Notifications);
