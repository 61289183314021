import { CancelToken } from 'axios';
import { ErrorType, ErrorsType } from 'src/app/components/ImportDataComponent/import-data-component.config';
import { EnterpriseInterestLookupResultType } from 'src/app/services/interest/interest.service.type';

export type StorageParams = {
  path?: string;
  productName?: string;
};

export const formDataHeader = {
  'Content-Type': 'multipart/form-data',
};

export type FileUploadDataType = {
  excelFile: File;
  userId?: string;
  customerId?: string;
  // onProgress: (progressEvent: ProgressEvent) => void;
  cancelToken: CancelToken;
  blockErrorHandler: boolean;
};

export type FileUploadResultType = {
  companiesErrors: ErrorsType;
  interactionsErrors: ErrorsType;
  blacklistedCompanies: {
    company: EnterpriseInterestLookupResultType['rows'];
    toInterestId: string;
    relationTypeId: string;
    volume?: string;
  }[];
};
export type BlackListFileUploadResultType = {
  blacklistsErrors: ErrorType;
};
