import React from 'react';

import { ProgressSpinnerPropsType } from 'src/app/components/ProgressSpinner/progress-spinner.config';

import styles from 'src/app/components/ProgressSpinner/ProgressSpinner.module.scss';

const ProgressSpinner: React.FC<ProgressSpinnerPropsType> = ({ anyPendingRequest }) => {
  const progressText = 'Loading';
  return anyPendingRequest ? (
    <div className={styles.container}>
      <svg xmlns="http://www.w3.org/2000/svg" width="230.836" height="230.825" viewBox="0 0 230.836 230.825">
        <g id="Group_40235" data-name="Group 40235" transform="translate(-1366 -4245)">
          <g className={styles.loader} id="loader" transform="translate(1366 4245)" opacity="0.582">
            <path
              id="Path_36730"
              data-name="Path 36730"
              d="M1145.477,282.373l33.293-19.218a115.274,115.274,0,0,0-18.259-23.946l-27.2,27.2A77.587,77.587,0,0,1,1145.477,282.373Z"
              transform="translate(-963.487 -205.404)"
            />
            <path
              id="Path_36734"
              data-name="Path 36734"
              d="M1164.447,323.161l37.129-9.948a114.467,114.467,0,0,0-11.5-27.813l-33.288,19.218A76.372,76.372,0,0,1,1164.447,323.161Z"
              transform="translate(-974.794 -227.648)"
            />
            <path
              id="Path_36735"
              data-name="Path 36735"
              d="M1174.27,368.9h38.493a115.465,115.465,0,0,0-4.033-29.845L1171.6,349a76.839,76.839,0,0,1,2.67,19.9Z"
              transform="translate(-981.927 -253.485)"
            />
            <path
              id="Path_36729"
              data-name="Path 36729"
              d="M1171.56,416.517l37.129,9.948a115.4,115.4,0,0,0,4.033-29.845h-38.472A76.807,76.807,0,0,1,1171.56,416.517Z"
              transform="translate(-981.907 -281.21)"
            />
            <path
              id="Path_36724"
              data-name="Path 36724"
              d="M1164.442,435a76.669,76.669,0,0,1-7.662,18.544l33.293,19.223a114.512,114.512,0,0,0,11.5-27.818Z"
              transform="translate(-974.789 -299.693)"
            />
            <path
              id="Path_36725"
              data-name="Path 36725"
              d="M1133.29,486.732l27.207,27.186a116.189,116.189,0,0,0,18.264-23.925l-33.288-19.223A76.989,76.989,0,0,1,1133.29,486.732Z"
              transform="translate(-963.477 -316.919)"
            />
            <path
              id="Path_36728"
              data-name="Path 36728"
              d="M1102.49,513.718l19.218,33.293a115.447,115.447,0,0,0,23.956-18.285l-27.207-27.207A77.433,77.433,0,0,1,1102.49,513.718Z"
              transform="translate(-948.644 -331.728)"
            />
            <path
              id="Path_36726"
              data-name="Path 36726"
              d="M1066.72,532.723l9.948,37.124a114.471,114.471,0,0,0,27.813-11.5l-19.218-33.293A76.493,76.493,0,0,1,1066.72,532.723Z"
              transform="translate(-931.418 -343.059)"
            />
            <path
              id="Path_36727"
              data-name="Path 36727"
              d="M1028.34,542.5v38.472a115.177,115.177,0,0,0,29.845-4.039l-9.948-37.124A76.812,76.812,0,0,1,1028.34,542.5Z"
              transform="translate(-912.935 -350.168)"
            />
            <path
              id="Path_36722"
              data-name="Path 36722"
              d="M980.763,539.85l-9.943,37.124a115.171,115.171,0,0,0,29.84,4.038V542.52a76.812,76.812,0,0,1-19.9-2.67Z"
              transform="translate(-885.234 -350.187)"
            />
            <path
              id="Path_36723"
              data-name="Path 36723"
              d="M936.333,525.05,917.11,558.343a114.8,114.8,0,0,0,27.823,11.5l9.964-37.124A76.493,76.493,0,0,1,936.333,525.05Z"
              transform="translate(-859.369 -343.059)"
            />
            <path
              id="Path_36719"
              data-name="Path 36719"
              d="M844.2,396.62H805.73a115.4,115.4,0,0,0,4.033,29.845l37.124-9.948a77.036,77.036,0,0,1-2.685-19.9Z"
              transform="translate(-805.73 -281.21)"
            />
            <path
              id="Path_36720"
              data-name="Path 36720"
              d="M868.988,470.77,835.7,489.993a116,116,0,0,0,18.259,23.925l27.207-27.2a77.333,77.333,0,0,1-12.178-15.947Z"
              transform="translate(-820.163 -316.919)"
            />
            <path
              id="Path_36721"
              data-name="Path 36721"
              d="M850.634,435l-37.124,9.948a114.507,114.507,0,0,0,11.5,27.818L858.3,453.544A76.807,76.807,0,0,1,850.634,435Z"
              transform="translate(-809.477 -299.693)"
            />
            <path
              id="Path_36718"
              data-name="Path 36718"
              d="M898.127,501.57,870.92,528.751a115.671,115.671,0,0,0,23.946,18.264l19.223-33.293A77.243,77.243,0,0,1,898.127,501.57Z"
              transform="translate(-837.124 -331.752)"
            />
            <path
              id="Path_36731"
              data-name="Path 36731"
              d="M1118.463,249.45l27.2-27.2A115.66,115.66,0,0,0,1121.708,204l-19.218,33.293A77.3,77.3,0,0,1,1118.463,249.45Z"
              transform="translate(-948.644 -188.448)"
            />
            <path
              id="Path_36732"
              data-name="Path 36732"
              d="M1085.264,226.591l19.218-33.277a114.475,114.475,0,0,0-27.813-11.5l-9.948,37.129A76.481,76.481,0,0,1,1085.264,226.591Z"
              transform="translate(-931.418 -177.761)"
            />
            <path
              id="Path_36733"
              data-name="Path 36733"
              d="M1048.237,215.162l9.948-37.129A115.4,115.4,0,0,0,1028.34,174v38.472A76.815,76.815,0,0,1,1048.237,215.162Z"
              transform="translate(-912.935 -174)"
            />
            <path
              id="Path_36736"
              data-name="Path 36736"
              d="M881.165,266.406l-27.207-27.2A115.277,115.277,0,0,0,835.7,263.156l33.288,19.218a77.153,77.153,0,0,1,12.178-15.967Z"
              transform="translate(-820.163 -205.404)"
            />
            <path
              id="Path_36737"
              data-name="Path 36737"
              d="M858.3,304.618,825.014,285.4a114.473,114.473,0,0,0-11.5,27.813l37.124,9.948A76.6,76.6,0,0,1,858.3,304.618Z"
              transform="translate(-809.477 -227.648)"
            />
            <path
              id="Path_36738"
              data-name="Path 36738"
              d="M846.887,349l-37.124-9.948A115.46,115.46,0,0,0,805.73,368.9H844.2A77.066,77.066,0,0,1,846.887,349Z"
              transform="translate(-805.73 -253.485)"
            />
            <path
              id="Path_36739"
              data-name="Path 36739"
              d="M914.089,237.272,894.866,204a115.871,115.871,0,0,0-23.946,18.269l27.207,27.2A77.44,77.44,0,0,1,914.089,237.272Z"
              transform="translate(-837.124 -188.447)"
            />
            <path
              id="Path_36740"
              data-name="Path 36740"
              d="M954.9,218.909l-9.943-37.129A114.716,114.716,0,0,0,917.11,193.3l19.223,33.288A76.461,76.461,0,0,1,954.9,218.909Z"
              transform="translate(-859.369 -177.747)"
            />
            <path
              id="Path_36741"
              data-name="Path 36741"
              d="M1000.62,212.472V174a115.391,115.391,0,0,0-29.84,4.033l9.964,37.129a76.814,76.814,0,0,1,19.876-2.691Z"
              transform="translate(-885.215 -174)"
            />
          </g>
          <g id="Group_40185" data-name="Group 40185" transform="translate(1243 4041.988)">
            <path
              id="Path_36716"
              data-name="Path 36716"
              d="M911.117,414.125l-.467-.266-.11-.061c-.228-.122-.5-.262-.794-.406l-.1-.049c-.243-.114-.505-.236-.783-.353l-.247-.1-.6-.239c-.167-.065-.334-.122-.505-.182l-.433-.148c-.16-.053-.323-.1-.482-.148l-.266-.08c-.41-.114-.76-.2-1.14-.285l-.22-.049-.5-.095-.437-.08-.505-.08-.482-.061-.444-.049-.612-.049-.38-.023c-.334,0-.669-.023-1.01-.023a12.68,12.68,0,0,1-11.483-7.153,30.01,30.01,0,0,1,11.483-2.253,30.366,30.366,0,1,1-30.1,34.23h.429a13.518,13.518,0,0,1,11.628,6.838,20.976,20.976,0,0,0,39.039-10.7,20.416,20.416,0,0,0-8.064-16.505c-.581-.425-2.386-1.622-2.386-1.622"
              transform="translate(-662.244 -96.301)"
              fill="#92aabb"
            />
            <path
              id="Path_36717"
              data-name="Path 36717"
              d="M900.207,374.229a30.365,30.365,0,1,1,30.241-32.965,13.223,13.223,0,0,1-11.692-7.217,20.968,20.968,0,1,0-18.549,30.769c.38,0,.786-.019,1.174-.038a12.657,12.657,0,0,1,10.306,7.191,30.074,30.074,0,0,1-11.487,2.26"
              transform="translate(-661.842 -41.488)"
              fill="#92aabb"
            />
          </g>
        </g>
      </svg>
      {progressText ? <p className={styles.description}>{progressText}</p> : null}
    </div>
  ) : null;
};

export default ProgressSpinner;
