import { LoadingOutlined } from '@ant-design/icons';
import { Badge, Form, Input, Progress, Select, Spin, Tooltip, message } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { t } from 'i18next';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import ExtraTab from 'src/app/components/ExtraTabcomponent/ExtraTab';
import FormComponent from 'src/app/components/FormComponent/FormComponent';
import { useNumberMutation, useStartMutation } from 'src/app/redux/blackSystem/blackSystem.slice';
import { useAppSelector } from 'src/app/redux/hook';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';
import { UploadIcon } from 'src/icons';

function OSSComponent() {
  const [submitType, setSubmitType] = useState<string>('');

  const [socialMediaForm] = useForm();

  const [start] = useStartMutation();
  const [number] = useNumberMutation();
  const [numberMessage, setNumberMessage] = useState<string>('');
  const { theme } = useAppSelector((state) => state.configContent);
  const [downloadState, setDownloadState] = useState<{
    state: boolean;
    done: boolean;
    size: string;
  }>({
    state: false,
    done: false,
    size: '0.00',
  });

  const socialMedias = ['Instagram'];
  const selectedSocialMedia = useWatch('socialMedia', socialMediaForm);
  const mediaTypes = ['top_media', 'recent_media'];

  const onFinishSocialMedia = async () => {
    const instagramFormValues = socialMediaForm.getFieldsValue(['tag', 'mediaType']);
    if (submitType === 'Start') {
      message.success(t('blackSystemPage.startOperationSuccess'));
      start({ data: { instagramFormValues }, url: selectedSocialMedia })
        .unwrap()
        .catch(() => message.error('Start Operation have been failed...'));
    } else if (submitType === 'Export') {
      try {
        message.success(t('blackSystemPage.exportOperationSuccess'), 5);
        await axios({
          url: urlsConfig.blackSystem(selectedSocialMedia).export,
          method: 'POST',
          data: { instagramFormValues },
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const bytesDownloaded = progressEvent.loaded;
            const megabytesDownloaded = bytesDownloaded / (1024 * 1024);
            if (megabytesDownloaded) {
              setDownloadState((prevState) => ({
                ...prevState,
                state: true,
                size: `${megabytesDownloaded.toFixed(2)}`,
              }));
            }
          },
          blockSpinner: true,
        })
          .then((res: any) => {
            setDownloadState({
              size: '0.00',
              state: false,
              done: true,
            });
            fileDownload(res.data, `${selectedSocialMedia}.zip`);
          })
          .catch(() => {
            message.error(t('blackSystemPage.exportOperationFailed'), 5);
            setDownloadState({
              done: false,
              size: '0.00',
              state: false,
            });
          });
      } catch (error) {
        console.log('err', error);
      }
    } else if (submitType === 'Number') {
      number({ data: { instagramFormValues }, url: selectedSocialMedia })
        .unwrap()
        .then((res) => setNumberMessage(res))
        .catch(() => {
          setNumberMessage('');
          message.error(t('blackSystemPage.numberOperationFailed'), 5);
        });
    }
  };

  return (
    <div className="social-media">
      <div className="first-form">
        <div className="news-api-form">
          <FormComponent
            extraButton={
              <div className="extra-btns">
                <ExtraTab
                  onClick={() => {
                    setSubmitType('Start');
                    socialMediaForm.submit();
                  }}
                  icon={null}
                  text={t('blackSystemPage.commonBtns.start')}
                  bgTheme
                />

                <ExtraTab
                  onClick={() => {
                    setSubmitType('Export');
                    socialMediaForm.submit();
                  }}
                  icon={<UploadIcon />}
                  text={t('blackSystemPage.commonBtns.exportData')}
                  bgTheme
                />
                <ExtraTab
                  onClick={() => {
                    setSubmitType('Number');
                    socialMediaForm.submit();
                  }}
                  icon={null}
                  text={t('blackSystemPage.commonBtns.number')}
                  bgTheme
                />
              </div>
            }
            options={{ labelCol: { span: 9 }, form: socialMediaForm, size: 'middle', onFinish: onFinishSocialMedia }}
          >
            <Form.Item
              name="socialMedia"
              label={
                <div style={{ display: 'flex' }}>
                  {t('blackSystemPage.OSS.selectSocialMedia')}
                  <Tooltip placement="topRight" title={t('blackSystemPage.OSS.selectSocialMedia')}>
                    <Badge offset={[10, 0]} />
                  </Tooltip>
                </div>
              }
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={socialMedias[0]}
            >
              <Select bordered={false}>
                {socialMedias.map((socialMedia) => (
                  <Select.Option value={socialMedia} key={socialMedia}>
                    {socialMedia}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {selectedSocialMedia === 'Instagram' ? (
              <>
                <Form.Item name="tag" label="Select Tag" rules={[{ required: true }]}>
                  <Input variant="borderless" placeholder="# Enter a tag..." />
                </Form.Item>
                <Form.Item
                  name="mediaType"
                  label={
                    <div style={{ display: 'flex' }}>
                      {t('blackSystemPage.OSS.selectMediaType')}
                      <Tooltip placement="topRight" title={t('blackSystemPage.OSS.selectMediaType')}>
                        <Badge offset={[10, 0]} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  initialValue={mediaTypes[0]}
                >
                  <Select bordered={false}>
                    {mediaTypes.map((mediaType) => (
                      <Select.Option value={mediaType} key={mediaType}>
                        {mediaType}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : null}
          </FormComponent>
          <div className="resultAreas">
            {(downloadState.done || downloadState.state) && (
              <div
                className="resultArea"
                style={{
                  ...(theme.colors.quoteBgc && {
                    borderTop: `1px solid ${theme.colors.quoteBgc}`,
                    borderBottom: `1px solid ${theme.colors.quoteBgc}`,
                    color: `${theme.colors.quoteBgc}`,
                  }),
                }}
              >
                {downloadState.state ? (
                  <>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    <p>{downloadState.size}mb</p>
                  </>
                ) : (
                  downloadState.done && (
                    <>
                      <Progress type="circle" percent={100} size={30} />
                      <p>Successfully Downloaded...</p>
                    </>
                  )
                )}
              </div>
            )}
            {numberMessage !== '' && (
              <div
                className="resultArea"
                style={{
                  ...(theme.colors.quoteBgc && {
                    borderTop: `1px solid ${theme.colors.quoteBgc}`,
                    borderBottom: `1px solid ${theme.colors.quoteBgc}`,
                    color: `${theme.colors.quoteBgc}`,
                  }),
                }}
              >
                <p>
                  {t('blackSystemPage.newsApi.numberMessage')}
                  {numberMessage}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OSSComponent;
