import { CreateLabelType, GetLabelsResultType, UpdateLabelType } from 'src/app/services/labels/labels-service.type';
import { urlsConfig } from 'src/configs';
import { LabelType } from 'src/types/labels.type';
import { LookupDataType } from 'src/types/query.type';
import { apiSlice } from 'src/app/redux/api/apiSlice';

export const labelSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLabels: builder.query<GetLabelsResultType, LookupDataType | void>({
      query: () => ({
        url: urlsConfig.labels.lookup,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'Labels' as const,
                id,
              })),
              { type: 'Labels', id: 'LIST' },
            ]
          : [{ type: 'Labels', id: 'LIST' }],
    }),
    createLabel: builder.mutation<LabelType, CreateLabelType>({
      query: (data) => ({
        url: urlsConfig.labels.update,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Labels', id: 'LIST' }],
    }),
    updateLabel: builder.mutation<LabelType, UpdateLabelType>({
      query: (data) => ({
        url: urlsConfig.labels.update,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, data) => [{ type: 'Labels', id: data.id }],
    }),
    deleteLabel: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `${urlsConfig.labels.delete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'Labels', id }],
    }),
  }),
});

export const { useGetLabelsQuery, useCreateLabelMutation, useUpdateLabelMutation, useDeleteLabelMutation } = labelSlice;
