import React, { ReactNode } from 'react';
import InfoTooltip, { IInfoTooltipProps } from 'src/app/components/InfoTooltipComponent/InfoTooltip';

interface IInfoTooltipWithLabelProps extends IInfoTooltipProps {
  label: string | ReactNode;
}
const InfoTooltipWithLabel: React.FC<IInfoTooltipWithLabelProps> = ({ label, tooltipContent }) => {
  return (
    <div style={{ display: 'flex' }}>
      {label}
      <InfoTooltip tooltipContent={tooltipContent} />
    </div>
  );
};

export default InfoTooltipWithLabel;
