import { urlHelper } from 'src/app/helpers';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  StudentCreateDataType,
  StudentDataType,
  StudentImportDataType,
  StudentLookupDataType,
  StudentLookupResultType,
  StudentUpdateDataType,
} from 'src/app/services/student/student.service.type';
import { urlsConfig } from 'src/configs';

export const studentsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStudents: builder.query<StudentLookupResultType, StudentLookupDataType | void>({
      query: (params?: StudentLookupDataType) => ({
        url: urlsConfig.student.lookup,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'Students' as const,
                id,
              })),
              { type: 'Students', id: 'LIST' },
            ]
          : [{ type: 'Students', id: 'LIST' }],
    }),

    createStudent: builder.mutation<StudentDataType, StudentCreateDataType>({
      query: (data) => ({
        method: 'POST',
        body: data,
        url: urlsConfig.student.create,
      }),
      invalidatesTags: [{ type: 'Students', id: 'LIST' }],
    }),
    updateStudent: builder.mutation<StudentDataType, StudentUpdateDataType>({
      query: (data) => ({
        method: 'PATCH',
        body: data,
        url: urlsConfig.student.update,
      }),
      invalidatesTags: (_result, _error, data: StudentUpdateDataType) => [{ type: 'Students', id: data.id }],
    }),
    deleteStudent: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `${urlsConfig.student.destroy}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'Students', id }],
    }),
    importStudent: builder.mutation<void, StudentImportDataType>({
      query: (data) => ({
        url: urlsConfig.importStudent,
        method: 'POST',
        body: convertImportDataToFormData(data),
      }),
      invalidatesTags: [{ type: 'Students', id: 'LIST' }],
    }),
    exportStudentPdf: builder.mutation<ArrayBuffer, { graduatedOrNot: boolean; ids?: string[] }>({
      query: (data) => ({
        url: urlsConfig.student.exportRecord,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
    exportStudentExcell: builder.mutation<ArrayBuffer, { graduatedOrNot: boolean; ids?: string[] }>({
      query: (data) => ({
        url: urlsConfig.exports.student,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

const convertImportDataToFormData = (data: StudentImportDataType) => {
  const formData = new FormData();
  formData.append('file', data.file);
  return formData;
};

export const {
  useGetStudentsQuery,
  useLazyGetStudentsQuery,
  useCreateStudentMutation,
  useDeleteStudentMutation,
  useUpdateStudentMutation,
  useImportStudentMutation,
  useExportStudentPdfMutation,
  useExportStudentExcellMutation,
} = studentsSlice;
