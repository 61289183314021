import {
  ChangeSelectedNotificationsStatus,
  NotificationResultType,
} from 'src/app/services/notifications/notifications-service.type';
import { NotificationLabelPriorityStatus } from 'src/enums/labels.enum';
import { NotificationReadStatusEnum, NotificationStarStatusEnum } from 'src/enums/notifications.enum';
import { LabelType } from 'src/types/labels.type';

export type NotificationListType = 'Inbox' | 'Archived';

export const defaultPageSize = 20;
export const headerPageSize = 8;

export type FilterType = {
  labelIds: string[];
  readStatus: NotificationReadStatusEnum | undefined;
  starStatus: NotificationStarStatusEnum | undefined;
  priorities: string[];
  filteredNotificationCount: number;
};

export const getIconColorAndTextWithPriorityNumber = (
  // notificationOfLabels: { id: string; labelId: string }[] | undefined,
  labels: LabelType[],
  labelList: LabelType[],
): { color: string; title: string } => {
  let minNumber: NotificationLabelPriorityStatus = NotificationLabelPriorityStatus.Uncategorized;

  labels?.forEach((obj) => {
    const label = labelList.find((lbl) => lbl.id === obj.id);
    if (!label) return;
    if (label.priority < minNumber) minNumber = label.priority;
  });

  switch (Number(minNumber)) {
    case NotificationLabelPriorityStatus.Critical:
      return {
        color: 'red',
        title: 'High',
      };
    case NotificationLabelPriorityStatus.Low:
      return {
        color: 'yellow',
        title: 'Low',
      };
    case NotificationLabelPriorityStatus.Medium:
      return {
        color: '#92aabb',
        title: 'Medium',
      };
    default:
      return {
        color: '#92aabb',
        title: 'Uncategorized',
      };
  }
};

export type NotificationStatusType = 'read' | 'archive' | 'relevant' | 'star';

export const changeStatusNotification = (
  type: NotificationStatusType | string,
  id: string,
  notification: NotificationResultType,
) => {
  const formData: ChangeSelectedNotificationsStatus = {
    ...notification,
    ids: [notification.id],
  };
  switch (type) {
    case 'read':
      formData.readStatus = Number(!notification.readStatus);
      break;
    case 'archive':
      formData.archiveStatus = Number(!notification.archiveStatus);
      break;
    case 'relevant':
      formData.relevantStatus = Number(!notification.relevantStatus);
      if (Number(!notification.relevantStatus) === 0) formData.readStatus = 1;
      break;
    case 'star':
      formData.starStatus = Number(!notification.starStatus);
      break;
    default:
      break;
  }
  return formData;
};
