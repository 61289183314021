import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ReactGridLayout from 'react-grid-layout';
import { CardType, HomeCardType } from 'src/app/pages/HomePage/Card/home-card.config';
import { DashboardState, getLayoutOptions } from 'src/app/pages/HomePage/home-page.config';
import { surferApiSlice } from 'src/app/redux/api/surferApiSlice';
import { DataCountBodyType, DataCountResultType } from 'src/app/services/surfer-service/surfer.service.type';
import { urlsConfig } from 'src/configs';

const initialState: DashboardState = {
  layout: [],
  toolbox: [],
};

function sortLayout(ob1: ReactGridLayout.Layout, ob2: ReactGridLayout.Layout) {
  if (ob1.y > ob2.y) {
    return 1;
  }
  if (ob1.y < ob2.y) {
    return -1;
  }
  if (ob1.x < ob2.x) {
    return -1;
  }
  if (ob1.x > ob2.x) {
    return 1;
  }
  return 0;
}

const lastIndexOfGrid = (id: string, layout: ReactGridLayout.Layout[], type: HomeCardType) => {
  const lastItem = layout.sort(sortLayout)[layout.length - 1];
  const itemLayout: ReactGridLayout.Layout = {
    x: 0,
    y: 0,
    w: 1,
    h: 1,
    i: id,
    isResizable: false,
  };

  if (lastItem) {
    itemLayout.x = (lastItem.x + 1) % 4;
    itemLayout.y = itemLayout.x === 0 ? lastItem.y + 1 : lastItem.y;
  }

  return { ...itemLayout, ...getLayoutOptions(type) };
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    dropToLayout: (state, action: PayloadAction<ReactGridLayout.Layout>) => {
      state.toolbox = state.toolbox.filter((id) => id !== action.payload.i);
      state.layout.push(action.payload);
    },

    setDashboard: (state, action: PayloadAction<DashboardState>) => {
      state.layout = action.payload.layout;
      state.toolbox = action.payload.toolbox;
    },
    addCardToToolbox: (state, action: PayloadAction<string>) => {
      state.layout = state.layout.filter((item) => item.i !== action.payload);
      state.toolbox.push(action.payload);
    },
    addCardToLayout: (state, action: PayloadAction<CardType>) => {
      const newLayout = lastIndexOfGrid(action.payload.id, state.layout, action.payload.type);
      state.toolbox = state.toolbox.filter((id) => id !== action.payload.id);
      state.layout.push(newLayout);
    },
    layoutResized: (state, action: PayloadAction<ReactGridLayout.Layout>) => {
      const index = state.layout.findIndex((item) => item.i === action.payload.i);
      state.layout[index] = action.payload;
    },
    deleteFromToolbox: (state, action: PayloadAction<string>) => {
      state.toolbox = state.toolbox.filter((id) => id !== action.payload);
    },
    cardAdded: (state, action: PayloadAction<CardType>) => {
      state.layout.push(lastIndexOfGrid(action.payload.id, state.layout, action.payload.type));
    },
  },
});

export const dashboardApiSlice = surferApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardData: builder.mutation<DataCountResultType, DataCountBodyType>({
      query: (data) => ({
        url: urlsConfig.surfer.dashboard,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  setDashboard,
  addCardToLayout,
  addCardToToolbox,
  dropToLayout,
  layoutResized,
  deleteFromToolbox,
  cardAdded,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const { useGetDashboardDataMutation } = dashboardApiSlice;
