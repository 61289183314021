export const languageAr = {
  // HEADER
  header: {
    search: 'ابحث',

    menu: {
      profile: 'حساب تعريفي',
      fileUpload: 'File Upload',
      settings: 'إعدادات',
      importData: 'بيانات الاستيراد',
      helpAndSupport: 'مساعدة و دعم',
      administration: 'إدارة',
      exportData: 'بيانات التصدير',
      SC3portal: 'SC3 بوابة الامتثال المستمر لسلسلة التوريد',
      SAMAportal: 'بوابة سما',
      logout: 'تسجيل خروج',
    },
  },
  // SIDEBAR
  sidebar: {
    homePage: 'الصفحةالرئيسية',
    network: 'شبكة',
    myNotifications: 'الإشعارات الخاصة بي',
    topic: 'الموضوع',
    location: 'الموقع',
    industry: 'الصناعة',
    report: 'التقرير',
    resources: 'موارد',
    customerAndUser: 'العميل والمستخدم',
    customerAndUserInvitations: 'دعوات العملاء والمستخدمين',
    students: 'طلاب',
    interests: 'Interests',
    nsearch: 'بحث',
    footer: {
      SC3text: 'مشغل بواسطة قاعدة المعرفة و موحدالبيانات',
      SAMAtextOne: 'Powered By ngnir®',
      SAMAtextTwo: 'ETE Technology®',
      credits: 'الاعتمادات',
      termsOfUse: 'شروط الاستخدام',
    },
  },

  // PROFILE PAGE
  profilePage: {
    userProfile: {
      tabName: 'ملف تعريفي للمستخدم ',
      form: {
        fields: {
          fullName: 'الاسم الكامل',
          email: 'بريد إلكتروني',
          userRole: 'وظيفة المستخدم',
          currentPassword: 'كلمة السر الحالية',
        },
      },
    },
    password: {
      tabName: 'كلمة المرور',
    },
  },

  // SETTINGS PAGE
  settingsPage: {
    theme: {
      tabName: 'الموضوع',
      pickColor: 'اختر اللون',
      changeToDefault: 'التغيير إلى الافتراضي',
    },
    userSettings: {
      tabName: 'إعدادات المستخدم',
      inviteUser: 'دعوة المستخدم',
    },
    securitySettings: {
      tabName: 'اعدادات الامان',
    },
    notificationSettings: {
      tabName: 'إعدادات الإشعار',
      notificationRules: {
        tabName: 'قواعد الإشعار',
        addNotification: 'إضافة اشعار',
      },
      notificationSettings: {
        tabName: 'إعدادات الإشعار',
        allLabels: 'جميع التصنيفات',
        createUserBasedLabel: 'إنشاء تصنيف يعتمد على المستخدم',
        labelName: 'اسم التصنيف',
        labelColour: 'لون التصنيف',
        colourPalette: 'لوحة الالوان',
        prioritizeActiveLabels: 'إعطاء الأولوية للتصنيفات النشطة',
        systemBased: 'مبني على النظام',
        userBased: 'مبني على المستخدم',
        tooltips: {
          prioritizeActiveLabels: 'قم بسحب وإسقاط التصنيفات لتغيير أولوية الإشعارات',
        },
        high: 'عالي',
        medium: 'متوسط',
        low: 'منخفض',
        uncategorized: 'غير مصنف',
      },
    },
    saqSettings: {
      tabName: 'اعدادات استبيان التقييم الذاتي',
    },
    cocSettings: {
      tabName: 'إعدادات قواعد السلوك',
    },
    monitoredSupplierSettings: {
      tabName: 'إعدادات المورد المراقبة',
    },
    controlPanel: {
      tabName: 'لوحة التحكم',
      runningModules: {
        tabName: 'وحدات التشغيل',
        addModule: 'إضافة وحدة',
      },
      machines: {
        tabName: 'آلات',
      },
    },
    authenticationSettings: {
      tabName: 'إعدادات المصادقة',
    },
    senderEmailSettings: {
      tabName: 'إعدادات البريد الإلكتروني للمرسل',
    },
  },

  // HELP AND SUPPORT PAGE
  helpAndSupportPage: {
    userGuide: {
      tabName: 'دليل المستخدم',
    },
    faq: {
      tabName: 'الأسئلة المتكررة',
    },
    troubleTicket: {
      tabName: 'بطاقة المشكلة',
    },
    configurationChangeRequest: {
      tabName: 'طلب تغيير التكوين',
    },
  },
  // Portal
  blackSystemPage: {
    startOperationSuccess: 'Start Operation Have Been Started Successfully...',
    exportOperationSuccess: 'Export Operation Have Been Started Successfully...',
    numberOperationSuccess: 'Number Operation Have Been Started Successfully...',
    startOperationFailed: 'Start Operation Have Been Failed...',
    exportOperationFailed: 'Export Operation Have Been Failed...',
    numberOperationFailed: 'Number Operation Have Been Failed...',
    atLeastOneTopicNeeded: 'Please at least add one keyword for the actions...',
    noDataFound: 'No Data Was Found...',
    tabName: 'Black System',
    newsApi: {
      tabName: 'News Site',
      addTopic: 'Add New Topic',
      chooseSelector: 'Choose Selector',
      days: 'Days',
      form: {
        addNewKeyword: 'Add New Keyword',
        chooseSelector: 'Choose Selector',
        fromDate: 'From Date',
        toDate: 'To Date',
        noKeywordsAdded: 'No Keywords Added Yet...',
      },
      numberMessage: 'Export Count of Given Inputs is: ',
      numberMessageOss: 'Export Count of Selected Site is: ',
    },
    OSS: {
      tabName: 'Social Media',
      selectSite: 'Select Site',
      selectSocialMedia: 'Select Social Media',
      selectMediaType: 'Select Media Type',
      numberMessage: 'Export Count of the selected site is: ',
    },
    BlackList: {
      tabName: 'Blacklist',
    },
    CourtRecord: {
      tabName: 'Court Record',
      numberMessage: 'Export Count for given input is: ',
    },
    WebSite: {
      tabName: 'Web Site',
    },
    commonBtns: {
      exportData: 'Export Data',
      start: 'Start',
      number: 'Number',
    },
  },

  // DASHBOARD/HOME PAGE
  homePage: {
    numberOfSuppliers: 'عدد الموردين',
    numberOfSourcingCountries: 'عدد دول المصدر',
    myMedia: 'وسائلي',
    mySuppliersMedia: 'وسائل الإعلام الخاصة بالمورد',
    blacklistedStatus: 'حالة القائمة السوداء',
    countryRisk: 'مخاطر الدولة',
    industryRisk: 'مخاطر الصناعة',
    vulnerabilityToModernSlavery: 'التعرض للعبودية الحديثة',
    prevalenceIndexRank: 'ترتيب مؤشر الانتشار',
    governmentResponseRating: 'تصنيف استجابة الحكومة',
    tipTier: 'مستوى النصيحة',
    tier1: 'المستوى 1',
    tier2AndBeyond: 'المستوى 2 وما بعده',
  },

  // CUSTOMER & USER PAGE
  customerAndUserPage: {
    userDetails: 'بيانات المستخدم',
    inviteCustomer: 'دعوة العميل',
    online: 'Online',
    offline: 'Offline',
    modals: {
      companyDetails: 'تفاصيل الشركة',
      companyInfo: 'معلومات الشركة',
      companyNotes: 'ملاحظات الشركة',
      subscriptionInfo: 'معلومات الاشتراك',
      userInfo: 'معلومات المستخدم',
      resourceInfo: 'معلومات المورد',
      editModal: {
        title: 'تحرير بيانات المورد',
      },
      deleteModal: {
        title: 'مسح المستخدم',
        content: `سيتم حذف المستخدم المسمى <strong>{{firstname}}</strong> نهائيًا، ولا يمكن التراجع عن هذه العملية.
        هل أنت متأكد؟`,
      },
      freezeModal: {
        title: 'تجميد المستخدم',
        content: `سيتم تجميد المستخدم ولن يتمكن من استخدام النظام حتى يتم إلغاء التجميد.
         هل أنت متأكد؟`,
        okText: 'تجميد',
      },
      unfreezeModal: {
        title: 'إلغاء تجميد المستخدم',
        content: `سيتم إلغاء تجميد المستخدم.
         هل أنت متأكد؟`,
        okText: 'قم بإلغاء التجميد',
      },
    },
  },

  // CUSTOMER & USER INVITATIONS PAGE
  customerAndUserInvitationsPage: {
    customerInvitations: {
      tabName: 'دعوات العملاء',
    },
    userInvitations: {
      tabName: 'دعوات المستخدمين',
    },
  },

  // SAQFORM PAGE
  saqFormPage: {
    title: 'تلقي استبيان التقييم الذاتي',
    solveSAQ: {
      tabName: 'حل استبيان التقييم الذاتي',
      openSAQ: 'فتح استبيان التقييم الذاتي',
      receiverSAQMessage: 'You have received SAQ. Click below button to solve the SAQ.',
      solvedSAQMessage: 'You have solved the SAQ. You can monitor information about the SAQ and Actions.',
    },
    monitorSAQ: {
      tabName: 'مراقبة استبيان التقييم الذاتي',
      table: {
        headers: {
          title: 'عنوان',
          deliveryStatus: 'حالة التسليم',
          deliveryDate: 'تاريخ التسليم',
          completionStatus: 'حالة الاكتمال',
          completionDate: 'موعد الإكمال',
          receiverCompany: 'شركة الاستقبال',
          receiverEmails: 'البريد الالكتروني للمستقبلين',
          riskScore: 'درجة المخاطر',
          summary: 'ملخص',
        },
      },
    },
    actionCenter: {
      tabName: 'مركز التحكم',
      table: {
        headers: {
          actionTitle: 'عنوان العمل',
          actionSetDate: 'تاريخ تعيين الإجراء',
          deadlineDate: 'تاريخ الموعد النهائي',
          urgency: 'السرعة المطلوبة',
          responsiblePerson: 'الشخص المسؤول',
          assignedPerson: 'الشخص المعين',
          receiverSupplier: 'مورد جهاز الاستقبال',
          status: 'حالة',
        },
      },
    },
  },

  eteForms: {
    welcomePage: {
      start: 'يبدأ',
      press: 'يضعط',
      takesMinute: 'دقائق هذا القسم {{minute}} يأخد',
      takesOverall: 'دقائق بشكل عام {{minute}} يأخذ',
      maxScore: 'الدرجةالقصوى:',
      minScore: 'الدرجة الدنيا:',
    },
  },

  // NOTIFICATIONS PAGE
  notificationsPage: {
    inbox: 'صندوق الوارد',
    archived: 'مؤرشف',
    markAsRead: 'وضع علامة كمقروء',
    markAsUnread: 'وضع علامة كغير مقروءة',
    editLabel: ' تحرير التصنيف ',
    markAsIrrelevant: ' وضع علامة على أنها غير ذات صلة ',
    markAsRelevant: ' وضع علامة ذات صلة ',
    archive: ' أرشيف ',
    unArchive: ' إلغاء الأرشفة ',
    delete: 'حذف ',
    labels: ' تصنيفات ',
    prioritizeBased: ' على أساس الأولويات ',
    readStatus: 'قراءة الحالة',
    starStatus: 'وضع النجمة',
    all: 'الكل',
    high: 'عالي',
    read: 'مقروء',
    unread: 'غير مقروء',
    star: 'نجمة',
    unstar: 'إلغاء النجمة',
    markAsReadAll: 'وضع علامة كمقروءة للكل',
    markAsUnreadAll: 'وضع علامة كغير مقروءة للكل',
    markAsRelevantAll: 'وضع علامة على أنها ذات صلة بالكل',
    markAsIrrelevantAll: 'وضع علامة على أنها غير ذات صلة بالكل',
    markStarTitle: 'انقر هنا لوضع علامة على هذا الإشعار على أنه مهم بالنسبة لك',
    markUnStarTitle: 'انقر هنا لوضع علامة على هذا الإشعار على أنه غير مهم بالنسبة لك',
    archiveAll: 'ارشفة الكل',
    unArchiveAll: 'إلغاء أرشفة الكل',
    deleteAll: 'حذف الكل',
    tooltips: {
      low: 'منخفض',
      star: 'انقر هنا لوضع علامة على هذا الإشعار على أنه مهم بالنسبة لك',
      add: 'إعداد قاعدة اشعار جديدة',
      settings: 'إعدادات الإشعار',
    },

    detail: {
      title: 'تفاصيل الإشعار',
      generalInfo: 'معلومات عامة',
      newsInfo: 'معلومات الأشعار',
      interestsInData: 'الاهتمامات في البيانات',
    },
  },
  momentCalendar: {
    sameDay: 'اليوم',
    lastDay: 'أمس',
  },

  // LOCATIONS PAGE
  locationsPage: {
    title: 'جميع المواقع',
    tableView: {
      tabName: 'عرض جدول',
    },
    mapView: {
      tabName: 'عرض الخريطة',
    },
  },

  // REPORTS PAGE
  reportsPage: {
    tabs: {
      reports: {
        tabName: 'التقارير',
        createReport: 'إنشاء تقرير',
        modal: {
          formSections: 'أقسام النموذج',
        },
      },
      monitorSaq: {
        tabName: 'مراقبة استبيان التقييم الذاتي',
      },
      actionCenter: {
        tabName: 'مركز التحكم',
        selectReportType: 'حدد نوع التقرير',
        selectReport: 'حدد تقريرًا',
        selectSaq: 'حدد استبيان التقييم الذاتي',
      },
    },
  },

  forms: {
    // PROFILE FORM
    profileForm: {
      fields: {
        fullName: 'الاسم الكامل',
        email: 'البريد الالكتروني',
        userRole: 'وظيفة المستخدم',
        currentPassword: 'كلمة السر الحالية',
      },
    },
    // PASSWORD FORM
    passwordForm: {
      fields: {
        currentPassword: 'كلمة السر الحالية',
        newPassword: 'كلمة السر الجديدة',
        passwordConfirmation: 'تأكيد كلمة المرور',
      },
    },

    // SECURITY SETTINGS FORM
    securityForm: {
      fields: {
        enableTwoFactor: 'تمكين المصادقة متعددة العوامل',
        sessionExpireTime: 'وقت انتهاء الجلسة',
        currentPassword: 'كلمة السر الحالية',
        authentication: 'المصادقة',
      },
      placeholders: {
        selectAuthType: 'حدد نوع المصادقة',
      },
      options: {
        emailAuth: 'المصادقة عبرالبريد الإلكتروني',
        qrCodeAuth: 'مصادقة رمز الاستجابة السريعة',
      },
      logoutAfter: 'تسجيل الخروج بعد',
      minutesIdle: 'دقائق الخمول',
    },
    // NOTIFICATION FORM
    notificationForm: {
      fields: {
        subject: 'موضوع',
        sources: 'مصادر',
        interestType: 'نوع الاهتمام',
        interestName: 'اسم الاهتمام',
        expirationDate: 'تاريخ انتهاء الصلاحية',
        webNotification: 'إشعار الويب',
        receiver: 'المتلقي',
        emailNotification: 'إشعار البريد الإلكتروني',
      },
      modals: {
        editModalTitle: 'تحديث الاشعارات',
      },
    },
    // SAQ AND COC SETTINGS FORM
    saqAndCocSettingsForm: {
      fields: {
        timePassed: 'الوقت المنقضي',
        timePeriod: 'فترة زمنية',
        reminderCount: 'عدد التذكيرات',
        reminderMailTemplate: 'نموذج البريد التذكيري',
      },
      tooltips: {
        timePassed: `Determine how many days after the initial email the reminder should be sent.`,
        timePeriod: `Determine the period between each reminder mail as day after the initial reminder mail sent.`,
        reminderCount: `Determine the maximum reminder count which means that the supplier will receive exactly this amount of reminder mail.`,
        reminderMailTemplate: `Determine which mail template you want to use.`,
      },
    },

    // COMPANY DETAILS FORM
    detailsForm: {
      fields: {
        companyName: 'اسم الشركة',
        isProxy: 'الوكيل',
        legalCompanyName: 'اسم الشركة القانوني',
        emails: 'البريد الإلكتروني',
        industry: 'صناعة',
        website: 'موقع إلكتروني',
        DUNS: 'رقم نظام تصنيف الشركات والعمليات',
        registryId: 'معرف التسجيل',
        companyNumber: 'رقم الشركة',
        country: 'دولة',
      },
    },

    // INVITE USER FORM
    inviteUserForm: {
      fields: {
        email: 'بريد إلكتروني',
      },
    },

    // SUPPLIER FORM
    supplierForm: {
      fields: {
        from: 'من',
        relationType: 'نوع العلاقة',
        to: 'الى',
        strategicImportance: 'الأهمية الاستراتيجية',
      },
    },

    // GENERATE REPORT FORM
    generateReportForm: {
      fields: {
        company: 'شركة',
        exportType: 'نوع التصدير',
        applyFilterChoices: 'تطبيق خيارات التصفية',
      },
    },

    // SAQ TEMPLATE FORM
    saqTemplateForm: {
      fields: {
        saqName: 'اسم استبيان التقييم الذاتي',
        status: 'حالة ',
      },
    },
    // EMAIL TEMPLATE FORM
    emailTemplateForm: {
      fields: {
        templateName: 'اسم النموذج',
        topContent: 'المحتوى الرئيسي',
        bodyContent: 'المحتوى الاساسي',
        bottomContent: 'المحتوى الاضافي',
        emailTemplateType: 'نوع نموذج البريد الإلكتروني',
        notes: 'ملاحظات',
      },
    },

    // FileUploadForm
    fileUploadForm: {
      fields: {
        locations: 'Locations',
        title: 'Title',
        time: 'Time',
        latitude: 'Latitude',
        longtitude: 'Longtitude',
        sensors: 'Sensors',
        selectFile: 'Select File',
        selectFolder: 'Select Folder',
      },
    },
    // ACTION DETAILS FORM
    actionDetailsForm: {
      fields: {
        question: 'سؤال',
        answer: 'إجابة',
        actionTitle: 'عنوان العمل',
        actionSummary: 'ملخص العمل',
        actionSetDate: 'تاريخ تعيين الإجراء',
        deadline: 'الموعد النهائي',
        urgency: 'السرعة المطلوبة',
        senderResponsiblePerson: 'الشخص المسؤول عن الارسال',
        receiverResponsiblePerson: 'الشخص المسؤول عن الاستقبال',
        attachments: 'المرفقات',
        status: 'حالة',
        commentActivities: 'أنشطة التعليق',
      },
    },
    exportDataForm: {
      fields: {
        title: 'بيانات التصدير',
        customers: 'عملاء',
        reportTypes: 'أنواع التقرير',
      },
    },
    configurationChangeRequestForm: {
      ccrId: 'معرف طلب تغيير التكوين',
      ccrTitle: 'عنوان طلب تغيير التكوين',
      createdById: 'انشأ من قِبَل',
      tooltip: {
        attachmentTooltip: 'Supported file types are only .jpg, .jpeg, .png, .pdf, .docx, .xlsx, .pptx and videos.',
      },
    },
    troubleTicketForm: {
      ccrId: 'معرف طلب تغيير التكوين',
      ccrTitle: 'عنوان طلب تغيير التكوين',
      createdById: 'مُعرِّف هوية المنشأ',
      tooltip: {
        attachmentTooltip: 'Supported file types are only .jpg, .jpeg, .png, .pdf, .docx, .xlsx, .pptx and videos.',
      },
    },
  },

  tables: {
    // USER SETTINGS TABLE
    userSettings: {
      columns: {
        fullName: 'الاسم الكامل',
        email: 'البريد الالكتروني',
        userRole: 'دور المستخدم',
        deliveryStatus: 'حالة التسليم',
        deliveryDate: 'تاريخ التسليم',
        registerStatus: 'حالة التسجيل',
        registerDate: 'تاريخ التسجيل',
      },
      tooltips: {
        editUser: 'تعديل المستخدم',
        deleteUser: 'مسح المستخدم',
      },
      modals: {
        deleteModal: {
          title: 'مسح المستخدم',
          content: `{{firstname}}  سيتم حذف المستخدم المسمى  
          نهائيًا من حساب العميل الخاص بك
                     هل أنت متأكد؟.`,
        },
      },
    },
    // NOTIFICATION RULE TABLE
    notificationRules: {
      columns: {
        information: 'معلومة',
        emailNotification: 'إشعار البريد الإلكتروني',
        webNotification: 'إشعار الويب',
        subject: 'موضوع',
        expireDate: 'تاريخ انتهاء الصلاحية',
        period: 'فترة',
        to: 'إلى',
        cc: 'نسخة',
        bcc: 'نسخة مخفية الوجهة',
        receiver: 'المتلقي',
      },
    },
    // MONITORED SUPLLIER SETTINGS TABLE
    monitorSuppliers: {
      columns: {
        companyName: 'اسم الشركة',
        country: 'دولة',
        industry: 'صناعة',
        strategicImportance: 'الأهمية الاستراتيجية',
      },
      tooltips: {
        edit: 'تحرير المورد',
        delete: 'حذف المورد',
      },
      modals: {
        editModal: {
          title: 'تحرير بيانات المورد',
        },
        deletModal: {
          title: 'حذف المورد',
          content: `{{ companyName }} ستتم إزالة 
 من سلسلة التوريد الخاصة بك
           هل أنت متأكد؟`,
        },
      },
    },

    // VALUE CHAIN TABLE
    valueChain: {
      columns: {
        companyName: 'اسم الشركة',
        tierLevel: 'مستوى التصنيف',
        country: 'دولة',
        industry: 'صناعة',
        strategicImportance: 'الأهمية الاستراتيجية',
        coc: 'قواعد السلوك',
        saq: 'استبيان التقييم الذاتي ',
        hasNews: 'لديه أخبار',
        blacklisted: 'القائمة السوداء',
      },
      tooltips: {
        country: 'Red background color indicates high risk',
        industry: 'Red background color indicates high risk',
        strategicImportance: 'Red background color indicates high risk',
        coc: "Your supplier's latest response date of COC",
        saq: "Your supplier's latest complete date of SAQ",
        hasNews:
          'Indicates whether the company has news media or not. The asterisk (*) at the end means that news media collected within 90 days.',
        blacklist:
          'SC3 system uses the following organisations as Blacklist sources; UN Human Rights Council, US Customs and Border Protection, US Department of Homeland Security, and Norges Bank.',
        editSupplier: 'Edit Supplier',
        deleteSupplier: 'Delete Supplier',
        modernSlaveryStatement: 'Modern Slavery Statement',
      },
      modals: {
        editModal: {
          title: 'تحرير بيانات المورد',
        },
        deleteModal: {
          title: 'حذف المورد',
          content: `{{companyName}} ستتم إزالة 
 من سلسلة التوريد الخاصة بك.
          هل أنت متأكد؟`,
        },
      },
    },

    // SAQ TEMPLATES TABLE
    saqTemplates: {
      columns: {
        saqName: 'اسم استبيان التقييم الذاتي',
        uploadedAt: 'تم التحميل في',
        status: 'حالة',
      },
      modals: {
        editModal: {
          title: 'تحرير استبيان التقييم الذاتي',
        },
      },
    },
    // EMAIL TEMPLATES TABLE
    emailTemplates: {
      columns: {
        templateName: ' اسم النموذج',
        notes: 'ملاحظات',
        createdBy: 'انشأ من قِبَل',
        createdAt: 'أنشئت في',
        emailType: 'نوع البريد الإلكتروني',
      },
      tooltips: {
        showEmailTemplates: 'إظهار نموذج البريد الإلكتروني',
      },
      modals: {
        showModal: {
          saqTitle: 'أرسل استبيان التقييم الذاتي للمورد',
          cocTitle: 'أرسل قواعد السلوك',
        },
        editModal: {
          saqTitle: 'تحرير استبيان التقييم الذاتي ',
          cocTitle: 'تحرير قواعد السلوك',
        },
      },
    },
    // SEND SAQ OR COC TABLE
    sendSaqOrCoc: {
      columns: {
        companyName: 'اسم الشركة',
        country: 'الدولة',
        industry: 'صناعة',
        strategicImportance: 'الأهمية الاستراتيجية',
      },
      modals: {
        sendModal: {
          saqTitle: 'أرسل استبيان التقييم الذاتي للمورد',
          cocTitle: 'ارسل قواعد السلوك',
        },
      },
      tooltips: {
        openSaq: 'افتح استبيان التقييم الذاتي',
        displayActions: 'إجراءات العرض',
      },
    },
    // MONITOR SAQ TABLE
    monitorSaq: {
      columns: {
        title: 'عنوان',
        deliveryStatus: 'حالة التسليم',
        deliveryDate: 'تاريخ التسليم او الوصول',
        completionDate: 'موعد الإكتمال',
        completionStatus: 'حالة الإكتمال',
        receiverCompany: 'شركة الاستقبال',
        receiverEmails: 'رسائل البريد الإلكتروني للمستقبل',
        riskScore: 'درجة المخاطر',
        summary: 'ملخص',
      },
      tooltips: {
        summary: `The colors indicate the progress of actions. Red means "not started" action, yellow means "in progress" action, and green means "completed" action. The numbers indicate the number of not started/in progress/completed actions over the total number of actions.`,
      },
    },
    // ACTION CENTER TABLE
    actionCenter: {
      columns: {
        actionTitle: ' عنوان العمل ',
        actionSetDate: ' تاريخ تعيين الإجراء ',
        deadlineDate: ' تاريخ الموعد النهائي ',
        urgency: 'السرعة المطلوبة ',
        responsiblePerson: ' شخص مسؤول ',
        assignedPerson: ' الشخص المعين ',
        receiverSupplier: ' المورد المستلم',
        status: ' حالة ',
      },
      tooltips: {
        responsiblePerson: ' الشخص المسؤول مخول بتعديل تفاصيل الإجراء.',
      },
      modals: {
        editModal: {
          title: ' نموذج تفاصيل الإجراء ',
        },
        deleteModal: {
          title: ' حذف الإجراء ',
          content: `من مركزالعمل. {{actionTitle}}سيتم حذف
هل أنت متأكد؟`,
        },
      },
    },
    // COC TEMPLATES TABLE
    cocTemplates: {
      columns: {
        cocName: 'اسم قواعد السلوك',
        uploadedBy: 'تم التحميل بواسطة ',
        uploadedAt: ' تم التحميل في ',
        status: ' حالة ',
      },
      modals: {
        editModal: {
          title: 'تحرير قواعد السلوك',
        },
        deleteModal: {
          title: 'حذف قواعد السلوك',
        },
      },
    },
    // MONITOR COC TABLE
    monitorCoc: {
      columns: {
        cocName: 'اسم قواعد السلوك ',
        deliveryStatus: ' حالة التسليم ',
        deliveryDate: ' تاريخ التسليم  ',
        responseStatus: ' حالة الاستجابة ',
        responseDate: ' تاريخ الاستجابة ',
        sender: ' مرسل ',
        receiverCompany: ' شركة الاستقبال ',
        receiverEmails: ' رسائل البريد الإلكتروني للمستقبل ',
      },
      tooltips: {
        show: ' تفاصيل الرد ',
      },
      modals: {
        showModal: {
          title: ' تفاصيل الرد ',
        },
      },
    },
    // MEDIA TABLE
    media: {
      columns: {
        title: ' عنوان ',
        categories: ' فئات ',
        mediaType: ' نوع الوسائط ',
        date: ' تاريخ ',
      },
    },
    // LOCATIONS TABLE
    locations: {
      columns: {
        countryName: 'Country Name',
        vulnerabilityToModernSlaveryScore: 'Vulnerability To Modern Slavery Score (%)',
        estimatedPrevalence: 'Estimated Prevalence of Modern Slavery (per 1,000 population)',
        governmentResponseScore: 'Government Response Score (%)',
        tipTier: 'TIP Tier',
      },
      tooltips: {
        general: {
          linkReference: 'Click here for reference',
          countryName: `The country list below shows risk stratification based on SFA expert analysis using GSI data, TIP tier data and SFA research, where the color red is associated with the highest risk and color green is with the lowest risk.`,
          modernSlaveryScore: `Measuring the level of vulnerability to modern slavery in 160 countries. The vulnerability model estimates how vulnerable people in a country are to modern slavery and in what ways. Based on a set of country level indicators, the model is designed to enable us to identify and better understand the potential drivers of this crime. <br/>
          Total Vulnerability score (%) maps 23 risk variables across five major dimensions related to Modern slavery: <br/>
          <1><0> Governance Issues </0><0>Lack of Basic Needs</0><0>Inequality</0><0>Disenfranchised Groups</0><0>Effects of Conflict</0></1>
          <br/> The highest number equals to the highest vulnerability to modern slavery.<br/>
          <br/><5>{{Link}}</5>`,
          prevalenceIndexRank: `Measuring the scale of modern slavery in 160 countries. These national estimates of the prevalence per thousand people and number of people were calculated using individual and country-level risk factors of modern slavery. The analysis draws on thousands of interviews with survivors of modern slavery collected through nationally representative household surveys across 75 countries and Walk Free\`s assessment of national-level vulnerability. Unlike the national scores for vulnerability and government response, our data on the scale of modern slavery cannot be disaggregated (for example, by form of modern slavery or gender).<br/><br/>

          Below the estimated prevalence of modern slavery per 1,000 population is shown. The highest risk is associated with the highest number, and the lowest risk is associated with the lowest number.<br/><br/>
          
          The Index targets private citizens, non-governmental organisations, businesses and public officials so that they can work to end modern slavery. All data involved are available for download from the website. <br/>
          <br/><5>{{Link}}</5>`,
          governmentResponse: `Measuring national efforts to end modern slavery in 176 countries. The government response assessment provides a comparative look at the legal, policy, and programmatic actions that governments are taking to respond to modern slavery. This is based on data collected on 141 indicators that are relevant to understanding how each government is tracking towards achieving 42 activities organised into five milestones. Each milestone represents an aspect of a strong government response to modern slavery; for example, supporting survivors to exit and remain out of modern slavery:<br/><br/>

          <1><0> Survivors of slavery are identified and supported to exit and remain out of slavery.</0>
          <0>Criminal justice mechanisms function effectively to prevent modern slavery.</0>
          <0>Coordination occurs at the national and regional level, and governments are held to account for their response.</0>
          <0>Risk factors such as attitudes, social systems, and institutions that enable modern slavery are addressed.</0>
          <0>Government and business stop sourcing goods and services produced by forced labour.</0>
          </1>
          <br/>
        There are several Tiers within this index, where AAA is the best, meaning that the government has implemented an effective and comprehensive response to all forms of modern slavery and D is the worst, meaning the government has a wholly inadequate response to modern slavery, and/or there is evidence of government-sanctioned modern slavery.<br/><br/>
        
        The highest number refers to the best government response rating and the lowest number is to the worst rating.<br/>
        <br/><5>{{Link}}</5>`,

          tier: `Trafficking in Persons Report, or the TIP Report, is an annual report issued by the U.S. State Department's Office to Monitor and Combat Trafficking in Persons. It ranks governments based on their perceived efforts to acknowledge and combat human trafficking. The report divides nations into tiers based on their compliance with standards outlined in the Trafficking Victims Protection Act (TVPA) of 2000.<br/><br/>

      There are four Tiers, ranging from the best (Tier 1), where governments are fully compliant (green colour), to the worst Tier 3, where governments are not compliant with the minimum standards (red colour).<br/>
      <br/><5>{{Link}}</5>`,
        },

        special: {
          linkReference: 'Click here for reference',
          countryName: `Geographical location of the suppliers and the number of suppliers from the specific location within brackets. Example: China (3) meaning that there are 3 suppliers located in China. The country list below shows risk stratification based on SFA expert analysis using GSI data, TIP tier data and SFA research, where the color red is associated with the highest risk and color green is with the lowest risk.`,
          modernSlaveryScore: `Measuring the level of vulnerability to modern slavery in 160 countries. The vulnerability model estimates how vulnerable people in a country are to modern slavery and in what ways. Based on a set of country level indicators, the model is designed to enable us to identify and better understand the potential drivers of this crime. <br/>
          <1><0> Governance Issues </0><0>Lack of Basic Needs</0><0>Inequality</0><0>Disenfranchised Groups</0><0>Effects of Conflict</0></1>
          <br/>
          The highest number equals to the highest vulnerability to modern slavery.<br/>
          <br/><5>{{Link}}</5>`,
          prevalenceIndexRank: `Measuring the scale of modern slavery in 160 countries. These national estimates of the prevalence per thousand people and number of people were calculated using individual and country-level risk factors of modern slavery. The analysis draws on thousands of interviews with survivors of modern slavery collected through nationally representative household surveys across 75 countries and Walk Free\`s assessment of national-level vulnerability. Unlike the national scores for vulnerability and government response, our data on the scale of modern slavery cannot be disaggregated (for example, by form of modern slavery or gender).<br/><br/>

          Below the estimated prevalence of modern slavery per 1,000 population is shown. The highest risk is associated with the highest number, and the lowest risk is associated with the lowest number.<br/><br/>
          
          The Index targets private citizens, non-governmental organisations, businesses and public officials so that they can work to end modern slavery. All data involved are available for download from the website.<br/>
          <br/><5>{{Link}}</5>`,
          governmentResponse: `Measuring national efforts to end modern slavery in 176 countries. The government response assessment provides a comparative look at the legal, policy, and programmatic actions that governments are taking to respond to modern slavery. This is based on data collected on 141 indicators that are relevant to understanding how each government is tracking towards achieving 42 activities organised into five milestones. Each milestone represents an aspect of a strong government response to modern slavery; for example, supporting survivors to exit and remain out of modern slavery:<br/><br/>

          <1><0>Survivors of slavery are identified and supported to exit and remain out of slavery.</0>
          <0>Criminal justice mechanisms function effectively to prevent modern slavery.</0>
          <0>Coordination occurs at the national and regional level, and governments are held to account for their response.</0>
          <0>Risk factors such as attitudes, social systems, and institutions that enable modern slavery are addressed.</0>
          <0>Government and business stop sourcing goods and services produced by forced labour.</0>
          <br/>
          There are several Tiers within this index, where AAA is the best, meaning that the government has implemented an effective and comprehensive response to all forms of modern slavery and D is the worst, meaning the government has a wholly inadequate response to modern slavery, and/or there is evidence of government-sanctioned modern slavery.
          <br/><br/>
          
          The highest number refers to the best government response rating and the lowest number is to the worst rating.<br/>
          <br/><5>{{Link}}</5>`,

          tier: `Trafficking in Persons Report, or the TIP Report, is an annual report issued by the U.S. State Department's Office to Monitor and Combat Trafficking in Persons. It ranks governments based on their perceived efforts to acknowledge and combat human trafficking. The report divides nations into tiers based on their compliance with standards outlined in the Trafficking Victims Protection Act (TVPA) of 2000.<br/><br/>

          There are four Tiers, ranging from the best (Tier 1), where governments are fully compliant (green colour), to the worst Tier 3, where governments are not compliant with the minimum standards (red colour).<br/>
          <br/><5>{{Link}}</5>`,
          tabTooltip: `How can I use this information to inform my sourcing strategy?<br/> <br/>

          This data enables you to exercise responsible purchasing practices and make informed decisions on your sourcing strategy that reduce the risk of modern slavery. For example, if you have two identical cost prices from two different suppliers, this data can highlight where one supplier may be based in a country with lower vulnerability to modern slavery or with a stronger government response that can guide you on which supplier to partner with.`,
        },
      },
    },
    // INDUSTRIES TABLE
    industries: {
      columns: {
        GICSIndustrySector: ' قطاع صناعة معيار تصنيف الصناعة العالمي ',
        GICSIndustryGroup: 'مجموعة صناعية لمعيار تصنيف الصناعة العالمي ',
        GICSIndustry: ' معيار تصنيف الصناعة العالمي ',
        code: ' شفرة ',
        name: ' اسم ',
      },
      tooltips: {
        general: {
          linkReference: 'Click here for reference',
          GICSIndustrySector: `Global Industry Classification Standard (GICS) - The GICS sector list consists 11 sectors<br/>

        GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.<br/><br/>
        
        The list below shows the GICS sector based classification of your suppliers.<br/>
        
        <br/><5>{{Link}}</5>`,
          GICSIndustryGroup: `Global Industry Classification Standard (GICS) - The GICS industry group list consists 24 industry groups<br/>

        GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.<br/><br/>
        
        The list below shows the GICS sector based classification of your suppliers.<br/>
        <br/><5>{{Link}}</5>`,

          GICSIndustry: `Global Industry Classification Standard (GICS) - The GICS industry list consists 69 industries.<br/>

        GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.<br/><br/>
        
        The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.<br/>
        
        <br/><5>{{Link}}</5>`,
        },
        special: {
          GICSIndustrySector: `Global Industry Classification Standard (GICS) - The GICS sector list consists 11 sectors<br/>

          GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.<br/><br/>
          
          The list below shows the GICS sector based classification of your suppliers.<br/>
          <br/><5>{{Link}}</5>`,

          GICSIndustryGroup: `Global Industry Classification Standard (GICS) - The GICS industry group list consists 24 industry groups<br/>
  
          GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.<br/><br/>
          
          The list below shows the GICS sector based classification of your suppliers.<br/>
          <br/><5>{{Link}}</5>`,
          GICSIndustry: `Global Industry Classification Standard (GICS) - The GICS industry list consists 69 industries.<br/><br/>
  
          GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.<br/><br/>
          
          The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.<br/><br/>
          
          The list below shows the GICS industry based classification of your suppliers. The number in () after each industry shows how many of your suppliers are having its principal business activity within the respective GICS Industry. The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.<br/>

          <br/><5>{{Link}}</5>`,
        },
      },
    },
    // MEDIA INSIGHTS TABLE
    mediaInsights: {
      columns: {
        mediaInsight: ' فهم الاعلام ',
      },
    },
    // REPORTS TABLE
    reports: {
      columns: {
        reportType: ' نوع التقرير ',
        description: ' وصف ',
        score: ' نتيجة ',
        progress: ' تقدم ',
        createdAt: ' أنشئت في ',
        completedAt: ' اكتمل في ',
      },
      tooltips: {
        openReport: ' افتح التقرير ',
        cloneReport: ' تقرير الاستنساخ ',
        displayActions: ' إجراءات العرض ',
        deleteReport: ' حذف التقرير ',
      },
      modals: {
        reportModal: {
          title: ' أقسام النموذج ',
        },
      },
    },
    // OPEN REPORT TABLE
    openReport: {
      columns: {
        section: ' قسم ',
        createdAt: ' أنشئت في ',
        numberOfQuestions: ' عدد الأسئلة ',
        completedAt: ' اكتمل في ',
        score: ' نتيجة ',
        completedBy: ' اكتمل بواسطة ',
      },
    },
    // RESOURCES TABLE
    resources: {
      columns: {
        ResourceName: ' اسم المورد ',
        Type: ' نوع ',
        Size: ' حجم ',
        RequiredSubscriptionType: ' نوع الاشتراك المطلوب ',
        ResourceCategory: ' فئة الموارد ',
        Price: ' سعر ',
        PriceBronze: ' سعر البرونز ',
        PriceSilver: ' سعر الفضة ',
        PriceGold: ' سعر الذهب ',
        Classification: 'Classification',
        Interests: 'Interests',
      },
      tooltips: {
        download: ' قم بتنزيل المورد ',
      },
    },

    // STUDENTS TABLE
    students: {
      students: {
        tabName: 'Students',
      },
      archived: {
        tabName: 'Archive',
      },
      columns: {
        studentName: ' أسم الطالب ',
        qId: ' البطاقة الشخصية ',
        rank: ' رتبة ',
        branch: ' فرع ',
        militaryId: ' الهوية العسكرية ',
        universityName: ' اسم الجامعة ',
        universityCity: ' مدينة الجامعة ',
        universityCountry: ' بلد الجامعة ',
        degreeType: ' نوع الشهادةالعلمية ',
        program: ' برنامج ',
        yearOfProgram: ' سنة البرنامج ',
        startYear: ' سنة البدء ',
        expectedGraduationYear: ' سنة التخرج المتوقعة ',
        graduationYear: ' سنة التخرج ',
        graduationApproved: 'Graduation Approved',
      },
    },
    // CCR TABLE
    configurationChangeRequest: {
      columns: {
        CCRId: 'معرف طلب تغيير التكوين',
        CCR: ' طلب تغيير التكوين',
        creationDTG: ' انشاء مجموعة الوقت والتاريخ',
        service: ' خدمة ',
        function: ' وظيفة ',
        criticality: ' الأهمية ',
        urgency: ' السرعة المطلوبة ',
        status: ' حالة ',
      },
    },
    // TT TABLE
    troubleTicket: {
      columns: {
        troubleTicketId: ' معرف تذكرة المشكلة ',
        troubleTicket: ' تذكرة المشكلة ',
        creationDTG: ' انشاء مجموعة الوقت والتاريخ ',
        service: ' خدمة ',
        function: 'وظيفة',
        criticality: 'الاهمية',
        urgency: 'السرعة المطلوبة',
        status: 'الحالة',
      },
    },
    // CUSTOMER INVITATION TABLE
    customerInvitations: {
      columns: {
        customersCompanyName: ' اسم شركة العملاء ',
        receiverUserName: ' الاسم المستخدم للمستقبل ',
        receiverUserEmail: ' البريد الإلكتروني للمستخدم المستقبل ',
        deliveryStatus: ' حالة التسليم ',
        deliveryDate: ' تاريخ التسليم  ',
        registerStatus: ' حالة التسجيل ',
        registerDate: ' تاريخ التسجيل ',
        senderUserName: ' اسم المستخدم المرسل ',
        senderUserEmail: ' البريد الإلكتروني للمستخدم المرسل ',
      },
    },
    userInvitations: {
      columns: {
        usersCompanyName: ' اسم شركة المستخدمين ',
        receiverUserName: ' الاسم المستخدم للمستقبل ',
        receiverUserEmail: ' البريد الإلكتروني للمستخدم المستقبل ',
        deliveryStatus: ' حالة التسليم ',
        deliveryDate: ' تاريخ التسليم او الوصول ',
        registerStatus: ' حالة التسجيل ',
        registerDate: ' تاريخ التسجيل ',
        senderUserName: ' اسم المستخدم المرسل ',
        senderUserEmail: ' البريد الإلكتروني للمستخدم المرسل ',
      },
    },
  },

  // GLOBAL VARIABLES
  global: {
    add: ' اضف  ',
    save: ' احفظ ',
    upload: ' تحميل ',
    export: ' يصدّر ',
    ok: ' موافق ',
    yes: 'نعم',
    no: 'لا',
    download: ' تحميل ',
    print: 'Print',
    cancel: ' يلغي ',
    all: ' الجميع ',
    reset: ' إعادة ضبط ',
    filter: ' نقي ',
    modernSlaveryStatement: ' بيان العبودية الحديثة ',
    editSupplier: ' تحرير المورد ',
    deleteSupplier: ' حذف المورد ',
    edit: ' يحرر ',
    archive: ' أرشيف ',
    delete: ' يمسح ',
    send: ' يرسل ',
    proceed: ' يتابع ',
    freeze: ' تجميد ',
    unfreeze: ' قم بإلغاء التجميد ',
    show: ' يعرض ',
    openSaq: ' يفتح ',
    actions: ' أجراءات ',
    submit: ' يُقدِّم ',
    enter: ' يدخل ',
    create: ' يبتكر ',
    start: 'Start',
    number: 'Number',
    network: 'Network',
    inviteUser: 'Invite User',
    inviteCustomer: 'Invite Customer',
    openReport: ' افتح التقرير ',
    cloneReport: ' تقرير الاستنساخ ',
    actionReport: 'لا يوجد أي إجراء موصى به لاستبيان الاندماج.',
    goToAction: 'Go to action',
    archiveModal: {
      title: ' أرشيف ',
      content: `هل أنت متأكد؟{{content}} سيتم أرشفة`,
    },
    deleteModal: {
      title: ' يمسح ',
      content: `هل أنت متأكد؟{ { content } } سيتم حذف`,
      formDeleteSentence: 'سيتم حذف هذا النموذج. هل أنت متأكد؟',
    },
    subscriptionTypes: {
      bronze: ' برونز',
      silver: 'فضة',
      gold: 'ذهب',
    },
    twoFATypes: {
      DISABLED: ' عدم القدرة ',
      GOOGLEAUTHENTICATION: 'Google مصادقة',
      MAILAUTHENTICATION: 'المصادقة على البريد ',
      SMSAUTHENTICATION: ' مصادقة الرسائل القصيرة ',
      QRCODEAUTHENTICATION: ' مصادقة رمز الاستجابة السريعة ',
    },
    relevant: 'Relevant',
    deleteAll: 'Delete All',
    archiveAll: 'Archive All',
    relevantAll: 'Relevant All',
    readAll: 'Read All',
    read: 'Read',
    star: 'Star',
    editLabel: 'Edit Label',
  },

  // LOGIN PAGE
  loginPage: {
    dontHaveAccount: ' ليس لديك حساب بعد؟',
    register: ' يسجل ',
    title: ' تسجيل الدخول ',
    email: ' بريد إلكتروني ',
    customer: ' عميل ',
    password: ' كلمة المرور ',
    submit: ' تسجيل الدخول ',
    forgotPassword: ' هل نسيت كلمة السر ',
    needHelp: ' تحتاج مساعدة ',
    selectCompany: ' اختر الشركة ',
    freezeModal: {
      titleUser: ' المستخدم المجمد ',
      titleCustomer: ' العميل المجمد ',
      contact1: ' يرجى الاتصال بنا باستخدام ',
      contact2: ' العنوان للحصول على مزيد من المعلومات وإلغاء تجميد حسابك.',
      emailAddress: 'info@dataunitor.com',
      user: ' مستخدم ',
      customer: ' عميل ',
      close: ' يغلق ',
    },
  },

  // GOOGLE AUTHENTICATION PAGE
  googleAuthenticationVerificationPage: {
    title: ' Googleالتحقق من مصادقة ',
    email: ' بريد إلكتروني ',
    password: ' كلمة المرور ',
    next: ' التالي ',
    submit: ' تسجيل الدخول ',
    authentication: ' إعداد التحقق من رمز الاستجابة السريعة ',
    authenticate: ' مصادقة ',
    code: ' شفرة ',
    placeholder: ' أعد كتابة كلمة المرور الخاصة بك ',
    openApp: 'افتح تطبيق Google للمصادقة على هاتفك أو جهازك اللوحي ',

    scan: ' يرجى مسح رمز الاستجابة السريعة ضوئيًا على الشاشة والتحقق من صحة عملية تسجيل الدخول باستخدام الرمز المكون من 6 أرقام ',
    modalPlaceholder: ' ادخل الرمز ',
    verifyGoogle: ' الرجاء إعادة كتابة كلمة المرور الخاصة بك لمتابعة التحقق من مصادقة  Google ',

    login: {
      email: ' بريد إلكتروني ',
      customer: ' هوية الزبون ',
      password: ' كلمة المرور ',
    },
  },

  // MAIL AUTHENTICATION PAGE
  mailAuthenticationPage: {
    googleTitle: ' مصادقة رمز الاستجابة السريعة ',
    mailTitle: ' مصادقة البريد ',
    title: ' مصادقة متعددة العوامل ',
    subTitle: ' توضح هذه الخطوة الإضافية أنك تحاول بالفعل تسجيل الدخول ',
    mailMessage: ' تم للتو إرسال بريد إلكتروني يحتوي على رمز التحقق المكون من 6 أرقام إلى ',
    code: ' شفرة ',
    submit: ' يُقدِّم ',
    codeMessageGoogle: 'الرجاء إدخال رمز التحقق المكون من 6 أرقام لرمز الاستجابة السريعة الخاص بك.',
    codeMessageMail: 'الرجاء إدخال رمز التحقق المكون من 6 أرقام لمرة واحدة.',
    openApp: ' على هاتفك او جهازك اللوحي Googleافتح تطبيق مصادقة ',
    openMail: ' افتح صندوق الوارد لبريدك الإلكتروني ',
    placeholder: ' ادخل الرمز ',
    lostQR: ' فقدت رمز الاستجابة السريعة ',
    authentication: {
      code: ' شفرة ',
    },
  },

  // REGISTER PAGE
  registerPage: {
    title: ' يسجل ',
    fullname: ' الاسم الكامل ',
    password: ' كلمة المرور ',
    confirmPassword: ' تأكيد كلمة المرور ',
    submit: ' يسجل ',
    star: '*',
    warning: ' تحذير ',
    warningModal: {
      title: ' تحذير ',
      expireContent: ' انتهت صلاحية رابط دعوة تسجيل العميل.',
      registeredContent: ' لقد قمت بالفعل بالتسجيل في الامتثال المستمر لسلسلة التوريد باستخدام رابط الدعوة.',
      expireButtonText: ' يغلق ',
      registeredButtonText: ' صفحة تسجيل الدخول ',
    },
  },
  // FORGOT PASSWORD PAGE
  forgotPasswordPage: {
    title: ' إعادة تعيين كلمة المرور ',
    detail: ' الرجاء إدخال عنوان البريد الإلكتروني الخاص بك لطلب إعادة تعيين كلمة المرور ',
    email: ' بريد إلكتروني ',
    buttonText: ' إعادة تعيين كلمة المرور ',
    forgotPassword: {
      email: ' بريد إلكتروني ',
    },
  },

  // FORGOT PASSWORD DONE PAGE
  forgotPasswordDonePage: {
    title: ' إعادة تعيين كلمة المرور ',
    detail: ' لقد تم إرسال بريد إلكتروني إلى عنوان البريد الإلكتروني الخاص بك.',
    moreDetail: ' يمكنك استخدام الرابط الموجود في صندوق الوارد الخاص بك وإعادة تعيين كلمة المرور الخاصة بك.',
    buttonText: ' تم ',
  },

  // RESET PASSWORD PAGE
  resetPasswordPage: {
    title: ' إنشاء كلمة المرور ',
    newPassword: ' كلمة المرور الجديدة ',
    newPasswordConfirmation: ' أعد كتابة كلمة السر الجديدة ',
    submit: ' ينشأ ',
  },

  // NEED HELP PAGE
  needHelpPage: {
    title: ' تحتاج مساعدة ',
    sendMail:
      '  يرجى إرسال بريد إلكتروني يحتوي على المشكلة التي تواجهها ومعلومات حساب الامتثال المستمر لسلسلة التوريدالخاص بك لمعالجتها أدناه ',
    mailAddress: 'sc3.sfa.consultant@slavefreealliance.org',
    sc3Team: 'بعد إرسال البريد الإلكتروني، سيقوم فريق الامتثال المستمر لسلسلة التوريد بالتواصل معك في أقرب وقت ممكن.',
  },

  // CUSTOMER REGISTER PAGE
  customerRegisterPage: {
    title: ' تسجيل العملاء ',
    formLabels: {
      email: ' بريد إلكتروني ',
      fullname: ' الاسم الكامل ',
      password: ' كلمة المرور ',
      confirmPassword: ' تأكيد كلمة المرور ',
      companyName: ' اسم الشركة ',
      website: ' موقع إلكتروني ',
      duns: ' نظام ترقيم البيانات العالمي ',
      subscribeType: ' حدد نوع الاشتراك الخاص بك ',
      registryId: ' معرف التسجيل ',
      companyNumber: ' رقم الشركة ',
      legalCompanyName: ' اسم الشركة القانوني ',
      country: ' دولة ',
      industry: ' صناعة ',
      accountType: ' نوع الاشتراك ',
    },
    star: '*',
    submit: ' إنشاء عميل ',
    complete: ' مكتمل ',
    next: ' التالي ',
    modalWarning1: ' تمت إضافته بالفعل إلى النظام. إذا كنت متأكدًا من معلومات الشركة، فيرجى الاتصال بنا باستخدام ',
    mailAddress: 'help@sc3.slavefreealliance.com',
    modalWarning2: ' عنوان. يمكنك محاولة التغيير ',
    modalWarning3: ' الحقول لإكمال هذه الخطوة.',
    or: ' أو ',
    ok: ' نعم ',
    fillWarning: ' يرجى تعبئة جميع الحقول المطلوبة.',
  },
  accountTypeComponentContent: {
    monitoredCompanyLimit: ' مراقبة حدود الشركة ',
    userLimit: ' حد المستخدم ',
  },

  // ERROR MESSAGES
  errors: {
    cocDependency:
      "هناك إجراء ارتباط لـ قواعد السلوك  متعلق بهذا الوثيقة. للمتابعة، يرجى حذف قواعد السلوك المرتبطة في علامة 'رصد قواعد السلوك  أولاً ثم حاول مرة أخرى.",
    unknownError: ' خطأ غير معروف ',
    badRequest: ' طلب غير جيد ',
    resetPasswordLimit: ' لقد قمت بتغيير كلمة المرور الخاصة بك مؤخرا. الرجاء معاودة المحاولة في وقت لاحق.',
    NotFound: ' لا يمكن العثور على المرفق ',
    internalServerError: ' خطأ في الخادم الداخلي ',
    connectionError: ' خطأ في الإتصال ',
    sessionExpired: ' انتهت الجلسة ',
    authorizationError: ' خطأ في الترخيص ',
    dataNotFound: ' لم يتم العثور على بيانات ',
    unsuccessfull: ' عملية غير ناجحة ',
    missingValue: ' مطلوب قيمة واحدة على الأقل ',
    incorrectCredentials: ' أوراق غير صحيحة ',
    incorrectPassword: ' كلمة سر خاطئة ',
    emailAlreadyExists: ' البريد الالكتروني موجود بالفعل ',
    emailDesNotExists: ' البريد الإلكتروني غير موجود ',
    fieldRequired: ' هذا الحقل لا يمكن ان يكون فارغا ',
    invalidEmail: ' يجب أن يكون بريدًا إلكترونيًا صالحًا ',
    min8: ' يجب أن يكون أكثر من 8 أحرف ',
    max64: ' يجب أن يكون 64 حرفًا كحد أقصى ',
    max100: ' يجب أن يكون 100 حرف كحد أقصى ',
    max128: ' يجب أن يكون 128 حرفًا كحد أقصى ',
    max255: ' يجب أن يكون أقل من 256 حرفًا ',
    max256: ' يجب أن يكون 256 حرفًا كحد أقصى ',
    max30: ' يجب أن يكون أقل من 30 حرفا ',
    max1000: ' يجب أن يكون 1000 حرف كحد أقصى ',
    max10000: ' يجب أن يكون 10000 حرف كحد أقصى ',
    email: ' يجب أن يكون بريدًا إلكترونيًا صالحًا ',
    onlyLetters: ' مسموح الحروف فقط ',
    required: ' هذا الحقل لا يمكن ان يكون فارغا ',
    range: ' يجب أن يكون بين: ',
    invalidSignature: ' رابط التسجيل منتهي الصلاحية ',
    passwordResetExpired: ' انتهت صلاحية رابط إعادة تعيين كلمة المرور.',
    customerRegisterExpired: ' انتهت صلاحية رابط تسجيل العملاء.',
    mailAuthenticationExpired: ' انتهت صلاحية رابط مصادقة البريد.',
    passwordsNotMatching: ' كلمات المرور غير متطابقة ',
    verificationCodeNotMatch: ' رمز التحقق غير صالح.',
    websiteFormat: ' يجب أن يكون موقع الويب بتنسيق معرف المورد الموحد ',
    isPositive: ' يجب أن يكون هذا الحقل رقمًا موجبًا ',
    dateNotFound: ' لم يتم العثور على التاريخ ',
    titleNotFound: ' لم يتم العثور على العنوان ',
    urlNotFound: `لم يتم العثور على عنوان معرف المورد الموحد `,
    invalidPassword: ' مطلوب حرف كبير وصغير ورقم واحد على الأقل ',
    sameEnterprise: ' لا يمكن للشركات أن تكون هي نفسها ',
    interactionExists: ' تمت إضافة هذه الشركة بالفعل إلى سلسلة التوريد الخاصة بك ',
    missingInterests: ' المصالح المفقودة ',
    EXCEL_ERROR: 'Excelخطأ في',
    EXCEL_WORKSHEET_NAMES_DOES_NOT_MATCH: ' أسماء أوراق عمل اكسل غير متطابقة ',
    EXCEL_FIELD_NAMES_DOES_NOT_MATCH: ' أسماء الحقول غير متطابقة ',
    EXCEL_FIELD_VALIDATION_ERROR: ' خطأ في التحقق من صحة الحقل ',
    EXCEL_CREATE_ERROR: ' إنشاء تحذير ',
    EXCEL_INVALID_COUNTRY_NAME_ERROR: ' اسم البلد غير ساري ',
    EXCEL_INVALID_COMPANY_NAME_ERROR: ' اسم الشركة غير ساري ',
    EXCEL_INVALID_RELATION_TYPE_ERROR: ' نوع العلاقة غير سارية ',
    EXCEL_DELETE_ERROR: ' حذف خطأ ',
    errorRow: ' صف ',
    errorColumn: ' عمود ',
    errorHandled: ' تمت معالجة الخطأ ',
    companies: ' شركات ',
    blacklists: ' القوائم السوداء ',
    interactions: ' التفاعلات ',
    forgotPasswordOverflow: ' لقد تم الوصول إلى الحد الأقصى لطلب كلمة المرور.',
    lostQrCodeOverflow: ' تم الوصول إلى حد طلب رمز الاستجابة السريعة المفقود',
    incorrectAuthenticationCode: ' الرمز غير صالح ',
    companyDeleteErrorMessage: ' لا يمكن حذف الشركة بسبب التأثيرات على العملاء الآخرين.',
    companyEditErrorMessage: ' لا يمكن تحرير الشركة بسبب التأثيرات على العملاء الآخرين.',
    companyAlreadyExists: ' توجد شركة بالفعل تحمل اسم الشركة ورقم الشركة والبلد.',
    fileTypeError: 'Supported image types are only .jpg and .jpeg',
    fileTypeErrorTemplate: 'Please upload the template excel file',
    // fileTypeErrorRequest:
    //   'Supported file types are .jpg, .jpeg, .png, .jpg, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx and videos',
    fileTypeErrorRequest: 'Supported file types only .jpg, .jpeg, .png, .pdf, .docx, .xlsx, .pptx and videos',
    cocFileTypeErrorRequest: 'Supported file types are .pdf and .docx',
    nullAuthentication: ' الرجاء تحديد نوع المصادقة ',
    changeAuthentication: ' الرجاء تغيير نوع المصادقة الحالي الخاص بك ',
    ppSizeError: ' يتجاوز الحد الأقصى لحجم الملف. الحد الأقصى لحجم الملف المسموح به هو 1 ميجابايت ',
    requestSizeError: ' يتجاوز الحد الأقصى لحجم الملف. الحد الأقصى لحجم الملف المسموح به هو اجمالا 5 ميجابايت ',
    requestSizeErrorCoc: ' يتجاوز الحد الأقصى لحجم الملف. الحد الأقصى لحجم الملف المسموح به هو اجمالا 10 ميجابايت ',
    anErrorOccurred: ' حدث خطأ. حاول مرة اخرى.',
    incorrectPasswordGoogleAuth: ' كلمة المرور غير صحيحة ',
    customerAccountTypeLimitsReached: ' تم الوصول إلى حدود نوع حساب العميل ',
    cannotDownloadResourceWithoutPayment: ' لا يمكن تنزيل المورد بدون دفع ',
    notificationFeedbackError: ' لقد حدثت مشكلة أثناء إرسال ملاحظاتك.',
    mandatoryQuestionEmpty: ' لا يمكن أن تكون الأسئلة الإلزامية فارغة.',
    selectSAQ: ' الرجاء تحديد استبيان التقييم الذاتي للمتابعة.',
    selectEmail: ' الرجاء تحديد عنوان بريد إلكتروني واحد على الأقل.',
    maxSupllierMonitorLimit: ' لقد وصلت إلى الحد الأقصى، يرجى ترقية نوع اشتراكك لمراقبة المزيد من الموردين.',
    minCurrentDate: ' لا يمكن أن يكون لديك تاريخ أقل من تاريخ تعيين الإجراء ',
    setupNotificationExpiresError:
      'يجب ألا يكون تاريخ انتهاء الصلاحية في الماضي، إعلانك تم إنشاؤه بدون تحديد تاريخ انتهاء.',
    systemLabelEditWarning: ` تم إنشاء هذه التسمية بواسطة النظام، ولا يمكن تغييرها.`,
    attachmentPathNotFound: ' المرفقات لم يتم العثور عليها ',
    linkAlreadyRegistered: ' تم الانتهاء من التسجيل بالفعل مع هذا الرابط ',
    linkIsExpired: ' رابط التسجيل منتهي الصلاحية ',
  },

  success: {
    updated: ' تم التحديث بنجاح ',
    edited: ' تم التعديل بنجاح ',
    added: ' أضيف بنجاح ',
    deleted: ' تم الحذف بنجاح ',
    archived: ' تمت الأرشفة بنجاح ',
    approved: ' تمت الموافقة بنجاح ',
    declined: ' تم الرفض بنجاح ',
    saved: ' تم الحفظ بنجاح ',
    started: ' بدأت بنجاح ',
    stoped: ' تم التوقف بنجاح ',
    restarted: ' تمت إعادة التشغيل بنجاح ',
    uploaded: ' تم الرفع بنجاح ',
    registered: ' سجلت بنجاح ',
    created: ' تم إنشاؤه بنجاح ',
    invitationSent: ' تم إرسال الدعوة بنجاح ',
    typeformIntegration: ' لقد تم تسجيل إجاباتك بنجاح.',
    report: ' تم إنشاء التقرير بنجاح ',
    lostQRCode: ' تم إرسال رمز الاستجابة السريعة الخاص بك للتو إلى بريدك الإلكتروني.',
    notificationFeedbackSuccess: ' لقد تم تلقي ملاحظاتك بنجاح.',
    ruleEditExpiredDateSuccess:
      ' تم حفظ التغييرات بنجاح. ومع ذلك، فإن تاريخ انتهاء صلاحية هذا الإشعار قد انقضى بالفعل.',
    ruleEditInactiveFeedbackSuccess: ' تم حفظ التغييرات بنجاح. ومع ذلك، فإن حالة هذا الإشعار غير نشطة.',
    saqSended: ' تم إرسال استبيان التقييم الذاتي بنجاح ',
    cocSended: ' تم إرسال قواعد السلوك بنجاح ',
    download: ' تم تنزيل الملف بنجاح ',
  },

  // INTERESTS PAGE
  interestsPage: {
    pageName: 'All Interests',
    tabCategorizedName: {
      identified: 'Identified',
      recognized: 'Recognized',
    },
    person: {
      tabname: 'Person',
      addPerson: 'Add Person',
      identified: {
        columnNames: {
          name: 'Name',
          surname: 'Surname',
          gender: 'Gender',
          age: 'Age',
          email: 'Email',
          status: 'Status',
          nationality: 'Nationality',
          organization: 'Organization',
          whiteCollar: 'White Collar',
          blueCollar: 'Blue Collar',
        },
      },
      recognized: {
        columnNames: {
          genders: 'Genders',
          ages: 'Ages',
        },
      },
    },
    vehicle: {
      tabname: 'Vehicle',
      addVehicle: 'Add Vehicle',
      identified: {
        columnNames: {
          name: 'Name',
          category: 'Category',
          brand: 'Brand',
          color: 'Color',
          plate: 'Plate',
          staff: 'Staff',
          organization: 'Organization',
          description: 'Description',
        },
      },
      recognized: {
        columnNames: {
          types: 'Types',
          colors: 'Colors',
          brands: 'Brands',
        },
      },
    },
    location: {
      tabname: 'Location',
      addLocation: 'Add Location',
      identified: {
        columnNames: {
          name: 'Name',
          category: 'Category',
          description: 'Description',
          latitude: 'Latitude',
          longtitude: 'Longtitude',
        },
      },
    },
    enterprise: {
      tabname: 'Enterprise',
      addEnterprise: 'Add Enterprise',
      mapView: 'Map View',
      identified: {
        columnNames: {
          companyName: 'Company Name',
          country: 'Country',
          industry: 'Industry',
          hasNews: 'Has News',
          blacklisted: 'Blacklisted',
        },
      },
    },
    industry: {
      tabname: 'Industry',
    },
    topic: {
      tabname: 'Topic',
      addTopic: 'Add Topic',
      identified: {
        columnNames: {
          name: 'Name',
        },
      },
    },
    functionalArea: {
      tabname: 'Functional Area',
      recognized: {
        columnNames: {
          name: 'Name',
          directorate: 'Directorate',
          functionalArea: 'Functional Area',
        },
      },
    },
    sensor: {
      tabname: 'Sensor',
      addSensor: 'Add Sensor',
      identified: {
        columnNames: {
          name: 'Name',
          sensor: 'Sensor',
          category: 'Category',
          sensorName: 'Sensor Name',
          ipAddress: 'Ip Adress',
          macAddress: 'Mac Adress',
          description: 'Description',
        },
      },
    },
    incident: {
      tabname: 'Incident',
      recognized: {
        columnNames: {
          name: 'Name',
          type: 'Type',
        },
      },
    },
    communication: {
      tabname: 'Communication',
      recognized: {
        columnNames: {
          name: 'Name',
        },
      },
    },
    object: {
      tabname: 'Object',
      recognized: {
        columnNames: {
          name: 'Name',
        },
      },
    },
    unit: {
      tabname: 'Unit',
      recognized: {
        columnNames: {
          category: 'Category',
          types: 'Types',
          units: 'Units',
        },
      },
    },
    relation: {
      tabname: 'Interaction',
      addInteraction: 'Add Interaction',
      interaction: {
        columnNames: {
          name: 'Name',
          fromInterestType: 'Source Interest Type',
          fromInterest: 'Source Interest',
          relation: 'Relation',
          toInterestType: 'Target Interest Type',
          toInterest: 'Target Interest',
        },
      },
    },
  },

  // NETWORK PAGE
  networkPage: {
    valueChain: {
      tabName: ' سلسلة القيمة الخاصة بي ',
      tabName2: ' سلسلة القيم ',
      tableView: {
        tabName: ' عرض جدول ',
        generateRiskReport: ' إنشاء تقرير المخاطر ',
        addSupplier: ' إضافة مورد ',
        generateReport: ' انشاء تقرير ',
      },
      networkView: {
        tabName: ' عرض الشبكة ',
      },

      filtered: ' تمت تصفيته ',
      monitored: ' مراقب ',
      totalSupplier: ' إجمالي المورد ',
    },
    saq: {
      tabName: ' استبيان التقييم الذاتي الخاص بي ',
      tabName2: ' استبيان التقييم الذاتي ',

      saqTemplates: {
        tabName: 'نماذج استبيان التقييم الذاتي',
      },
      emailTemplates: {
        tabName: ' نماذج البريد الإلكتروني ',
        createMailTemplate: ' إنشاء نموذج البريد ',
        createEmailTemplate: ' إنشاء نموذج البريد الإلكتروني ',
      },
      sendSaq: {
        tabName: 'ارسال استبيان التقييم الذاتي',
      },
      monitorSaq: {
        tabName: 'مراقبة استبيان التقييم الذاتي',
      },
      actionCenter: {
        tabName: ' مركز التحكم ',
      },
    },
    coc: {
      tabName: 'قواعد السلوك الخاصة بي ',
      tabName2: 'قواعد السلوك ',
      cocTemplates: {
        tabName: 'نماذج قواعد السلوك ',
        uploadCoc: ' تحميل  قواعد السلوك ',
      },
      emailTemplates: {
        tabName: ' نماذج البريد الإلكتروني ',
        createMailTemplate: ' إنشاء نموذج البريد ',
      },
      sendCoc: {
        tabName: ' إرسال  قواعد السلوك ',
      },
      monitorCoc: {
        tabName: ' مراقبة قواعد السلوك ',
      },
    },
    media: {
      tabName: ' وسائل الإعلام الخاصة بي ',
      tabName2: ' وسائط ',
      source: ' مصدر ',
      company: ' شركة ',
      location: ' موقع ',
      industry: ' صناعة ',
      mediaInsight: ' فهم الاعلام ',
      mediaType: ' نوع الوسائط ',
      date: ' تاريخ ',
    },
    locations: {
      tabName: ' مواقعي ',
      tabName2: ' المواقع ',
      tableView: {
        tabName: ' عرض جدول ',
      },
      mapView: {
        tabName: ' عرض الخريطة ',
      },
    },
    industries: {
      tabName: 'صناعاتي ',
      tabName2: ' الصناعات ',
    },
    details: {
      tabName: ' تفاصيلي ',
      tabName2: ' تفاصيل ',
    },
  },

  industries: {
    pageName: 'All Industries',
    tableColumns: {
      GICSIndustryCode: 'GICS Industry Code',
      GICSIndustryName: 'GICS Industry',
      GICSIndustryGroup: 'GICS Industry Group',
      GICSSector: 'GICS Sector',
    },
    tableColumns2: {
      parents: {
        GICSIndustrySector: 'GICS Industry Sector',
        e1: '',
        GICSIndustryGroup: 'GICS Industry Group',
        e2: '',
        GICSIndustry: 'GICS Industry',
      },
      children: {
        GICSSectorCode: 'Code',
        GICSSector: 'Name',
        GICSIndustryGroupCode: 'Code',
        GICSIndustryGroup: 'Name',
        GICSIndustryCode: 'Code',
        GICSIndustryName: 'Name',
      },
    },
    columnTooltips: {
      source: 'Source: ',
      sourceLink: 'https://www.msci.com/our-solutions/indexes/gics',
      GICSIndustrySector: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS sector list consists 11 sectors',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
      },
      GICSIndustryGroup: {
        para1:
          'Global Industry Classification Standard (GICS) - The GICS industry group list consists 24 industry groups',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
      },
      GICSIndustry: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS industry list consists 69 industries.',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3:
          'The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.',
      },
    },
    columnTooltipsTab: {
      source: 'Source: ',
      sourceLink: 'https://www.msci.com/our-solutions/indexes/gics',
      GICSIndustrySector: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS sector list consists 11 sectors',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3: 'The list below shows the GICS sector based classification of your suppliers.',
      },
      GICSIndustryGroup: {
        para1:
          'Global Industry Classification Standard (GICS) - The GICS industry group list consists 24 industry groups',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3: 'The list below shows the GICS sector based classification of your suppliers.',
      },
      GICSIndustry: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS industry list consists 69 industries.',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3:
          'The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.',
        para4:
          'The list below shows the GICS industry based classification of your suppliers. The number in () after each industry shows how many of your suppliers are having its principal business activity within the respective GICS Industry. The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.',
      },
    },
    industryEditPage: {
      tabs: {
        data: ' بيانات ',
        network: ' شبكة ',
      },
    },
  },

  resourcesPage: {
    pageName: ' موارد ',
    addResources: ' أضف الموارد ',
    columnTooltips: {
      source: 'Source: ',
      sourceLink: 'https://www.msci.com/our-solutions/indexes/gics',
      GICSIndustrySector: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS sector list consists 11 sectors',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
      },
      GICSIndustryGroup: {
        para1:
          'Global Industry Classification Standard (GICS) - The GICS industry group list consists 24 industry groups',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
      },
      GICSIndustry: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS industry list consists 69 industries.',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3:
          'The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.',
      },
    },
    columnTooltipsTab: {
      source: 'Source: ',
      sourceLink: 'https://www.msci.com/our-solutions/indexes/gics',
      GICSIndustrySector: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS sector list consists 11 sectors',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3: 'The list below shows the GICS sector based classification of your suppliers.',
      },
      GICSIndustryGroup: {
        para1:
          'Global Industry Classification Standard (GICS) - The GICS industry group list consists 24 industry groups',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3: 'The list below shows the GICS sector based classification of your suppliers.',
      },
      GICSIndustry: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS industry list consists 69 industries.',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3:
          'The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.',
        para4:
          'The list below shows the GICS industry based classification of your suppliers. The number in () after each industry shows how many of your suppliers are having its principal business activity within the respective GICS Industry. The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.',
      },
    },
  },

  companySettingsPage: {
    pageName: ' إعدادات الشركة ',
    tableContent: {
      operationNames: {
        freezeCompany: 'تجميد  الشركة ',
        deleteCompany: ' حذف الشركة ',
        changeSubscriptionType: ' تغيير نوع الاشتراك ',
        change2FASettings: ' تغيير إعدادات التحقق الثنائي للعامل ',
      },
      descriptions: {
        freezeCompany:
          ' يؤدي تجميد الشركة إلى منع مستخدمي هذه الشركة من تسجيل الدخول إلى نظام الامتثال المستمر لسلسلة التوريد. يمكنك التراجع عن هذه العملية.',
        deleteCompany:
          ' يؤدي حذف الشركة إلى حذف الشركة ومستخدميها من نظام الامتثال المستمر لسلسلة التوريد. لا يمكنك التراجع عن هذه العملية.',
        changeSubscriptionType:
          ' يؤثر نوع الاشتراك على قيود الشركة مثل عدد المستخدمين وعدد قواعد الإشعارات وما إلى ذلك. يمكنك تغيير نوع الاشتراك إلى أي مستوى.',
        change2FASettings:
          ' إذا قمت بتغيير التحقق الثنائي للعامل ، فسيتأثر جميع المستخدمين النهائيين لهذه الشركة بهذا الإجراء. وهم بحاجة إلى التفويض بهذا الاختيار.',
      },
      actionTooltips: {
        freezeCompany: ' تجميد ',
        deleteCompany: ' يمسح ',
        changeSubscriptionType: ' تغيير نوع الاشتراك ',
        change2FASettings: ' تغيير إعدادات التحقق الثنائي للعامل ',
      },
    },
    modalContent: {
      change2FASettings: ' إعدادات المصادقة متعددة العوامل',
      freezeContent: ' {{content}}. لن يكون مستخدمواالشركة قادرين على استخدام النظام بعد الآن. هل أنت متأكد؟',

      deleteContent: ` سيتم حذف الشركة ومستخدميها نهائيا.لا يمكن التراجع عن هذه العملية.هل أنت متأكد؟`,
      subscriptionTypeSentence: ' حدد نوع الاشتراك.',
      subscriptionTypeLabel: ' نوع الاشتراك ',
      enable2FA: ' تمكين المصادقة متعددة العوامل ',
      authentication: ' نوع المصادقة ',
    },
  },
  // PERMISSIONS
  permissions: {
    home: ' الصفحة الرئيسية ',
    network: ' شبكة ',
    valueChain: ' سلسلة القيم ',
    tableView: ' عرض جدول ',
    networkView: ' عرض الشبكة ',
    saq: 'استبيان التقييم الذاتي',
    saqTemplates: 'نماذج استبيان التقييم الذاتي ',
    emailTemplates: ' نماذج البريد الإلكتروني ',
    sendSaq: 'ارسل استبيان التقييم الذاتي ',
    monitorSaq: 'مراقبة استبيان التقييم الذاتي ',
    actionCenter: ' مركز التحكم ',
    coc: ' قواعد السلوك ',
    cocTemplates: 'نماذج قواعد السلوك ',
    sendCoc: 'ارسل قواعد السلوك ',
    monitorCoc: 'مراقبة قواعد السلوك ',
    media: ' وسائط ',
    locations: 'مواقع',
    industries: 'صناعات',
    details: 'تفاصيل',
    notifications: ' إشعارات ',
    topic: ' عنوان ',
    location: ' موقع ',
    industry: ' صناعة ',
    reports: ' التقارير ',
    resources: ' موارد ',
    customerAndUserInvitations: ' دعوات العملاء والمستخدمين ',
    settings: ' إعدادات ',
    theme: 'الموضوع',
    userSettings: ' إعدادات المستخدم ',
    securitySettings: ' اعدادات الامان ',
    notificationsSettings: ' إعدادات الإشعارات ',
    notificationRules: ' قواعد الإشعارات ',
    notificationSettings: ' إعدادات الإشعار ',
    saqSettings: 'اعدادات استبيان التقييم الذاتي',
    cocSettings: 'اعدادات قواعد السلوك',
    monitorSupplierSettings: ' مراقبة إعدادات المورد ',
    controlPanel: ' لوحة التحكم ',
    authenticationSettings: ' إعدادات المصادقة ',
    students: ' طالب ',
    customerAndUser: ' العميل والمستخدم ',
    administration: ' إدارة ',
    profile: 'حساب تعريفي ',
    portal: ' بوابة ',
    importData: ' بيانات الاستيراد ',
    exportData: ' بيانات التصدير ',
    helpAndSupport: ' مساعدة و دعم ',
    tabs: ' مساعدة و دعم ',
    userGuide: ' دليل المستخدم ',
    faq: ' الاسئلةالمتكررة ',
    ccr: ' طلب تغيير التكوين ',
    tt: ' بطاقة المشكلة ',
    ruleSettings: ' إعدادات القاعدة ',
    interests: ' الإهتمامات ',
    fileUpload: ' تحميل الملف ',
    nSearch: 'بحث',
    runningModules: 'Running Modules',
    socialMedia: 'Social Media',
    newsSite: 'News Site',
    blacklist: 'Blacklist',
    courtRecord: 'Court Record',
    webSite: 'Web Site',
    machines: 'Machines',
    start: 'Start',
    number: 'Number',
    person: 'Person',
    vehicle: 'Vehicle',
    enterprise: 'Enterprise',
    functionalArea: 'Functional Area',
    sensor: 'Sensor',
    incident: 'Incident',
    communication: 'Communication',
    object: 'Object',
    identified: 'Identified',
    recognized: 'Recognized',
    unit: 'Unit',
    relation: 'Relation',
    interaction: 'Interaction',
  },
  map: {
    low: ' خطر قليل ',
    high: ' مخاطرة عالية ',
    selectCountry: ' اختر دولة لعرضها ',
    information: ' معلومة.',
    enterpriseMapContent: {
      selectCountry1: ' تمثل الدول الملونة على الخريطة مواقع الشركات الموردة لها ',
      selectCountry2: ' اختر دولة لرؤية الموردين.',
      supplyChainMap: ' خريطة سلسلة التوريد ',
      country: ' دولة ',
      supplierCompanies: ' الشركات الموردة من ',
    },
  },
  importData: {
    downloadTemplate: ' تحميل النموذج ',
    explanationFirstLine: ' يمكن إضافة الشركات والموردين بملفExcel  .',
    explanationSecondLine: ' استخدم زر تنزيل النموذج للوصول إلى ملف Excel المقدم.',
    justSelectFile: ' حدد ملف ',
  },
  page404: {
    title: ' الصفحة غير موجودة ',
    detail: ' عذرًا، لا يمكن العثور على الصفحة التي طلبتها. يرجى العودة إلى الصفحة الرئيسية ',
    buttonText: ' اذهب إلى الصفحة الرئيسية ',
  },
};
