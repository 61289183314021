import { FormInstance, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React from 'react';

interface ISelectAllProps extends SelectProps {
  setSelectedOptions?: any;
  formOptions?: { form: FormInstance; name: string };
}
const SelectAll: React.FC<ISelectAllProps> = ({ setSelectedOptions, formOptions, ...selectProps }) => {
  const options: DefaultOptionType[] =
    selectProps && selectProps.options && !selectProps.options.every((opt) => opt.disabled)
      ? [{ label: 'All', value: 'all' }, ...selectProps.options]
      : [];

  const dynamicSetter = (opt: DefaultOptionType[] | string[]) => {
    if (formOptions) {
      return formOptions.form.setFieldValue(formOptions.name, opt);
    }
    if (setSelectedOptions) {
      return setSelectedOptions(opt);
    }
  };

  const onOptionChange = (value: DefaultOptionType[] | string[]) => {
    const filteredOptions = options.filter((opt) => !opt.disabled);

    if (selectProps?.labelInValue) {
      const isAllSelected = (value as DefaultOptionType[]).find((opt) => opt.value === 'all');
      if (isAllSelected) {
        if (value.length === filteredOptions.length) {
          dynamicSetter([]);
        } else {
          const filtered = filteredOptions.filter((opt) => opt.value !== 'all');
          dynamicSetter(filtered);
        }
      } else {
        dynamicSetter(value);
      }
    } else if ((value as string[]).includes('all')) {
      if (value.length === filteredOptions.length) {
        dynamicSetter([]);
      } else {
        const filtered = filteredOptions.filter((opt) => opt.value !== 'all').map((opt) => opt.value as string);
        dynamicSetter(filtered);
      }
    } else {
      dynamicSetter(value);
    }
  };
  return <Select {...selectProps} options={options} onChange={onOptionChange} />;
};

export default SelectAll;
