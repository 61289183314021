import React from 'react';

import { iconType } from 'src/types';

const HomeIcon: React.FC<iconType.PropsType> = ({ color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      //   style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
        <circle id="Ellipse_1" data-name="Ellipse 1" cx="15.5" cy="15.5" r="15.5" fill={circleColor} />
        <path
          id="home_v2"
          d="M308.581,420.889l-9.736-9.292a.79.79,0,0,0-1.082,0l-9.736,9.314a1.2,1.2,0,0,0-.368.876,1.222,1.222,0,0,0,1.233,1.222h1.4v7.951a1.666,1.666,0,0,0,1.666,1.655h3.494a.79.79,0,0,0,.79-.79v-5.712a.087.087,0,0,1,.076-.087h3.938a.085.085,0,0,1,.087.087v5.679a.779.779,0,0,0,.79.79h3.494a1.666,1.666,0,0,0,1.666-1.655v-7.951h1.4a1.233,1.233,0,0,0,1.222-1.233A1.212,1.212,0,0,0,308.581,420.889Zm-8.275,3.591h-3.938a1.666,1.666,0,0,0-1.655,1.666v4.89h-2.7a.087.087,0,0,1-.087-.076v-8.741a.79.79,0,0,0-.79-.79h-1.309l8.513-8.167,8.524,8.167h-1.309a.79.79,0,0,0-.79.79v8.73a.087.087,0,0,1-.087.087h-2.7v-4.922a1.666,1.666,0,0,0-1.655-1.634Z"
          transform="translate(-282.66 -406.382)"
          fill={color}
        />
      </svg>
    </div>
  );
};

HomeIcon.defaultProps = {
  style: { display: 'flex', alignSelf: 'center' },
  color: 'white',
  onClick: undefined,
  className: undefined,
};

export default HomeIcon;
