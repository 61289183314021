import { Dropdown, MenuProps } from 'antd';
import { t } from 'i18next';
import React from 'react';
import ThreeDotIcon from 'src/icons/ThreeDotIcon';
import { NotificationResultType } from 'src/app/services/notifications/notifications-service.type';
import {
  NotificationArchiveStatusEnum,
  NotificationReadStatusEnum,
  NotificationRelevantStatusEnum,
} from 'src/enums/notifications.enum';

import showModal from 'src/app/components/ShowModalComponent/ShowModal';
import { checkPermission } from 'src/app/helpers/permission.helper';
import { routes } from 'src/configs/permissions.dictionary';
import usePermissions from 'src/app/hooks/usePermissions';
import { deleteNotifications, updateNotifications } from 'src/app/redux/notifications/notificationsSlice';
import { useAppDispatch } from 'src/app/redux/hook';
import EditLabel from 'src/app/pages/NotificationsPage/Dropdowns/EditLabel';

interface INotificationProp {
  notification: NotificationResultType;
}

const NotificationThreeDot: React.FC<INotificationProp> = ({ notification }) => {
  const dispatch = useAppDispatch();
  const { permissions } = usePermissions();

  const dropdownItems: MenuProps['items'] = [
    {
      key: 'read',
      label:
        notification.readStatus === NotificationReadStatusEnum.Unread
          ? t('notificationsPage.markAsRead')
          : t('notificationsPage.markAsUnread'),
      onClick: () =>
        dispatch(
          updateNotifications({
            ids: [notification.id],
            readStatus: Number(!notification.readStatus),
          }),
        ),

      disabled: !checkPermission(permissions, [routes['/Notifications'], 'read']),
    },
    {
      key: '2',
      label: t('notificationsPage.editLabel'),
      onClick: () =>
        showModal({
          title: 'Labels',
          hasFooter: false,
          content: <EditLabel notification={notification} />,
          backgroundColor: 'white',
          size: 'xsmall',
        }),
      disabled: !checkPermission(permissions, [routes['/Notifications'], 'editLabel']),
    },
    {
      key: 'relevant',
      label:
        notification.relevantStatus === NotificationRelevantStatusEnum.RELEVANT
          ? t('notificationsPage.markAsIrrelevant')
          : t('notificationsPage.markAsRelevant'),
      onClick: () =>
        dispatch(
          updateNotifications({
            ids: [notification.id],
            relevantStatus: Number(!notification.relevantStatus),
          }),
        ),
      disabled: !checkPermission(permissions, [routes['/Notifications'], 'relevant']),
    },
    {
      key: 'archive',
      label:
        notification.archiveStatus === NotificationArchiveStatusEnum.ARCHIVE
          ? t('notificationsPage.unArchive')
          : t('notificationsPage.archive'),
      onClick: () =>
        dispatch(
          updateNotifications({
            ids: [notification.id],
            archiveStatus: Number(!notification.archiveStatus),
          }),
        ),
      disabled: !checkPermission(permissions, [routes['/Notifications'], 'archive']),
    },
    {
      key: '5',
      label: t('notificationsPage.delete'),
      onClick: () => dispatch(deleteNotifications({ ids: [notification.id] })),
      disabled: !checkPermission(permissions, [routes['/Notifications'], 'delete']),
    },
  ];

  const filteredItems = dropdownItems.filter((item: any) => !item.disabled);

  return (
    <Dropdown
      menu={{
        items: filteredItems,
        onClick: (e) => {
          e.domEvent.stopPropagation();
        },
      }}
      placement="bottomRight"
      trigger={['click']}
      arrow
    >
      <ThreeDotIcon onClick={(e) => e.stopPropagation()} style={{ height: '28px', width: '8px', marginTop: '8px' }} />
    </Dropdown>
  );
};

export default NotificationThreeDot;
