import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, InputRef, Space, TableColumnType, UploadFile, message } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { UploadChangeParam } from 'antd/es/upload';
import { t } from 'i18next';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ActionColumnComponent from 'src/app/components/ActionColumnComponent/ActionColumnComponent';
import PageLayout from 'src/app/components/PageLayoutComponent/PageLayout';
import showModal from 'src/app/components/ShowModalComponent/ShowModal';
import TabLayout from 'src/app/components/TabLayoutComponent/TabLayout';
import { ICustomTabs } from 'src/app/components/TabLayoutComponent/tab-layout.config';
import { CustomColumnsType } from 'src/app/components/TableComponent/TableComponent';
import { ExportInterestForm } from 'src/app/forms/ExportInterestForm/ExportInterestForm';
import StudentForm from 'src/app/forms/StudentForm/StudentForm';
import { formHelper } from 'src/app/helpers';
import styles from 'src/app/pages/StudentsPage/StudentsPage.module.scss';
import { StudentDataIndex } from 'src/app/pages/StudentsPage/students-page.config';
import { ConfigContentStateType } from 'src/app/redux/configContent/configContentSlice';
import {
  useDeleteStudentMutation,
  useGetStudentsQuery,
  useImportStudentMutation,
  useUpdateStudentMutation,
} from 'src/app/redux/students/studentsSlice';
import AddInteractionIcon from 'src/icons/AddInteractionIcon';
import { StudentsColumnDataType } from 'src/types/table.type';

// Lazy Imports
const LazyStudentsTable = React.lazy(() => import('./StudentsTable/StudentsTable'));

const ranksArr = [
  { text: 'Mulazim - Second Lieutenant', value: 'Mulazim - Second Lieutenant' },
  { text: 'Mulazim Awwal - Lieutenant', value: 'Mulazim Awwal - Lieutenant' },
  { text: 'Naqib - Captain', value: 'Naqib - Captain' },
  { text: "Ra'id - Major", value: "Ra'id - Major" },
  {
    text: 'Muqaddam - Lieutenant Colonel',
    value: 'Muqaddam - Lieutenant Colonel',
  },
  { text: 'Aqid - Colonel', value: 'Aqid - Colonel' },
  { text: 'Madania - Civilian', value: 'Madania - Civilian' },
];

const branchArr = [
  { text: 'Land Force', value: 'Land Force' },
  { text: 'Navy', value: 'Navy' },
  { text: 'Air Force', value: 'Air Force' },
  { text: 'Civilian', value: 'Civilian' },
];

const degreesArr = [
  { text: 'Bachelor', value: 'Bachelor' },
  { text: 'Ph.D.', value: 'Ph.D.' },
  { text: 'Master', value: 'Master' },
];

const StudentsPage = () => {
  const [filteredTableData, setFilteredTableData] = useState<[]>([]);

  // For AntD filter By Search...
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (confirm: FilterDropdownProps['confirm']) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void, confirm: FilterDropdownProps['confirm']) => {
    clearFilters();
    confirm();
  };

  const getColumnSearchProps = (dataIndex: StudentDataIndex): TableColumnType<StudentsColumnDataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search by ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes((value as string)?.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const { data: studentsList, refetch: refetchStudents } = useGetStudentsQuery();

  const [deleteStudent] = useDeleteStudentMutation();

  const [studentForm] = Form.useForm();
  const [exportForm] = Form.useForm();

  const [importStudent] = useImportStudentMutation();

  const currentTheme: ConfigContentStateType = useSelector((state: any) => state.configContent);

  const [updateStudent] = useUpdateStudentMutation();

  const addStudent = (form: FormInstance) => (
    <div className="flex-center" style={{ columnGap: '50px', marginRight: '-2rem' }}>
      <div
        className="extra-tab-content"
        onClick={(event) =>
          showModal({
            event,
            title: 'Add Student',
            size: 'medium-2',
            content: <StudentForm form={form} />,
            disableCancelButton: true,
            okText: 'Create',
            onSave: (modal) => formHelper.submitForm(studentForm, modal),
            onCancel: () => studentForm.resetFields(),
          })
        }
      >
        <AddInteractionIcon color={currentTheme.theme.colors.quoteBgc} />
        <Button className="bordered-tab-button">
          <span className={styles.headerText}>Add Student</span>
        </Button>
      </div>
    </div>
  );
  const columns: CustomColumnsType<StudentsColumnDataType> = [
    {
      width: '5%',
      title: 'SeqNo',
      dataIndex: 'indexNumber',
      key: 'indexNumber',
      render(value, record, index) {
        return index + 1;
      },
    },
    {
      ...getColumnSearchProps('studentName'),
      width: '10%',
      title: t('tables.students.columns.studentName'),
      dataIndex: 'studentName',
      key: 'studentName',
      sorter: (a, b) => {
        if (!a.qId) a.studentName = '';
        if (!b.qId) b.studentName = '';
        return a.studentName.localeCompare(b.studentName);
      },
    },
    {
      width: '8%',
      ...getColumnSearchProps('qId'),
      title: t('tables.students.columns.qId'),
      dataIndex: 'qId',
      key: 'qId',
      sorter: (a, b) => {
        if (!a.qId) a.qId = '';
        if (!b.qId) b.qId = '';
        return a.qId.localeCompare(b.qId);
      },
    },
    {
      width: '10%',
      title: t('tables.students.columns.rank'),
      dataIndex: 'rank',
      key: 'rank',
      sorter: (a, b) => {
        if (!a.qId) a.rank = '';
        if (!b.qId) b.rank = '';
        return a.rank.localeCompare(b.rank);
      },
      filters: ranksArr,
      onFilter: (value: any, record) => record.rank === value,
    },
    {
      width: '7%',
      title: t('tables.students.columns.branch'),
      dataIndex: 'branch',
      key: 'branch',
      sorter: (a, b) => {
        if (!a.qId) a.branch = '';
        if (!b.qId) b.branch = '';
        return a.branch.localeCompare(b.branch);
      },
      filters: branchArr,
      onFilter: (value, record) => record.branch === value,
    },
    {
      width: '8%',
      title: t('tables.students.columns.militaryId'),
      dataIndex: 'militaryId',
      key: 'militaryId',
      sorter: (a, b) => {
        if (!a.qId) a.militaryId = '';
        if (!b.qId) b.militaryId = '';
        return a.militaryId.localeCompare(b.militaryId);
      },
      onFilter: (value, record) => record.militaryId === value,
    },
    {
      width: '9%',
      title: t('tables.students.columns.universityName'),
      dataIndex: 'universityName',
      key: 'universityName',
      sorter: (a, b) => {
        if (!a.qId) a.universityName = '';
        if (!b.qId) b.universityName = '';
        return a.universityName.localeCompare(b.universityName);
      },
      onFilter: (value, record) => record.universityName === value,
    },
    // {
    //   title: t("tables.students.columns.universityCity"),
    //   dataIndex: "universityCity",
    //   key: "universityCity",
    //   sorter: (a, b) => a.universityCity.localeCompare(b.universityCity),
    //   filters: [],
    //   onFilter: (value, record) => record.universityCity === value,
    // },
    // {
    //   title: t("tables.students.columns.universityCountry"),
    //   dataIndex: "universityCountry",
    //   key: "universityCountry",
    //   sorter: (a, b) => a.universityCountry.localeCompare(b.universityCity),
    //   filters: [],
    //   onFilter: (value, record) => record.universityCountry === value,
    // },
    {
      width: '7%',
      title: t('tables.students.columns.degreeType'),
      dataIndex: 'degreeType',
      key: 'degreeType',
      sorter: (a, b) => {
        if (!a.qId) a.degreeType = '';
        if (!b.qId) b.degreeType = '';
        return a.degreeType.localeCompare(b.degreeType);
      },
      filters: degreesArr,
      onFilter: (value, record) => record.degreeType === value,
    },
    {
      width: '9%',
      title: t('tables.students.columns.program'),
      dataIndex: 'program',
      key: 'program',
      sorter: (a, b) => {
        if (!a.qId) a.program = '';
        if (!b.qId) b.program = '';
        return a.program.localeCompare(b.program);
      },
      onFilter: (value, record) => record.program === value,
    },
    {
      width: '7%',
      title: t('tables.students.columns.yearOfProgram'),
      dataIndex: 'yearOfProgram',
      key: 'yearOfProgram',
      sorter: (a, b) => {
        return a.yearOfProgram - b.yearOfProgram;
      },
      onFilter: (value, record) => record.yearOfProgram === value,
    },
    {
      width: '7%',
      title: t('tables.students.columns.startYear'),
      dataIndex: 'startYear',
      key: 'startYear',
      sorter: (a, b) => a.startYear - b.startYear,
      onFilter: (value, record) => record.startYear === value,
    },
    {
      width: '8%',
      title: t('tables.students.columns.expectedGraduationYear'),
      dataIndex: 'expectedGraduationYear',
      key: 'expectedGraduationYear',
      sorter: (a, b) => a.expectedGraduationYear - b.expectedGraduationYear,
      onFilter: (value, record) => record.expectedGraduationYear === value,
    },
    {
      width: '7%',
      title: t('tables.students.columns.graduationYear'),
      dataIndex: 'graduationYear',
      key: 'graduationYear',
      sorter: (a, b) => a.graduationYear - b.graduationYear,
      onFilter: (value, record) => record.graduationYear === value,
    },
    {
      width: '7%',
      title: t('tables.students.columns.graduationApproved'),
      dataIndex: 'graduationApproved',
      key: 'graduationApproved',
    },
    {
      width: '150px',
      title: '',
      fixed: 'right' as const,
      render: (_: any, record: StudentsColumnDataType) => {
        return (
          <ActionColumnComponent
            editComponent={{
              onClick: (event) => {
                studentForm.resetFields();
                showModal({
                  size: 'large',
                  event,
                  title: 'Edit Student',
                  content: <StudentForm form={studentForm} recordId={record.id} />,
                  onSave: (modal) => formHelper.submitForm(studentForm, modal),
                  onCancel: () => studentForm.resetFields(),
                });
              },
              title: t('global.edit'),
            }}
            deleteComponent={{
              onClick: (event) =>
                showModal({
                  event,
                  content: `${record.studentName} will be removed.\n Are you sure?`,
                  title: 'Delete Student',
                  okText: 'Delete',
                  onSave: (modal) =>
                    formHelper.deleteRecordFromTable({
                      modal,
                      deleteFn: deleteStudent,
                      recordId: record.id,
                    }),
                }),
              title: t('global.delete'),
            }}
            archiveComponent={{
              onClick: () => {
                const graduationApproved = record.graduationApproved !== 'Approved';
                updateStudent({ id: record.id, graduationApproved });
              },
            }}
          />
        );
      },
    },
  ];

  const onFileImport = (value: UploadChangeParam<UploadFile<any>>) => {
    const importFile = value.fileList[0].originFileObj;
    if (!importFile) return;
    importStudent({ file: importFile })
      .unwrap()
      .then(() => {
        message.success('File imported successfully');
        refetchStudents();
      })
      .catch(() => message.error('Invalid file type'));
  };

  const onFileExport = (event: any) => {
    showModal({
      event,
      size: 'medium',
      title: 'Export Students',
      content: <ExportInterestForm form={exportForm} filteredInterestIds={filteredTableData} interestName="student" />,
      disableCancelButton: true,
      onSave: (modal) => formHelper.submitForm(exportForm, modal),
    });
  };

  const totalStudentCount = studentsList?.rows.length ? studentsList.rows.length : 0;
  const tabItems: ICustomTabs[] = [
    {
      key: '1',
      label: <Button>{t('tables.students.students.tabName')}</Button>,
      children: (
        <LazyStudentsTable
          columns={columns}
          onFileImport={onFileImport}
          onFileExport={onFileExport}
          graduatedOrNot={false}
          infoTotalCount={totalStudentCount}
          setFilteredTableData={setFilteredTableData}
          addStudent={addStudent}
          studentForm={studentForm}
        />
      ),
      // extendedContents:
      // <div style={{position: "absolute", right: "13.5em", top: "6.5em", zIndex: "1"}}>{addStudent(studentForm)}</div>
    },
    {
      key: '2',
      label: <Button>{t('tables.students.archived.tabName')}</Button>,
      children: (
        <LazyStudentsTable
          columns={columns}
          onFileImport={onFileImport}
          onFileExport={onFileExport}
          graduatedOrNot
          infoTotalCount={totalStudentCount}
          setFilteredTableData={setFilteredTableData}
        />
      ),
    },
  ];

  return (
    <PageLayout id="students-page" title={t('sidebar.students')}>
      <TabLayout paramName="subTab" items={tabItems} className="tabs-with-border-container" />
    </PageLayout>
  );
};

export default StudentsPage;
