import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  GetRunningControlPanelResultType,
  RestartModuleDataType,
} from 'src/app/services/control-panel/control-panel.service.type';
import { urlsConfig } from 'src/configs';

export const controlPanelSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getModulesAndMachines: builder.query<GetRunningControlPanelResultType, { name: string }>({
      query: () => ({
        url: urlsConfig.controlPanel.running,
      }),
      providesTags: [{ type: 'ControlPanel', id: 'LIST' }],
    }),
    restartModule: builder.mutation<void, RestartModuleDataType>({
      query: (data) => ({
        url: urlsConfig.controlPanel.restart,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error) => [{ type: 'ControlPanel', id: 'LIST' }],
    }),
  }),
});

export const { useGetModulesAndMachinesQuery, useRestartModuleMutation } = controlPanelSlice;
