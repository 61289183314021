import moment from 'moment';
import { useEffect } from 'react';

interface ISessionTimeoutProps {
  onActive: () => void;
  onIdle: () => void;
  idleTime: number | undefined;
}

const SessionTimeout: React.FC<ISessionTimeoutProps> = ({ idleTime, onActive, onIdle }) => {
  let timer: any;
  const events = ['click', 'scroll', 'load', 'keydown', 'mousemove'];

  const eventHandler = () => {
    localStorage.setItem('lastInteractionTime', moment().toString());
    if (timer && idleTime) {
      onActive();
      startTimer(idleTime);
    }
  };

  useEffect(() => {
    if (idleTime && idleTime > 0) {
      addEvents(idleTime);
    }

    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, [idleTime]);

  const startTimer = (time: number) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(
      () => {
        const lastInteractionTime = localStorage.getItem('lastInteractionTime');
        const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
        const timeInterval = time * 1000 * 60;
        if (!timeInterval) return;
        if (diff.asMilliseconds() < timeInterval) {
          startTimer(time);
          onActive();
        } else {
          onIdle();
          clearTimeout(timer);
        }
      },
      time * 1000 * 60,
    );
  };

  const addEvents = (time: number) => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer(time);
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  return null;
};

export default SessionTimeout;
