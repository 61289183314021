import React from 'react';
import { iconType } from 'src/types';

const ThreeDotIcon: React.FC<iconType.PropsType> = ({ style, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="8" height="32" viewBox="0 0 8 32">
        <g id="Group_40599" data-name="Group 40599" transform="translate(-807 -406.5)">
          <g
            id="Ellipse_86"
            data-name="Ellipse 86"
            transform="translate(807 418.5)"
            fill="#fff"
            stroke="#d4977c"
            strokeWidth="1"
          >
            <circle cx="4" cy="4" r="4" stroke="none" />
            <circle cx="4" cy="4" r="3.5" fill="none" />
          </g>
          <g
            id="Ellipse_90"
            data-name="Ellipse 90"
            transform="translate(807 430.5)"
            fill="#fff"
            stroke="#d4977c"
            strokeWidth="1"
          >
            <circle cx="4" cy="4" r="4" stroke="none" />
            <circle cx="4" cy="4" r="3.5" fill="none" />
          </g>
          <g
            id="Ellipse_88"
            data-name="Ellipse 88"
            transform="translate(807 406.5)"
            fill="#fff"
            stroke="#d4977c"
            strokeWidth="1"
          >
            <circle cx="4" cy="4" r="4" stroke="none" />
            <circle cx="4" cy="4" r="3.5" fill="none" />
          </g>
        </g>
      </svg>
    </div>
  );
};

ThreeDotIcon.defaultProps = {
  style: { width: '3.5px', height: '14px' },
  color: 'var(--header-icon-color)',
  onClick: undefined,
  className: undefined,
};

export default ThreeDotIcon;
