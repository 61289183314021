export enum ReportTypes {
  Company_Info = 0,
  Customer_Info = 1,
  Subscription = 2,
  User_Activity = 3,
  Resource = 4,
  Nsearch = 5,
}

export enum OperationType {
  excel = 0,
  form = 1,
}
