import type { MenuProps } from 'antd';
import { Badge, Dropdown, Form, Layout, Popover, Switch } from 'antd';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import styles from 'src/app/components/HeaderComponent/HeaderComponent.module.scss';
import showModal from 'src/app/components/ShowModalComponent/ShowModal';
import { formHelper, routeHelper, socketHelper } from 'src/app/helpers';
import Notifications from 'src/app/pages/NotificationsPage/Notifications';
import { useAppDispatch, useAppSelector } from 'src/app/redux/hook';

import alarmSound from 'public/alarm.mp3';
import notificationSound from 'public/msn.mp3';
import { InterestComponents, InterestsTypes } from 'src/enums/interests.enum';

import { BaseOptionType } from 'antd/es/select';
import FileUploadForm from 'src/app/forms/FileUploadForm/FileUploadForm';
import { getEnumKeyByValue } from 'src/app/helpers/enum.helper';
import { genericExcludeFilter, getPermissionKey } from 'src/app/helpers/permission.helper';
import useInterestTableClick from 'src/app/hooks/useInterestTableClick';
import usePermissions from 'src/app/hooks/usePermissions';
import { headerPageSize } from 'src/app/pages/NotificationsPage/notification-list.config';
import { useLazyGetAllInterestsQuery } from 'src/app/redux/interests/interestsSlice';
import {
  addNewNotification,
  setHeaderNotifications,
  setNotificationsMuted,
  setUnreadInboxCount,
} from 'src/app/redux/notifications/notificationsSlice';
import { getNotifications, getUnreadNotificationsCount } from 'src/app/services/notifications/notfications.service';
import { routes } from 'src/configs/permissions.dictionary';
import { LAYOUTVIEW, LOGIN_URL } from 'src/configs/system.config';
import { RoutesEnum } from 'src/enums/route.enums';
import { LayoutViewType } from 'src/enums/user.enum';

import { MoonFilled, SunFilled } from '@ant-design/icons';
import { ThemeModeContext } from 'src/app/App';
import DebounceSelect from 'src/app/components/DebouncedSelectComponent/DebouncedSelect';
import { useGetCustomersQuery } from 'src/app/redux/customers/customersSlice';
import { useLazyGetRulesQuery } from 'src/app/redux/rules/rulesSlice';
import { logout, reset } from 'src/app/redux/user/userSlice';
import { NotificationResultType } from 'src/app/services/notifications/notifications-service.type';
import { getFile } from 'src/app/services/storage-server-service/storage.service';
import { NotificationArchiveStatusEnum } from 'src/enums/notifications.enum';
import { HeaderIcon, NotificationEmpty, NotificationMuted, SearchIcon } from 'src/icons/index';
import { Header as HeaderTheme } from 'src/theme/Header.styled';

const LazyImportDataComponent = React.lazy(() => import('../ImportDataComponent/ImportDataComponent'));

const { Header } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  menuKey: React.Key,
  permissions: string[],
  label: JSX.Element,
  key: React.Key,
  icon?: React.ReactNode,
  excludeLayouts?: LayoutViewType[],
  children?: MenuItem[],
): MenuItem {
  const routeKey = getEnumKeyByValue(RoutesEnum, key.toString());
  const checkedPermission = getPermissionKey([routes[routeKey]]);

  if (excludeLayouts && !genericExcludeFilter(excludeLayouts, LAYOUTVIEW)) return null;

  if (!permissions.includes(checkedPermission)) return null;
  return {
    key: menuKey,
    icon,
    children,
    label,
  } as MenuItem;
}

const overflowCount = LAYOUTVIEW === LayoutViewType.LAYOUT_1 ? 99 : Number.MAX_SAFE_INTEGER;
const HeaderComponent = () => {
  // const [enterpriseClassificationAndDB, setEnterpriseClassificationAndDB] = useState<{
  //   dbVersion: string;
  //   classification: string;
  // }>({
  //   dbVersion: '',
  //   classification: '',
  // });

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { permissions } = usePermissions();
  const [isOpenSearch, setisOpenSearch] = useState<boolean>(false);
  const [fileUploadForm] = Form.useForm();
  const { customerId, id: userId, fullname, roles: userRoles, profilePicture } = useAppSelector((state) => state.user);

  const { headerNotifications, isMuted } = useAppSelector((state) => state.notificationsState);

  const customer = useGetCustomersQuery(
    {
      ids: [customerId!],
    },
    { skip: !customerId },
  ).data?.rows[0];

  const { theme, logo } = useAppSelector((state) => state.configContent);
  const { notifications, selectedNotification, unreadInboxCount } = useAppSelector((state) => state.notificationsState);

  const [getRule] = useLazyGetRulesQuery();

  const [profilePictureSrc, setProfilePictureSrc] = useState<string>();

  useEffect(() => {
    const getProfileImage = async (filename: string | undefined) => {
      if (!filename) {
        setProfilePictureSrc('user2.png');
        return;
      }
      try {
        const { data } = await getFile({
          path: `profile-images/${userId}/${filename}`,
        });
        if (!data) return;
        let binary = '';
        const bytes = new Uint8Array(data);

        const len = bytes.byteLength;
        for (let i = 0; i < len; i += 1) {
          binary += String.fromCharCode(bytes[i]);
        }
        setProfilePictureSrc(`data:image/png;base64,${window.btoa(binary)}`);
      } catch (error) {
        console.log('error', error);
      }
    };

    getProfileImage(profilePicture);
  }, [profilePicture]);

  useEffect(() => {
    const getUnreadAllNotificationsCount = async () => {
      try {
        const { data } = await getUnreadNotificationsCount();
        dispatch(setUnreadInboxCount(data));
      } catch (error) {
        console.log('error', error);
      }
    };
    // if (SYS_TYPE !== SystemType.BLACK) {
    //   getUnreadAllNotificationsCount();
    // }
    if (LAYOUTVIEW !== LayoutViewType.LAYOUT_4) {
      getUnreadAllNotificationsCount();
    }
  }, [notifications]);

  useEffect(() => {
    const getHeaderNotifications = async () => {
      try {
        const { data } = await getNotifications({
          limit: headerPageSize,
          offset: 0,
          archiveStatus: NotificationArchiveStatusEnum.UNARCHIVE,
        });
        dispatch(setHeaderNotifications(data.rows));
      } catch (error) {
        console.log('error', error);
      }
    };
    // if (SYS_TYPE !== SystemType.BLACK) {
    //   getHeaderNotifications();
    // }
    if (LAYOUTVIEW !== LayoutViewType.LAYOUT_4) {
      getHeaderNotifications();
    }
  }, [notifications[NotificationArchiveStatusEnum.UNARCHIVE].data]);

  const { onInterestClick } = useInterestTableClick();

  const { mainTheme, setMainTheme } = useContext(ThemeModeContext);

  const [selectedInterest, setSelectedInterest] = useState<BaseOptionType>();
  const [notificationDropdownState, setNotificationDropdownState] = useState<boolean>(false);
  const [dropdownState, setDropdownState] = useState<boolean>(false);
  const [incomingNotification, setIncomingNotification] = useState<NotificationResultType>();
  const [getInterests] = useLazyGetAllInterestsQuery();

  const onLogout = async () => {
    if (LAYOUTVIEW === LayoutViewType.LAYOUT_2) {
      await dispatch(logout());
      dispatch(reset());
    } else {
      await dispatch(logout());
      dispatch(reset());
    }
    window.location.replace(LOGIN_URL);
  };

  useEffect(() => {
    if (userId) {
      socketHelper.createConnection();
      socketHelper.listenSocket(userId, (notificationData: NotificationResultType) => {
        setIncomingNotification(notificationData);
      });
    }
  }, [userId]);

  useEffect(() => {
    if (incomingNotification) {
      dispatch(addNewNotification(incomingNotification));
      if (!isMuted) new Audio(notificationSound).play();

      getRule({ ids: [incomingNotification.ruleId], blockSpinner: true })
        .unwrap()
        .then((data) => {
          const foundRule = data.rows[0];
          if (!foundRule?.sound) return;
          const alarm = new Audio(alarmSound);
          alarm.loop = true;
          alarm.play();

          showModal({
            title: 'ALARM',
            content: (
              <div>
                There is an alarm from <span style={{ fontWeight: '900' }}>{foundRule.subject}</span> rule
              </div>
            ),
            okText: 'Close',
            isClosable: false,
            disableCancelButton: true,
            onSave: (modal) => {
              alarm.pause();
              modal.destroy();
            },
          });
        })
        .catch((err) => console.log('err', err));

      setIncomingNotification(undefined);
    }
  }, [incomingNotification, isMuted]);

  const items: MenuItem[] = [
    getItem(
      '1',
      permissions,
      <Link to="/Profile" onClick={() => setDropdownState(false)}>
        {t('header.menu.profile')}
      </Link>,
      RoutesEnum['/Profile'],
    ),
    getItem(
      '2',
      permissions,
      <Link to="/Settings" onClick={() => setDropdownState(false)}>
        {t('header.menu.settings')}
      </Link>,
      RoutesEnum['/Settings'],
    ),
    getItem(
      '3',
      permissions,
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        type="text"
        onClick={() => {
          // eslint-disable-next-line no-sequences
          setDropdownState(false);
          const modal = showModal({
            title: t('header.menu.importData'),
            size: 'medium',
            content: (
              <LazyImportDataComponent
                isClickedModal={() => {
                  modal.destroy();
                }}
              />
            ),
            // onSave: () => form.validateFields().then((res) => form.submit()),
            // okText: t("global.export"),
            disableCancelButton: true,
            hasFooter: false,
          });
        }}
      >
        {t('header.menu.importData')}
      </a>,
      RoutesEnum['/ImportData'],
      undefined,
      [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3],
    ),
    getItem(
      '4',
      permissions,
      <Link to="/HelpAndSupport" onClick={() => setDropdownState(false)}>
        {t('header.menu.helpAndSupport')}
      </Link>,
      RoutesEnum['/HelpAndSupport'],
    ),

    getItem(
      '5',
      permissions,
      <Link to="/Administration" onClick={() => setDropdownState(false)}>
        {t('header.menu.administration')}
      </Link>,
      RoutesEnum['/Administration'],
    ),

    // getItem(
    //   permissions,
    //   // eslint-disable-next-line jsx-a11y/anchor-is-valid
    //   <a
    //     type="text"
    //     onClick={(event) => (
    //       // eslint-disable-next-line no-sequences
    //       setDropdownState(true),
    //       showModal({
    //         title: t("forms.exportDataForm.fields.title"),
    //         size: "large",
    //         content: <ExportDataForm form={form} />,
    //         onSave: (modal) =>
    //           form.validateFields().then((res) => {
    //             form.submit(), modal.destroy(), form.resetFields();
    //           }),
    //         okText: t("global.export"),
    //         disableCancelButton: true,
    //         event,
    //       })
    //     )}
    //   >
    //     {t("header.menu.exportData")}
    //   </a>,
    //   RoutesEnum["/ExportData"],
    //   undefined,
    //   [LayoutViewType.LAYOUT_2]
    // ),

    getItem(
      '6',
      permissions,
      <Link to="/Portal" onClick={() => setDropdownState(false)}>
        {/* {LAYOUTVIEW === LayoutViewType.LAYOUT_1 ? t('header.menu.SC3portal') : t('header.menu.SAMAportal')} */}
        {t('header.menu.portal')}
      </Link>,
      RoutesEnum['/Portal'],
    ),
    getItem(
      '7',
      permissions,
      <div
        className={styles.dropdownItem}
        onClick={() => {
          setDropdownState(false);
          showModal({
            title: t('header.menu.fileUpload'),
            size: 'xlarge-2',
            content: <FileUploadForm form={fileUploadForm} />,
            okText: 'Upload',
            onSave: (modal) => formHelper.submitForm(fileUploadForm, modal),
            disableCancelButton: true,
          });
        }}
      >
        {t('header.menu.fileUpload')}
      </div>,
      RoutesEnum['/FileUpload'],
    ),
    {
      label: (
        <div className={styles.dropdownItem} onClick={onLogout}>
          {t('header.menu.logout')}
        </div>
      ),
      key: '8',
    },
  ];
  const handleOnClickNotificationsViewAll = () => {
    setNotificationDropdownState(false);
    routeHelper.handleRoute(navigate, '/Notifications');
  };

  useEffect(() => {
    if (selectedNotification) {
      routeHelper.handleRoute(navigate, '/Notifications');
      setNotificationDropdownState(false);
    }
  }, [selectedNotification]);

  // useEffect(() => {
  //   setEnterpriseClassificationAndDB({
  //     classification: customer?.classification ? customer?.classification : '',
  //     dbVersion: customer?.dbVersion ? customer?.dbVersion : '',
  //   });
  // }, [customer?.classification, customer?.dbVersion]);

  useEffect(() => {
    setTimeout(() => {
      setSelectedInterest(undefined);
    }, 300);
  }, [location]);

  useEffect(() => {
    try {
      const cachedTheme = localStorage.getItem('mainTheme');
      if (cachedTheme) {
        setMainTheme(cachedTheme);
        document.querySelector('body')?.setAttribute('theme', cachedTheme);
      }
    } catch (error) {
      console.log('err', error);
    }
  }, []);

  const setTheme = (isChecked: boolean) => {
    const newTheme = isChecked ? 'dark' : 'light';
    if (isChecked) {
      setMainTheme('dark');
    } else {
      setMainTheme('light');
    }
    localStorage.setItem('mainTheme', newTheme);
    document.querySelector('body')?.setAttribute('theme', newTheme);
  };
  // useEffect(() => {
  //   // bu useeffect sadece ilk girişte çalışıyor. o yüzden sayfa x e basılıp kapanılınca o kontrol burda yapabilirz.
  //   const lastActiveDate = storageHelper.getValueWithKey("lastLoginDate");
  //   if (lastActiveDate) {
  //     const dateDiff =
  //       (new Date().getTime() - Number(lastActiveDate)) / 1000 / 60;
  //     if (dateDiff > user.idleTime!) {
  //       onLogout();
  //     }
  //   }
  //   // dispatch(setProgressText('SC3 Web Data Initializing...'));
  // }, []);

  const notificationMenuComponent = (
    <div id="notificationModal" className={styles.notificationMenu}>
      {/* <div className={styles.notificationMenuTriangle} /> */}
      <div className={styles.notificationMenuHeader}>
        <p>Notifications</p>
        <div className={styles.muteSwitch}>
          <Switch
            style={{ backgroundColor: '#F0F0F0', marginTop: '-5px' }}
            unCheckedChildren={
              <NotificationEmpty color="#92AABB" style={{ width: '15px', height: '15px', marginTop: '10px' }} />
            }
            checkedChildren={<NotificationMuted style={{ width: '15px', height: '15px', marginTop: '4px' }} />}
            checked={isMuted}
            onChange={(checked) => dispatch(setNotificationsMuted(checked))}
          />
          {/* <Dropdown
            placement="bottomRight"
            overlay={subDropdownMenuComponent(isNotificationsMuted, isMarkAllReadClicked)}
            visible={subDropdownState}
          >
            <ThreeDotIcon
              style={{ width: '20', height: '20' }}
              onClick={() => setSubDropdownState(!subDropdownState)}
            />
          </Dropdown> */}
        </div>
      </div>
      <div style={{ padding: 10, height: '45vh' }}>
        <Notifications
          dataSource={{
            data: headerNotifications,
          }}
          isStatic
        />
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.notificationMenuButton}
          type="button"
          // style={{ backgroundColor: '#d6673f', margin: '0 8px', color: 'white' }}

          onClick={handleOnClickNotificationsViewAll}
        >
          <p>ViewAll</p>
        </button>
      </div>
      <div className={styles.notificationMenuFooter} />
    </div>
  );

  const getLogo = () => {
    if (logo)
      return (
        <div className={styles.logo} id="logo">
          <img src={logo} alt="" loading="lazy" style={{ width: '8em', height: '6em', objectFit: 'contain' }} />
        </div>
      );

    if (LAYOUTVIEW === LayoutViewType.LAYOUT_1)
      return (
        <a
          href="https://www.slavefreealliance.org"
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          rel="noreferrer"
          style={{ paddingLeft: '1.5rem' }}
        >
          <HeaderIcon />
        </a>
      );

    return (
      <div className={styles.logo} id="logo">
        <img
          src={
            LAYOUTVIEW === LayoutViewType.LAYOUT_3
              ? './logo_securETE.png'
              : LAYOUTVIEW === LayoutViewType.LAYOUT_4
                ? '/headerLogos/Sama_Black.png'
                : '/headerLogos/SAMA_RED.png'
          }
          alt=""
          loading="lazy"
          style={{ width: '8em', height: '6em' }}
        />
      </div>
    );
  };

  return (
    <HeaderTheme theme={theme}>
      <Header className={styles.headerMain} dir="ltr">
        <div id="header" className={`${styles.header} headerTheme`}>
          {getLogo()}
          {[LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_2].includes(LAYOUTVIEW) && (
            <div
              style={{
                color: theme.colors.quoteBgc,
              }}
              className={styles.classificationAndDbDiv}
            >
              {customer?.classification && <p>{customer.classification} </p>}
              {customer?.dbVersion && <p> v{customer.dbVersion}</p>}
            </div>
          )}
          <div className={styles.userMenu} id="userMenu">
            {LAYOUTVIEW !== LayoutViewType.LAYOUT_4 ? (
              <div className={styles.searchButton} id="searchButton">
                <SearchIcon style={{ height: '28.95px', width: '28.95px' }} />
                <div className={styles.searchBorder} />
                {/* // TODO: type düzenlemelerini yap */}
                <DebounceSelect
                  placeholder={t('header.search')}
                  variant="borderless"
                  showSearch
                  open={isOpenSearch}
                  onDropdownVisibleChange={(isOpen: boolean) => setisOpenSearch(isOpen)}
                  value={selectedInterest}
                  fetchOptions={getInterests}
                  suffixIcon={null}
                  style={{ width: '100%' }}
                  onSelect={(_, interest) => {
                    setSelectedInterest(interest);
                    if (Number(interest.type) === InterestsTypes.Enterprise && LAYOUTVIEW === LayoutViewType.LAYOUT_1) {
                      routeHelper.handleRoute(navigate, '/Network', {
                        id: interest.value as string,
                      });
                    } else {
                      onInterestClick(
                        {
                          id: interest.value as string,
                          name: interest.name,
                          type: interest.type,
                          classType: interest.classType,
                        },
                        InterestComponents.NSearch,
                        [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3].includes(LAYOUTVIEW)
                          ? '/Interests'
                          : '/NSearch',
                      );
                    }
                  }}
                />
              </div>
            ) : null}

            <div className={styles.headerIcons}>
              {LAYOUTVIEW !== LayoutViewType.LAYOUT_4 ? (
                <div className={styles.notificationIcon} id="notificationIcon">
                  <Popover
                    placement="bottom"
                    content={notificationMenuComponent}
                    trigger="click"
                    style={{ background: 'transparent' }}
                    destroyTooltipOnHide
                    open={notificationDropdownState}
                    onOpenChange={(visible: boolean) => {
                      setNotificationDropdownState(visible);
                    }}
                  >
                    <Badge
                      count={unreadInboxCount}
                      overflowCount={overflowCount}
                      size="default"
                      style={{
                        background: 'transparent',
                        color: theme.colors.quoteBgc,
                        border: 'none',
                        borderColor: 'transparent',
                        fontSize: 13,
                        fontWeight: 'bold',
                      }}
                      offset={[-19, 12]}
                      title={unreadInboxCount > overflowCount ? unreadInboxCount.toString() : undefined}
                    >
                      <NotificationEmpty
                        style={{
                          width: '26.15px',
                          height: '26.15px',
                          marginTop: '5px',
                          cursor: 'pointer',
                        }}
                        color="#92aabb"
                      />
                    </Badge>
                  </Popover>
                </div>
              ) : null}
              <div className={styles.mainThemeSwitcherDiv}>
                <Switch
                  className={styles.mainThemeSwitcher}
                  id="theme-switcher"
                  style={{
                    background:
                      mainTheme === 'dark'
                        ? 'linear-gradient(to right, #979797, #fff)'
                        : 'linear-gradient(to right, #cfcfcf, #979797, #626262, #323232, #000000)',
                  }}
                  onChange={(checked: boolean) => {
                    setTheme(checked);
                  }}
                  value={mainTheme === 'dark'}
                  unCheckedChildren={
                    <MoonFilled style={{ color: 'white', fontSize: '16px', marginTop: '-2.5px', marginLeft: '-8px' }} />
                  }
                  checkedChildren={
                    <SunFilled style={{ color: 'black', fontSize: '16px', marginTop: '2.5px', marginLeft: '-8px' }} />
                  }
                />
              </div>
              <Dropdown
                menu={{ items }}
                trigger={['click']}
                rootClassName="headerDropdown"
                className={styles.headerDropdown}
                overlayStyle={{
                  background: theme.colors.quoteBgc,
                }}
                destroyPopupOnHide
                open={dropdownState}
                onOpenChange={(nextOpen: boolean, info) => {
                  if (info.source === 'trigger' || nextOpen) {
                    setDropdownState(nextOpen);
                  }
                }}
              >
                <div>
                  <div className={styles.userIcon}>
                    <img src={profilePictureSrc} alt="" />
                  </div>
                  <div className={styles.userInfo}>
                    <span className={styles.avatarText}>{fullname}</span>
                    <div
                      className={styles.userRoleText}
                      style={{
                        color: theme.colors.secondaryText,
                      }}
                    >
                      {userRoles.map((role: { name: string }, index: number) =>
                        index < userRoles.length - 1 ? role.name.concat(',') : role.name.concat(''),
                      )}
                    </div>
                  </div>
                  <div className={styles.menuArrowDown}>
                    <AiOutlineMenu />
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </Header>
    </HeaderTheme>
  );
};

export default HeaderComponent;
