export enum NotificationLabelPriorityStatus {
  Critical = 0,
  Medium = 1,
  Low = 2,
  Uncategorized = 3,
}

export enum LabelCreatorEnum {
  System = 0,
  User = 1,
}
