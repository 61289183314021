import { urlsConfig } from 'src/configs';
import { apiSlice } from 'src/app/redux/api/apiSlice';

export const blackSystemSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    start: builder.mutation<any, any>({
      query: ({ data, url }) => ({
        url: urlsConfig.blackSystem(url).start,
        method: 'POST',
        body: data,
      }),
    }),
    exportData: builder.mutation<any, any>({
      query: ({ data, url }) => ({
        url: urlsConfig.blackSystem(url).export,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
    number: builder.mutation<any, any>({
      query: ({ data, url }) => ({
        url: urlsConfig.blackSystem(url).number,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useStartMutation, useExportDataMutation, useNumberMutation } = blackSystemSlice;
