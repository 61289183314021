import { Checkbox, Form, Select, message } from 'antd';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import ExtraTab from 'src/app/components/ExtraTabcomponent/ExtraTab';
import FormComponent from 'src/app/components/FormComponent/FormComponent';
import showModal from 'src/app/components/ShowModalComponent/ShowModal';
import { formHelper } from 'src/app/helpers';
import {
  CustomDataNode,
  generateDefaultPermissionsTree,
  generatePermissionsTree,
} from 'src/app/helpers/permission.helper';
import PermissionCard from 'src/app/pages/AdministrationPage/UserRights/PermissionCard/PermissionCard';
import styles from 'src/app/pages/AdministrationPage/UserRights/UserRights.module.scss';
import { useAppDispatch, useAppSelector } from 'src/app/redux/hook';
import { updateUserRole } from 'src/app/redux/user/userSlice';
import { useUpdateUserRolePermissionMutation } from 'src/app/redux/userRolePermissions/userRolePermissionsSlice';
import { useGetUserRolesQuery } from 'src/app/redux/userRoles/userRolesSlice';
import { permissionsConfig } from 'src/configs';
import { PureCancelIcon, SaveIcon } from 'src/icons';

const UserRights = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [updateUserRolePermission] = useUpdateUserRolePermissionMutation();
  const [displayTree, setDisplayTree] = useState<boolean>(false);
  const { data: userRoles } = useGetUserRolesQuery({ includeSuperUser: true });

  const [treeState, setTreeState] = useState<{
    treeNodes: {
      [key: string]: CustomDataNode[];
    };
    checkboxes: { [key: string]: boolean };
  }>({ checkboxes: {}, treeNodes: {} });

  const [treeStateCopy, setTreeStateCopy] = useState<{
    treeNodes: {
      [key: string]: CustomDataNode[];
    };
    checkboxes: { [key: string]: boolean };
  }>({ checkboxes: {}, treeNodes: {} });

  const [atomicPermissions, setAtomicPermissions] = useState<string[]>([]);

  const user = useAppSelector((state) => state.user);
  const { theme } = useAppSelector((state) => state.configContent);

  const getCheckedPermissions = useCallback(
    (id: string) => {
      const permissions: string[] = [];
      userRoles?.rows
        .filter((role) => role.id === id)
        .forEach((role) =>
          role.permissions.forEach((per) => {
            permissions.push(per.permissionId);
          }),
        );

      const { tree, viewKeys } = generateDefaultPermissionsTree(permissions);

      const newChecboxes: { [key: string]: boolean } = {};
      viewKeys.forEach((obj) => {
        newChecboxes[obj.key] = obj.checked;
      });
      setTreeState({ checkboxes: newChecboxes, treeNodes: tree });
      setTreeStateCopy({ checkboxes: newChecboxes, treeNodes: tree });
    },
    [userRoles],
  );

  useEffect(() => {
    if (!treeState?.treeNodes) return;

    const atomics: string[] = [];
    Object.values(treeState.treeNodes)?.forEach((node) => {
      node?.forEach((obj: CustomDataNode) => {
        const joinedKey = obj.key.toString().split('-').join('');
        if (!obj.isView) {
          atomics.push(joinedKey);
        }
      });
    });
    setAtomicPermissions(atomics);
  }, [treeState?.treeNodes]);

  useEffect(() => {
    const roleOfUser = user.roles.find((role) => role.id === form.getFieldValue('userRoleId'));
    if (roleOfUser && userRoles) {
      const updatedRole = userRoles.rows.find((role) => role.id === roleOfUser.id);
      if (!updatedRole) return;
      dispatch(updateUserRole(updatedRole));
    }
  }, [userRoles]);

  const onFinish = async (values: any) => {
    const viewKeys: string[] = [];
    (Object.entries(treeState.checkboxes) || []).forEach(([key, value]) => {
      if (value) return viewKeys.push(key);
    });

    updateUserRolePermission({
      ...values,
      permissionId: [...atomicPermissions, ...viewKeys],
    })
      .unwrap()
      .then(() => message.success(t('success.updated')))
      .catch(() => message.error(t('errors.unknownError')));
  };

  const generateTreeData = (obj: any) => {
    const tree = generatePermissionsTree(obj);

    function test(treeChild: any) {
      if (!treeChild.children?.length) return;
      if (treeChild?.title) {
        const treeKey = treeChild.key.split('-').join('');

        const checked = treeState?.checkboxes ? treeState.checkboxes[treeKey] : false;

        treeChild.title = (
          <div style={{ display: 'flex' }}>
            {`${treeChild.title}`}
            <div style={{ marginLeft: '.5rem', color: theme.colors.quoteBgc }} onClick={(e) => e.stopPropagation()}>
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  setTreeState((prev) => ({
                    ...prev,
                    checkboxes: {
                      ...prev?.checkboxes,
                      [treeKey]: e.target.checked,
                    },
                  }));
                }}
              >
                View
              </Checkbox>
            </div>
          </div>
        );

        treeChild.children.forEach(test);
      }
    }
    if (tree) test(tree);

    if (tree) return [tree];
    return [];
  };

  return (
    <div>
      <FormComponent options={{ onFinish, form }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item label="Role" name="userRoleId" style={{ width: '40%' }}>
            <Select
              options={userRoles?.rows.map((role: any) => ({
                label: role.name,
                value: role.id,
              }))}
              onSelect={(value) => {
                getCheckedPermissions(value);
                setDisplayTree(true);
              }}
              variant="borderless"
            />
          </Form.Item>

          {displayTree && (
            <div className="flex-center" style={{ columnGap: '2rem', margin: '0 1rem 0 1rem' }}>
              <ExtraTab
                onClick={() => {
                  showModal({
                    title: 'Cancel',
                    content: 'Are you sure to cancel all changes?',
                    onSave: (modal) => {
                      setTreeState(treeStateCopy);
                      modal.destroy();
                    },
                  });
                }}
                text={t('global.cancel')}
                icon={<PureCancelIcon />}
                bgTheme
              />
              <ExtraTab
                onClick={() => {
                  showModal({
                    title: 'Save',
                    content: 'Are you sure to apply all changes?',
                    onSave: (modal) => formHelper.submitForm(form, modal),
                  });
                }}
                text={t('global.save')}
                icon={<SaveIcon />}
                iconTheme
              />
            </div>
          )}
        </div>

        {displayTree && (
          <div className={styles.cardContainer}>
            {Object.entries(permissionsConfig.options).map((obj: any) => (
              <PermissionCard
                key={obj[0]}
                route={t(obj[1].key)}
                treeData={generateTreeData(obj)}
                checkedKeys={atomicPermissions}
                setTreeState={setTreeState}
              />
            ))}
          </div>
        )}
      </FormComponent>
    </div>
  );
};

export default UserRights;
