import { iconType } from 'src/types';

const ArchiveIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={
        onClick
          ? {
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }
          : { display: 'flex', alignItems: 'center' }
      }
    >
      <svg fill={color && color} xmlns="http://www.w3.org/2000/svg" height="24" width="24" style={{ ...style }}>
        <path d="M3 20V8.7q-.425-.275-.712-.7Q2 7.575 2 7V4q0-.825.588-1.413Q3.175 2 4 2h16q.825 0 1.413.587Q22 3.175 22 4v3q0 .575-.288 1-.287.425-.712.7V20q0 .825-.587 1.413Q19.825 22 19 22H5q-.825 0-1.413-.587Q3 20.825 3 20ZM5 9v11h14V9Zm15-2V4H4v3ZM9 14h6v-2H9Zm-4 6V9v11Z" />
      </svg>
    </div>
  );
};

ArchiveIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '12px' },
  color: 'var(--primary_text_color)',
  onClick: undefined,
  className: undefined,
};
export default ArchiveIcon;
