import { t } from 'i18next';
import React from 'react';
import PageLayout from 'src/app/components/PageLayoutComponent/PageLayout';
import { ICustomTabs } from 'src/app/components/TabLayoutComponent/tab-layout.config';
import TabLayout from 'src/app/components/TabLayoutComponent/TabLayout';
import { getPermissionKey } from 'src/app/helpers/permission.helper';
import BlackSystem from 'src/app/pages/PortalPage/BlackSystemPage/BlackSystem';
import CustomerInvitations from 'src/app/pages/PortalPage/CustomerAndUserInvitationsPage/CustomerInvitaions/CustomerInvitations';
import UserInvitations from 'src/app/pages/PortalPage/CustomerAndUserInvitationsPage/UserInvitations/UserInvitations';
import CustomerAndUserPage from 'src/app/pages/PortalPage/CustomerAndUserPage/CustomerAndUserPage';
import SystemLogsPage from 'src/app/pages/PortalPage/SystemLogsPage/SystemLogsPage';
import Users from 'src/app/pages/PortalPage/Users/Users';
import ControlPanelComponent from 'src/app/pages/SettingsPage/ControlPanel/ControlPanel';
import { routes } from 'src/configs/permissions.dictionary';
import { LayoutViewType } from 'src/enums/user.enum';

const LazySenderEmailSettings = React.lazy(() => import('./SenderEmailSettings/SenderEmailSettings'));

const LazyResourcesPage = React.lazy(() => import('./Resources/Resources'));

const PortalPage = () => {
  const tabItems: ICustomTabs[] = [
    {
      key: '1',
      label: t('customerAndUserInvitationsPage.customerInvitations.tabName'),
      children: <CustomerInvitations />,
      permission: getPermissionKey([routes['/Portal'], 'CustomerInvitations']),
      excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_4],
    },
    {
      key: '2',
      label: t('customerAndUserInvitationsPage.userInvitations.tabName'),
      children: <UserInvitations />,
      permission: getPermissionKey([routes['/Portal'], 'UserInvitations']),
      excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_4],
    },
    {
      key: '3',
      label: t('sidebar.customerAndUser'),
      children: <CustomerAndUserPage />,
      permission: getPermissionKey([routes['/Portal'], 'CustomerAndUser']),
      // excludeSystemTypes: [SystemType.BLACK],
    },
    // {
    //   key: "4",
    //   label: `${t("settingsPage.authenticationSettings.tabName")}`,
    //   children: <LazyAuthenticationSettings />,
    // },
    {
      key: '4',
      label: `${t('settingsPage.senderEmailSettings.tabName')}`,
      children: <LazySenderEmailSettings />,
      permission: getPermissionKey([routes['/Portal'], 'SenderEmailSettings']),
      excludeLayouts: [LayoutViewType.LAYOUT_4],
    },
    {
      key: '5',
      label: `Resources`,
      children: <LazyResourcesPage />,
      permission: getPermissionKey([routes['/Portal'], 'Resources']),
      excludeLayouts: [LayoutViewType.LAYOUT_4],
    },
    {
      key: '6',
      label: 'Control Panel',
      children: <ControlPanelComponent />,
      permission: getPermissionKey([routes['/Portal'], 'ControlPanel']),
      excludeLayouts: [LayoutViewType.LAYOUT_1, LayoutViewType.LAYOUT_4],
    },
    {
      key: '7',
      label: 'OSS Config',
      children: <BlackSystem />,
      permission: getPermissionKey([routes['/Portal'], 'OSSConfig']),
      excludeLayouts: [LayoutViewType.LAYOUT_1],
    },
    {
      key: '8',
      label: 'System Logs',
      children: <SystemLogsPage />,
      permission: getPermissionKey([routes['/Portal'], 'SystemLogs']),
    },
    {
      key: '9',
      label: 'Users',
      children: <Users />,
      excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_1],
      permission: getPermissionKey([routes['/Portal'], 'Users']),
    },
  ];
  return (
    <PageLayout title={t('header.menu.portal')}>
      <TabLayout paramName="tab" items={tabItems} />
    </PageLayout>
  );
};

export default PortalPage;
