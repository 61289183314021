import { AxiosPromise } from 'axios';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';
import { CustomerInvitationsLookUpResultType } from 'src/app/services/registerInvitations/registerInviations-service.type';

// export const userAllRegisterInvitation = (
//   customerId: string,
//   options?: {
//     blockErrorHandler: boolean;
//   }
// ): AxiosPromise<servicesUserTypes.UserLookupResultType> => {
//   return axios.get(
//     urlsConfig.user.userRegisterInvitationsLookUp /*+`/${customerId}` */,
//     options
//   );
// };

export const lookUpRegisterInvitations = (
  customerId?: string,
  options?: {
    blockErrorHandler: boolean;
  },
): AxiosPromise<CustomerInvitationsLookUpResultType> => {
  return axios.get(urlsConfig.user.userAllRegisterInvitation /* +`/${customerId}` */, options);
};

export const sendRegisterInvitation = (
  email: string,
  customerId: string,
  options?: { blockErrorHandler: boolean },
): AxiosPromise<undefined> => {
  return axios.post(urlsConfig.user.sendRegisterInvitation, { email, customerId }, options);
};

export default {
  // userAllRegisterInvitation,
  sendRegisterInvitation,
  lookUpRegisterInvitations,
};
