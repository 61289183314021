import { Col, Row } from 'antd';
import React from 'react';
import styles from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyInfoModalContent/CompanyInfoModalContent.module.scss';
import { Enterprise } from 'src/types/enterprise.type';

interface ContentProps {
  companyInfo: Pick<
    Enterprise,
    'company' | 'country' | 'id' | 'industry' | 'isFirstCompany' | 'website' | 'emails' | 'companyNumber'
  >[];
}

const CompanyInfoModalContent: React.FC<ContentProps> = ({ companyInfo }) => {
  return (
    <div className={styles.content} id="customerAndUser_modalShowContents">
      <br />
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Name
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {companyInfo[0].company.name}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Company Number
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {companyInfo[0].companyNumber}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Country Name
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {companyInfo[0].country.name}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Email(s)
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {companyInfo[0].emails && companyInfo[0].emails.map((email) => <div key={email}>{email}</div>)}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Website
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {companyInfo[0].website}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Industry
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {companyInfo[0].industry.name}
        </Col>
      </Row>
    </div>
  );
};

export default CompanyInfoModalContent;
