import React from 'react';

import { PropsType } from 'src/app/components/TableFooter/table.footer.config';

import styles from 'src/app/components/TableFooter/TableFooter.module.scss';

const TableFooter: React.FC<PropsType> = ({ style }) => {
  return <div style={style} className={styles.footerContainer} />;
};
export default TableFooter;
