import { Button } from 'antd';
import { t } from 'i18next';
import TabLayout from 'src/app/components/TabLayoutComponent/TabLayout';
import { ICustomTabs } from 'src/app/components/TabLayoutComponent/tab-layout.config';
import { getPermissionKey } from 'src/app/helpers/permission.helper';
import 'src/app/pages/PortalPage/BlackSystemPage/BlackSystem.scss';
import CourtRecordComponent from 'src/app/pages/PortalPage/BlackSystemPage/CourtRecord/CourtRecordComponent';
import NewsApiComponent from 'src/app/pages/PortalPage/BlackSystemPage/NewsApi/NewsApiComponent';
import OSSComponent from 'src/app/pages/PortalPage/BlackSystemPage/OSS/OSSComponent';
import { routes } from 'src/configs/permissions.dictionary';

function BlackSystem() {
  const tabItems: ICustomTabs[] = [
    {
      key: '1',
      label: (
        //   <Button>{t("settingsPage.controlPanel.runningModules.tabName")}</Button>
        <Button>{t('blackSystemPage.newsApi.tabName')}</Button>
      ),
      children: <NewsApiComponent />,
      permission: getPermissionKey([routes['/Portal'], 'OSSConfig', 'NewsSite']),
      style: { overflow: 'scroll', height: '60vh' },
    },
    {
      key: '2',
      // label: <Button>{t("settingsPage.controlPanel.machines.tabName")}</Button>,
      label: <Button>{t('blackSystemPage.OSS.tabName')}</Button>,
      children: <OSSComponent />,
      permission: getPermissionKey([routes['/Portal'], 'OSSConfig', 'SocialMedia']),
    },
    {
      key: '3',
      // label: <Button>{t("settingsPage.controlPanel.machines.tabName")}</Button>,
      label: <Button>{t('blackSystemPage.BlackList.tabName')}</Button>,
      children: <OSSComponent />,
      permission: getPermissionKey([routes['/Portal'], 'OSSConfig', 'Blacklist']),
    },
    {
      key: '4',
      // label: <Button>{t("settingsPage.controlPanel.machines.tabName")}</Button>,
      label: <Button>{t('blackSystemPage.CourtRecord.tabName')}</Button>,
      children: <CourtRecordComponent />,
      permission: getPermissionKey([routes['/Portal'], 'OSSConfig', 'CourtRecord']),
    },
    {
      key: '5',
      // label: <Button>{t("settingsPage.controlPanel.machines.tabName")}</Button>,
      label: <Button>{t('blackSystemPage.WebSite.tabName')}</Button>,
      children: <OSSComponent />,
      permission: getPermissionKey([routes['/Portal'], 'OSSConfig', 'WebSite']),
    },
  ];
  return <TabLayout paramName="subTab" items={tabItems} className="tabs-with-border-container" />;
}

export default BlackSystem;
