import { FONT_FAMILY } from 'src/configs/system.config';
import * as globalTheme from 'src/theme/Theme.styled';
import { createGlobalStyle } from 'styled-components';
import tinycolor from 'tinycolor2';

export type MyProps2 = {
  theme: typeof globalTheme.light;
};

export const GlobalStyles = createGlobalStyle<MyProps2>`
*,
*::before,
*::after {
  margin:  0;
  // padding: 0; // bu kısım user guide list tasarımını bozduğu için kapatıldı.
  box-sizing: border-box;
}

body {
  background-color: ${({ theme }) => theme.colors.header} !important;
  color: ${({ theme }) => theme.colors.quoteBgc} !important;
  // font-family: monospace;
  font-family: ${FONT_FAMILY} !important;
  overflow-x: hidden;

  ul.ant-dropdown-menu {

    .headerDropdown {
      li {
        :hover {
          ${({ theme }) => theme.colors.quoteBgc} !important;

        }
      }
    }

    // background-color: ${({ theme }) => theme.colors.quoteBgc} !important;
    // border-bottom: 6px solid ${({ theme }) => theme.colors.border} !important;
    li {
      
      // border-bottom: 1px solid ${({ theme }) => theme.colors.border}!important; 
      // background-color:  ${({ theme }) => theme.colors.quoteBgc};
      // We have commented the bottom line because again we wanted primary_text_color to take effect
      // span {
      //   color: ${({ theme }) => theme.colors.quoteTitle} !important;
      // }
      // :hover {
      //   background-color:  ${({ theme }) => theme.colors.text} !important;
      // }
     
    }
    li:last-child {
      border-bottom: none !important;
    }
    
  }
  .ant-dropdown-arrow::after {
    background-color: ${({ theme }) => theme.colors.quoteBgc} !important;
  }


}


.circle-picker {

  border: 1px solid  ${({ theme }) => theme.colors.quoteBgc} !important;
  
}

.ant-modal-content {
    background-color: ${({ theme }) => theme.colors.quoteBgc} !important;
      .ant-modal-body {
          
          .ant-modal-confirm-title {
            background-color: ${({ theme }) => theme.colors.quoteBgc};
          }
          .ant-modal-confirm-btns {
            button:last-child {
              background-color: ${({ theme }) => theme.colors.quoteBgc};
            }
          }
          }
      }
    }






//TODO: kosul eklenecek
#form-component  .ant-form-item:first-of-type:not(.borderless){
      border-top: 1px solid ${({ theme }) => theme.colors.quoteBgc};
}

#form-component {
.ant-form-item.borderless{
  // padding: 5px 0;
  margin-bottom: 0px;
}
.ant-form-item:not(.borderless) {
 
  margin-bottom: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quoteBgc};
}

.ant-row.ant-form-item-row:not(:has(.tableEditableCellSelect)) {
  padding: 5px 0 5px 0;

.ant-col:nth-child(2) {
  height: 100%;
  border-inline-start: 1px solid ${({ theme }) => theme.colors.quoteBgc};
}
.ant-col:first-child {
  border-inline-start: none;
  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      display: flex;
    }
  }
}
}

}

// for right border on screen
#notifications-left-content, .tab-container{
  border-inline-end: 1px solid ${({ theme }) => theme.colors.quoteBgc};
}

// for footer background
.ant-layout-footer {
  background-color: ${({ theme }) => theme.colors.quoteBgc};
}

// for email addresses in MyDetails page
#tag-button {
  background-color: ${({ theme }) => theme.colors.quoteBgc};
}

.ant-modal {
  .ant-modal-not-centered  {
    
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 9px solid transparent;
    border-top: 9px solid transparent;
    clear: both;

  }

  .left-arrow {
    right: 100%;
    border-right: 15px solid ${({ theme }) => theme.colors.quoteBgc};
  }

  .right-arrow {
    left: 100%;
    border-left: 15px solid ${({ theme }) => theme.colors.quoteBgc};
  }

}



// for card border in home page 
#home-card-container {
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.quoteBgc};

  #home-card-title {
    border-top: 0.5px solid ${({ theme }) => theme.colors.quoteBgc};
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.quoteBgc};
  }
}

// for drawer border 
.ant-drawer-wrapper-body {
  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 80%;
    top: 55%;
    transform: translate(0, -50%);
    left: 0;
    background-color: ${({ theme }) => theme.colors.quoteBgc};;
  }
}

.customerAndUSer_treeSelect {

  padding-top: 5px;
    padding-bottom: 5px;
  .ant-select-selection-item {
    background-color:  ${({ theme }) => theme.colors.quoteBgc}; 
  }
}


.select_space {
  border-top: 1px solid ${({ theme }) => theme.colors.quoteBgc};
  border-bottom: 1px solid ${({ theme }) => theme.colors.quoteBgc};
  .ant-space-item {
.ant-select {
  .ant-select-selector {
    color: white;
  }
}

  }
}

.modal-tab-container  {

  .ant-tabs-nav{
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        
        
        .ant-tabs-ink-bar {
          background-color: ${({ theme }) => theme.colors.quoteBgc};
        }
      }
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {

        color: ${({ theme }) => theme.colors.quoteBgc};
      }
    }
  }
}
#customerAndUser_modalShowContents {
  #customerAndUser_modalShowUsername {
    border-bottom: 1px solid ${({ theme }) => theme.colors.quoteBgc};

  }
  #customerAndUser_showRow {#customerAndUser_showCol {
    border-right: 1px solid ${({ theme }) => theme.colors.quoteBgc};
      
  }}
}
// message component style properties
.ant-message {
  padding-top: 9.6vh;
      .ant-message-notice-content{
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        background-color: ${({ theme }) => theme.colors.border} !important;
        color: white;
        .ant-message-error > .anticon{
           color: ${({ theme }) => theme.colors.quoteBgc} !important;
          }
          .ant-message-success > .anticon{
            color: ${({ theme }) => theme.colors.quoteBgc} !important;
           }}
        }
      }

  }
//company settings page froms
  #twoFA_Checkbox {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${({ theme }) => theme.colors.quoteBgc};
        border-color: ${({ theme }) => theme.colors.quoteBgc};
      }
      ::after {
        border-color: ${({ theme }) => theme.colors.quoteBgc};
      }
    }
    ::after {
      border-color: ${({ theme }) => theme.colors.quoteBgc};
    }
  }

  #twoFA_FormItem {
    .ant-form-item {
      border-top: 1px solid ${({ theme }) => theme.colors.quoteBgc};
    }
  }

  .ant-select-selector {
    // Commented this out because
    // they were someHow getting "black" color
    // For Example: Status section of Trouble Ticked on Help And Support Page...
    color: inherit !important;
    font-size: inherit !important;
    font-family: inherit !important;
    .ant-select-selection-item {
      font-weight: inherit !important;
    }
    .ant-select-selection-search-input {
      font-size: inherit !important;
    }
  }

  #dos-modal-data:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.quoteBgc};
  }

  #rightContent {
    padding-top: 24px;
    padding-inline-end: 24px;
    padding-bottom: 24px;
    .tab-container {
        border-inline-end: 1px solid ${({ theme }) => theme.colors.quoteBgc};
    }
  }


  .table-of-contents {
    color:  ${({ theme }) => theme.colors.quoteBgc};

    ul > li > a {
      :hover {
        color:  ${({ theme }) => theme.colors.quoteBgc};
      }
    }
  }

  #converted-word-file {
    h1, h2 {
      color:  ${({ theme }) => theme.colors.quoteBgc};
    }
  }

  .dos-info-span {
    color:  ${({ theme }) => theme.colors.quoteBgc};
    padding-inline-start: .3rem;
  }


  .reminder-mail-container {
    display: flex;
    justify-content: center;
    padding-top: 2rem;

    .reminder-saved-message {
      font-weight: 900;
      font-size: 20px;
      
    }

    #reminderEmailForm {
      .ant-checkbox-inner {
        width: 23px;
        height: 23px;
        border-radius: 13px;
        border: 1px solid  ${({ theme }) => theme.colors.quoteBgc};
  
        &:after {
          border-top: 0 !important;
          border-left: 0 !important;
          border: 3px solid #fff;
          width: 7.5px;
          height: 13px;
        }
      }

      button {
        background: ${({ theme }) => theme.colors.quoteBgc};
        color: ${({ theme }) => (tinycolor(theme.colors.quoteBgc).isDark() ? '#fff' : '#000')};
        display: flex;
        width: 116px;
        height: 31px;
        border-radius: 5px 5px 0 0;
        opacity: 1;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        margin-left: auto;
      }
    }

  }
 
  
`;

export default GlobalStyles;
