export enum InterestClassType {
  Identified = 0,
  Recognized = 1,
}

export enum InterestsTypes {
  Communication = 0,
  Incident = 1,
  Location = 2,
  Object = 3,
  Person = 4,
  Sensor = 5,
  Vehicle = 6,
  Functional_Area = 7,
  Relation = 8,
  Enterprise = 9,
  Industry = 10,
  Topic = 11,
  Agent = 12,
}

export enum ElementOfCompositeRulesEnum {
  INDIVIDUAL = 'Individual',
  GENESIS = 'Genesis',
  PART = 'Part',
}

export enum RelationsType {
  'DETECT' = 0,
  'RECOGNIZE' = 1,
  'IDENTIFY' = 2,
  'HIDE' = 3,
  'SHELTER' = 4,
  'MOVE' = 5,
  'CARRY' = 6,
  'DELIVER' = 7,
  'OWN' = 8,
  'LIVE' = 9,
  'STAY' = 10,
  'PASSBY' = 11,
  'CAPITALOF' = 12,
  'CITYOF' = 13,
  'IN' = 14,
  'SUPERVISE' = 15,
  'SUBORDINATE' = 16,
  'MANAGE' = 17,
  'REPORT' = 18,
  'FINCON' = 19,
  'LEGAD' = 20,
  'POLAD' = 21,
  'ENGAGE' = 22,
  'ATTACK' = 23,
  'DEFEND' = 24,
  'AMBUSH' = 25,
  'DEMOLISH' = 26,
  'TRAP' = 27,
  'DELAY' = 28,
  'CANALIZE' = 29,
  'UNIT MOVE' = 30,
  'DIRECT SUPPORT' = 31,
  'GENERAL SUPPORT' = 32,
  'FULL CONTROL' = 33,
  'OPERATIONAL CONTROL' = 34,
}

export enum CommunicationPhoneSubcategory {
  Call = '0',
  SMS = '1',
}

export enum CommunicationMailSubcategory {
  Email = '0',
}

export enum CommunicationSocialMediaSubcategory {
  Whatsapp = '0',
  Instagram = '1',
  Twitter = '2',
  Facebook = '3',
}

export enum CommunicationSubjectSubcategory {
  World = '0',
  Economy = '1',
  Culture = '2',
  Health = '3',
  Politics = '4',
  Sport = '5',
  Technology = '6',
}

export enum CommunicationStatusSubcategory {
  Positive = '0',
  Negative = '1',
  Neutral = '2',
}

export enum InterestComponents {
  NSearch,
  Network,
}
