import { iconType } from 'src/types';

const InfoIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block', ...style } : { display: 'inline-block', ...style }}
    >
      <svg
        style={{ ...style, width: '16px', height: '16px' }}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill={color || '#E19477'}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
      </svg>
    </div>
  );
};

InfoIcon.defaultProps = {
  // color: 'var(--sidebar-icon-color)',
  onClick: undefined,
  className: undefined,
};

export default InfoIcon;
