import { Form, Input, message, Select } from 'antd';
import { isDefined } from 'class-validator';
import React from 'react';
import FormComponent from 'src/app/components/FormComponent/FormComponent';
import { IFormProps } from 'src/app/forms/form.config';
import { convertToSelectOptionsWithId } from 'src/app/helpers/form.helper';
import { useGetCustomersQuery } from 'src/app/redux/customers/customersSlice';
import { useAppSelector } from 'src/app/redux/hook';
import { useUpdatePortalUserMutation } from 'src/app/redux/user/userSlice';
import { useGetUserRolesQuery } from 'src/app/redux/userRoles/userRolesSlice';

const CustomerAndUserEditForm: React.FC<IFormProps> = ({ form, recordId }) => {
  const { customer } = useAppSelector((state) => state.user);
  const { data: customerList } = useGetCustomersQuery({ isFirstCompany: true });

  const foundUser = customer
    ? customerList?.rows?.find((row) => row.id === customer?.id)?.users?.some((user) => user.id === recordId)
    : true;

  const { data: userRoles } = useGetUserRolesQuery({ includeSuperUser: foundUser }, { skip: !isDefined(foundUser) });
  const [updateUser] = useUpdatePortalUserMutation();

  const onFinish = async (values: any) => {
    if (!recordId) return;
    updateUser({
      id: recordId,
      fullname: values.fullname || undefined,
      password: values.password || undefined,
      userRole: values.userRole || undefined,
    })
      .unwrap()
      .then(() => message.success('Successfully updated'))
      .catch((e) => message.error(e.data.message));
  };

  if (!userRoles) return null;

  return (
    <FormComponent
      options={{
        form,
        onFinish,
      }}
    >
      <Form.Item label="User Name" name="fullname">
        <Input variant="borderless" />
      </Form.Item>
      <Form.Item label="Password" name="password">
        <Input type="password" variant="borderless" />
      </Form.Item>
      <Form.Item label="User Role" name="userRole">
        <Select
          variant="borderless"
          placeholder="Select user role"
          mode="multiple"
          options={convertToSelectOptionsWithId(userRoles.rows)}
        />
      </Form.Item>
    </FormComponent>
  );
};

export default CustomerAndUserEditForm;
