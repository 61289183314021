import { NavigateFunction } from 'react-router-dom';
import { InterestsTypes } from 'src/enums/interests.enum';
import { RoutesEnum } from 'src/enums/route.enums';
import { LayoutViewType } from 'src/enums/user.enum';
import { routesTypes } from 'src/types';
import { urlHelper } from '.';

export const handleRoute = (
  navigate: NavigateFunction,
  url: routesTypes.RoutesType,
  params?: Record<string, string>,
  states?: Record<string, any>,
) => {
  if (params && states) {
    return navigate(
      {
        pathname: url,
        search: urlHelper.addQueryParameters(params, ''),
      },
      { state: states },
    );
  }
  if (params) {
    return navigate({
      pathname: url,
      search: urlHelper.addQueryParameters(params, ''),
    });
  }
  if (states) {
    return navigate(url, { state: states });
  }

  return navigate(url);
};

export const findInterestRoute = (
  type: InterestsTypes,
  layout: LayoutViewType,
): keyof typeof RoutesEnum | undefined => {
  if (layout === LayoutViewType.LAYOUT_1) {
    switch (type) {
      case InterestsTypes.Topic:
        return '/Topic';

      case InterestsTypes.Industry:
        return '/Industry';
      case InterestsTypes.Location:
        return '/Location';
      default:
        return undefined;
    }
  } else return '/Interests';
};
