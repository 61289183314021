export enum TemplateStatus {
  Expired = 0,
  Active = 1,
  Archived = 2,
}

export enum EmailPeriod {
  Never = 0,
  Instantly = 1,
  Daily = 2,
  Weekly = 3,
}

export enum MailTemplateType {
  Initial = 0,
  Reminder = 1,
}

export enum CocInvitationStatus {
  Rejected = 0,
  Accepted = 1,
  Pending = 2,
  Not_Delivered = 3,
}

export enum FormTypeEnum {
  Saq = 'Saq',
  Standard = 'Standard',
  Onboarding = 'Onboarding',
}

export enum FormCompletionStatus {
  Rejected = 0,
  Accepted = 1,
  Pending = 2,
  Not_Delivered = 3,
  Completed = 4,
  Delivery_Pending = 5,
  Reviewed = 6,
}

export enum VolumeEnum {
  Very_Low = 0,
  Low = 1,
  Moderate = 2,
  High = 3,
}

export enum OverallRiskLevelType {
  Very_High = 0,
  High = 1,
  Moderate = 2,
  Low = 3,
}

export enum IndustryRiskLevelType {
  Low = 1,
  Moderate = 2,
  High = 3,
}

export enum BlackListStatusEnum {
  Active = 1,
  Inactive = 0,
  Observation = 2,
}

export enum CcrTtUrgencyCriticalityEnum {
  Extreme,
  High,
  Normal,
  Low,
  Very_Low,
}

export enum DeliveryStatusType {
  Pending = 0,
  Delivered = 1,
  Not_Delivered = 2,
}

export enum RegisteredStatusType {
  Pending = 0,
  Registered = 1,
  Not_Registered = 2,
  Expired = 3,
}

export enum SubscriptionType {
  Gold = 0,
  Silver = 1,
  Bronze = 2,
}

export enum ReportTypeEnum {
  Standard,
  Onboarding,
}
