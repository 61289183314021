import React from 'react';

import { iconType } from 'src/types';

const TipTierIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="50" height="34" viewBox="0 0 50 34">
        <path
          id="Rectangle_14946"
          data-name="Rectangle 14946"
          d="M5,0H50a0,0,0,0,1,0,0V34a0,0,0,0,1,0,0H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
          transform="translate(50 34) rotate(180)"
          fill={circleColor}
        />
        <g transform="translate(19 4)">
          <path
            id="Path_37332"
            data-name="Path 37332"
            d="M87.071,21.739H83.045a3.023,3.023,0,0,1-3.019-3.019c0-4.479-4.026-4.21-4.026-9.662a9.058,9.058,0,1,1,18.116,0c0,5.409-4.026,5.217-4.026,9.662A3.023,3.023,0,0,1,87.071,21.739ZM85.058,2.013a7.053,7.053,0,0,0-7.045,7.045c0,4.479,4.026,4.21,4.026,9.662a1.008,1.008,0,0,0,1.006,1.006h4.026a1.008,1.008,0,0,0,1.006-1.006c0-5.409,4.026-5.217,4.026-9.662A7.053,7.053,0,0,0,85.058,2.013Z"
            transform="translate(-76)"
            fill={color}
          />
          <path
            id="Path_37333"
            data-name="Path 37333"
            d="M201.032,474.013h-4.026a1.006,1.006,0,0,1,0-2.013h4.026a1.006,1.006,0,0,1,0,2.013Z"
            transform="translate(-189.961 -448.248)"
            fill={color}
          />
          <path
            id="Path_37334"
            data-name="Path 37334"
            d="M199.019,204.026a1,1,0,0,1-.712-.295l-2.013-2.013a1.006,1.006,0,0,1,1.423-1.423l1.3,1.3,1.3-1.3a1.006,1.006,0,0,1,1.423,1.423l-2.013,2.013A1,1,0,0,1,199.019,204.026Z"
            transform="translate(-189.961 -189.935)"
            fill={color}
          />
          <path
            id="Path_37335"
            data-name="Path 37335"
            d="M237.006,244.026A1.006,1.006,0,0,1,236,243.019v-2.013a1.006,1.006,0,1,1,2.013,0v2.013A1.006,1.006,0,0,1,237.006,244.026Z"
            transform="translate(-227.948 -227.923)"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
};

TipTierIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default TipTierIcon;
