import { Button, Layout } from 'antd';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import HeaderComponent from 'src/app/components/HeaderComponent/HeaderComponent';
import Sidebar from 'src/app/components/Sidebar/Sidebar';
import usePermissions from 'src/app/hooks/usePermissions';
import { LayoutPropsType } from 'src/app/layouts/default/layout-default.config';
import styles from 'src/app/layouts/default/LayoutDefault.module.scss';
import { ConfigContentStateType } from 'src/app/redux/configContent/configContentSlice';
import { useAppSelector } from 'src/app/redux/hook';
import { LAYOUTVIEW } from 'src/configs/system.config';
import { LanguagePreferenceEnum } from 'src/enums/language.enum';
import { LayoutViewType } from 'src/enums/user.enum';
import { ArrowLeftIcon, ArrowRightIcon } from 'src/icons/index';
import { PageContent } from 'src/theme/PageContent.styled';

const { Content } = Layout;

export const SidebarContext = React.createContext<{
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
  collapseChanged: boolean;
  setCollapseChanged: Dispatch<SetStateAction<boolean>>;
}>({
  isCollapsed: false,
  setIsCollapsed: () => {},
  collapseChanged: false,
  setCollapseChanged: () => {},
});

const LayoutDefault: React.FC<LayoutPropsType> = () => {
  const [collapsedP, setCollapsedP] = useState(false); // sidebar collapse open-close
  const [collapseChanged, setCollapseChanged] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const currentTheme: ConfigContentStateType = useSelector((state: any) => state.configContent);
  const { preferredLanguage } = useAppSelector((state) => state.user);

  const { permissions } = usePermissions();

  const isOutsideUser = useMemo<boolean>(() => {
    return permissions.length > 0;
  }, [permissions]);

  const contextValue = useMemo(() => {
    return {
      isCollapsed: collapsedP,
      setIsCollapsed: setCollapsedP,
      collapseChanged,
      setCollapseChanged,
    };
  }, [collapsedP, setCollapsedP, collapseChanged, setCollapseChanged]);

  return (
    <SidebarContext.Provider value={contextValue}>
      <Layout style={{ minHeight: '100vh' }}>
        {isOutsideUser && <HeaderComponent />}
        <Layout hasSider className={styles.content}>
          {isOutsideUser && LAYOUTVIEW !== LayoutViewType.LAYOUT_4 ? (
            <Sidebar collapsed={collapsedP} setCollapsed={setCollapsedP} />
          ) : null}

          <Layout style={{ background: 'none' }}>
            <PageContent>
              <Content className={styles.content} style={!isOutsideUser ? { height: '100vh' } : undefined}>
                {isOutsideUser && (
                  <div className={styles.leftContent}>
                    <Button
                      type="text"
                      icon={
                        LAYOUTVIEW === LayoutViewType.LAYOUT_4 ? null : preferredLanguage ===
                          LanguagePreferenceEnum.AR ? (
                          collapsedP ? (
                            <ArrowLeftIcon color={currentTheme.theme.colors.quoteBgc} />
                          ) : (
                            <ArrowRightIcon color={currentTheme.theme.colors.quoteBgc} />
                          )
                        ) : collapsedP ? (
                          <ArrowRightIcon color={currentTheme.theme.colors.quoteBgc} />
                        ) : (
                          <ArrowLeftIcon color={currentTheme.theme.colors.quoteBgc} />
                        )
                      }
                      onClick={() => {
                        setCollapsedP(!collapsedP);
                        setCollapseChanged((prev) => !prev);
                      }}
                      style={
                        preferredLanguage === LanguagePreferenceEnum.AR
                          ? {
                              right: '-15px',
                              left: '0',
                            }
                          : { left: '-15px', right: '0' }
                      }
                    />
                  </div>
                )}
                <div
                  className={styles.rightContent}
                  id="rightContent"
                  style={!isOutsideUser ? { padding: '24px' } : undefined}
                >
                  <Outlet />
                </div>
              </Content>
            </PageContent>
          </Layout>
        </Layout>
      </Layout>
    </SidebarContext.Provider>
  );
};

export default LayoutDefault;
