import styled from 'styled-components';

export const PageContent = styled.section`
  #rightContent {
    #profileContainer {
      #profileContent {
        #formContainer {
          #profileForm {
            .ant-form-item {
              :first-child {
                div {
                  border-top: none !important;
                }
              }
            }
          }
        }
      }
    }
    #settingsContainer {
      #addButton {
        #inviteUserIcon,
        #addNotificationIcon {
          background-color: ${({ theme }) => theme.colors.quoteBgc};
          border: 1px solid ${({ theme }) => theme.colors.quoteBgc};
          svg {
            color: white;
          }
        }
      }
      #notificationSettingsMain {
        .ant-tabs {
          .ant-tabs-nav {
            border-bottom: none;
            .ant-tabs-nav-wrap {
              .ant-tabs-nav-list {
                .ant-tabs-tab {
                  button {
                    border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryText};
                    border-top: 1px solid ${({ theme }) => theme.colors.secondaryText};
                  }
                }
                .ant-tabs-tab-active {
                  button {
                    background-color: ${({ theme }) => theme.colors.background};
                    border-top: none;
                  }
                }
              }
            }
          }
        }
        .notificationSettingsCompContainer {
          .left {
            .bottom {
              .content {
                .labelColorPalette {
                  .colorPaletteContainer {
                  }
                }
              }
            }
          }
        }
      }
    }
    // .ant-form-item {
    //   margin-bottom: 0;

    //   .ant-row.ant-form-item-row:not(:has(.tableEditableCellSelect)) {
    //     padding: 5px 0 5px 0;
    //     border-top: 1px solid ${({ theme }) => theme.colors.quoteBgc};
    //     .ant-col:nth-child(1) {
    //       border-right: 1px solid ${({ theme }) => theme.colors.quoteBgc};
    //     }
    //     .ant-col:last-child {
    //       border-right: none;
    //       .ant-form-item-control-input {
    //         .ant-form-item-control-input-content {
    //           display: flex;
    //           justify-content: flex-end;
    //         }
    //       }
    //     }
    //   }
    // }
    .ant-form-item:nth-child(3) {
    }
    // .ant-form-item:last-child {
    //  .ant-form-item-control-input-content:not(:has(.tableEditableCellSelect)) {
    //    margin-top: 20px;
    //  }
    //}
    button {
      // we have removed this, so now instead of this section we ourself
      // controlling the color of the buttons with considering the current theme...
      // color: ${({ theme }) => theme.colors.border};

      //   border-top: 0.5px solid ${({ theme }) => theme.colors.secondaryText};
      //   border-bottom: 0.5px solid ${({ theme }) => theme.colors.secondaryText};
    }
    .ant-form-item-explain {
      display: flex;
      margin-left: 11px;
      .ant-form-item-explain-error {
        // margin-top: -25px;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .ant-table-wrapper {
      .ant-table-container {
        thead {
          tr {
            th {
              .ant-table-filter-column {
                .ant-table-filter-trigger {
                  padding-left: 7px;
                  border-left: 1px solid ${({ theme }) => theme.colors.quoteBgc};
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                }
                .ant-table-column-title {
                  padding-right: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
  // This part is very very important because this is what gives the bg color
  //to the page content (Main Area...)
  background-color: var(--page_content_bg) !important;

  //   padding: 20px;
  //   text-align: center;
  font-weight: bold;
`;
