import React from 'react';

import { iconType } from 'src/types';

const LockClosedIcon: React.FC<iconType.PropsType> = ({ style, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex' }}
    >
      {/* <svg
        style={{ ...style }}
        id="Group_40464"
        data-name="Group 40464"
        xmlns="http://www.w3.org/2000/svg"
        width="229.914"
        height="229.913"
        viewBox="0 0 229.914 229.913"
      >
        <path
          id="Path_37318"
          data-name="Path 37318"
          d="M179.878,162.832l24.306-75.875L227.941,63.2a6.736,6.736,0,0,0,0-9.526l-51.7-51.7a6.736,6.736,0,0,0-9.526,0L142.958,25.73,67.082,50.037a6.737,6.737,0,0,0-4.253,4.054L.429,220.817a6.742,6.742,0,0,0,6.31,9.1H208.281a6.736,6.736,0,0,0,0-13.472H43.95l131.874-49.356A6.738,6.738,0,0,0,179.878,162.832Zm-8.4-146.57,42.175,42.174L198.267,73.822,156.092,31.648ZM33.454,205.986l61.233-61.232a29.084,29.084,0,1,0-9.532-9.518L23.929,196.461,74.3,61.87l70.407-22.554,45.89,45.89-22.554,70.407ZM98.887,108.92a15.632,15.632,0,1,1,0,22.107A15.65,15.65,0,0,1,98.887,108.92Z"
          transform="translate(0 -0.001)"
        />
      </svg> */}

      {/* edit icon */}
      {/* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#1a3441">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
      </svg> */}

      {/* lock-closed */}
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#1a3441"
      >
        <g fill="none">
          <path d="M0 0h24v24H0V0z" />
          <path d="M0 0h24v24H0V0z" opacity=".87" />
        </g>
        <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" />
      </svg>

      {/* payment needed */}
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
      >
        <rect fill="none" height="24" width="24" />
        <path
          d="M6.83,4H20c1.11,0,2,0.89,2,2v12c0,0.34-0.08,0.66-0.23,0.94L20,17.17V12h-5.17l-4-4H20V6H8.83 L6.83,4z M20.49,23.31L17.17,20H4c-1.11,0-2-0.89-2-2L2.01,6c0-0.34,0.08-0.66,0.23-0.93L0.69,3.51L2.1,2.1l19.8,19.8L20.49,23.31z M4,6.83V8h1.17L4,6.83z M15.17,18l-6-6H4v6H15.17z"
          enableBackground="new"
        />
      </svg> */}

      {/* download */}
      {/* <svg
        style={{ ...style }}
        id="download_v1"
        xmlns="http://www.w3.org/2000/svg"
        width="29.422"
        height="22.032"
        viewBox="0 0 29.422 22.032"
      >
        <path
          id="Path_37356"
          data-name="Path 37356"
          d="M32.078,40.28v3.81H28.45l6.2,6.2,6.2-6.2H37.213V40.28Z"
          transform="translate(-19.934 -28.254)"
          fill={color || '#FFF'}
        />
        <path
          id="Path_37357"
          data-name="Path 37357"
          d="M394.8,265.5a9.107,9.107,0,0,0-15.911,0,6.774,6.774,0,1,0,.036,13.548h2.424l-2.389-2.389h-.036A4.386,4.386,0,0,1,377.94,268a4.272,4.272,0,0,1,.982-.116,4.354,4.354,0,0,1,1.442.248,6.724,6.724,0,0,1,12.955,0,4.353,4.353,0,0,1,1.442-.248,4.272,4.272,0,0,1,.982.116,4.386,4.386,0,0,1-.982,8.659h-.036l-2.389,2.389h2.421A6.774,6.774,0,1,0,394.8,265.5Z"
          transform="translate(-372.13 -260.822)"
          fill={color || '#FFF'}
        />
      </svg> */}

      {/* open icon for report page */}
      {/* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#1a3441">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
      </svg> */}
    </div>
  );
};

LockClosedIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '12px' },
  color: 'var(--primary-color)',
  onClick: undefined,
  className: undefined,
};

export default LockClosedIcon;
