import axios from 'axios';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import TabLayout from 'src/app/components/TabLayoutComponent/TabLayout';
import { ICustomTabs } from 'src/app/components/TabLayoutComponent/tab-layout.config';
import styles from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyDetailsModal/CompanyDetailsModal.module.scss';
import ResourceInfoModalContent from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyDetailsModal/ResourceInfoModalContent/ResourceInfoModalContent';
import SubscriptionInfoModalContent from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyDetailsModal/SubscriptionInfoModalContent/SubscriptionInfoModalContent';
import CompanyInfoModalContent from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyInfoModalContent/CompanyInfoModalContent';
import UserInfoTabModalContent from 'src/app/pages/PortalPage/CustomerAndUserPage/UserInfoTabModalComponent/UserInfoTabModalContent';
import { CustomerResultType } from 'src/app/services/customer/customer-service.type';
import { urlsConfig } from 'src/configs';
import { OperationType, ReportTypes } from 'src/enums/logger-export.enum';

type CompanyDetailsModalProp = {
  customerTableData: CustomerResultType;
};

const CompanyDetailsModal: React.FC<CompanyDetailsModalProp> = ({ customerTableData }) => {
  const [newTableData, setNewTableData] = useState<any>();
  useEffect(() => {
    async function getFormData(customerId: string) {
      try {
        const { data } = await axios.post(
          urlsConfig.data.exportData,
          {
            customerIds: [customerId],
            reportTypes: [ReportTypes.Customer_Info, ReportTypes.Subscription, ReportTypes.Resource],
            operationType: OperationType.form,
          },
          {
            baseURL: urlsConfig.loggerServerBaseURL,
          },
        );
        setNewTableData(data);
      } catch (error) {
        console.log('err: ', error);
      }
    }
    getFormData(customerTableData.id);
  }, [customerTableData]);

  const tabItemsCompanyDetailModal: ICustomTabs[] = [
    {
      key: '1',
      label: `${t('customerAndUserPage.modals.companyInfo')}`,
      children: <CompanyInfoModalContent companyInfo={customerTableData.enterpriseIdentified} />,
    },
    {
      key: '2',
      label: `${t('customerAndUserPage.modals.subscriptionInfo')}`,
      children: (
        <SubscriptionInfoModalContent
          subscriptionInfo={newTableData?.find((data: any) => data?.name === 'Subscription')}
        />
      ),
    },
    {
      key: '3',
      label: `${t('customerAndUserPage.modals.userInfo')}`,
      children: (
        <UserInfoTabModalContent userInfo={newTableData?.find((data: any) => data?.name === 'Customer Info')} />
      ),
    },
    {
      key: '4',
      label: `${t('customerAndUserPage.modals.resourceInfo')}`,
      children: (
        <ResourceInfoModalContent resourceInfo={newTableData?.find((data: any) => data?.name === 'Resource')} />
      ),
    },
  ];

  return (
    <div className={styles.companyIcons}>
      <div className="modal-tab-container">
        <TabLayout items={tabItemsCompanyDetailModal} />
      </div>
    </div>
  );
};

export default CompanyDetailsModal;
