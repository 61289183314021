import { Form, Input, InputNumber, Select } from 'antd';
import React, { useEffect } from 'react';
import FormComponent from 'src/app/components/FormComponent/FormComponent';
import {
  useCreateStudentMutation,
  useGetStudentsQuery,
  useUpdateStudentMutation,
} from 'src/app/redux/students/studentsSlice';
import { Rule } from 'antd/es/form';
import { convertStudentRecordToFormData } from 'src/app/forms/StudentForm/student-form.config';
import { IFormProps } from 'src/app/forms/form.config';

const validator = (_rule: any, value: number) => {
  if (!value || value?.toString()?.length === 4) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(`Must be 4 character`));
};
const yearRule: Rule[] = [{ required: true }, { validator }];

const StudentForm: React.FC<IFormProps> = ({ form, recordId }) => {
  const [createStudent] = useCreateStudentMutation();
  const [updateStudent] = useUpdateStudentMutation();

  const { data: editData } = useGetStudentsQuery(undefined, {
    skip: !recordId,
    selectFromResult: ({ data }) => ({
      data: data?.rows.find((row) => row.id === recordId),
    }),
  });

  const onFinish = async (values: any) => {
    if (!recordId) {
      createStudent(values);
    } else {
      updateStudent({
        id: recordId,
        ...values,
      });
    }
  };

  useEffect(() => {
    if (editData) {
      form.setFieldsValue(convertStudentRecordToFormData(editData));
    }
  }, [editData, form]);

  return (
    <FormComponent
      options={{
        form,
        onFinish,
      }}
    >
      <Form.Item label="Student Name" name="studentName">
        <Input variant="borderless" />
      </Form.Item>
      <Form.Item label="QID" name="qId" rules={[{ len: 11 }]}>
        <Input variant="borderless" />
      </Form.Item>
      <Form.Item label="Rank" name="rank">
        <Select
          variant="borderless"
          options={[
            {
              label: 'Mulazim - Second Lieutenant',
              value: 'Mulazim - Second Lieutenant',
            },
            {
              label: 'Mulazim Awwal - Lieutenant',
              value: 'Mulazim Awwal - Lieutenant',
            },
            { label: 'Naqib - Captain', value: 'Naqib - Captain' },
            { label: "Ra'id - Major", value: "Ra'id - Major" },
            {
              label: 'Muqaddam - Lieutenant Colonel',
              value: 'Muqaddam - Lieutenant Colonel',
            },
            { label: 'Aqid - Colonel', value: 'Aqid - Colonel' },
            { label: 'Madania - Civilian', value: 'Madania - Civilian' },
          ]}
        />
      </Form.Item>
      <Form.Item label="Branch" name="branch">
        <Input bordered={false} />
      </Form.Item>
      <Form.Item label="Military ID" name="militaryId" rules={[{ required: true }]}>
        <Input variant="borderless" />
      </Form.Item>
      <Form.Item label="University Name" name="universityName">
        <Input variant="borderless" />
      </Form.Item>
      <Form.Item label="University Country" name="universityCountry">
        <Input variant="borderless" />
      </Form.Item>
      <Form.Item label="University City" name="universityCity">
        <Input variant="borderless" />
      </Form.Item>
      <Form.Item label="Type of Degree" name="degreeType">
        <Select
          variant="borderless"
          options={[
            { label: 'Bachelor', value: 'Bachelor' },
            { label: 'Ph.D.', value: 'Ph.D.' },
            { label: 'Master', value: 'Master' },
          ]}
        />
      </Form.Item>
      <Form.Item label="Program" name="program">
        <Input bordered={false} />
      </Form.Item>
      <Form.Item
        label="Year of Program"
        name="yearOfProgram"
        rules={[
          {
            validator(rule, value) {
              if (!value || value?.toString().length === 1) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Year of program must be 1 character'));
            },
          },
        ]}
      >
        <InputNumber variant="borderless" />
      </Form.Item>
      <Form.Item label="Start Year" name="startYear">
        <InputNumber bordered={false} />
      </Form.Item>
      <Form.Item label="Expected Graduation Year" name="expectedGraduationYear">
        <InputNumber variant="borderless" />
      </Form.Item>
      <Form.Item label="Graduation Year" name="graduationYear" rules={[yearRule[1]]}>
        <InputNumber variant="borderless" />
      </Form.Item>
      <Form.Item label="Graduation Approved" name="graduationApproved">
        <Select
          bordered={false}
          options={[
            { label: 'Approved', value: true },
            { label: 'Not Yet', value: false },
          ]}
        />
      </Form.Item>
    </FormComponent>
  );
};

export default StudentForm;
