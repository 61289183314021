import { urlHelper } from 'src/app/helpers';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  CompanyLookupDataType,
  CompanyLookupResultType,
  EnterpriseCreateDataType,
  EnterpriseGenerateReportFormDataType,
  EnterpriseIdentifiedDataType,
  EnterpriseIdentifiedResultType,
  EnterpriseUpdateDataType,
} from 'src/app/services/interest/Enterprise/enterprise-service.type';
import { EnterpriseInterestLookupDataType } from 'src/app/services/interest/interest.service.type';
import { urlsConfig } from 'src/configs';

export const companySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<CompanyLookupResultType, CompanyLookupDataType | void>({
      query: (params?: CompanyLookupDataType) => ({
        url: urlsConfig.interest.recognized.enterprise,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'Companies' as const,
                id,
              })),
              { type: 'Companies', id: 'LIST' },
            ]
          : [{ type: 'Companies', id: 'LIST' }],
    }),
    getEnterprises: builder.query<EnterpriseIdentifiedResultType, EnterpriseInterestLookupDataType | void>({
      query: (params?: EnterpriseInterestLookupDataType) => ({
        url: urlsConfig.interest.identified.enterprise.lookup,
        params: urlHelper.getLookupQuery(params),
      }),
      transformResponse: (data: EnterpriseIdentifiedResultType) => {
        return {
          count: data.count,
          rows: data.rows.map((ent) => {
            if (ent.isProxy) {
              return {
                ...ent,
                company: {
                  ...ent.company,
                  name: `#${ent.company.name}`,
                },
              };
            }
            return ent;
          }),
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'Enterprises' as const,
                id,
              })),
              { type: 'Enterprises', id: 'LIST' },
            ]
          : [{ type: 'Enterprises', id: 'LIST' }],
    }),
    createEnterprise: builder.mutation<EnterpriseIdentifiedDataType, EnterpriseCreateDataType>({
      query: (data) => ({
        url: urlsConfig.interest.recognized.enterprise,
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: [
        { type: 'AddSupplier', id: 'LIST' },
        { type: 'Companies', id: 'LIST' },
        { type: 'Enterprises', id: 'LIST' },
        { type: 'Interests', id: 'LIST' },
      ],
    }),
    updateEnterprise: builder.mutation<EnterpriseIdentifiedDataType, EnterpriseUpdateDataType>({
      query: (data: EnterpriseUpdateDataType) => ({
        url: urlsConfig.interest.identified.enterprise.update,
        method: 'PATCH',
        body: { ...data },
      }),
      invalidatesTags: (_result, _error, data: EnterpriseUpdateDataType) => [
        { type: 'AddSupplier', id: data.id },
        { type: 'Enterprises', id: data.id },
        { type: 'Interactions', id: 'LIST' },
      ],
    }),
    deleteEnterprise: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `${urlsConfig.interest.identified.enterprise.delete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: 'Enterprises', id },
        { type: 'Interests', id: 'LIST' },
      ],
    }),
    generateEnterpriseReport: builder.mutation<Blob, EnterpriseGenerateReportFormDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.enterprise.generateReport,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
    exportEnterpriseIdentifiedExcell: builder.mutation<ArrayBuffer, { ids?: string[] }>({
      query: (data) => ({
        url: urlsConfig.exports.enterprise,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useCreateEnterpriseMutation,
  useUpdateEnterpriseMutation,
  useGetEnterprisesQuery,
  useGenerateEnterpriseReportMutation,
  useGetCompaniesQuery,
  useDeleteEnterpriseMutation,
  useLazyGetCompaniesQuery,
  useExportEnterpriseIdentifiedExcellMutation,
  useLazyGetEnterprisesQuery,
} = companySlice;
