import { iconType } from 'src/types';

const AddIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex' }}
    >
      <svg
        fill={color || '#92aabb'}
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 24 24"
      >
        <path d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
      </svg>
    </div>
  );
};

AddIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '12px' },
  // color: 'var(--primary-color)',
  onClick: undefined,
  className: undefined,
};

export default AddIcon;
