import { ClearOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import type { ColumnsType, ColumnType, TableProps } from 'antd/es/table';
import { CSSProperties, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ColumnHighlight from 'src/app/components/ColumnHighlightComponent/ColumnHighlight';
import styles from 'src/app/components/TableComponent/TableComponent.module.scss';
import { genericExcludeFilter } from 'src/app/helpers/permission.helper';
import { SidebarContext } from 'src/app/layouts/default/LayoutDefault';
import { useAppSelector } from 'src/app/redux/hook';
import { LAYOUTVIEW } from 'src/configs/system.config';
import { LanguagePreferenceEnum } from 'src/enums/language.enum';
import { LayoutViewType } from 'src/enums/user.enum';
import { FilterIcon } from 'src/icons';
import NoDataIcon from 'src/icons/NoDataIcon';
import { Table as TableTheme } from 'src/theme/Table.styled';
import tinycolor from 'tinycolor2';

export interface IColumnType<T> extends ColumnType<T> {
  tooltipText?: string | ReactNode;
  title?: string | ReactNode;
  children?: ColumnsType<T>;
  isOrderable?: boolean;
  excludeLayouts?: LayoutViewType[];
  editable?: boolean;
}
export type CustomColumnsType<T> = IColumnType<T>[];

interface ITableComponentProps<T> extends TableProps<T> {
  extraContentWidth?: number;
  isModalTable?: boolean;
  columns: CustomColumnsType<T>;

  containerStyle?: CSSProperties;

  onTableReset?: () => void;
}

const EllipsisTooltipCell = ({ children }: { children: React.ReactNode | string }) => {
  const textRef = useRef<any>(null);
  const [isEllipsis, setIsEllipsis] = useState(false);

  useEffect(() => {
    const checkEllipsis = () => {
      if (textRef.current) {
        setIsEllipsis(textRef.current.scrollWidth > textRef.current.offsetWidth);
      }
    };
    checkEllipsis();
    window.addEventListener('resize', checkEllipsis);
    return () => window.removeEventListener('resize', checkEllipsis);
  }, [children]);

  return (
    <Tooltip title={isEllipsis ? children : ''}>
      <div ref={textRef} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {children}
      </div>
    </Tooltip>
  );
};

const TableComponent = <T extends object>(props: ITableComponentProps<T>) => {
  const { extraContentWidth, isModalTable, containerStyle, onTableReset, ...tableProps } = props;

  const ref = useRef<any>();
  const { isCollapsed } = useContext(SidebarContext);
  const { theme } = useAppSelector((state) => state.configContent);
  const { preferredLanguage } = useAppSelector((state) => state.user);
  const collapseWidth = LAYOUTVIEW === LayoutViewType.LAYOUT_4 ? 0 : isCollapsed ? 200 : 360;
  const [tabWidth, setTabWidth] = useState<number>(window.innerWidth - collapseWidth - (extraContentWidth || 0));
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [showResetFilterBtn, setShowResetFilterBtn] = useState(false);

  const mergedColumns: CustomColumnsType<T> = useMemo(() => {
    const mergeColumnsRecursively = (columns: IColumnType<T>[]): IColumnType<T>[] => {
      return columns.map((col) => {
        if (col.children) {
          return {
            ...col,

            children: mergeColumnsRecursively(col.children as IColumnType<T>[]),
          };
        }
        return {
          ...col,
          onCell: (_record) => ({
            style: { borderBottom: `1px solid ${theme.colors.quoteBgc}` },
          }),
          title: col.tooltipText ? <ColumnHighlight col={col} /> : col.title,
          filterIcon: (filtered: boolean) => (filtered ? <FilterIcon color={theme.colors.quoteBgc} /> : <FilterIcon />),
          fixed: col?.fixed === 'right' && preferredLanguage === LanguagePreferenceEnum.AR ? 'left' : col.fixed,
          ...(!col.render && {
            render: (value: any) => <EllipsisTooltipCell>{value}</EllipsisTooltipCell>,
          }),
        };
      });
    };

    if (!tableProps.columns) return [];
    const filteredColumns = [...tableProps.columns].filter((col) => {
      let result = true;
      if (col.excludeLayouts) {
        result = genericExcludeFilter(col.excludeLayouts, LAYOUTVIEW);
      }
      return result;
    });

    return mergeColumnsRecursively(filteredColumns);
  }, [tableProps.columns]);

  const handleResize = () => {
    const newWidth = window.innerWidth - (extraContentWidth || 0) - collapseWidth;
    setTabWidth(newWidth);
  };

  useEffect(() => {
    let newWidth: number;
    if (!isFirstLoad) {
      if (isCollapsed) {
        newWidth = tabWidth + 185;
        setTabWidth(newWidth);
      } else {
        newWidth = tabWidth - 185;
        setTabWidth(newWidth);
      }
    }
    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isCollapsed]);

  // const tableWidth = useMemo(() => {
  //   const extraContentWidth = props.extraContentWidth ? props.extraContentWidth : 0;

  //   return isCollapsed ? window.innerWidth - 210 - extraContentWidth : window.innerWidth - 370 - extraContentWidth;
  // }, [isCollapsed, props.extraContentWidth]);

  const currentTheme = useAppSelector((state) => state.configContent.theme);
  const calculateXWidth =
    mergedColumns.length > 12
      ? '150vw'
      : tabWidth > 1250
        ? undefined
        : mergedColumns.length >= 8
          ? '150vw'
          : tabWidth < 650 && mergedColumns.length >= 3
            ? '150vw'
            : props?.scroll?.x
              ? props?.scroll?.x
              : undefined;

  const [tableKey, setTableKey] = useState(0);
  const resetFilters = () => {
    setShowResetFilterBtn(false);
    setTableKey((newKey) => newKey + 1);
    if (onTableReset) onTableReset();
  };

  return (
    <div style={containerStyle}>
      <TableTheme
        className={isModalTable ? styles.tableModal : styles.tableMain}
        theme={currentTheme}
        style={{ height: '100%' }}
      >
        <div
          id="table-container"
          style={{
            width: '100%',
            maxWidth: tabWidth,
            position: 'relative',
          }}
          ref={ref}
        >
          {showResetFilterBtn ? (
            <Button
              className={styles.resetFilterBtn}
              style={{ background: theme.colors.quoteBgc }}
              onClick={resetFilters}
            >
              <ClearOutlined
                style={{
                  color: tinycolor(theme.colors.quoteBgc).isDark() ? '#fff' : '#000',
                  fontSize: '20px',
                  marginRight: '8px',
                  marginBottom: '10px',
                }}
              />
            </Button>
          ) : null}
          <Table
            key={tableKey}
            {...tableProps}
            columns={mergedColumns}
            pagination={false}
            scroll={{
              y: tableProps.scroll ? tableProps.scroll.y : window.innerHeight - 400,
              x: calculateXWidth,
            }}
            onChange={(pagination, filters, sorter, extra) => {
              if (tableProps.onChange) tableProps.onChange(pagination, filters, sorter, extra);
              if (Object.values(filters).filter(Boolean).length) {
                setShowResetFilterBtn(true);
              } else setShowResetFilterBtn(false);
            }}
            locale={{
              triggerAsc: 'Sorting canceled, click to sort ascending',
              triggerDesc: 'Sorted in ascending order, click to sort descending',
              cancelSort: 'Sorted in descending order, click to cancel sorting',
              emptyText: (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: tableProps.scroll ? `calc(${tableProps.scroll.y} - 20px)` : `${window.innerHeight - 450}px`,
                    borderBottom: `1px solid ${theme.colors.quoteBgc}`,
                  }}
                >
                  <NoDataIcon color={theme.colors.quoteBgc} />
                </div>
              ),
            }}
          />
        </div>
      </TableTheme>
    </div>
  );
};

export default TableComponent;
