import { Tabs } from 'antd';
import { isDefined } from 'class-validator';
import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ColumnHighlight from 'src/app/components/ColumnHighlightComponent/ColumnHighlight';
import { ICustomTabs } from 'src/app/components/TabLayoutComponent/tab-layout.config';
import { genericExcludeFilter } from 'src/app/helpers/permission.helper';
import usePermissions from 'src/app/hooks/usePermissions';
import { useAppSelector } from 'src/app/redux/hook';
import { INTERESTS_WHITELIST, LAYOUTVIEW } from 'src/configs/system.config';

interface ITableLayoutProps {
  items: ICustomTabs[];
  className?: 'tabs-with-border-container';
  onTabChange?: (value: string) => void;
  customTabOptions?: { tabKey: string; options: HTMLAttributes<HTMLElement> };
  id?: string;

  paramName?: 'tab' | 'subTab';
}

const TabLayout: React.FC<ITableLayoutProps> = ({ items, className, onTabChange, customTabOptions, id, paramName }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { customer } = useAppSelector((state) => state.user);
  const { permissions } = usePermissions();

  const [extraContent, setExtraContent] = useState<JSX.Element | string>();

  const mergedTabs = useMemo(() => {
    return items
      .filter((col) => {
        const resultArr: boolean[] = [];

        if (isDefined(col.permission)) {
          resultArr.push(permissions.indexOf(col.permission) !== -1);
        }

        if (isDefined(col.exclude)) {
          resultArr.push(!col.exclude);
        }

        if (col.excludeAccountTypes) {
          resultArr.push(genericExcludeFilter(col.excludeAccountTypes, customer?.customerAccountTypeId));
        }
        if (isDefined(col.excludeConfigInterest)) {
          resultArr.push(!genericExcludeFilter(INTERESTS_WHITELIST, col.excludeConfigInterest));
        }
        if (col.excludeLayouts) {
          resultArr.push(genericExcludeFilter(col.excludeLayouts, LAYOUTVIEW));
        }

        // if (col.excludeSystemTypes) {
        //   resultArr.push(genericExcludeFilter(col.excludeSystemTypes, SYS_TYPE));
        // }

        return resultArr.every((val) => val === true);
      })
      .map((col) => {
        const features: ICustomTabs = { key: col.key, label: col.label };
        if (col.tooltipText) {
          features.label = <ColumnHighlight tabs={col} />;
        }
        return {
          ...col,
          ...features,
        };
      });
  }, [items]);

  const getDefaultKey = () => {
    return mergedTabs.length > 0 ? mergedTabs.find((tab) => !tab.disabled)?.key || '1' : '1';
  };

  const getActiveKeyFromUrl = () => {
    if (!paramName) return getDefaultKey();
    const params = new URLSearchParams(location.search);
    const activeKey = params.get(paramName) || getDefaultKey();
    return mergedTabs.some((item) => item.key === activeKey) ? activeKey : getDefaultKey();
  };
  const [activeTabKey, setActiveTabKey] = useState<string>(getActiveKeyFromUrl);

  useEffect(() => {
    const newActiveKey = getActiveKeyFromUrl();
    if (newActiveKey !== activeTabKey) {
      setActiveTabKey(newActiveKey);
    }
  }, [location.search]);

  useEffect(() => {
    const getExtraContents = () => {
      const foundTab = items.find((item) => item.key === activeTabKey);
      if (!foundTab) return;
      setExtraContent(foundTab.extendedContents);
    };
    getExtraContents();
  }, [items, activeTabKey]);

  // TODO: any typeları daha sonra değiştir
  const renderTabBars: any = (props: any, DefaultTabProps: any) => {
    return (
      <DefaultTabProps {...props}>
        {(node: any) => {
          if (node.key === customTabOptions?.tabKey) {
            return React.cloneElement(node, {
              ...customTabOptions?.options,
            });
          }
          return node;
        }}
      </DefaultTabProps>
    );
  };

  const onChange = (key: string) => {
    setActiveTabKey(key);
    if (paramName) {
      const params = new URLSearchParams(location.search);
      if (paramName !== 'subTab') {
        // Ana tab değiştirildiğinde alt tab sıfırlanır
        params.set('tab', key);
        params.delete('subTab'); // Alt tab'ı sıfırla
      } else {
        params.set(paramName, key); // Alt tab'ı güncelle
      }
      navigate({ search: params.toString() }, { state: location.state });
    }

    if (onTabChange) onTabChange(key);
  };

  return (
    <div className={className} style={{ height: '100%' }}>
      <Tabs
        id={id}
        style={{ width: '100%' }}
        activeKey={activeTabKey}
        items={mergedTabs}
        onChange={onChange}
        tabBarExtraContent={<div style={{ paddingInlineEnd: '1rem' }}> {extraContent} </div>}
        destroyInactiveTabPane
        renderTabBar={customTabOptions ? renderTabBars : undefined}
      />
    </div>
  );
};

export default TabLayout;
