import { DefaultOptionType } from 'antd/es/select';
import { groupBy } from 'lodash';
import { getEnumKeyByValue } from 'src/app/helpers/enum.helper';
import * as serviceInterestTypes from 'src/app/services/interest/interest.service.type';
import { InterestClassType, InterestsTypes } from 'src/enums/interests.enum';
import { interestsTypes } from 'src/types';
import { IdValuePairType } from 'src/types/interests.type';

export const convertInterestListToSelectOptions = (
  interests: serviceInterestTypes.InterestsWithNames[],
  filter?: (value: IdValuePairType[]) => void,
) => {
  const result: DefaultOptionType[] = [];

  interests.forEach((row) => {
    const groupedInterests = groupBy(row.interests, (obj) => obj.classType);

    Object.entries(groupedInterests).forEach(([key, value]) => {
      result.push({
        label: getEnumKeyByValue(InterestClassType, key),
        options: filter
          ? filter(value)
          : value.map((int) => ({
              value: int.id,
              label: int.value,
              title: int.type,
              key: int.id,
            })),
      });
    });
  });

  return result;
};

// TODO: gerekli oldukça ekle
export const getInterestNameFromLookup = (interestData: serviceInterestTypes.InterestLookupDataType): string => {
  const { type } = interestData;
  const classType = interestData.interestClassType;

  let result: string | undefined;

  switch (type) {
    case InterestsTypes.Sensor:
      if (classType === InterestClassType.Identified) {
        result = interestData.sensorIdentified?.name;
      }
      break;

    case InterestsTypes.Topic:
      if (classType === InterestClassType.Identified) {
        result = interestData.topicIdentified?.name;
      }
      break;

    case InterestsTypes.Vehicle:
      if (classType === InterestClassType.Identified) {
        result = interestData.vehicleIdentified?.name;
      } else if (classType === InterestClassType.Recognized) {
        result = interestData.vehicleRecognized?.name;
      }
      break;
    case InterestsTypes.Person:
      if (classType === InterestClassType.Identified) {
        result = `${interestData.personIdentified?.name} ${interestData.personIdentified?.surname}`;
      } else if (classType === InterestClassType.Recognized) {
        result = `${interestData.personRecognized?.name}`;
      }
      break;
    case InterestsTypes.Location:
      if (classType === InterestClassType.Identified) {
        result = interestData.locationIdentified?.name;
      } else if (classType === InterestClassType.Recognized) {
        result = interestData.locationRecognized?.name;
      }
      break;
    case InterestsTypes.Enterprise:
      if (classType === InterestClassType.Identified) {
        result = interestData.enterpriseIdentified?.company.name;
      }
      break;

    case InterestsTypes.Object:
      if (classType === InterestClassType.Recognized) {
        result = interestData.objectRecognized?.name;
      }
      break;

    case InterestsTypes.Incident:
      if (classType === InterestClassType.Recognized) {
        result = interestData.incidentRecognized?.name;
      }
      break;

    case InterestsTypes.Functional_Area:
      if (classType === InterestClassType.Recognized) {
        result = interestData.functionalARecognized?.name;
      }
      break;

    case InterestsTypes.Communication:
      if (classType === InterestClassType.Recognized) {
        result = interestData.communicationRecognized?.name;
      }
      break;
    case InterestsTypes.Industry:
      if (classType === InterestClassType.Recognized) {
        result = interestData.industryRecognized?.name;
      }
      break;

    case InterestsTypes.Agent:
      if (classType === InterestClassType.Recognized) {
        result = interestData.agentRecognized?.name;
      }
      break;

    default:
      result = '';
      break;
  }

  return result || '';
};

export const formatInterestDataToIdValuePair = (
  interestData: serviceInterestTypes.InterestLookupDataType[],
  interestType: InterestsTypes | 'Interaction',
  addHashForProxy: boolean = false,
): interestsTypes.IdValuePairType[] => {
  switch (interestType) {
    case InterestsTypes.Enterprise:
      return interestData
        .filter((int) =>
          int.interestClassType === InterestClassType.Identified ? int.enterpriseIdentified : int.enterpriseRecognized,
        )

        .map((eachInterest) => {
          const classType = eachInterest.interestClassType;

          if (classType === InterestClassType.Identified) {
            let value = `${eachInterest.enterpriseIdentified?.company.name}`;

            if (eachInterest?.enterpriseIdentified?.isProxy && addHashForProxy) {
              value = `#${eachInterest.enterpriseIdentified?.company.name.replace('#', '')}`;
            }

            return {
              id: eachInterest.id,
              value,
              picture: undefined,
              type: InterestsTypes.Enterprise,
              classType: eachInterest.interestClassType,
              interest: {
                ...eachInterest.enterpriseIdentified,
              },
            };
          }
          return {
            id: eachInterest.enterpriseRecognized?.id || '',
            value: eachInterest.enterpriseRecognized?.name || '',
            picture: undefined,
            type: InterestsTypes.Enterprise,
            classType: eachInterest.interestClassType,
            interest: {
              ...eachInterest.enterpriseRecognized,
            },
          };
        });

    case InterestsTypes.Sensor:
      return interestData
        .filter((int) => int.sensorIdentified)
        .map((eachInterest) => ({
          id: eachInterest.sensorIdentified ? eachInterest.sensorIdentified?.id : '',
          value: `${eachInterest.sensorIdentified?.name}`,
          type: InterestsTypes.Sensor,
          classType: eachInterest.interestClassType,
          interest: {
            ...eachInterest.sensorIdentified,
          },
        }));

    case InterestsTypes.Relation:
      return interestData
        .filter((int) => int.relationIdentified)
        .map((eachInterest) => ({
          id: eachInterest.relationIdentified ? eachInterest.relationIdentified?.id : '',
          value: `${eachInterest.relationIdentified?.name}`,
          type: InterestsTypes.Relation,
          classType: eachInterest.interestClassType,
          interest: {
            ...eachInterest.relationIdentified,
          },
        }));

    case InterestsTypes.Topic:
      return interestData
        .filter((int) => int.topicIdentified)

        .map((eachInterest) => ({
          id: eachInterest.topicIdentified ? eachInterest.topicIdentified?.id : '',
          value: eachInterest.topicIdentified ? eachInterest.topicIdentified?.name : '', // eachInterest.topicIdentifyClass.name,
          type: InterestsTypes.Topic,
          classType: eachInterest.interestClassType,
          interest: {
            ...eachInterest.topicIdentified,
          },
        }));

    case InterestsTypes.Location:
      return interestData
        .filter((int) =>
          int.interestClassType === InterestClassType.Identified ? int.locationIdentified : int.locationRecognized,
        )
        .map((eachInterest) => {
          const classType = eachInterest.interestClassType;

          if (classType === InterestClassType.Identified) {
            return {
              id: eachInterest?.locationIdentified?.id || '',
              value: eachInterest?.locationIdentified?.name || '',
              type: InterestsTypes.Location,
              classType: eachInterest.interestClassType,
              interest: {
                ...eachInterest.locationIdentified,
              },
            };
          }
          return {
            id: eachInterest.locationRecognized ? eachInterest.locationRecognized?.id : '',
            value: eachInterest.locationRecognized ? eachInterest.locationRecognized?.name : '', // eachInterest.locationRecognitionClass.name,
            type: InterestsTypes.Location,
            classType: eachInterest.interestClassType,
            interest: {
              ...eachInterest.locationRecognized,
            },
          };
        });

    case InterestsTypes.Industry:
      return interestData
        .filter((int) => int.industryRecognized)

        .map((eachInterest) => ({
          id: eachInterest.industryRecognized ? eachInterest.industryRecognized?.id : '',
          value: eachInterest.industryRecognized ? eachInterest.industryRecognized?.name : '', // eachInterest.industryRecognitionClass.name,
          picture: undefined,
          type: InterestsTypes.Industry,
          classType: eachInterest.interestClassType,
          interest: {
            ...eachInterest.industryRecognized,
          },
        }));

    case InterestsTypes.Communication:
      return interestData
        .filter((int) => int.communicationRecognized)

        .map((eachInterest) => ({
          id: eachInterest.id,
          value: eachInterest?.communicationRecognized?.name || '',
          picture: undefined,
          type: InterestsTypes.Communication,
          classType: eachInterest.interestClassType,
          interest: {
            ...eachInterest.communicationRecognized,
          },
        }));

    case InterestsTypes.Incident:
      return interestData
        .filter((int) => int.incidentRecognized)

        .map((eachInterest) => ({
          id: eachInterest.id,
          value: eachInterest?.incidentRecognized?.name.replaceAll('_', ' ') || '',
          type: InterestsTypes.Incident,
          classType: eachInterest.interestClassType,
          interest: {
            ...eachInterest.incidentRecognized,
          },
        }));
    case InterestsTypes.Person:
      return interestData /* as serviceInterestTypes.PersonInterestDataType[] */

        .filter((int) =>
          int.interestClassType === InterestClassType.Identified ? int.personIdentified : int.personRecognized,
        )

        .map((eachInterest) => {
          const classType = eachInterest.interestClassType;
          if (classType === InterestClassType.Identified) {
            return {
              id: eachInterest.id,
              value:
                eachInterest?.personIdentified?.name.concat(' ', eachInterest?.personIdentified?.surname || '') || '',
              picture: undefined,
              type: InterestsTypes.Person,
              classType,
              interest: {
                ...eachInterest.personIdentified,
              },
            };
          }
          return {
            id: eachInterest.id,
            value: eachInterest?.personRecognized?.name || '', // vehicleIdentifyClass?
            picture: undefined,
            type: InterestsTypes.Person,
            classType,
            interest: {
              ...eachInterest.personRecognized,
            },
          };
        });

    case InterestsTypes.Object:
      return interestData
        .filter((int) => int.objectRecognized)
        .map((eachInterest) => ({
          id: eachInterest.id,
          value: eachInterest.objectRecognized?.name || '',
          picture: undefined,
          type: InterestsTypes.Object,
          classType: eachInterest.interestClassType,
          interest: {
            ...eachInterest.objectRecognized,
          },
        }));

    case InterestsTypes.Vehicle:
      return interestData /* as serviceInterestTypes.VehicleInterestDataType[] */
        .filter((int) =>
          int.interestClassType === InterestClassType.Identified ? int.vehicleIdentified : int.vehicleRecognized,
        )
        .map((eachInterest) => {
          const classType = eachInterest.interestClassType;
          if (classType === InterestClassType.Identified) {
            return {
              id: eachInterest.id,
              value: eachInterest?.vehicleIdentified?.name || '', // vehicleIdentifyClass?
              picture: undefined,
              type: InterestsTypes.Vehicle,
              classType,
              interest: {
                ...eachInterest.vehicleIdentified,
              },
            };
          }
          return {
            id: eachInterest.id,
            value: eachInterest?.vehicleRecognized?.name || '', // vehicleIdentifyClass?
            picture: undefined,
            type: InterestsTypes.Vehicle,
            classType,
            interest: {
              ...eachInterest.vehicleRecognized,
            },
          };
        });

    case InterestsTypes.Functional_Area:
      return interestData
        .filter((int) => int.functionalARecognized)
        .map((eachInterest) => ({
          id: eachInterest.id,
          value: eachInterest.functionalARecognized ? eachInterest.functionalARecognized?.name : '', // eachInterest.locationRecognitionClass.name,
          type: InterestsTypes.Functional_Area,
          classType: eachInterest.interestClassType,
          interest: {
            ...eachInterest.functionalARecognized,
          },
        }));

    case InterestsTypes.Agent:
      return interestData
        .filter((int) => int.agentRecognized)
        .map((eachInterest) => ({
          id: eachInterest.id,
          value: eachInterest.agentRecognized?.name || '',
          picture: undefined,
          type: InterestsTypes.Agent,
          classType: eachInterest.interestClassType,
          interest: {
            ...eachInterest.agentRecognized,
          },
        }));

    default:
      return [];
  }
};
