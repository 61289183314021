import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/cascader';
import { BaseOptionType } from 'antd/es/select';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getEnumKeyByValue } from 'src/app/helpers/enum.helper';
import { InterestsWithNamesLookup } from 'src/app/services/interest/interest.service.type';

import { InterestsTypes } from 'src/enums/interests.enum';
import { LookupDataType } from 'src/types/query.type';

export type LazyGetTriggerType = LazyQueryTrigger<
  QueryDefinition<
    LookupDataType | void, // Request parameters type
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    InterestsWithNamesLookup, // Response type // TODO: daha sonra generic hale getir
    'apiSlice' // Your reducerPath
  >
>;

interface DebounceSelectProps<ValueType> extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: LazyGetTriggerType;
  debounceTimeout?: number;
}

function DebounceSelect<ValueType extends BaseOptionType[]>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const fetchRef = useRef(0);

  useEffect(() => {
    if (!props.value && options.length) setOptions([]);
  }, [props.value]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (!value) {
        setOptions([]);
        return;
      }
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      fetchOptions({ search: [value] })
        .then(({ data }) => {
          if (fetchId !== fetchRef.current) {
            return;
          }

          if (!data?.rows || data?.rows.length <= 0) {
            return;
          }

          const newOptions: DefaultOptionType[] = [];

          data.rows.forEach(({ interests }) => {
            interests.forEach((interest) => {
              newOptions.push({
                label: `${interest.value} (${getEnumKeyByValue(InterestsTypes, interest.type)})`,
                value: interest.id,
                type: interest.type,
                name: interest.value,
                classType: interest.classType,
              });
            });
          });
          setOptions(newOptions);
        })
        .catch((err) => console.log('err', err));
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      autoClearSearchValue
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      //   notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

export default DebounceSelect;
