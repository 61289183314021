import { urlHelper } from 'src/app/helpers';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  InteractionData,
  InteractionLookupDataType,
  InteractionLookupResultType,
} from 'src/app/redux/interaction/interaction-slice.type';
import { urlsConfig } from 'src/configs';
import {
  EnterpriseAddSupplierType,
  EnterpriseRelationsResultType,
  UpdateMonitorSupplierType,
} from 'src/types/enterprise.type';
import { InteractionsCreateType, InteractionsUpdateType } from 'src/types/interactions.type';

export const interactionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInteractions: builder.query<InteractionLookupResultType, InteractionLookupDataType | void>({
      query: (params?: InteractionLookupDataType) => ({
        url: urlsConfig.interest.identified.relation.lookup,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'Interactions' as const,
                id,
              })),
              { type: 'Interactions', id: 'LIST' },
            ]
          : [{ type: 'Interactions', id: 'LIST' }],
    }),

    getEnterpriseInteractions: builder.query<EnterpriseRelationsResultType, string>({
      query: (id: string) => ({
        url: `${urlsConfig.interest.identified.relation.getRelationsOfCompany}/${id}`,
      }),
      transformResponse: (data: EnterpriseRelationsResultType) => {
        return {
          from: {
            ...data.from,
            rows: data.from.rows.reduce((acc: EnterpriseRelationsResultType['from']['rows'], row) => {
              const ent = row.fromInterest.enterpriseIdentified;
              if (ent.isProxy) {
                acc.push({
                  ...row,
                  fromInterest: {
                    ...row.fromInterest,
                    enterpriseIdentified: {
                      ...ent,
                      company: {
                        ...ent.company,
                        name: `#${ent.company.name}`,
                      },
                    },
                  },
                });
              } else acc.push(row);
              return acc;
            }, []),
          },
          to: data.to,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.from.rows.map(({ id }) => ({
                type: 'EnterpriseInteractions' as const,
                id,
              })),
              { type: 'EnterpriseInteractions', id: 'LIST' },
            ]
          : [{ type: 'EnterpriseInteractions', id: 'LIST' }],
    }),
    createInteraction: builder.mutation({
      query: (data: InteractionsCreateType) => ({
        url: urlsConfig.interest.identified.relation.create,
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: [
        { type: 'Interactions', id: 'LIST' },
        { type: 'EnterpriseInteractions', id: 'LIST' },
        { type: 'AddSupplier', id: 'LIST' },
      ],
    }),
    updateInteraction: builder.mutation({
      query: (data: InteractionsUpdateType) => ({
        url: urlsConfig.interest.identified.relation.update,
        method: 'PATCH',
        body: { ...data },
      }),
      invalidatesTags: (_result, _error, data: InteractionData) => [
        { type: 'Interactions', id: data.id },
        { type: 'EnterpriseInteractions', id: 'LIST' },
      ],
    }),
    enterpriseAddSupplier: builder.query<EnterpriseAddSupplierType, string>({
      query: (id: string) => ({
        url: `${urlsConfig.interest.identified.relation.addSupplier}/${id}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.from.rows.map(({ id }) => ({
                type: 'AddSupplier' as const,
                id,
              })),
              { type: 'AddSupplier', id: 'LIST' },
            ]
          : [{ type: 'AddSupplier', id: 'LIST' }],
    }),
    deleteInteraction: builder.mutation({
      query: (id: string) => ({
        url: `${urlsConfig.interest.identified.relation.delete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: 'Interactions', id },
        { type: 'EnterpriseInteractions', id: 'LIST' },

        { type: 'AddSupplier', id: 'LIST' },
      ],
    }),
    deleteSupplierInteraction: builder.mutation({
      query: (id: string) => ({
        url: `${urlsConfig.interest.identified.relation.deleleteSupplier}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: 'Interactions', id },
        { type: 'EnterpriseInteractions', id: 'LIST' },

        { type: 'AddSupplier', id: 'LIST' },
      ],
    }),
    updateMonitorSupplier: builder.mutation({
      query: (data: UpdateMonitorSupplierType) => ({
        url: urlsConfig.interest.identified.relation.updateMonitorSupplier,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [
        { type: 'Interactions', id: 'LIST' },
        { type: 'EnterpriseInteractions', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetInteractionsQuery,
  useLazyGetInteractionsQuery,
  useGetEnterpriseInteractionsQuery,
  useCreateInteractionMutation,
  useEnterpriseAddSupplierQuery,
  useDeleteInteractionMutation,
  useDeleteSupplierInteractionMutation,
  useUpdateInteractionMutation,
  useUpdateMonitorSupplierMutation,
} = interactionSlice;
