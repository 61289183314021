import { iconType } from 'src/types';

const DeleteIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={
        onClick
          ? { cursor: 'pointer', display: 'flex', alignItems: 'center' }
          : { display: 'flex', alignItems: 'center' }
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill={color || '#1a3441'}
        style={style}
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
      </svg>
    </div>
  );
};

DeleteIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '12px' },
  color: 'var(--primary_text_color)',
  onClick: undefined,
  className: undefined,
};

export default DeleteIcon;
