import {
  convertDateToReadableText,
  exportFileSendMail,
} from 'src/app/forms/ExportInterestForm/export-interest-form.config';
import { pdfExport } from 'src/app/helpers/pdf.helper';
import { NSearchLoggerType } from 'src/app/redux/nSearch/nSearch-slice-type';
import { getNSearchLogs } from 'src/app/services/nSearchBackend/nSearchLogger-service';
import { ReportTypes } from 'src/enums/logger-export.enum';

export const exportSC3DataFilter = [
  { key: 'company_info', value: ReportTypes.Company_Info, label: 'Company Info' },
  { key: 'customer_info', value: ReportTypes.Customer_Info, label: 'Customer Info' },
  { key: 'subscription', value: ReportTypes.Subscription, label: 'Subscription' },
  { key: 'user_activity', value: ReportTypes.User_Activity, label: 'User Activity' },
  { key: 'resource', value: ReportTypes.Resource, label: 'Resource' },
  { key: 'nSearch', value: ReportTypes.Nsearch, label: 'Nsearch' },
];

export const getNSearches = async ({
  mailList,
  customerIds,
  excel,
  isSendAttachment,
}: {
  mailList: string[];
  customerIds?: string[];
  excel?: any;
  isSendAttachment?: boolean;
}) => {
  try {
    const { data } = await getNSearchLogs({ ...(customerIds && { customerIds }) });
    await exportNSearchPdf({ nSearch: data.rows, mailList, excel, isSendAttachment });
  } catch (error) {
    console.log('error', error);
  }
};

const allInterestTableValues = (nSearch: NSearchLoggerType[]) => {
  const headers = [
    'User',
    'Source Category',
    'Source Type',
    'Source',
    'Interests',
    'Media Type',
    // "Location",
    'Action Date',
  ];

  const values = nSearch.map((logData) => [
    logData.username,
    logData.dataSourceCategory?.join('\n'),
    logData.dataSourceType?.join('\n'),
    logData.dataSource?.join('\n'),
    logData.interests?.join('\n'),
    logData.mediaTypes?.join('\n'),
    // logData.coordinates?.map(coordinate => [coordinate.lat.toFixed(6), coordinate.lng.toFixed(6)].join(", ")).join("\n"),
    convertDateToReadableText(logData.createdAt),
  ]);

  return { values, headers };
};

export const exportNSearchPdf = async ({
  nSearch,
  mailList,
  excel,
  isSendAttachment,
}: {
  nSearch: NSearchLoggerType[];
  mailList: any[];
  excel?: any;
  isSendAttachment?: boolean;
}) => {
  const tableDataValues = allInterestTableValues(nSearch);
  if (tableDataValues) {
    const pdfData = pdfExport({
      headers: tableDataValues.headers,
      tableBodyValues: tableDataValues.values,
      interestName: 'NSearch',
      options: {
        orientation: 'p',
        format: 'a4',
      },
      isReturnFunc: true,
    });
    if (mailList?.length > 0) {
      if (excel) {
        exportFileSendMail({
          emails: mailList,
          type: 'pdf & excel',
          ...(isSendAttachment && { excel, pdf: pdfData }),
        });
      } else {
        exportFileSendMail({ emails: mailList, type: 'pdf', pdf: pdfData });
      }
    }
  }
};
