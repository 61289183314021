import { Form, message } from 'antd';
import { t } from 'i18next';
import ActionColumnComponent from 'src/app/components/ActionColumnComponent/ActionColumnComponent';
import ExtraTab from 'src/app/components/ExtraTabcomponent/ExtraTab';
import showModal from 'src/app/components/ShowModalComponent/ShowModal';
import { ICustomTabs } from 'src/app/components/TabLayoutComponent/tab-layout.config';
import TabLayout from 'src/app/components/TabLayoutComponent/TabLayout';
import TableComponent, { CustomColumnsType } from 'src/app/components/TableComponent/TableComponent';
import UserRoleForm from 'src/app/forms/UserRoleForm/UserRoleForm';
import { formHelper } from 'src/app/helpers';
import { useDeleteUserRoleMutation, useGetUserRolesQuery } from 'src/app/redux/userRoles/userRolesSlice';
import AddInteractionIcon from 'src/icons/AddInteractionIcon';
import { UserRoleColumnDataType } from 'src/types/table.type';

const UserRoles = () => {
  // const { data: userRoles, isLoading } = useUserRoleLookup();
  const { data: userRoles, isLoading } = useGetUserRolesQuery({ includeSuperUser: true });

  // const { mutate: userRoleDelete } = useUserRoleDelete();
  const [deleteUserRole] = useDeleteUserRoleMutation();

  const [form] = Form.useForm();
  const columns: CustomColumnsType<UserRoleColumnDataType> = [
    {
      title: 'User Role',
      dataIndex: 'name',
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },

    {
      title: 'Abbreviation',
      dataIndex: 'abbreviation',
      sorter: (a, b) => a?.abbreviation?.localeCompare(b?.abbreviation),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: (a, b) => a?.description?.localeCompare(b?.description),
    },
    {
      width: '10%',
      render: (_: any, record) => (
        <ActionColumnComponent
          editComponent={{
            onClick: (event) => {
              form.setFieldsValue({ ...record });
              showModal({
                title: 'Edit User Role',
                content: <UserRoleForm form={form} mode="edit" />,
                event,
                size: 'large',
                onSave: (modal) => {
                  formHelper.submitForm(form, modal);
                },
              });
            },
            title: t('global.edit'),
          }}
          deleteComponent={{
            onClick: (event) => {
              showModal({
                title: 'Delete User Role',
                content: `Delete ${record.name}`,
                event,
                onSave: async (modal) => {
                  // userRoleDelete(record.id);
                  deleteUserRole(record.id)
                    .unwrap()
                    .then(() => message.success(t('success.deleted')))
                    .catch(() => message.error(t('errors.unknownError')));
                  modal.destroy();
                },
              });
            },
            title: t('global.delete'),
          }}
        />
      ),
    },
  ];
  const tabItems: ICustomTabs[] = [
    {
      key: '1',
      label: '',
      children: <TableComponent dataSource={userRoles?.rows || []} loading={isLoading} columns={columns} />,
      extendedContents: (
        <ExtraTab
          icon={<AddInteractionIcon />}
          text="Add User Role"
          iconTheme
          onClick={(event) =>
            showModal({
              size: 'large',
              title: 'Add User Role',
              event,
              content: <UserRoleForm form={form} mode="add" />,
              onSave: (modal) => formHelper.submitForm(form, modal),
            })
          }
        />
      ),
    },
  ];
  return <TabLayout paramName="tab" items={tabItems} className="tabs-with-border-container" />;
};

export default UserRoles;
