import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/app/redux/hook';
import { logout } from 'src/app/redux/user/userSlice';

const GuestUserLayout = () => {
  const { token } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token) {
      dispatch(logout());
    }
  }, []);

  return <Outlet />;
};

export default GuestUserLayout;
