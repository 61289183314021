import { Layout, Menu, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import FooterComponent from 'src/app/components/FooterComponent/FooterComponent';
import styles from 'src/app/components/Sidebar/Sidebar.module.scss';
import { enumHelper, routeHelper } from 'src/app/helpers';
import { getEnumKeyByValue } from 'src/app/helpers/enum.helper';
import { genericExcludeFilter, getPermissionKey } from 'src/app/helpers/permission.helper';
import usePermissions from 'src/app/hooks/usePermissions';
import { ConfigContentStateType } from 'src/app/redux/configContent/configContentSlice';
import { useAppSelector } from 'src/app/redux/hook';
import { routes } from 'src/configs/permissions.dictionary';
import { LAYOUTVIEW } from 'src/configs/system.config';
import { routesEnums } from 'src/enums';
import { RoutesEnum } from 'src/enums/route.enums';
import { LayoutViewType } from 'src/enums/user.enum';
import {
  CustomerAndUserIcon,
  HomeIcon,
  IndustryIcon,
  LocationIcon,
  NetworkIcon,
  NotificationIcon,
  ReportIcon,
  ResourcesIcon,
  SearchIcon,
  TopicIcon,
} from 'src/icons/index';
import StudentIcon from 'src/icons/StudentIcon';
import { Sidebar as SidebarTheme } from 'src/theme/Sidebar.styled';
import { routesTypes } from 'src/types';
import { RoutesType } from 'src/types/routes.type';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  permissions: string[],
  label: string,
  key: React.Key,
  icon?: React.ReactNode,
  excludeLayouts?: LayoutViewType[],
  children?: MenuItem[],
): MenuItem {
  const routeKey = getEnumKeyByValue(RoutesEnum, key.toString());

  const checkedPermission = getPermissionKey([routes[routeKey]]);

  if (excludeLayouts && !genericExcludeFilter(excludeLayouts, LAYOUTVIEW)) return null;
  if (!permissions.includes(checkedPermission)) return null;
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
interface IProps {
  collapsed: boolean;
  setCollapsed: (active: boolean) => void;
}

const Sidebar = ({ collapsed, setCollapsed }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { permissions } = usePermissions();
  const [sidebarMenu, setSidebarMenu] = useState<string>();

  const currentTheme: ConfigContentStateType = useAppSelector((state) => state.configContent);

  const items: MenuItem[] = [
    getItem(
      permissions,
      t('sidebar.homePage'),
      RoutesEnum['/'],
      <HomeIcon circleColor={currentTheme.theme.colors.header} color={currentTheme.theme.colors.quoteTitle} />,
    ),
    getItem(
      permissions,
      t('sidebar.network'),
      RoutesEnum['/Network'],
      <NetworkIcon circleColor={currentTheme.theme.colors.header} color={currentTheme.theme.colors.quoteTitle} />,
      [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3],
    ),
    getItem(
      permissions,
      t('sidebar.nsearch'),
      RoutesEnum['/NSearch'],
      <SearchIcon
        style={{ height: '28px', width: '28px', marginLeft: '3px' }}
        circleColor={currentTheme.theme.colors.header}
        color={currentTheme.theme.colors.quoteTitle}
      />,
      [LayoutViewType.LAYOUT_1],
    ),
    getItem(
      permissions,
      t('sidebar.myNotifications'),
      RoutesEnum['/Notifications'],
      <NotificationIcon circleColor={currentTheme.theme.colors.header} color={currentTheme.theme.colors.quoteTitle} />,
    ),
    getItem(
      permissions,
      t('sidebar.topic'),
      RoutesEnum['/Topic'],
      <TopicIcon circleColor={currentTheme.theme.colors.header} color={currentTheme.theme.colors.quoteTitle} />,
      [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3],
    ),
    getItem(
      permissions,
      t('sidebar.location'),
      RoutesEnum['/Location'],
      <LocationIcon circleColor={currentTheme.theme.colors.header} color={currentTheme.theme.colors.quoteTitle} />,
      [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3],
    ),
    getItem(
      permissions,
      t('sidebar.industry'),
      RoutesEnum['/Industry'],
      <IndustryIcon circleColor={currentTheme.theme.colors.header} color={currentTheme.theme.colors.quoteTitle} />,
      [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3],
    ),

    getItem(
      permissions,
      t('sidebar.report'),
      RoutesEnum['/Reports'],
      <ReportIcon circleColor={currentTheme.theme.colors.header} color={currentTheme.theme.colors.quoteTitle} />,
      [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3],
    ),
    getItem(
      permissions,
      t('sidebar.resources'),
      RoutesEnum['/Resources'],
      <ResourcesIcon circleColor={currentTheme.theme.colors.header} color={currentTheme.theme.colors.quoteTitle} />,
      [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3],
    ),
    // getItem(
    //   permissions,
    //   t("sidebar.customerAndUser"),
    //   RoutesEnum["/CustomerAndUser"],
    //   <CustomerAndUserIcon
    //     circleColor={currentTheme.theme.colors.header}
    //     color={currentTheme.theme.colors.quoteTitle}
    //   />
    // ),
    // getItem(
    //   permissions,
    //   t("sidebar.customerAndUserInvitations"),
    //   RoutesEnum["/CustomerAndUserInvitations"],
    //   <CustomerAndUserIcon
    //     circleColor={currentTheme.theme.colors.header}
    //     color={currentTheme.theme.colors.quoteTitle}
    //   />
    // ),
    getItem(
      permissions,
      t('sidebar.students'),
      RoutesEnum['/Students'],
      <StudentIcon circleColor={currentTheme.theme.colors.header} color={currentTheme.theme.colors.quoteTitle} />,
      [LayoutViewType.LAYOUT_1, LayoutViewType.LAYOUT_3],
    ),
    getItem(
      permissions,
      t('sidebar.interests'),
      RoutesEnum['/Interests'],
      <CustomerAndUserIcon
        circleColor={currentTheme.theme.colors.header}
        color={currentTheme.theme.colors.quoteTitle}
      />,
      [LayoutViewType.LAYOUT_1],
    ),
  ];

  useEffect(() => {
    const handleActiveTabInSidebar = () => {
      const routeEnum = `/${location.pathname.split('/')[1]}` as RoutesType;
      if (routesEnums.RoutesEnum[routeEnum] !== undefined) {
        setSidebarMenu(routesEnums.RoutesEnum[routeEnum]);
      } else {
        setSidebarMenu(undefined);
      }
    };

    handleActiveTabInSidebar();
  }, [location]);

  const onMenuClick: MenuProps['onClick'] = (menuItem) => {
    const route = enumHelper.getEnumKeyByValue(routesEnums.RoutesEnum, menuItem.key);
    routeHelper.handleRoute(navigate, route as routesTypes.RoutesType);
  };

  return (
    <SidebarTheme theme={currentTheme.theme}>
      <div className={styles.sideBar}>
        <Sider
          collapsible
          breakpoint="lg"
          collapsedWidth={90}
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          // theme="light"
          trigger={null}
          width={275}
        >
          <div className={styles.menu} id="menu">
            <div id="menuContainer" className={styles.menuContainer}>
              <Menu
                // theme="light"
                // defaultSelectedKeys={["0"]}
                mode="inline"
                items={items}
                onClick={onMenuClick}
                selectedKeys={sidebarMenu ? [sidebarMenu] : []}
              />

              <div className={styles.collapseIcon} />
            </div>

            <div className={styles.menuFooter} id="menuFooter">
              {collapsed ? (
                <img src="/logo_nginr_metalik_amblem.png" alt="Sama Logo" className={styles.nginrLogo} />
              ) : (
                <FooterComponent />
              )}
            </div>
          </div>
        </Sider>
      </div>
    </SidebarTheme>
  );
};

export default Sidebar;
