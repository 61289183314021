import axios from 'axios';
import { urlsConfig } from 'src/configs';

declare module 'axios' {
  export interface AxiosRequestConfig {
    blockSpinner?: boolean;
    blockErrorHandler?: boolean;
    flag?: boolean;
  }
}

const setToken = (newToken?: string) => {
  axiosInstance.defaults.headers.common.Authorization = newToken ? `Bearer ${newToken}` : undefined;
  surferAxios.defaults.headers.common.Authorization = newToken ? `Bearer ${newToken}` : undefined;
  dfmAxios.defaults.headers.common.Authorization = newToken ? `Bearer ${newToken}` : undefined;
};

const axiosInstance = axios.create({
  baseURL: urlsConfig.webServerBaseURL,
});

const surferAxios = axios.create({
  baseURL: urlsConfig.surferURL,
});
const dfmAxios = axios.create({
  baseURL: urlsConfig.dfmURL,
});

export { axiosInstance, surferAxios, dfmAxios, setToken };
