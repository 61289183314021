import { CSSProperties, useState } from 'react';

import { Button, Tooltip } from 'antd';
import { FaRegSnowflake } from 'react-icons/fa';
import { FiEye } from 'react-icons/fi';
import { IActionIconProps } from 'src/app/components/ActionColumnComponent/action-column-component.config';
import styles from 'src/app/components/ActionColumnComponent/ActionColumn.module.scss';
import { useAppSelector } from 'src/app/redux/hook';
import {
  CopyIcon,
  EditIcon,
  LockClosedIcon,
  LockOpenedIcon,
  NetworkIcon,
  PaymentNeededIcon,
  PaymentVerifiedIcon,
} from 'src/icons';
import ActionsIcon from 'src/icons/ActionsIcon';
import AddIcon from 'src/icons/AddIcon';
import ArchiveIcon from 'src/icons/ArchiveIcon';
import ChangeIcon from 'src/icons/ChangeIcon';
import DeleteIcon from 'src/icons/DeleteIcon';
import DownloadIconPlain from 'src/icons/DownloadIconPlain';
import LinkIcon from 'src/icons/LinkIcon';
import OpenFileIcon from 'src/icons/OpenFileIcon';
import PrinterIcon from 'src/icons/PrinterIcon';
import SendMailIcon from 'src/icons/SendMailIcon';

interface IActionColumnProps {
  createComponent?: IActionIconProps;

  editComponent?: IActionIconProps;
  deleteComponent?: IActionIconProps;
  linkComponent?: IActionIconProps;
  archiveComponent?: IActionIconProps;
  showComponent?: IActionIconProps;
  actionsComponent?: IActionIconProps;
  openFileComponent?: IActionIconProps;
  freezeComponent?: IActionIconProps;
  unFreezeComponent?: IActionIconProps;
  sendMail?: IActionIconProps;
  downloadComponent?: IActionIconProps;
  saveCancelButtonsComponent?: IActionIconProps;
  changeComponent?: IActionIconProps;
  cloneFileComponent?: IActionIconProps;
  paymentVerifiedComponent?: IActionIconProps;
  paymentNeededComponent?: IActionIconProps;
  lockOpenedComponent?: IActionIconProps;
  lockClosedComponent?: IActionIconProps;
  networkComponent?: IActionIconProps;
  printComponent?: IActionIconProps;
}

const disabledIconStyle: CSSProperties = {
  opacity: '0.4',
  cursor: 'not-allowed',
};

const ActionColumnComponent = (props: IActionColumnProps) => {
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const { theme } = useAppSelector((state) => state.configContent);
  const {
    createComponent,
    actionsComponent,
    archiveComponent,
    changeComponent,
    cloneFileComponent,
    deleteComponent,
    downloadComponent,
    editComponent,
    freezeComponent,
    linkComponent,
    lockClosedComponent,
    lockOpenedComponent,
    networkComponent,
    openFileComponent,
    paymentNeededComponent,
    paymentVerifiedComponent,
    printComponent,
    saveCancelButtonsComponent,
    sendMail,
    showComponent,
    unFreezeComponent,
  } = props;

  const onSave = () => {
    const onEditSave = editComponent?.saveButtonConfig?.onSave;
    if (onEditSave) onEditSave();
    setShowSaveButton(false);
  };
  const onCancel = () => {
    const onEditCancel = editComponent?.saveButtonConfig?.onCancel;
    if (onEditCancel) onEditCancel();
    setShowSaveButton(false);
  };

  if (showSaveButton)
    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          className={styles.saveButton}
          onClick={onSave}
          style={{ backgroundColor: theme.colors.quoteBgc, fontWeight: 'bold' }}
        >
          Save
        </Button>
        <Button className={styles.cancelButton} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    );
  return (
    <div className="flex-center" style={{ columnGap: '8px' }}>
      {/* SEND MAIL ICON */}
      {sendMail && (
        <Tooltip placement="top" title={sendMail.title}>
          <div className={styles.iconContainer}>
            <SendMailIcon
              onClick={!sendMail.disabled ? sendMail.onClick : undefined}
              style={sendMail.disabled ? disabledIconStyle : undefined}
            />
          </div>
        </Tooltip>
      )}
      {/* LINK ICON */}
      {linkComponent && (
        <Tooltip placement="top" title={linkComponent.title}>
          <div className={styles.iconContainer}>
            <LinkIcon
              onClick={(e) => {
                e.stopPropagation();
                if (!linkComponent?.disabled) linkComponent?.onClick();
              }}
              style={linkComponent.disabled ? disabledIconStyle : undefined}
            />
          </div>
        </Tooltip>
      )}
      {/* EYE ICON  */}
      {showComponent && (
        <Tooltip placement="top" title={showComponent.title}>
          <div className={styles.iconContainer}>
            <FiEye
              style={showComponent.disabled ? disabledIconStyle : { cursor: 'pointer' }}
              size={23}
              onClick={!showComponent.disabled ? showComponent.onClick : undefined}
            />
          </div>
        </Tooltip>
      )}
      {/* lock opened ICON  */}
      {lockOpenedComponent && (
        <Tooltip placement="top" title={lockOpenedComponent.title}>
          <div className={styles.iconContainer}>
            <LockOpenedIcon style={{ cursor: 'pointer' }} onClick={lockOpenedComponent.onClick} />
          </div>
        </Tooltip>
      )}
      {/* lock closed ICON  */}
      {lockClosedComponent && (
        <Tooltip placement="top" title={lockClosedComponent.title}>
          <div className={styles.iconContainer}>
            <LockClosedIcon style={{ cursor: 'pointer' }} onClick={lockClosedComponent.onClick} />
          </div>
        </Tooltip>
      )}
      {/* payment verified ICON  */}
      {paymentVerifiedComponent && (
        <Tooltip placement="top" title={paymentVerifiedComponent.title}>
          <div className={styles.iconContainer}>
            <PaymentVerifiedIcon style={{ cursor: 'pointer' }} onClick={paymentVerifiedComponent.onClick} />
          </div>
        </Tooltip>
      )}
      {/* payment needed ICON  */}
      {paymentNeededComponent && (
        <Tooltip placement="top" title={paymentNeededComponent.title}>
          <div className={styles.iconContainer}>
            <PaymentNeededIcon style={{ cursor: 'pointer' }} onClick={paymentNeededComponent.onClick} />
          </div>
        </Tooltip>
      )}

      {/* Print ICON */}
      {printComponent && (
        <Tooltip placement="top" title={printComponent.title}>
          <div className={styles.iconContainer}>
            <PrinterIcon
              onClick={!printComponent.disabled ? printComponent.onClick : undefined}
              style={printComponent.disabled ? disabledIconStyle : { cursor: 'pointer' }}
            />
          </div>
        </Tooltip>
      )}

      {/* DOWNLOAD ICON */}
      {downloadComponent && (
        <Tooltip placement="top" title={downloadComponent.title}>
          <div className={styles.iconContainer}>
            <DownloadIconPlain
              onClick={downloadComponent.onClick}
              style={downloadComponent.disabled ? disabledIconStyle : undefined}
            />
          </div>
        </Tooltip>
      )}

      {/* NETWORK ICON */}
      {networkComponent && (
        <div className={styles.iconContainer}>
          <NetworkIcon
            circleColor="white"
            color="#1a3441"
            style={
              networkComponent.disabled
                ? { ...disabledIconStyle, width: '24px', height: '24px' }
                : { width: '24px', height: '24px' }
            }
            onClick={(e) => {
              e.stopPropagation();
              if (networkComponent?.onClick) networkComponent.onClick();
            }}
          />
        </div>
      )}

      {/* EXPORT ICON */}
      {/* {exportComponent && (
        <div className={styles.iconContainer}>
          <ExportIcon
            color="#1a3441"
            style={{ width: "24px", height: "24px" }}
            onClick={(e) => {
              e.stopPropagation();
              if (exportComponent?.onClick)
                exportComponent.onClick();
            }}
          />
        </div>
      )} */}

      {/* CREATE ICON */}
      {createComponent && (
        <Tooltip placement="top" title={createComponent.title}>
          <div className={styles.iconContainer}>
            <AddIcon
              color="black"
              onClick={(e) => {
                e.stopPropagation();
                if (createComponent?.disabled) return;
                if (createComponent?.saveButtonConfig) setShowSaveButton(true);
                if (createComponent?.onClick) createComponent.onClick(e);
              }}
              style={createComponent.disabled ? disabledIconStyle : undefined}
            />
          </div>
        </Tooltip>
      )}

      {/* EDIT ICON */}
      {editComponent && (
        <Tooltip placement="top" title={editComponent.title}>
          <div className={styles.iconContainer}>
            <EditIcon
              onClick={(e) => {
                e.stopPropagation();
                if (editComponent?.disabled) return;
                if (editComponent?.saveButtonConfig) setShowSaveButton(true);
                if (editComponent?.onClick) editComponent.onClick(e);
              }}
              style={editComponent.disabled ? disabledIconStyle : undefined}
            />
          </div>
        </Tooltip>
      )}
      {/* ARCHIVE ICON */}
      {archiveComponent && (
        <Tooltip placement="top" title={archiveComponent.title}>
          <div className={styles.iconContainer}>
            <ArchiveIcon
              onClick={!archiveComponent.disabled ? archiveComponent.onClick : undefined}
              style={archiveComponent.disabled ? disabledIconStyle : undefined}
            />
          </div>
        </Tooltip>
      )}
      {/* OPEN FILE ICON */}
      {openFileComponent && (
        <Tooltip placement="top" title={openFileComponent.title}>
          <div className={styles.iconContainer}>
            <OpenFileIcon
              style={openFileComponent.disabled ? disabledIconStyle : { cursor: 'pointer' }}
              onClick={openFileComponent.onClick}
            />
          </div>
        </Tooltip>
      )}
      {/* copy ICON  */}
      {cloneFileComponent && (
        <Tooltip placement="top" title={cloneFileComponent.title}>
          <div className={styles.iconContainer}>
            <CopyIcon style={{ cursor: 'pointer' }} onClick={cloneFileComponent.onClick} />
          </div>
        </Tooltip>
      )}
      {/* ACTIONS ICON */}
      {actionsComponent && (
        <Tooltip placement="top" title={actionsComponent.title}>
          <div className={styles.iconContainer}>
            <ActionsIcon
              onClick={!actionsComponent.disabled ? actionsComponent.onClick : undefined}
              style={actionsComponent.disabled ? disabledIconStyle : undefined}
            />
          </div>
        </Tooltip>
      )}
      {/* FREEZE ICON */}
      {freezeComponent && (
        <Tooltip placement="top" title={freezeComponent.title}>
          <div className={styles.iconContainer} style={{ cursor: 'pointer' }}>
            <FaRegSnowflake
              onClick={!freezeComponent.disabled ? freezeComponent.onClick : undefined}
              opacity={0.6}
              className={styles.freezeIcon}
              style={freezeComponent.disabled ? disabledIconStyle : { color: freezeComponent.color }}
            />
          </div>
        </Tooltip>
      )}
      {/* UNFREEZE ICON */}
      {unFreezeComponent && (
        <Tooltip placement="top" title={unFreezeComponent.title}>
          <div className={styles.iconContainer} style={{ cursor: 'pointer' }}>
            <FaRegSnowflake
              onClick={!unFreezeComponent.disabled ? unFreezeComponent.onClick : undefined}
              className={styles.freezeIcon}
              style={unFreezeComponent.disabled ? disabledIconStyle : { color: unFreezeComponent.color }}
            />
          </div>
        </Tooltip>
      )}

      {/* SAVE CANCEL BUTTONS */}
      {saveCancelButtonsComponent && (
        <div className={styles.iconContainer}>
          <Button>save</Button>
          <Button>cancel</Button>
        </div>
      )}

      {/* CHANGE ICON  */}
      {changeComponent && (
        <Tooltip placement="top" title={changeComponent.title}>
          <div className={styles.iconContainer}>
            <ChangeIcon
              color="rgba(0,0,0,0.9)"
              style={changeComponent.disabled ? disabledIconStyle : { cursor: 'pointer' }}
              onClick={!changeComponent.disabled ? changeComponent.onClick : undefined}
            />
          </div>
        </Tooltip>
      )}

      {/* DELETE ICON */}
      {deleteComponent && (
        <Tooltip placement="top" title={deleteComponent.title}>
          <div className={styles.iconContainer}>
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation();
                if (deleteComponent?.disabled) return;
                if (deleteComponent?.onClick) deleteComponent.onClick(e);
              }}
              style={deleteComponent.disabled ? disabledIconStyle : { color: 'rgb(146, 170, 187)' }}
              // color="rgb(146, 170, 187)"
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default ActionColumnComponent;
