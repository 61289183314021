import { t } from 'i18next';
import { useEffect, useState } from 'react';
import TableComponent, { CustomColumnsType } from 'src/app/components/TableComponent/TableComponent';
import { timeHelper } from 'src/app/helpers';
import { getEnumKeyByValue } from 'src/app/helpers/enum.helper';
import { deliveryStatusFilterArr, registerStatusFilterArr } from 'src/app/helpers/enumToFilter.helper';
import { compareDates } from 'src/app/helpers/time.helper';
import registerInvitationsService from 'src/app/services/registerInvitations/registerInvitations.service';
import { DeliveryStatusType, RegisteredStatusType } from 'src/enums/sc3-type.enum';
import { CustomerInvitationsColumnDataType } from 'src/types/table.type';

const UserInvitaions = () => {
  const [tableDataSource, setTableDataSource] = useState<CustomerInvitationsColumnDataType[]>([]);

  const getCustomerInvitations = async () => {
    try {
      const { data } = await registerInvitationsService.lookUpRegisterInvitations();
      const tableData: CustomerInvitationsColumnDataType[] = [];
      data.rows.forEach((invitation) => {
        tableData.push({
          id: invitation.id,
          companyName: invitation.usersCompanyName,
          receiverUserName: invitation.receiverUserName,
          receiverUserEmail: invitation.email,
          deliveryStatus: getEnumKeyByValue(DeliveryStatusType, invitation.deliveryStatus),
          deliveryDate: invitation.deliveryDate ? timeHelper.formatDate(invitation.deliveryDate) : '',
          registerStatus: getEnumKeyByValue(RegisteredStatusType, invitation.registerStatus),
          registerDate: invitation.registerDate ? timeHelper.formatDate(invitation.registerDate) : '',
          senderUserName: invitation.senderUserName,
          senderUserEmail: invitation.senderUserEmail,
        });
      });

      setTableDataSource(tableData);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getCustomerInvitations();
  }, []);
  const columns: CustomColumnsType<CustomerInvitationsColumnDataType> = [
    {
      title: t('tables.userInvitations.columns.usersCompanyName'),
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: (a, b) => a?.companyName?.localeCompare(b?.companyName),
    },
    {
      title: t('tables.userInvitations.columns.receiverUserName'),
      dataIndex: 'receiverUserName',
      key: 'receiverUserName',
      sorter: (a, b) => a?.receiverUserName?.localeCompare(b?.receiverUserName),
    },
    {
      title: t('tables.userInvitations.columns.receiverUserEmail'),
      dataIndex: 'receiverUserEmail',
      key: 'receiverUserEmail',
      sorter: (a, b) => a?.receiverUserEmail?.localeCompare(b?.receiverUserEmail),
    },
    {
      title: t('tables.userInvitations.columns.deliveryStatus'),
      dataIndex: 'deliveryStatus',
      key: 'deliveryStatus',
      sorter: (a, b) => a?.deliveryStatus?.localeCompare(b?.deliveryStatus),
      filters: deliveryStatusFilterArr,
      onFilter: (value: any, record) => record?.deliveryStatus === value,
    },
    {
      title: t('tables.userInvitations.columns.deliveryDate'),
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      sorter: (a, b) => compareDates(a?.deliveryDate, b?.deliveryDate),
    },
    {
      title: t('tables.userInvitations.columns.registerStatus'),
      dataIndex: 'registerStatus',
      key: 'registerStatus',
      sorter: (a, b) => a?.registerStatus?.localeCompare(b?.registerStatus),
      filters: registerStatusFilterArr,
      onFilter: (value: any, record) => record?.registerStatus === value,
    },
    {
      title: t('tables.userInvitations.columns.registerDate'),
      dataIndex: 'registerDate',
      key: 'registerDate',
      sorter: (a, b) => compareDates(a?.registerDate, b?.registerDate),
    },
    {
      title: t('tables.userInvitations.columns.senderUserName'),
      dataIndex: 'senderUserName',
      key: 'senderUserName',
      sorter: (a, b) => a?.senderUserName?.localeCompare(b?.senderUserName),
    },
    {
      title: t('tables.userInvitations.columns.senderUserEmail'),
      dataIndex: 'senderUserEmail',
      key: 'senderUserEmail',
      sorter: (a, b) => a?.senderUserEmail?.localeCompare(b?.senderUserEmail),
    },
  ];

  return <TableComponent columns={columns} dataSource={tableDataSource} size="middle" />;
};

export default UserInvitaions;
