import { urlHelper } from 'src/app/helpers';

import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  SensorIdentifiedCreateDataType,
  SensorIdentifiedDataType,
  SensorIdentifiedLookupDataType,
  SensorIdentifiedLookupResultType,
  SensorIdentifiedUpdateDataType,
} from 'src/app/redux/sensors/sensor-slice.type';
import { urlsConfig } from 'src/configs';

export const sensorIdentifiedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSensorsIdentified: builder.query<SensorIdentifiedLookupResultType, SensorIdentifiedLookupDataType | void>({
      query: (params?: SensorIdentifiedLookupDataType) => ({
        url: urlsConfig.interest.identified.sensor.lookup,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'SensorIdentified' as const,
                id,
              })),
              { type: 'SensorIdentified', id: 'LIST' },
            ]
          : [{ type: 'SensorIdentified', id: 'LIST' }],
    }),
    createSensor: builder.mutation<SensorIdentifiedDataType, SensorIdentifiedCreateDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.sensor.create,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'SensorIdentified', id: 'LIST' }],
    }),
    updateSensor: builder.mutation<SensorIdentifiedDataType, SensorIdentifiedUpdateDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.sensor.update,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, data) => [{ type: 'SensorIdentified', id: data.id }],
    }),
    deleteSensor: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `${urlsConfig.interest.identified.sensor.delete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'SensorIdentified', id }],
    }),
    exportSensorIdentifiedExcell: builder.mutation<ArrayBuffer, { ids?: string[] }>({
      query: (data) => ({
        url: urlsConfig.exports.sensor,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetSensorsIdentifiedQuery,
  useLazyGetSensorsIdentifiedQuery,
  useCreateSensorMutation,
  useUpdateSensorMutation,
  useDeleteSensorMutation,
  useExportSensorIdentifiedExcellMutation,
} = sensorIdentifiedSlice;
