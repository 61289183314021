import React from 'react';

import { iconType } from 'src/types';

const FileUploadIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.7918 11.0749H9.64126V17.9899C9.59644 17.9927 9.55297 17.9974 9.5095 17.9974C6.87686 17.9974 4.24431 17.9974 1.61185 17.9974C0.868773 17.9969 0.334685 17.5393 0.213718 16.8032C0.202041 16.7163 0.197525 16.6286 0.200218 16.541C0.199858 12.4254 0.199678 8.30986 0.199678 4.19431C0.199678 3.35342 0.769677 2.76835 1.59376 2.76808C3.77638 2.76808 5.9589 2.76808 8.14133 2.76808C9.0502 2.76808 9.95925 2.76808 10.8685 2.76808C11.6458 2.76808 12.3071 2.12009 12.3522 1.31773C12.3932 0.579113 12.9638 0.0054045 13.6836 0.00374158C15.9426 -0.00124719 18.2017 -0.00124719 20.461 0.00374158C21.217 0.0054045 21.7989 0.615975 21.7992 1.39367C21.8006 6.4606 21.8006 11.5277 21.7992 16.595C21.7992 17.3971 21.2092 17.9974 20.4173 17.9985C18.5317 18.0011 16.6462 18.0011 14.7607 17.9985H12.3625V11.0769H15.2187L11.0048 5.53767C9.604 7.37853 8.211 9.20969 6.7918 11.0749Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

FileUploadIcon.defaultProps = {
  style: { width: '14px', height: '14px', marginRight: '8px' },
  color: 'var(--sidebar-text-color)',
  onClick: undefined,
  className: undefined,
};

export default FileUploadIcon;
