import React from 'react';

import { iconType } from 'src/types';

const IndustryIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
        <circle id="Ellipse_59" data-name="Ellipse 59" cx="15.5" cy="15.5" r="15.5" fill={circleColor} />
        <g id="Industry_v2" transform="translate(6 6)">
          <path
            id="Path_37266"
            data-name="Path 37266"
            d="M304.59,539.51a.71.71,0,0,0-.69,0l-5.29,3.09v-2.5a.67.67,0,0,0-.2-.48.64.64,0,0,0-.48-.2h0a.719.719,0,0,0-.34.09l-5.29,3.09v-7.71a.68.68,0,0,0-.68-.68h-5.17a.69.69,0,0,0-.68.68v17.8a.68.68,0,0,0,.68.67h17.8a.68.68,0,0,0,.68-.67V540.1A.67.67,0,0,0,304.59,539.51Zm-13,5h0a.67.67,0,0,0,.33-.1l5.29-3.09v2.51a.68.68,0,0,0,.68.68h0a.64.64,0,0,0,.35-.1l5.29-3.09V552h-16.4V537.42h3.81v6.37a.69.69,0,0,0,.68.68Zm-4.49-8.41v-.49h3.81v.49Z"
            transform="translate(-285.77 -534.21)"
            fill={color}
          />
          <g id="Group_39497" data-name="Group 39497">
            <g id="Group_39496" data-name="Group 39496">
              <path
                id="Path_15270"
                data-name="Path 15270"
                d="M293.84,546.58h-2.22a.55.55,0,0,0-.55.55v2.21a.56.56,0,0,0,.55.56h2.22a.56.56,0,0,0,.55-.56v-2.21A.55.55,0,0,0,293.84,546.58Zm-.56,2.21h-1.1v-1.11h1.1Z"
                transform="translate(-285.77 -534.21)"
                fill={color}
              />
            </g>
          </g>
          <g id="Group_39499" data-name="Group 39499">
            <g id="Group_39498" data-name="Group 39498">
              <path
                id="Path_15271"
                data-name="Path 15271"
                d="M300.15,546.58h-2.22a.55.55,0,0,0-.55.55v2.21a.56.56,0,0,0,.55.56h2.22a.55.55,0,0,0,.55-.56v-2.21a.54.54,0,0,0-.55-.55Zm-.55,2.21h-1.11v-1.11h1.11Z"
                transform="translate(-285.77 -534.21)"
                fill={color}
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IndustryIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  style: { margin: '5px 0 -8px 0' },
  color: 'black',
  onClick: undefined,
  className: undefined,
};

export default IndustryIcon;
