import UrlDataFile from 'src/configs/urls.json';

type ExecutableType =
  | 'development-env'
  | 'production-env'
  | 'operational-env'
  | 'local-online'
  | 'local-offline'
  | 'customized';
type UrlConfigType = {
  socketURL: string;
  webServerBaseURL: string;
  loggerServerBaseURL: string;
  fileServerBaseURL: string;
  layersServerBaseURL: string;
  surferURL: string;
  excelImportUrl: string;
  excelExportUrl: string;
  storageServerURL: string;
  systemLoginURL: string;
  counterServerURL: string;
  cppMessengerURL: string;
  dfmURL: string;
};

// bunu şu sebepten yapıyorum
// build kulanılırken dışarıdan verilen urls.json file ı okunsun diye yoksa build alınırken ki urls.json dosyası okunur.
const isBuildRun = window.EXECUTABLE_TYPE && window.EXECUTABLE_TYPE !== '{{EXECUTABLE_TYPE}}';

const selectedExecutableType: ExecutableType = 'customized'; // localinde çalışıyorsan url leri burdan değiştireceksin.

const urlConfiguration = (): UrlConfigType => {
  // eğer build aşamasındaysa exe ye verilen url ler okunacak
  if (isBuildRun) {
    return {
      socketURL: window.SOCKET_URL,
      excelImportUrl: window.EXCEL_IMPORT_GENERATOR_URL,
      excelExportUrl: window.EXCEL_EXPORT_GENERATOR_URL,
      storageServerURL: window.STORAGE_SERVER_URL,
      surferURL: window.SURFER_URL,
      webServerBaseURL: window.WEB_SERVER_URL,
      loggerServerBaseURL: window.LOGGER_SERVER_URL,
      fileServerBaseURL: window.FILE_SERVER_URL,
      layersServerBaseURL: window.LAYERS_SERVER_URL,
      systemLoginURL: window.SYSTEM_LOGIN_URL,
      counterServerURL: window.COUNTER_SERVER_URL,
      cppMessengerURL: window.CPP_MESSENGER_URL,
      dfmURL: window.DFM_URL,
    };
  }
  return UrlDataFile[selectedExecutableType] as UrlConfigType;
};
const urlsConfig = {
  ...urlConfiguration(),

  user: {
    register: '/users',
    multipleAccountRegister: '/users/multipleRegister',
    verifyRegisterToken: '/users/verifyRegisterToken',
    login: '/users/login',
    logout: '/users/logout',
    getInformation: '/users/@me',
    lookup: '/users',
    approveRegister: '/register/approve',
    declineRegister: '/register/decline',
    updateProfile: '/users/profile',
    updateUser: '/users',
    updatePassword: '/users/updatePassword',
    userEmail: '/users/email',
    deleteUser: '/users',
    getUsers: '/users',
    resetPassword: '/users/resetPassword',
    updateLanguage: '/users/preferred-language',
    twoFactorAuth: '/users/two-factor-auth',
    googleAuthLogin: '/users/google-auth-login',
    roles: '/roles',
    googleAuthGetQr: 'users/qrCode',
    lostQrCodeMail: 'users/lostQrCodeMail',
    verifyResetToken: '/users/verifyResetPassword',
    verifyMailAuthenticationToken: '/users/verifyMailAuthentication',
    authenticateUser: '/users/mail-auth-login',
    forgotPassword: '/users/resetPasswordMail',
    userAllRegisterInvitation: '/register-invitations',
    sendRegisterInvitation: '/register-invitations',
    updateIsFirstAdmin: '/users/firstAdmin',
    updateCustomerTwoFA: '/users/AuthTwoFactor',
    customers: '/users/customers',
    updatePortalUser: '/users/updateUserPortal',
  },
  nSearchLogger: {
    nSearch: '/nsearch',
  },
  surfer: {
    dashboard: '/dashboard',
    mediaInsights: '/mediaInsights',
    relevantNews: '/relevantNews',
    interestLocation: '/geotemporal',
    nsearch: '/chain/nsearch',
    interest: 'chain/interest',
    blockOriginal: '/block',
    individualNetwork: '/network/individual',
    socialNetwork: '/network/social',
    network: '/network',
    blockStatistic: {
      lookup: '/block-statistic',
      create: '/block-statistic',
      update: '/block-statistic',
      delete: '/block-statistic',
    },
    estimation: '/statistic/estimateNotification', // tamamlandı assetID = companyId
    dataCount: '/statistic/dataCount', // customerId zorunlu edildi
    bulkDataCount: '/statistic/dataCount2', // customerId eklendi
    timeline: '/timeline',
  },
  dfm: {
    decryptDataSrc: '/decrypt',
  },
  layersServer: 'layers_server',

  faq: {
    lookup: '/faq',
    create: '/faq',
    update: '/faq',
    delete: '/faq',
  },

  interest: {
    base: '/interests',
    bulkLookup: '/interests',
    identified: {
      topic: {
        lookup: '/interests/identified/topics',
        create: '/interests/identified/topics',
        update: '/interests/identified/topics',
        delete: '/interests/identified/topics',
      },
      location: {
        lookup: '/interests/identified/location',
        create: '/interests/identified/location',
        update: '/interests/identified/location',
        delete: '/interests/identified/location',
        images: '/interests/identified/location/images',
        checkPicture: '/interests/identified/location/checkPicture',
      },
      sensor: {
        lookup: '/interests/identified/sensors',
        create: '/interests/identified/sensors',
        update: '/interests/identified/sensors',
        delete: '/interests/identified/sensors',
      },

      enterprise: {
        lookup: '/interests/identified/enterprise',
        relations: '/interests/identified/enterprise/relations',
        update: '/interests/identified/enterprise',
        delete: '/interests/identified/enterprise',
        enterpriseIsExists: 'interests/identified/enterprise/isExists',
        generateReport: '/interests/identified/enterprise/report',
        // create: "/interests/identified/enterprise",
      },

      vehicle: {
        lookup: '/interests/identified/vehicles',
        create: '/interests/identified/vehicles',
        update: '/interests/identified/vehicles',
        delete: '/interests/identified/vehicles',
      },
      person: {
        lookup: '/interests/identified/people',
        create: '/interests/identified/people',
        update: '/interests/identified/people',
        delete: '/interests/identified/people',
        checkPicture: '/interests/identified/people/checkPicture',
        deletePhotosFromCache: '/interests/identified/people/deletePhotosFromCache',
        cleanCache: '/interests/identified/people/cleanCache',
        genders: '/genders',
        import: '/imports/person',
      },
      relation: {
        getRelationsOfCompany: '/interests/identified/relation/company/relations',
        update: '/interests/identified/relation',
        create: '/interests/identified/relation',
        lookup: '/interests/identified/relation',
        delete: '/interests/identified/relation',
        updateMonitorSupplier: '/interests/identified/relation/updateMonitoredSupplier',
        addSupplier: '/interests/identified/relation/addSupplier',
        bulkImport: '/interests/identified/relation/bulk',
        types: '/interactionTypes',
        deleleteSupplier: '/interests/identified/relation/supplier',
      },
    },
    recognized: {
      industry: '/interests/recognized/industries',
      location: '/interests/recognized/location',
      funtionalArea: '/interests/recognized/functionalArea',
      vehicle: '/interests/recognized/vehicle',
      person: '/interests/recognized/person',
      incident: {
        lookup: '/interests/recognized/incident',
        update: '/interests/recognized/incident',
      },
      communication: '/interests/recognized/communication',
      enterprise: '/interests/recognized/enterprise',
      object: '/interests/recognized/object',
      unit: {
        lookup: '/interests/recognized/agent/categories',
        types: '/unit/types',
        categories: '/interests/recognized/unitCategories',
        models: '/interests/recognized/agent/models',
      },
    },
  },
  customer: {
    register: '/customer',
    customerRegister: '/customers/register',
    lookup: '/customers',
    update: '/customers',
    delete: '/customers',
    customerMultipleAccountRegister: '/customer/multipleAccountRegister',
    registerInvitation: '/customer-register/sendRegisterInvitation',
    verifyRegisterToken: '/customer-register/verifyRegisterToken',
    customersOfUser: '/customer/customers-of-user',
    customerAccountTypes: '/customerAccountTypes',
    companyOfCustomers: {
      lookup: '/company-of-customers',
    },
    updateAccountType: '/customers/accountType',
  },
  webBackendSurfer: {
    surfer: '/surfer',
  },
  webBackendMessenger: {
    postSenderEmailSettings: '/messenger-module/senderEmailSettings',
    getSenderEmailSettings: '/messenger-module/emailSettings',
  },
  geoJSONCountries: {
    getCountries: 'geo-jsoncountries',
  },
  customerAccountTypes: {
    lookup: '/customerAccountTypes',
  },
  logs: {
    lookup: '/logs',
  },
  notes: {
    lookup: '/notes',
    create: '/notes',
    update: '/notes',
  },
  customerAndUserInvitations: {
    customerInvitations: '/customer-register',
  },
  controlPanel: {
    running: '/control-panel/modules/running',
    installed: '/control-panel/modules/installed',
    start: '/control-panel/start',
    stop: '/control-panel/stop',
    restart: '/control-panel/restart',
    modules: '/control-panel/modules',
  },
  blackSystem: (param: string) => {
    return {
      export: `black-system/generateAI/${param}/export`,
      start: `black-system/generateAI/${param}/start`,
      number: `black-system/generateAI/${param}/number`,
    };
  },
  saq: {
    saqTemplates: {
      lookup: '/saq-templates',
      update: '/forms/updateStatus',
      delete: '/saq-templates',
    },
    saqEmailTemplate: {
      lookup: '/saq-mail-templates',
      create: '/saq-mail-templates',
      update: '/saq-mail-templates',
      delete: '/saq-mail-templates',
    },
    saqSettings: {
      lookup: '/saq-settings',
      update: '/saq-settings',
    },
  },
  coc: {
    cocSettings: {
      lookup: '/coc-settings',
      update: '/coc-settings',
    },
    cocEmailTemplate: {
      lookup: '/coc-mail-templates',
      create: '/coc-mail-templates',
      update: '/coc-mail-templates',
      delete: '/coc-mail-templates',
    },
    cocTemplates: {
      lookup: '/coc-templates',
      create: '/coc-templates',
      update: '/coc-templates',
      delete: '/coc-templates',
      download: '/coc-templates/download',
    },
    cocInvitations: {
      lookup: '/coc-invitations',
      create: '/coc-invitations',
      verifyCocToken: '/coc-invitations/verifyCocToken',
      status: '/coc-invitations/status',
      delete: '/coc-invitations',
    },
  },
  configurationChangeRequest: {
    lookup: '/ccr',
    create: '/ccr',
    update: '/ccr',
    destroy: '/ccr',
    download: '/ccr/download',
  },
  troubleTicket: {
    lookup: '/tt',
    create: '/tt',
    update: '/tt',
    destroy: '/tt',
    download: '/tt/download',
  },
  notifications: {
    lookup: '/notifications',
    getNotifications: '/notifications/@me',
    updateStatus: '/notifications/status',
    selectUpdateStatus: '/notifications/multipleStatus',
    allUpdateStatus: '/notifications/changeAllStatus',
    deleteNotification: '/notifications/multipleDelete',
    deleteAllNotification: '/notifications/deleteAll',
    getUnreadNotificationsCount: '/notifications/unreadNotifications',
    getNotificationInfo: '/notifications/notification/@me',
    addLabel: '/notifications/addLabel',
    removeLabel: '/notifications/deleteLabel',
  },
  rules: {
    lookup: '/rules',
    create: '/rules',
    update: '/rules',
    delete: '/rules',
  },
  labels: {
    lookup: '/labels',
    create: '/labels',
    update: '/labels',
    delete: '/labels',
  },
  userRole: {
    lookup: '/user-roles',
    create: '/user-roles',
    update: '/user-roles',
    destroy: '/user-roles',
  },
  userRolePermission: {
    lookup: '/user-role-permissions',
    create: '/user-role-permissions',
    update: '/user-role-permissions',
    destroy: '/user-role-permissions',
  },
  student: {
    lookup: '/students',
    create: '/students',
    update: '/students',
    destroy: '/students',
    exportRecord: '/students/exportPdf',
    exportAll: '',
  },
  counter: {
    dataCount: '/counter/statistic/dashboard',
  },
  sendMail: { mail: '/mailSettings/credentials' },
  forms: {
    lookup: '/forms',
    create: '/forms/assign',
    sendSaq: '/forms/assignSaq',
    duplicate: '/forms/duplicate',
    delete: '/forms',
    saqFormGet: '/forms/saq',
    saqSubmitSection: '/forms/saqSubmitSection',
    deleteInvitation: '/forms/saqInvitation',
    generateReport: '/forms/generateReport',
    saqReminderEmailUpdate: 'forms/saqEmailType',
    cocReminderEmailUpdate: '/coc-invitations/emailType',
  },
  resources: {
    lookup: '/resources',
    update: '/resources',
    create: '/resources',
    delete: '/resources',
    download: '/resources/download',
  },
  currencies: {
    lookup: '/currencies',
  },
  states: {
    lookup: '/states',
  },
  transactions: {
    lookup: '/transactions',
  },
  data: {
    blacklistFileUpload: '/black-list',
    exportData: '/report-export/export',
  },
  actions: {
    lookup: '/actions',
    update: '/actions',
    create: '/actions',
    delete: '/actions',
    download: '/actions/download',
  },
  sections: {
    lookup: '/sections',
  },
  fileUpload: '/file-upload',
  seeds: '/seeds',
  import: '/imports',
  importStudent: '/imports/student',
  storage: {
    file: '/storage/file',
    convertDoc: '/storage/convertDoc',
  },
  exports: {
    student: '/exports/student',
    person: '/exports/person',
    vehicle: '/exports/vehicle',
    location: '/exports/location',
    sensor: '/exports/sensor',
    enterprise: '/exports/enterprise',
    topic: '/exports/topic',
    nsearchResult: '/exports/nsearch/result',
    nsearch: '/exports/nsearch',
    relation: '/exports/relation',
    saq: '/exports/saq',
  },
};

export default urlsConfig;
