import { urlHelper } from 'src/app/helpers';
import { getFormData } from 'src/app/helpers/form.helper';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  LocationIdentifiedCreateDataType,
  LocationIdentifiedDataType,
  LocationIdentifiedLookupDataType,
  LocationIdentifiedResultType,
  LocationIdentifiedUpdateDataType,
} from 'src/app/services/interest/Location/location-service.type';
import { urlsConfig } from 'src/configs';
import { MutationType } from 'src/enums/operation.enum';

export const locationIdentifiedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLocationsIdentified: builder.query<LocationIdentifiedResultType, LocationIdentifiedLookupDataType | void>({
      query: (params?: LocationIdentifiedLookupDataType) => ({
        url: urlsConfig.interest.identified.location.lookup,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'LocationIdentified' as const,
                id,
              })),
              { type: 'LocationIdentified', id: 'LIST' },
            ]
          : [{ type: 'LocationIdentified', id: 'LIST' }],
    }),

    // downloadLocationImages: builder.query<
    //   { filename: string; data: ArrayBuffer }[],
    //   { id: string }
    // >({
    //   query: (param: { id: string }) => ({
    //     url: `${urlsConfig.interest.identified.location.images}/${param.id}`,

    //     cache: "no-cache",

    //     responseHandler: async (response) =>
    //       URL.createObjectURL(await response.blob()),
    //   }),
    // }),

    createLocation: builder.mutation<LocationIdentifiedDataType, LocationIdentifiedCreateDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.location.create,
        body: getFormData(data, ['pictures'], MutationType.CREATE),
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'LocationIdentified', id: 'LIST' }],
    }),
    updateLocation: builder.mutation<LocationIdentifiedDataType, LocationIdentifiedUpdateDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.location.update,
        body: getFormData(data, ['pictures']),
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, data) => [{ type: 'LocationIdentified', id: data.id }],
    }),
    deleteLocation: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `${urlsConfig.interest.identified.location.delete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'LocationIdentified', id }],
    }),
    exportLocationIdentifiedExcell: builder.mutation<ArrayBuffer, { ids?: string[] }>({
      query: (data) => ({
        url: urlsConfig.exports.location,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetLocationsIdentifiedQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
  useExportLocationIdentifiedExcellMutation,
  useLazyGetLocationsIdentifiedQuery,
} = locationIdentifiedSlice;
