import { StudentDataType } from 'src/app/services/student/student.service.type';

export const convertStudentRecordToFormData = (std: StudentDataType) => {
  return {
    id: std.id,
    studentName: std.studentName,
    qId: std.qId,
    rank: std.rank,
    branch: std.branch,
    militaryId: std.militaryId,
    universityName: std.universityName,
    universityCountry: std.universityCountry,
    universityCity: std.universityCity,
    degreeType: std.degreeType,
    program: std.program,
    yearOfProgram: std.yearOfProgram,
    startYear: std.startYear,
    expectedGraduationYear: std.expectedGraduationYear,
    graduationYear: std.graduationYear,
  };
};
