import { ColumnConfig, LineConfig, PieConfig, ScatterConfig } from '@ant-design/charts';
import { isDefined } from 'class-validator';
import { groupBy } from 'lodash';
import ReactGridLayout from 'react-grid-layout';
import { getEnumKeyByValue } from 'src/app/helpers/enum.helper';
import {
  getUniqueCountriesFromSuppliers,
  getUniqueIndustriesFromSuppliers,
} from 'src/app/pages/HomePage/home-page.config';
import { IndustryRecognizedDataType } from 'src/app/services/interest/Industry/industry-service.type';
import { InterestsWithNamesLookup } from 'src/app/services/interest/interest.service.type';
import { LocationRecognizedDataType, TipTierEnum } from 'src/app/services/interest/Location/location-service.type';
import { StudentLookupResultType } from 'src/app/services/student/student.service.type';
import { DataCountResultType } from 'src/app/services/surfer-service/surfer.service.type';
import { InterestsTypes } from 'src/enums/interests.enum';
import { BlackListStatusEnum } from 'src/enums/sc3-type.enum';
import { LayoutViewType } from 'src/enums/user.enum';
import { routesTypes } from 'src/types';
import { EnterpriseRelationsResultType } from 'src/types/enterprise.type';

export type CardType = IStatisticCard | IChartCard;
export enum CardDependency {
  Suppliers,
  Students,
  Enterprises,
  Topics,
  Vehicles,
  Persons,
  Surfer,
}

interface ICard<T> {
  id: string;
  title: string;
  type: HomeCardType;
  filterType: T;
  navigate?: CardNavigateType;
  layout?: ReactGridLayout.Layout;
  isDefault?: boolean;
  layoutModes: LayoutViewType[];
  dependency: CardDependency;
  tooltip?: string;
}
export interface IStatisticCard extends ICard<StatisticFilters> {
  icon: CardIcons;
  value: string | number;
  tiers?: ExtraValuesType;
}

export interface IChartCard extends ICard<ChartFilters> {
  chartTypes: ChartTypes[];
  data: ChartTypeConfig;
}

export enum CardIcons {
  SUPPLIERS,
  WORLD,
  NEWSPAPER,
  BLACKLISTED,
  VEHICLE,
  PERSON,
  ENTERPRISE,
  STUDENT,
  TOPIC,
}

export enum HomeCardType {
  Statistic = 0,
  Chart = 1,
}

export type CardNavigateType = {
  root: routesTypes.RoutesType;
  state: Record<string, any>;
};

export type ExtraValuesType = {
  tier1: { value: number; navigate?: CardNavigateType };
  tier2: { value: number; navigate?: CardNavigateType };
};

export enum StatisticFilters {
  NUMBER_OF_SUPPLIERS = 0,
  NUMBER_OF_SOURCING_COUNTRIES = 1,
  MY_MEDIA = 2,
  MY_SUPPLIERS_MEDIA = 3,
  BLACKLISTED_STATUS = 4,
  NUMBER_OF_ENTERPRISES = 5,
  // NUMBER_OF_COUNTRIES = 6,
  NUMBER_OF_PERSONS = 7,
  NUMBER_OF_VEHICLES = 8,
  NUMBER_OF_STUDENTS = 9,
  ARCHIVE_OF_STUDENTS = 10,
  NUMBER_OF_TOPICS = 11,
}

export enum ChartFilters {
  COUNTRY_RISK,
  INDUSTRY_RISK,
  VULNERABILITRY_TO_MODERN_SLAVERY,
  PREVALENCE_INDEX_RANK,
  GOVERNMENT_RESPONSE_RATING,
  TIP_TIER,
  STUDENT_GRADUATION_TIME,
  STUDENT_EXPECTED_GRADUATION_TIME,
  STUDENT_BRANCHES,
  STUDENT_DEGREES,
  ENTERPRISE_INDUSTRIES,
  ENTERPRISE_COUNTRIES,
  PERSON_AGES,
  PERSON_GENDERS,
  VEHICLE_CATEGORIES,
  VEHICLE_BRANDS,
}

export enum ChartTypes {
  LINE = 0,
  // HISTOGRAM = 1,
  HISTOGRAM = 1,
  SCATTER = 2,
  PIE = 3,
}

export type ChartTypeConfig = {
  [ChartTypes.LINE]: LineConfig['data'];
  // [ChartTypes.HISTOGRAM]: HistogramConfig["data"];
  [ChartTypes.HISTOGRAM]: ColumnConfig['data'];
  [ChartTypes.PIE]: PieConfig['data'];
  [ChartTypes.SCATTER]: ScatterConfig['data'];
};

export const getStatisticCardsData = (
  cards: IStatisticCard[],
  dashboardData: DataCountResultType | undefined,
  suppliers: EnterpriseRelationsResultType | undefined,
  interestsList: InterestsWithNamesLookup | undefined,
  studentList: StudentLookupResultType | undefined,
) => {
  const result: IStatisticCard[] = [];

  cards.forEach((card) => {
    let { value } = card;
    let tiers: ExtraValuesType | undefined = card.tiers ? { ...card.tiers } : undefined;

    switch (card.filterType) {
      case StatisticFilters.MY_MEDIA:
        value = dashboardData?.newsCount?.companyItself || 0;
        break;
      case StatisticFilters.MY_SUPPLIERS_MEDIA:
        value = (dashboardData?.newsCount?.tier1 || 0) + (dashboardData?.newsCount?.tier2andBeyond || 0);
        if (tiers) {
          tiers = {
            tier1: {
              ...card.tiers?.tier1,
              value: dashboardData?.newsCount?.tier1 || 0,
            },
            tier2: {
              ...card.tiers?.tier2,

              value: dashboardData?.newsCount?.tier2andBeyond || 0,
            },
          };
        }
        break;
      case StatisticFilters.NUMBER_OF_SUPPLIERS:
        value = (dashboardData?.supplierCount?.tier1 || 0) + (dashboardData?.supplierCount?.tier2andBeyond || 0);
        tiers = {
          tier1: {
            ...card.tiers?.tier1,

            value: dashboardData?.supplierCount?.tier1 || 0,
          },
          tier2: {
            ...card.tiers?.tier2,
            value: dashboardData?.supplierCount?.tier2andBeyond || 0,
          },
        };
        break;
      case StatisticFilters.NUMBER_OF_SOURCING_COUNTRIES:
        value = getUniqueCountriesFromSuppliers(suppliers?.from?.rows || []).length;
        break;
      case StatisticFilters.NUMBER_OF_ENTERPRISES:
        const enterpriseList = interestsList?.rows?.find((row) => row.interestType === InterestsTypes.Enterprise);
        value = enterpriseList?.interests?.length || 0;
        break;
      case StatisticFilters.NUMBER_OF_PERSONS:
        const personList = interestsList?.rows?.find((row) => row.interestType === InterestsTypes.Person);
        value = personList?.interests?.length || 0;
        break;
      case StatisticFilters.NUMBER_OF_VEHICLES:
        const vehicleList = interestsList?.rows?.find((row) => row.interestType === InterestsTypes.Vehicle);
        value = vehicleList?.interests?.length || 0;
        break;
      case StatisticFilters.NUMBER_OF_TOPICS:
        const topicList = interestsList?.rows?.find((row) => row.interestType === InterestsTypes.Topic);
        value = topicList?.interests?.length || 0;
        break;
      case StatisticFilters.BLACKLISTED_STATUS:
        const enterprise = suppliers?.to;
        value =
          enterprise && isDefined(enterprise.blackListStatus)
            ? getEnumKeyByValue(BlackListStatusEnum, enterprise.blackListStatus!)
            : 'Not Blacklisted';
        break;
      case StatisticFilters.NUMBER_OF_STUDENTS:
        value = studentList?.count || 0;
        break;
      case StatisticFilters.ARCHIVE_OF_STUDENTS:
        const archivedStudents = studentList?.rows?.filter((stu) => stu.graduationApproved);
        value = archivedStudents?.length || 0;
        break;
      default:
        break;
    }
    result.push({
      ...card,
      tiers,
      value,
    });
  });
  return result;
};

export const getChartCards = (
  suppliers: EnterpriseRelationsResultType | undefined,
  studentList: StudentLookupResultType | undefined,
  interestList: InterestsWithNamesLookup | undefined,
  dashboardData: DataCountResultType | undefined,
  cards: CardType[],
) => {
  const newCards = getChartCardsData(
    cards.filter((card) => card.type === HomeCardType.Chart) as IChartCard[],
    suppliers?.from?.rows,
    studentList,
    interestList,
    dashboardData,
  );

  return newCards;
};

export const getStatisticCards = (
  dashboardData: DataCountResultType | undefined,
  supplierList: EnterpriseRelationsResultType | undefined,
  interestsList: InterestsWithNamesLookup | undefined,
  studentList: StudentLookupResultType | undefined,
  cards: CardType[],
) => {
  const defaultStatisticCards = cards.filter((card) => card.type === HomeCardType.Statistic) as IStatisticCard[];

  // if (!dashboardData) return defaultStatisticCards;
  const statisticCards = getStatisticCardsData(
    defaultStatisticCards,
    dashboardData,
    supplierList,
    interestsList,
    studentList,
  );
  return statisticCards;
};

export const getChartCardsData = (
  cards: IChartCard[],
  suppliers: EnterpriseRelationsResultType['from']['rows'] | undefined,
  studentList: StudentLookupResultType | undefined,
  interestList: InterestsWithNamesLookup | undefined,
  dashboardData: DataCountResultType | undefined,
): IChartCard[] => {
  const countries = getUniqueCountriesFromSuppliers(suppliers || []);
  const industries = getUniqueIndustriesFromSuppliers(suppliers || []);

  const result: IChartCard[] = [];
  cards.forEach((card) => {
    const chartCardDataObj = { ...card.data };

    card.chartTypes.forEach((chartType) => {
      switch (card.filterType) {
        case ChartFilters.VULNERABILITRY_TO_MODERN_SLAVERY:
          getModernSlaveryChartData(countries, chartType, chartCardDataObj);
          break;

        case ChartFilters.PREVALENCE_INDEX_RANK:
          getPrevalenceIndexChartData(countries, chartType, chartCardDataObj);
          break;

        case ChartFilters.GOVERNMENT_RESPONSE_RATING:
          getGovernmentResponseChartData(countries, chartType, chartCardDataObj);
          break;

        case ChartFilters.COUNTRY_RISK:
          getCountryRiskChartData(countries, chartType, chartCardDataObj);
          break;
        case ChartFilters.INDUSTRY_RISK:
          getIndustryRiskChartData(industries, chartType, chartCardDataObj);
          break;

        case ChartFilters.TIP_TIER:
          getTipTierChartData(countries, chartType, chartCardDataObj);
          break;

        case ChartFilters.STUDENT_GRADUATION_TIME:
          getStudentGraduationTimes(studentList, chartType, chartCardDataObj);
          break;

        case ChartFilters.STUDENT_EXPECTED_GRADUATION_TIME:
          getStudentExpectedGraduationTimes(studentList, chartType, chartCardDataObj);
          break;

        // case ChartFilters.STUDENT_BRANCHES:
        //   return getStudentBranchesData(
        //     studentList,
        //     chartType,
        //     chartCardDataObj
        //   );

        // case ChartFilters.STUDENT_DEGREES:
        //   return getStudentDegreesData(
        //     studentList,
        //     chartType,
        //     chartCardDataObj
        //   );

        case ChartFilters.ENTERPRISE_INDUSTRIES:
          getEnterpriseIndustries(interestList, chartType, chartCardDataObj);
          break;

        case ChartFilters.ENTERPRISE_COUNTRIES:
          getEnterpriseCountries(interestList, chartType, chartCardDataObj);
          break;

        case ChartFilters.PERSON_AGES:
          getPersonAges(dashboardData, chartType, chartCardDataObj);
          break;

        case ChartFilters.PERSON_GENDERS:
          getPersonGenders(dashboardData, chartType, chartCardDataObj);
          break;

        case ChartFilters.VEHICLE_CATEGORIES:
          getVehicleCategories(dashboardData, chartType, chartCardDataObj);
          break;

        case ChartFilters.VEHICLE_BRANDS:
          getVehicleBrands(dashboardData, chartType, chartCardDataObj);
          break;
      }
    });
    result.push({
      ...card,
      data: chartCardDataObj,
    });
  });

  return result;
};

const getVehicleBrands = (
  dashboardData: DataCountResultType | undefined,
  chartType: ChartTypes,
  chartCardDataObj: ChartTypeConfig,
) => {
  const chartData = [...(dashboardData?.vehicleStatistic?.brands || [])];

  switch (chartType) {
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = chartData;
      break;

    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = chartData;
      break;

    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = chartData;
      break;

    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = chartData;
      break;
  }
};

const getVehicleCategories = (
  dashboardData: DataCountResultType | undefined,
  chartType: ChartTypes,
  chartCardDataObj: ChartTypeConfig,
) => {
  const chartData = [...(dashboardData?.vehicleStatistic?.categories || [])];
  switch (chartType) {
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = chartData;
      break;

    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = chartData;
      break;

    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = chartData;
      break;

    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = chartData;
      break;
  }
};

const getPersonAges = (
  dashboardData: DataCountResultType | undefined,
  chartType: ChartTypes,
  chartCardDataObj: ChartTypeConfig,
) => {
  const chartData = [...(dashboardData?.personStatistic?.ages || [])];

  switch (chartType) {
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = chartData;
      break;

    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = chartData;
      break;

    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = chartData;
      break;

    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = chartData;
      break;
  }
};

const getPersonGenders = (
  dashboardData: DataCountResultType | undefined,
  chartType: ChartTypes,
  chartCardDataObj: ChartTypeConfig,
) => {
  const chartData = [...(dashboardData?.personStatistic?.genders || [])];
  switch (chartType) {
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = chartData;
      break;

    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = chartData;
      break;

    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = chartData;
      break;

    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = chartData;
      break;
  }
};

const getEnterpriseCountries = (
  interestList: InterestsWithNamesLookup | undefined,
  chartType: ChartTypes,
  chartCardDataObj: ChartTypeConfig,
) => {
  const enterpriseList = interestList?.rows?.find((row) => row.interestType === InterestsTypes.Enterprise);
  const enterprisesGrouped = groupBy(enterpriseList?.interests, (ent) => ent.interest?.country?.name);

  const columnArr: { value: string; count: number }[] = [];
  Object.entries(enterprisesGrouped).forEach(([key, value]) => {
    columnArr.push({
      value: key,
      count: value.length,
    });
  });

  switch (chartType) {
    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = [...columnArr];
      break;

    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = [...columnArr];
      break;

    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = [...columnArr];
      break;

    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = [...columnArr];
      break;
  }
};

const getEnterpriseIndustries = (
  interestList: InterestsWithNamesLookup | undefined,
  chartType: ChartTypes,
  chartCardDataObj: ChartTypeConfig,
) => {
  const enterpriseList = interestList?.rows?.find((row) => row.interestType === InterestsTypes.Enterprise);
  const enterprisesGrouped = groupBy(enterpriseList?.interests, (ent) => ent.interest?.industry?.name);

  const columnArr: { value: string; count: number }[] = [];

  Object.entries(enterprisesGrouped).forEach(([key, value]) => {
    columnArr.push({
      value: key,
      count: value.length,
    });
  });

  switch (chartType) {
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = [...columnArr];
      break;

    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = [...columnArr];
      break;

    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = [...columnArr];
      break;

    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = [...columnArr];
      break;
  }
};

const getStudentExpectedGraduationTimes = (
  studentList: StudentLookupResultType | undefined,
  chartType: ChartTypes,
  chartCardDataObj: ChartTypeConfig,
) => {
  const studentsGrouped = groupBy(studentList?.rows, (stu) => stu.expectedGraduationYear);

  const columnArr: { value: number; count: number }[] = [];

  Object.entries(studentsGrouped).forEach(([key, value]) => {
    if (key !== 'null') {
      columnArr.push({
        value: Number(key),
        count: value.length,
      });
    }
  });

  switch (chartType) {
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = [...columnArr];
      break;

    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = [...columnArr];
      break;

    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = [...columnArr];
      break;

    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = [...columnArr.map((obj) => ({ ...obj, value: obj.value.toString() }))];
      break;
  }
};

const getStudentGraduationTimes = (
  studentList: StudentLookupResultType | undefined,
  chartType: ChartTypes,
  chartCardDataObj: ChartTypeConfig,
) => {
  const studentsGrouped = groupBy(studentList?.rows, (stu) => stu.graduationYear);

  const columnArr: { value: number; count: number }[] = [];

  Object.entries(studentsGrouped).forEach(([key, value]) => {
    if (key !== 'null') {
      columnArr.push({
        value: Number(key),
        count: value.length,
      });
    }
  });

  switch (chartType) {
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = [...columnArr];
      break;

    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = [...columnArr];
      break;

    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = [...columnArr];
      break;

    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = [...columnArr.map((obj) => ({ ...obj, value: obj.value.toString() }))];
      break;
  }
};

// const getStudentDegreesData = (
//   studentList: StudentLookupResultType | undefined,
//   chartType: ChartTypes,
//   chartCardDataObj: ChartTypeConfig
// ) => {
//   switch (chartType) {
//     case ChartTypes.PIE:
//   }
// };

// const getStudentBranchesData = (
//   studentList: StudentLookupResultType | undefined,
//   chartType: ChartTypes,
//   chartCardDataObj: ChartTypeConfig
// ) => {
//   switch (chartType) {
//     case ChartTypes.PIE:
//   }
// };

const getPrevalenceIndexChartData = (
  countries: LocationRecognizedDataType[],
  chartType: ChartTypes,
  chartCardDataObj: ChartTypeConfig,
) => {
  const prevlanceIndexPieChart = {
    'No Data': 0,
    '0-40': 0,
    '41-80': 0,
    '81-120': 0,
    '121-167': 0,
  };
  countries.forEach((country) => {
    const pir = country.prevalenceIndexRank;
    if (!pir) {
      prevlanceIndexPieChart['No Data'] += 1;
    } else if (pir <= 40) {
      prevlanceIndexPieChart['0-40'] += 1;
    } else if (pir <= 80) {
      prevlanceIndexPieChart['41-80'] += 1;
    } else if (pir <= 120) {
      prevlanceIndexPieChart['81-120'] += 1;
    } else if (pir <= 167) {
      prevlanceIndexPieChart['121-167'] += 1;
    }
  });
  const columnsArr = Object.entries(prevlanceIndexPieChart).map(([key, value]) => {
    return { value: key, count: value };
  });
  switch (chartType) {
    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = [...columnsArr];
      break;
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = [...columnsArr];
      break;
    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = [...columnsArr];
      break;
    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = [...columnsArr];
      break;
  }

  console.log('chartCardDataObj', chartCardDataObj);
};

const getModernSlaveryChartData = (
  countries: LocationRecognizedDataType[],
  chartType: ChartTypes,
  chartCardDataObj: any,
) => {
  const modernSlaveryPieChart = {
    'No Data': 0,
    '0-24': 0,
    '25-49': 0,
    '50-74': 0,
    '75-100': 0,
  };
  countries.forEach((country) => {
    const msc = country.modernSlaveryScore;
    if (!msc) {
      modernSlaveryPieChart['No Data'] += 1;
    } else if (msc < 25) {
      modernSlaveryPieChart['0-24'] += 1;
    } else if (msc < 50) {
      modernSlaveryPieChart['25-49'] += 1;
    } else if (msc < 75) {
      modernSlaveryPieChart['50-74'] += 1;
    } else if (msc <= 100) {
      modernSlaveryPieChart['75-100'] += 1;
    }
  });

  const columnsArr = Object.entries(modernSlaveryPieChart).map(([key, value]) => {
    return { value: key, count: value };
  });

  switch (chartType) {
    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = [...columnsArr];
      break;
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = [...columnsArr];
      break;
    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = [...columnsArr];
      break;
    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = [...columnsArr];
      break;
  }
};

const getGovernmentResponseChartData = (
  countries: LocationRecognizedDataType[],
  chartType: ChartTypes,
  chartCardDataObj: any,
) => {
  const governmentResponsePieChart = {
    'No Data': 0,
    '-3-15': 0,
    '16-30': 0,
    '31-50': 0,
    '51-68': 0,
  };
  countries.forEach((country) => {
    const grt = country.governmentResponse;
    if (!grt) {
      governmentResponsePieChart['No Data'] += 1;
    } else if (grt <= 15) {
      governmentResponsePieChart['-3-15'] += 1;
    } else if (grt <= 30) {
      governmentResponsePieChart['16-30'] += 1;
    } else if (grt <= 50) {
      governmentResponsePieChart['31-50'] += 1;
    } else if (grt <= 68) {
      governmentResponsePieChart['51-68'] += 1;
    }
  });
  const columnsArr = Object.entries(governmentResponsePieChart).map(([key, value]) => {
    return { value: key, count: value };
  });

  switch (chartType) {
    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = [...columnsArr];
      break;
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = [...columnsArr];
      break;
    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = [...columnsArr];
      break;
    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = [...columnsArr];
      break;
  }
};

const getCountryRiskChartData = (
  countries: LocationRecognizedDataType[],
  chartType: ChartTypes,
  chartCardDataObj: any,
) => {
  const countryRiskPieChart = {
    'No Data': 0,
    Low: 0,
    Moderate: 0,
    High: 0,
    'Very High': 0,
  };

  countries.forEach((country) => {
    const risk = country.overallRiskLevel;
    if (risk === null) {
      countryRiskPieChart['No Data'] += 1;
    } else if (risk === 3) {
      countryRiskPieChart.Low += 1;
    } else if (risk === 2) {
      countryRiskPieChart.Moderate += 1;
    } else if (risk === 1) {
      countryRiskPieChart.High += 1;
    } else if (risk === 0) {
      countryRiskPieChart['Very High'] += 1;
    }
  });
  const columnsArr = Object.entries(countryRiskPieChart).map(([key, value]) => {
    return { value: key, count: value };
  });

  switch (chartType) {
    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = [...columnsArr];
      break;
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = [...columnsArr];
      break;
    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = [...columnsArr];
      break;
    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = [...columnsArr];
      break;
  }
};

const getIndustryRiskChartData = (
  industries: IndustryRecognizedDataType[],
  chartType: ChartTypes,
  chartCardDataObj: any,
) => {
  const industryRiskPieChart = {
    'No Data': 0,
    Low: 0,
    Moderate: 0,
    High: 0,
  };

  industries.forEach((industry) => {
    const risk = industry.riskLevel;

    if (risk === 0) {
      industryRiskPieChart['No Data'] += 1;
    } else if (risk === 1) {
      industryRiskPieChart.Low += 1;
    } else if (risk === 2) {
      industryRiskPieChart.Moderate += 1;
    } else if (risk === 3) {
      industryRiskPieChart.High += 1;
    }
  });
  const columnsArr = Object.entries(industryRiskPieChart).map(([key, value]) => {
    return { value: key, count: value };
  });
  switch (chartType) {
    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = [...columnsArr];
      break;
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = [...columnsArr];
      break;
    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = [...columnsArr];
      break;
    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = [...columnsArr];
      break;
  }
};

const getTipTierChartData = (countries: LocationRecognizedDataType[], chartType: ChartTypes, chartCardDataObj: any) => {
  const tipTierPieChart = {
    'No Data': 0,
    'Special Case': 0,
    'Tier 1': 0,
    'Tier 2': 0,
    'Tier 2 WL': 0,
    'Tier 3': 0,
  };

  countries.forEach((country) => {
    const { tier } = country;
    if (tier === null) {
      tipTierPieChart['No Data'] += 1;
    } else if (tier === TipTierEnum.Special_Case) {
      tipTierPieChart['Special Case'] += 1;
    } else if (tier === TipTierEnum.Tier_1) {
      tipTierPieChart['Tier 1'] += 1;
    } else if (tier === TipTierEnum.Tier_2) {
      tipTierPieChart['Tier 2'] += 1;
    } else if (tier === TipTierEnum.Tier_2_Watch_List) {
      tipTierPieChart['Tier 2 WL'] += 1;
    } else if (tier === TipTierEnum.Tier_3) {
      tipTierPieChart['Tier 3'] += 1;
    }
  });
  const columnsArr = Object.entries(tipTierPieChart).map(([key, value]) => {
    return { value: key, count: value };
  });
  switch (chartType) {
    case ChartTypes.PIE:
      chartCardDataObj[ChartTypes.PIE] = [...columnsArr];
      break;
    case ChartTypes.HISTOGRAM:
      chartCardDataObj[ChartTypes.HISTOGRAM] = [...columnsArr];
      break;
    case ChartTypes.LINE:
      chartCardDataObj[ChartTypes.LINE] = [...columnsArr];
      break;
    case ChartTypes.SCATTER:
      chartCardDataObj[ChartTypes.SCATTER] = [...columnsArr];
      break;
  }
};
