import { urlHelper } from 'src/app/helpers';
import {
  BulkInterestLookupDataType,
  BulkInterestLookupResultType,
  InterestLookupDataType,
  InterestsWithNamesLookup,
} from 'src/app/services/interest/interest.service.type';
import { urlsConfig } from 'src/configs';
import { InterestsTypes } from 'src/enums/interests.enum';
import { chain } from 'lodash';
import { formatInterestDataToIdValuePair } from 'src/app/helpers/service.helper';
import { INTERESTS_WHITELIST } from 'src/configs/system.config';
import { apiSlice } from 'src/app/redux/api/apiSlice';

export const interestsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllInterests: builder.query<InterestsWithNamesLookup, BulkInterestLookupDataType | void>({
      query: (params?: BulkInterestLookupDataType) => ({
        url: urlsConfig.interest.base,
        params: urlHelper.getLookupQuery(params),
      }),

      transformResponse: (data: BulkInterestLookupResultType): InterestsWithNamesLookup => {
        const filteredData = data.rows.filter((row) => INTERESTS_WHITELIST.includes(row.type));
        const groupedInterests = chain(filteredData)
          .groupBy('type')
          .map((value: InterestLookupDataType[], key) => {
            const type = Number(key) as InterestsTypes;
            return {
              interestType: type,
              interests: formatInterestDataToIdValuePair(value, type),
            };
          })
          .value();
        return {
          rows: groupedInterests,
          count: data.rows.length,
        };
      },
      providesTags: [{ type: 'Interests', id: 'LIST' }],
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.rows.map(({ interests }) => {
      //           return interests.map(interest => ({
      //             type: "Interests" as const,
      //             id: interest.id,
      //           }))
      //         } ),
      //         { type: "Interests", id: "LIST" },
      //       ]
      //     : [{ type: "Interests", id: "LIST" }],
    }),
  }),
});

export const { useLazyGetAllInterestsQuery, useGetAllInterestsQuery } = interestsSlice;
