import { Button, ConfigProvider, Modal } from 'antd';
import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'src/app/redux/store';
import { FONT_FAMILY } from 'src/configs/system.config';
import { CSSProperties } from 'styled-components';
import tinycolor from 'tinycolor2';

export interface IModalProps {
  title: string;
  content: string | JSX.Element;
  okText?: string;
  size?: 'medium' | 'medium-2' | 'large' | 'small' | 'xlarge' | 'xsmall' | 'xlarge-2';
  disableButtons?: boolean;
  disableCancelButton?: boolean;
  event?: React.MouseEvent<HTMLElement>;
  onSave?: (modal: { destroy: () => void; update: (configUpdate: IModalProps) => void }) => void;
  hasFooter?: boolean;
  backgroundColor?: CSSProperties['color'];
  onCancel?: () => void;
  saveButtonDisabled?: boolean;
  footer?: ReactNode;
  isClosable?: boolean;
}

const showModal = ({
  content,
  title,
  backgroundColor,
  disableCancelButton,
  event,
  footer,
  hasFooter = true,
  okText,
  onCancel,
  saveButtonDisabled,
  size,
  onSave,
  isClosable = true,
}: IModalProps) => {
  const observer = new MutationObserver((mutationsList) => {
    setTimeout(() => {
      const { body } = document;
      const direction = body.dir;

      const modalElement = document.querySelectorAll('.ant-modal-content');
      const pointer = document.createElement('div');
      pointer.className = 'ant-modal-not-centered';
      pointer.classList.add(direction === 'rtl' ? 'left-arrow' : 'right-arrow');

      for (const mutation of mutationsList) {
        if (mutation.addedNodes.length) {
          const lastModal = modalElement[modalElement.length - 1];

          if (lastModal) {
            if (event) {
              const rect = (event.target as HTMLElement).getBoundingClientRect(); // get some poition, scale,... properties of the item
              const height = lastModal.clientHeight;

              const width = calculateSize(size);

              if (height + rect.y > window.innerHeight) {
                // @ts-ignore

                lastModal.style.top = `${window.innerHeight - height - 20}px`;
                pointer.style.top = `${height - window.innerHeight + rect.y + 10}px`;
              } else {
                // @ts-ignore
                lastModal.style.top = `${rect.y - 20}px`;
                // lastModal.classList.add("ant-modal-not-centered");
                pointer.style.top = '4%';
              }
              if (direction === 'rtl') {
                // @ts-ignore
                lastModal.style.left = `${-1 * (window.innerWidth - width - rect.left - 20)}px`;
              } else {
                // @ts-ignore
                lastModal.style.left = `${rect.left - width - 10}px`;
              }
              // @ts-ignore

              lastModal.parentElement.style.visibility = 'visible';
              lastModal.appendChild(pointer);
              observer.disconnect(); // Stop observing after confirming the height
            }
          }
        }
      }
    }, 100);
  });

  observer.observe(document.body, { childList: true, subtree: true });
  // const modalPosition = () => {
  //   if (event) {
  //     var rect = (event.target as HTMLElement).getBoundingClientRect(); // get some poition, scale,... properties of the item

  //     const width = calculateSize(size);

  //     return {
  //       left: `${rect.left - width - 10}px`,
  //       // top:
  //       //   rect.y + height > window.innerHeight
  //       //     ? `${window.innerHeight}px`
  //       //     : `${rect.y - 20}px`,
  //     };
  //   }
  // };

  const onModalSave = (modal: { destroy: () => void; update: (configUpdate: IModalProps) => void }) => {
    if (onSave) {
      onSave(modal);
    }
  };

  const getFooter = () => {
    let result: ReactNode;
    const defaultFooter = [
      <div className="ant-modal-confirm-btns" key="modal-footer">
        {disableCancelButton ? null : (
          <Button
            onClick={() => {
              if (onCancel) onCancel();
              modal.destroy();
            }}
          >
            Cancel
          </Button>
        )}
        <Button
          key="submit"
          htmlType="submit"
          onClick={() => onModalSave(modal)}
          disabled={!!saveButtonDisabled}
          style={saveButtonDisabled ? { cursor: 'not-allowed' } : undefined}
        >
          {okText || 'Save'}
        </Button>
      </div>,
    ];

    if (!hasFooter) {
      result = null;
    } else if (footer) {
      result = <div className="ant-modal-footer">{footer}</div>;
    } else {
      result = defaultFooter;
    }
    return result;
  };

  const calculateSize = (modalSize: string | undefined) => {
    switch (modalSize) {
      case 'xlarge-2':
        return 1200;
      case 'xlarge':
        return 900;
      case 'large':
        return 800;
      case 'medium-2':
        return 700;
      case 'medium':
        return 600;
      case 'small':
        return 500;
      case 'xsmall':
        return 350;
      default:
        return 500;
    }
  };

  const modal = Modal.confirm({
    title,
    content,
    icon: null,
    closable: isClosable,
    width: calculateSize(size),
    centered: !event,
    style: {
      visibility: event ? 'hidden' : 'visible',
      fontFamily: FONT_FAMILY,
    },
    // style: {
    //   ...modalPosition(),
    // },
    // className: event ? "ant-modal-not-centered" : undefined,

    maskStyle: { position: 'relative' },
    wrapClassName: 'showModalComponent',
    footer: getFooter(),
    modalRender(node) {
      return (
        <Provider store={store}>
          <BrowserRouter>
            <ConfigProvider
              theme={{
                token: {
                  fontFamily: FONT_FAMILY,
                },
              }}
            >
              {node}
            </ConfigProvider>
          </BrowserRouter>
        </Provider>
      );
    },
    bodyStyle: {
      backgroundColor: backgroundColor || 'rgb(26, 52, 65)',
    },
    styles: {
      content: {
        color: backgroundColor ? (tinycolor(backgroundColor).isDark() ? '#fff' : '#000') : 'whitesmoke',
      },
      mask: {
        backdropFilter: 'brightness(60%)',
      },
    },
    onCancel,
    getContainer: () => document.querySelector('.fullscreen-enabled') || document.body,
  });

  return modal;
};

export default showModal;
