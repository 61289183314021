import { CSSProperties } from 'react';
import { UpdateLabelType } from 'src/app/services/labels/labels-service.type';
import { LabelCreatorEnum, NotificationLabelPriorityStatus } from 'src/enums/labels.enum';
import { LabelType } from 'src/types/labels.type';

export const disabledIconStyle: CSSProperties = {
  opacity: '0.4',
  cursor: 'not-allowed',
};

export type CategoryType = {
  id: NotificationLabelPriorityStatus;
  title: string;
  color: string;
  labelIds: string[];
};
export const prioritizeCategories: CategoryType[] = [
  {
    id: NotificationLabelPriorityStatus.Critical,
    title: 'High',
    labelIds: [],
    color: 'red',
  },
  {
    id: NotificationLabelPriorityStatus.Medium,
    title: 'Medium',
    color: 'yellow',
    labelIds: [],
  },
  {
    id: NotificationLabelPriorityStatus.Low,
    title: 'Low',
    labelIds: [],
    color: '#92aabb',
  },
  {
    id: NotificationLabelPriorityStatus.Uncategorized,
    title: 'Uncategorized',
    color: '#92aabb',
    labelIds: [],
  },
];
export interface ILabelColumns {
  // title: string;
  priority: NotificationLabelPriorityStatus;
  items: UpdateLabelType[];
}

type ConvertSettingsLabelType = {
  list: LabelType[];
  systemLabelShow: boolean;
  userLabelShow: boolean;
};
export const convertShowSettingsLabel = ({ list, systemLabelShow, userLabelShow }: ConvertSettingsLabelType) => {
  const result: LabelType[] = [];

  list.forEach((item) => {
    if (item.creator === LabelCreatorEnum.System) {
      if (systemLabelShow) result.push(item);
    } else if (item.creator === LabelCreatorEnum.User) {
      if (userLabelShow) result.push(item);
    }
  });

  return result;
};
