import { message } from 'antd';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import usePermissions from 'src/app/hooks/usePermissions';

interface IAuthorizedRouteProps {
  requiredPermissions: string[];
}

const AuthorizedRoute: React.FC<IAuthorizedRouteProps> = ({ requiredPermissions }) => {
  const { permissions } = usePermissions();

  const isAuthorized = permissions.some((per) => requiredPermissions.includes(per));

  const navigate = useNavigate();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;
    if (!isAuthorized) {
      message.error('You are not authorized to access this page', 1);
      timer = setTimeout(() => {
        navigate(-1);
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isAuthorized]);

  return isAuthorized ? <Outlet /> : null;
};

export default AuthorizedRoute;
