import { useEffect, useState } from 'react';

interface UseGetTableDataProps<Table, Rows> {
  data: Rows | undefined;
  convertFn: (lookupResult: Rows, ...args: any) => Table[];
  extraArgs?: any;
}
const useGetTableData = <T, Y>({ data, convertFn, extraArgs }: UseGetTableDataProps<T, Y>) => {
  const [tableDataSource, setTableDataSource] = useState<T[]>([]);

  useEffect(() => {
    if (data) {
      setTableDataSource(convertFn(data, extraArgs));
    }
  }, [data, convertFn, extraArgs]);

  return { tableDataSource };
};

export default useGetTableData;
