import { Form } from 'antd';
import Input from 'antd/es/input/Input';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import FormComponent from 'src/app/components/FormComponent/FormComponent';
import styles from 'src/app/forms/CompanySettingsForms/company-settings-form.module.scss';
import { IFormProps } from 'src/app/forms/form.config';
import { useGetCustomersQuery, useUpdateCustomerMutation } from 'src/app/redux/customers/customersSlice';

const ChangeClassificationAndDbForm: React.FC<IFormProps> = ({ form, recordId }) => {
  const customer = useGetCustomersQuery(
    {
      ...(recordId && {
        ids: [recordId],
      }),
    },
    { skip: !recordId },
  ).data?.rows[0];

  useEffect(() => {
    const customerClassificationAndDB = {
      classification: customer?.classification ? customer?.classification : '',
      dbVersion: customer?.dbVersion ? customer?.dbVersion : '',
    };
    form.setFieldsValue(customerClassificationAndDB);
  }, [customer?.classification, customer?.dbVersion]);

  const [updateCustomer] = useUpdateCustomerMutation();

  const onFinish = async (values: any) => {
    const dto = {
      classification: values.classification ? values.classification : null,
      dbVersion: values.dbVersion ? values.dbVersion : null,
    };
    try {
      await updateCustomer({
        id: recordId!,
        ...dto,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormComponent
      options={{
        form,
        onFinish,
      }}
    >
      <div className={styles.subscriptionType_text}>
        {t('companySettingsPage.modalContent.subscriptionTypeSentence')}
      </div>
      <Form.Item label={t('companySettingsPage.modalContent.classification')} name="classification">
        <Input variant="borderless" />
      </Form.Item>
      <Form.Item label={t('companySettingsPage.modalContent.dbVersion')} name="dbVersion">
        <Input variant="borderless" />
      </Form.Item>
    </FormComponent>
  );
};

export default ChangeClassificationAndDbForm;
