import React from 'react';

import { iconType } from 'src/types';

const ResourcesIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
        <circle id="Ellipse_66" data-name="Ellipse 66" cx="15.5" cy="15.5" r="15.5" fill={circleColor} />
        <g id="Group_40469" data-name="Group 40469" transform="translate(7 -754)">
          <g id="Resources_v2" transform="translate(-2 760)">
            <path
              id="Path_37281"
              data-name="Path 37281"
              d="M304.33,539.85a11.541,11.541,0,0,0-3.67-2.87h-6.31a3.236,3.236,0,0,0-.42-.36l-.77-.82a3.21,3.21,0,0,0-2.45-1.14h-3.33a2.57,2.57,0,0,0-2.55,2.55v12a11.309,11.309,0,0,0,2.87,3.66,2.56,2.56,0,0,0,2.34,1.54h13.28a2.55,2.55,0,0,0,2.55-2.55v-9.63a2.59,2.59,0,0,0-1.54-2.38Zm-17.05-1.31a1.45,1.45,0,0,1,1.43-1.43H292a2.251,2.251,0,0,1,.91.21H290a2.56,2.56,0,0,0-2.55,2.55v11.36a1.45,1.45,0,0,1-.21-.74Zm16,3.09H290a2.59,2.59,0,0,0-1.43.44v-2.2a1.43,1.43,0,0,1,1.43-1.43h3.33a2.11,2.11,0,0,1,1.62.77l1.28,1.37a.52.52,0,0,0,.41.18h6.64a1.44,1.44,0,0,1,1.42,1.31,2.56,2.56,0,0,0-1.38-.44Zm-14.71,2.56a1.43,1.43,0,0,1,1.43-1.43h13.28a1.44,1.44,0,0,1,1.43,1.43v7.63a1.44,1.44,0,0,1-1.43,1.43H290a1.43,1.43,0,0,1-1.43-1.43Zm8.31-4.55-.19-.2H302a1.47,1.47,0,0,1,.74.2Zm-1.23-1.32h0c-.07-.08-.15-.14-.22-.21h5.2a1.38,1.38,0,0,1,.74.21Zm-7-2.33a2.57,2.57,0,0,0-2.55,2.55V549.9a1.441,1.441,0,0,1-.2-.74V537.21a1.44,1.44,0,0,1,1.43-1.43h3.33a2.08,2.08,0,0,1,.89.21Z"
              transform="translate(-284.83 -534.66)"
              fill={color}
            />
            <path
              id="Path_37282"
              data-name="Path 37282"
              d="M299.67,544.75h3.65a.56.56,0,1,0,0-1.12h-3.65a.56.56,0,0,0,0,1.12Z"
              transform="translate(-284.83 -534.66)"
              fill={color}
            />
            <path
              id="Path_37283"
              data-name="Path 37283"
              d="M299.67,546.08h3.65a.57.57,0,0,0,.56-.57.56.56,0,0,0-.56-.56h-3.65a.57.57,0,0,0-.57.56A.58.58,0,0,0,299.67,546.08Z"
              transform="translate(-284.83 -534.66)"
              fill={color}
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

ResourcesIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  style: { margin: '5px 0 -8px 0' },
  color: 'black',
  onClick: undefined,
  className: undefined,
};

export default ResourcesIcon;
