import { Popover } from 'antd';
import { AbstractTooltipProps } from 'antd/es/tooltip';
import React, { CSSProperties, ReactNode } from 'react';
import InfoIcon from 'src/icons/InfoIcon';
import styles from 'src/app/components/InfoTooltipComponent/InfoTooltip.module.scss';

export interface IInfoTooltipProps {
  tooltipContent: string | ReactNode;
  props?: AbstractTooltipProps;
  style?: CSSProperties;
}
const InfoTooltip: React.FC<IInfoTooltipProps> = ({ tooltipContent, props, style }) => {
  return (
    <sup>
      <Popover
        placement={props?.placement || 'bottom'}
        color="#92AABB"
        content={
          <div className={styles.popoverStyle}>
            <p>{tooltipContent}</p>
          </div>
        }
      >
        <div style={style}>
          <InfoIcon style={{ cursor: 'help' }} />
        </div>
      </Popover>
    </sup>
  );
};

export default InfoTooltip;
