import { NotificationLabelPriorityStatus } from 'src/enums/labels.enum';
import { NotificationReadStatusEnum, NotificationStarStatusEnum } from 'src/enums/notifications.enum';

export type NotificationsFilterOptionsType = {
  labelIds: string[] | undefined;
  priorities: string[] | undefined;
  readStatus: NotificationReadStatusEnum | undefined;
  starStatus: NotificationStarStatusEnum | undefined;
};

export const DEFAULT_FILTERS: NotificationsFilterOptionsType = {
  labelIds: undefined,
  priorities: undefined,
  readStatus: undefined,
  starStatus: undefined,
};

type FilterOptionsConfigType = {
  title: string;
  subEnum: Object;
  key: keyof NotificationsFilterOptionsType;
}[];

export const FILTER_OPTIONS_CONFIG: FilterOptionsConfigType = [
  {
    key: 'priorities',
    title: 'Prioritize-Based',
    subEnum: NotificationLabelPriorityStatus,
  },
  {
    key: 'starStatus',
    title: 'Star Status',
    subEnum: NotificationStarStatusEnum,
  },
  {
    key: 'readStatus',
    title: 'Read Status',
    subEnum: NotificationReadStatusEnum,
  },
];
