export const languageTr = {
  // HEADER
  header: {
    search: 'Search',

    menu: {
      profile: 'Profile',
      settings: 'Settings',
      importData: 'Import Data',
      helpAndSupport: 'Help and Support',
      logout: 'Logout',
    },
  },
  // SIDEBAR
  sidebar: {
    homePage: 'My Dashboard',
    network: 'My Network',
    myNotifications: 'My Notifications',
    topic: 'All Media Insights',
    location: 'All Location',
    industry: 'All Industry',
    report: 'Reports',
    resources: 'Resources',
    customerAndUser: 'Customer & User',
    customerAndUserInvitations: 'Customer & User Invitations',
    students: 'Students',

    footer: {
      SC3text: 'Powered By ngnir® and Dataunitor® AS',
      SAMAtext: 'Powered By ngnir® and ETE Technology® AS',
      credits: 'Credits',
      termsOfUse: 'Terms of Use',
    },
  },

  // PROFILE PAGE
  profilePage: {
    userProfile: {
      tabName: 'User Profile',
      form: {
        fields: {
          fullName: 'Full Name',
          email: 'E-mail',
          userRole: 'User Role',
          currentPassword: 'Current Password',
        },
      },
    },
    password: {
      tabName: 'Password',
    },
  },

  // SETTINGS PAGE
  settingsPage: {
    theme: {
      tabName: 'Theme',
      pickColor: 'Pick Color',
      changeToDefault: 'Change to Default',
    },
    userSettings: {
      tabName: 'User Settings',
      inviteUser: 'Invite User',
    },
    securitySettings: {
      tabName: 'Security Settings',
    },
    notificationSettings: {
      tabName: 'Notification Settings',

      notificationRules: {
        tabName: 'Notification Rules',
        addNotification: 'Add Notification',
      },
      notificationSettings: {
        tabName: 'Notification Settings',
        allLabels: 'All Labels',
        createUserBasedLabel: 'Create User-Based Label',
        labelName: 'Label Name',
        labelColour: 'Label Colour',
        colourPalette: 'Colour Palette',
        prioritizeActiveLabels: 'Prioritize Active Labels',
        systemBased: 'System-based',
        userBased: 'User-based',
        tooltips: {
          prioritizeActiveLabels: 'Drag and drop labels to change priority of notifications',
        },
        high: 'High',
        medium: 'Medium',
        low: 'Low',
        uncategorized: 'Uncategorized',
      },
    },
    saqSettings: {
      tabName: 'SAQ Settings',
    },
    cocSettings: {
      tabName: 'CoC Settings',
    },
    monitoredSupplierSettings: {
      tabName: 'Monitored Supplier Settings',
    },
    controlPanel: {
      tabName: 'Control Panel',
      runningModules: {
        tabName: 'Running Modules',
        addModule: 'Add Module',
      },
      machines: {
        tabName: 'Machines',
      },
    },
    authenticationSettings: {
      tabName: 'Authentication Settings',
    },
    senderEmailSettings: {
      tabName: 'Sender Email Settings',
    },
  },

  // HELP AND SUPPORT PAGE
  helpAndSupportPage: {
    userGuide: {
      tabName: 'User Guide',
    },
    faq: {
      tabName: 'Frequently Asked Questions (FAQ)',
    },
    troubleTicket: {
      tabName: 'Trouble Ticket',
    },
    configurationChangeRequest: {
      tabName: 'Configuration Change Request',
    },
  },

  // DASHBOARD/HOME PAGE
  homePage: {
    numberOfSuppliers: 'Number of Suppliers',
    numberOfSourcingCountries: 'Number of Sourcing Countries',
    myMedia: 'My Media',
    mySuppliersMedia: "My Supplier's Media",
    blacklistedStatus: 'Blacklisted Status',
    countryRisk: 'Country Risk',
    industryRisk: 'Industry Risk',
    vulnerabilityToModernSlavery: 'Vulnerability To Modern Slavery Score',
    prevalenceIndexRank: 'Prevalence Index Rank',
    governmentResponseRating: 'Government Response Rating',
    tipTier: 'TIP Tier',
    tier1: 'Tier 1',
    tier2AndBeyond: 'Tier 2 and Beyond',
  },

  // CUSTOMER & USER PAGE
  customerAndUserPage: {
    userDetails: 'User Details',
    inviteUser: 'Invite User',
    modals: {
      companyDetails: 'Company Details',
      companyInfo: 'Company Info',
      companyNotes: 'Company Notes',
      subscriptionInfo: 'Subscription Info',
      userInfo: 'User Info',
      resourceInfo: 'Resource Info',
      editModal: {
        title: 'Edit Supplier Data',
      },
      deleteModal: {
        title: 'Delete User',
        content: `The user named <strong>{{firstname}}</strong>  will be deleted permanently,this process cannot be undone.
        Are you sure?`,
      },
      freezeModal: {
        title: 'Freeze User',
        content: `The user will be frozen and will not be able to use the system until unfrozen.
        Are you sure?`,
        okText: 'Freeze',
      },
    },
  },

  // CUSTOMER & USER INVITATIONS PAGE
  customerAndUserInvitationsPage: {
    customerInvitations: {
      tabName: 'Customer Invitations',
    },
    userInvitations: {
      tabName: 'User Invitations',
    },
  },

  // SAQFORM PAGE
  saqFormPage: {
    title: 'Received SAQ',
    solveSAQ: {
      tabName: 'Solve SAQ',
      openSAQ: 'Open SAQ',
      receiverSAQMessage: 'You have received SAQ. Click below button to solve the SAQ.',
      solvedSAQ: 'You have solved the SAQ. You can monitor information about the SAQ and Actions.',
    },
    monitorSAQ: {
      tabName: 'Monitor SAQ',
      table: {
        headers: {
          title: 'Title',
          deliveryStatus: 'Delivery Status',
          deliveryDate: 'Delivery Date',
          completionStatus: 'Reviewed',
          completionDate: 'Completion Date',
          receiverCompany: 'Receiver Company',
          receiverEmails: 'Receiver Emails',
          riskScore: 'Risk Score',
          summary: 'Summary',
        },
      },
    },
    actionCenter: {
      tabName: 'Action Center',
      table: {
        headers: {
          actionTitle: 'Action Title',
          actionSetDate: 'Action Set Date',
          deadlineDate: 'Deadline Date',
          urgency: 'Urgency',
          responsiblePerson: 'Responsible Person',
          assignedPerson: 'Assigned Person',
          receiverSupplier: 'Receiver Supplier',
          status: 'Status',
        },
      },
    },
  },

  eteForms: {
    welcomePage: {
      start: 'Start',
      press: 'press',
      takesMinute: 'takes {{minute}} mins this section',
      takesOverall: 'takes {{minute}} mins overall',
      maxScore: 'Max avaliable score:',
      minScore: 'Min avaliable score:',
    },
  },

  // NOTIFICATIONS PAGE
  notificationsPage: {
    inbox: 'Inbox',
    archived: 'Archived',
    markAsRead: 'Mark As Read',
    editLabel: 'Edit Label',
    markAsIrrelevant: 'Mark As Irrelevant',
    markAsRelevant: 'Mark As Relevant',
    arhive: 'Arhive',
    unArchive: 'Unarchive',
    delete: 'Delete',
    labels: 'Labels',
    prioritizeBased: 'Prioritize-Based',
    readStatus: 'Read Status',
    starStatus: 'Star Status',
    all: 'All',
    high: 'High',
    read: 'Read',
    unread: 'Unread',
    star: 'Star',
    unstar: 'Unstar',
    markAsReadAll: 'Mark As Read All',
    markAsUnreadAll: 'Mark As Unread All',
    markAsRelevantAll: 'Mark As Relevant All',
    markAsIrrelevantAll: 'Mark As Irrelevant All',
    markStarTitle: 'Click here to mark this notification as important for you',
    markUnStarTitle: 'Click here to mark this notification as not important for you',
    archiveAll: 'Archive All',
    unArchiveAll: 'Unarchive All',
    deleteAll: 'Delete All',
    tooltips: {
      low: 'Low',
      star: 'Click here to mark this notification as important for you',
      add: 'Setup New Notification Rule',
      settings: 'Notification Settings',
    },
    curatedContent: 'Curated Content',
    blacklist: 'Blacklist',
    saq: 'SAQ',
    detail: {
      title: 'Notification Detail',
      generalInfo: 'General Info',
      newsInfo: 'News Info',
      interestsInData: 'Interests In Data',
    },
  },
  momentCalendar: {
    sameDay: 'Today',
    lastDay: 'Yesterday',
  },
  // LOCATIONS PAGE
  locationsPage: {
    title: 'All Locations',
    tableView: {
      tabName: 'Table View',
    },
    mapView: {
      tabName: 'Map View',
    },
  },

  // REPORTS PAGE
  reportsPage: {
    tabs: {
      reports: {
        tabName: 'Reports',
        createReport: 'Create Report',
        modal: {
          formSections: 'Form Sections',
        },
      },
      monitorSaq: {
        tabName: 'Monitor SAQ',
      },
      actionCenter: {
        tabName: 'Action Center',
        selectReportType: 'Select a report type',
        selectReport: 'Select a Report',
        selectSaq: 'Select SAQ',
      },
    },
  },

  forms: {
    // PROFILE FORM
    profileForm: {
      fields: {
        fullName: 'Full Name',
        email: 'E-mail',
        userRole: 'User Role',
        currentPassword: 'Current Password',
      },
    },
    // PASSWORD FORM
    passwordForm: {
      fields: {
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        passwordConfirmation: 'Password Confirmation',
      },
    },

    // SECURITY SETTINGS FORM
    securityForm: {
      fields: {
        enableTwoFactor: 'Enable Two Factor Authentication',
        sessionExpireTime: 'Session Expire Time',
        currentPassword: 'Current Password',
        authentication: 'Authentication',
      },
      placeholders: {
        selectAuthType: 'Select an Authentication Type',
      },
      options: {
        emailAuth: 'E-Mail Authentication',
        qrCodeAuth: 'QR-Code Authentication',
      },
      logoutAfter: 'Log out after',
      minutesIdle: 'minutes Idle',
    },
    // NOTIFICATION FORM
    notificationForm: {
      fields: {
        subject: 'Subject',
        sources: 'Sources',
        interestType: 'Interest Type',
        interestName: 'Interest Name',
        expirationDate: 'Expiration Date',
        webNotification: 'Web Notification',
        receiver: 'Receiver',
        emailNotification: 'E-mail Notification',
      },
      modals: {
        editModalTitle: 'Update Notification',
      },
    },
    // SAQ AND COC SETTINGS FORM
    saqAndCocSettingsForm: {
      fields: {
        timePassed: 'Time Passed',
        timePeriod: 'Time Period',
        reminderCount: 'Reminder Count',
        reminderMailTemplate: 'Reminder Mail Template',
      },
      tooltips: {
        timePassed: `Determine how many days after the initial email the reminder should be sent.`,
        timePeriod: `Determine the period between each reminder mail as day after the initial reminder mail sent.`,
        reminderCount: `Determine the maximum reminder count which means that the supplier will receive exactly this amount of reminder mail.`,
        reminderMailTemplate: `Determine which mail template you want to use.`,
      },
    },

    // COMPANY DETAILS FORM
    detailsForm: {
      fields: {
        companyName: 'Company Name',
        isProxy: 'Is Proxy',
        legalCompanyName: 'Legal Company Name',
        emails: 'E-mail(s)',
        industry: 'Industry',
        website: 'Website',
        DUNS: 'DUNS',
        registryId: 'Registry Id',
        companyNumber: 'Company Number',
        country: 'Country',
      },
    },

    // INVITE USER FORM
    inviteUserForm: {
      fields: {
        email: 'E-mail',
      },
    },

    // SUPPLIER FORM
    supplierForm: {
      fields: {
        from: 'From',
        relationType: 'Relation Type',
        to: 'To',
        strategicImportance: 'Strategic Importance',
      },
    },

    // GENERATE REPORT FORM
    generateReportForm: {
      fields: {
        company: 'Company',
        exportType: 'Export Type',
        applyFilterChoices: 'Apply Filter Choices',
      },
    },

    // SAQ TEMPLATE FORM
    saqTemplateForm: {
      fields: {
        saqName: 'SAQ Name',
        status: 'Status',
      },
    },
    // EMAIL TEMPLATE FORM
    emailTemplateForm: {
      fields: {
        templateName: 'Template Name',
        topContent: 'Top Content',
        bodyContent: 'Body Content',
        bottomContent: 'Bottom Content',
        emailTemplateType: 'Email Template Type',
        notes: 'Notes',
      },
    },
    // ACTION DETAILS FORM
    actionDetailsForm: {
      fields: {
        question: 'Question',
        answer: 'Answer',
        actionTitle: 'Action Title',
        actionSummary: 'Action Summary',
        actionSetDate: 'Action Set Date',
        deadline: 'Deadline',
        urgency: 'Urgency',
        senderResponsiblePerson: 'Sender Responsible Person',
        receiverResponsiblePerson: 'Receiver Responsible Person',
        attachments: 'Attachments',
        status: 'Status',
        commentActivities: 'Comment Activities',
      },
    },
  },

  tables: {
    // USER SETTINGS TABLE
    userSettings: {
      columns: {
        fullName: 'Full Name',
        email: 'E-mail',
        userRole: 'User Role',
        deliveryStatus: 'Delivery Status',
        deliveryDate: 'Delivery Date',
        registerStatus: 'Register Status',
        registerDate: 'Register Date',
      },
      tooltips: {
        editUser: 'Edit User',
        deleteUser: 'Delete User',
      },
      modals: {
        deleteModal: {
          title: 'Delete User',
          content: `The user named <strong>{{firstname}}</strong> will be deleted permanently from your customer account.
          Are you sure?`,
        },
      },
    },
    // NOTIFICATION RULE TABLE
    notificationRules: {
      columns: {
        information: 'Information',
        emailNotification: 'E-mail Notification',
        webNotification: 'Web Notification',
        subject: 'Subject',
        expireDate: 'Expire Date',
        period: 'Period',
        to: 'To',
        cc: 'Cc',
        bcc: 'Bcc',
        receiver: 'Receiver',
      },
    },
    // MONITORED SUPLLIER SETTINGS TABLE
    monitorSuppliers: {
      columns: {
        companyName: 'Company Name',
        country: 'Country',
        industry: 'Industry',
        strategicImportance: 'Strategic Importance',
      },
      tooltips: {
        edit: 'Edit Supplier',
        delete: 'Delete Supplier',
      },
      modals: {
        editModal: {
          title: 'Edit Supplier Data',
        },
        deletModal: {
          title: 'Delete Supplier',
          content: `{{companyName}} will be removed from your supply chain.
          Are you sure?`,
        },
      },
    },

    // VALUE CHAIN TABLE
    valueChain: {
      columns: {
        companyName: 'Company Name',
        tierLevel: 'Tier Level',
        country: 'Country',
        industry: 'Industry',
        strategicImportance: 'Strategic Importance',
        coc: 'CoC',
        saq: 'SAQ',
        hasNews: 'Has News',
        blacklisted: 'Blacklisted',
      },
      tooltips: {
        country: 'Red background color indicates high risk',
        industry: 'Red background color indicates high risk',
        strategicImportance: 'Red background color indicates high risk',
        coc: "Your supplier's latest response date of COC",
        saq: "Your supplier's latest complete date of SAQ",
        hasNews: "Indicates that company has 'new' news within 90 days",
        blacklist:
          'SC3 system uses the following organisations as Blacklist sources; UN Human Rights Council, US Customs and Border Protection, US Department of Homeland Security, and Norges Bank.',
        editSupplier: 'Edit Supplier',
        deleteSupplier: 'Delete Supplier',
        modernSlaveryStatement: 'Modern Slavery Statement',
      },
      modals: {
        editModal: {
          title: 'Edit Supplier Data',
        },
        deleteModal: {
          title: 'Delete Supplier',
          content: `{{companyName}} will be removed from your supply chain.
          Are you sure?`,
        },
      },
    },

    // SAQ TEMPLATES TABLE
    saqTemplates: {
      columns: {
        saqName: 'SAQ Name',
        uploadedAt: 'Uploaded At',
        status: 'Status',
      },
      modals: {
        editModal: {
          title: 'Edit SAQ',
        },
      },
    },
    // EMAIL TEMPLATES TABLE
    emailTemplates: {
      columns: {
        templateName: 'Template Name',
        notes: 'Notes',
        createdBy: 'Created By',
        createdAt: 'Created At',
        emailType: 'Email Type',
      },
      tooltips: {
        showEmailTemplates: 'Show Email Templates',
      },
      modals: {
        showModal: {
          saqTitle: 'Send Code of Conduct (CoC)',
          cocTitle: 'Send Code of Conduct (CoC)',
        },
        editModal: {
          saqTitle: 'Edit SAQ',
          cocTitle: 'Edit CoC',
        },
      },
    },
    // SEND SAQ OR COC TABLE
    sendSaqOrCoc: {
      columns: {
        companyName: 'Company Name',
        country: 'Country',
        industry: 'Industry',
        strategicImportance: 'Strategic Importance',
      },
      modals: {
        sendModal: {
          saqTitle: 'Send Supplier Self Assessment Questionnaire (SAQ)',
          cocTitle: 'Send Code of Conduct (CoC)',
        },
      },
      tooltips: {
        openSaq: 'Open SAQ',
        displayActions: 'Display Actions',
      },
    },
    // MONITOR SAQ TABLE
    monitorSaq: {
      columns: {
        title: 'Title',
        deliveryStatus: 'Delivery Status',
        deliveryDate: 'Delivery Date',
        completionDate: 'Completion Date',
        completionStatus: 'Completion Status',
        receiverCompany: 'Receiver Company',
        receiverEmails: 'Receiver Emails',
        riskScore: 'Risk Score',
        summary: 'Summary',
      },
      tooltips: {
        summary: `The colors indicate the progress of actions. Red means "not started" action, yellow means "in progress" action, and green means "completed" action. The numbers indicate the number of not started/in progress/completed actions over the total number of actions.`,
      },
    },
    // ACTION CENTER TABLE
    actionCenter: {
      columns: {
        actionTitle: 'Action Title',
        actionSetDate: 'Action Set Date',
        deadlineDate: 'Deadline Date',
        urgency: 'Urgency',
        responsiblePerson: 'Responsible Person',
        assignedPerson: 'Assigned Person',
        receiverSupplier: 'Receiver Supplier',
        status: 'Status',
      },
      tooltips: {
        responsiblePerson: 'The responsible person is authorized to edit action details.',
      },
      modals: {
        editModal: {
          title: 'Action Details Form',
        },
        deleteModal: {
          title: 'Delete Action',
          content: `{{actionTitle}} will be deleted from Action Center. Are you sure?`,
        },
      },
    },
    // COC TEMPLATES TABLE
    cocTemplates: {
      columns: {
        cocName: 'CoC Name',
        uploadedBy: 'Uploaded By',
        uploadedAt: 'Uploaded At',
        status: 'Status',
      },
      modals: {
        editModal: {
          title: 'Edit CoC',
        },
        deleteModal: {
          title: 'Delete CoC',
        },
      },
    },
    // MONITOR COC TABLE
    monitorCoc: {
      columns: {
        cocName: 'CoC Name',
        deliveryStatus: 'Delivery Status',
        deliveryDate: 'Delivery Date',
        responseStatus: 'Response Status',
        responseDate: 'Response Date',
        sender: 'Sender',
        receiverCompany: 'Receiver Company',
        receiverEmails: 'Receiver Emails',
      },
      tooltips: {
        show: 'Response Details',
      },
      modals: {
        showModal: {
          title: 'Response Details',
        },
      },
    },
    // MEDIA TABLE
    media: {
      columns: {
        title: 'Title',
        categories: 'Categories',
        mediaType: 'Media Type',
        date: 'Date',
      },
    },
    // LOCATIONS TABLE
    locations: {
      columns: {
        countryName: 'Country Name',
        vulnerabilityToModernSlaveryScore: 'Vulnerability To Modern Slavery Score',
        estimatedPrevalence: 'Estimated Prevalence of Modern Slavery (per 1,000 population)',
        governmentResponseScore: 'Government Response Score (%)',
        tipTier: 'TIP Tier',
      },
      tooltips: {
        countryName: `Geographical location of the suppliers and the number of suppliers from the specific location within brackets. Example: China (3) meaning that there are 3 suppliers located in China. The country list below shows risk stratification based on TIP Tier, where the color red is associated with the highest risk TIP Tier 3, and color green is with the lowest risk TIP Tier 1.`,
        vulnerabilityToModernSlaveryScore: `Measuring the level of vulnerability to modern slavery in 160 countries. The vulnerability model estimates how vulnerable people in a country are to modern slavery and in what ways. Based on a set of country level indicators, the model is designed to enable us to identify and better understand the potential drivers of this crime.

        1.Governance Issues
        2.Lack of Basic Needs
        3.Inequality
        4.Disenfranchised Groups
        5.Effects of Conflict
        
        The highest number equals to the highest vulnerability to modern slavery.
        
        Source:
        https://www.walkfree.org/global-slavery-index/map/#mode=data:dimension=v`,
        estimatedPrevalence: `Measuring the scale of modern slavery in 160 countries. These national estimates of the prevalence per thousand people and number of people were calculated using individual and country-level risk factors of modern slavery. The analysis draws on thousands of interviews with survivors of modern slavery collected through nationally representative household surveys across 75 countries and Walk Free\`s assessment of national-level vulnerability. Unlike the national scores for vulnerability and government response, our data on the scale of modern slavery cannot be disaggregated (for example, by form of modern slavery or gender).

        Below the estimated prevalence of modern slavery per 1,000 population is shown. The highest risk is associated with the highest number, and the lowest risk is associated with the lowest number.
        
        The Index targets private citizens, non-governmental organisations, businesses and public officials so that they can work to end modern slavery. All data involved are available for download from the website.
        
        Source:
        https://www.walkfree.org/global-slavery-index/map/#mode=data:dimension=p`,
        governmentResponseScore: `Measuring national efforts to end modern slavery in 176 countries. The government response assessment provides a comparative look at the legal, policy, and programmatic actions that governments are taking to respond to modern slavery. This is based on data collected on 141 indicators that are relevant to understanding how each government is tracking towards achieving 42 activities organised into five milestones. Each milestone represents an aspect of a strong government response to modern slavery; for example, supporting survivors to exit and remain out of modern slavery:

        Survivors of slavery are identified and supported to exit and remain out of slavery.
        Criminal justice mechanisms function effectively to prevent modern slavery.
        Coordination occurs at the national and regional level, and governments are held to account for their response.
        Risk factors such as attitudes, social systems, and institutions that enable modern slavery are addressed.
        Government and business stop sourcing goods and services produced by forced labour.
        There are several Tiers within this index, where AAA is the best, meaning that the government has implemented an effective and comprehensive response to all forms of modern slavery and D is the worst, meaning the government has a wholly inadequate response to modern slavery, and/or there is evidence of government-sanctioned modern slavery.
        
        The highest number refers to the best government response rating and the lowest number is to the worst rating.
        
        Source: https://www.walkfree.org/global-slavery-index/map/#mode=data:dimension=r `,
      },
      tipTier: `Trafficking in Persons Report, or the TIP Report, is an annual report issued by the U.S. State Department's Office to Monitor and Combat Trafficking in Persons. It ranks governments based on their perceived efforts to acknowledge and combat human trafficking. The report divides nations into tiers based on their compliance with standards outlined in the Trafficking Victims Protection Act (TVPA) of 2000.

      There are four Tiers, ranging from the best (Tier 1), where governments are fully compliant (green colour), to the worst Tier 3, where governments are not compliant with the minimum standards (red colour).
      
      Source:
      https://www.state.gov/reports/2022-trafficking-in-persons-report/`,
    },
    // INDUSTRIES TABLE
    industries: {
      columns: {
        GICSIndustrySector: 'GICS Industry Sector',
        GICSIndustryGroup: 'GICS Industry Group',
        GICSIndustry: 'GICS Industry',
        code: 'Code',
        name: 'Name',
      },
      tooltips: {
        GICSIndustrySector: `Global Industry Classification Standard (GICS) - The GICS sector list consists 11 sectors

        GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.
        
        The list below shows the GICS sector based classification of your suppliers.
        
        Source:
        https://www.msci.com/our-solutions/indexes/gics`,
        GICSIndustryGroup: `Global Industry Classification Standard (GICS) - The GICS industry group list consists 24 industry groups

        GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.
        
        The list below shows the GICS sector based classification of your suppliers.
        
        Source:
        https://www.msci.com/our-solutions/indexes/gics`,
        GICSIndustry: `Global Industry Classification Standard (GICS) - The GICS industry list consists 69 industries.

        GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.
        
        The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.
        
        The list below shows the GICS industry based classification of your suppliers. The number in () after each industry shows how many of your suppliers are having its principal business activity within the respective GICS Industry. The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.
        
        Source:
        https://www.msci.com/our-solutions/indexes/gics`,
      },
    },
    // MEDIA INSIGHTS TABLE
    mediaInsights: {
      columns: {
        mediaInsight: 'Media Insight',
      },
    },
    // REPORTS TABLE
    reports: {
      columns: {
        reportType: 'Report Type',
        description: 'Description',
        score: 'Score',
        progress: 'Progress',
        createdAt: 'Created At',
        completedAt: 'Completed At',
      },
      tooltips: {
        openReport: 'Open Report',
        cloneReport: 'Clone Report',
        displayActions: 'Display Actions',
        deleteReport: 'Delete Report',
      },
      modals: {
        reportModal: {
          title: 'Form Sections',
        },
      },
    },
    // OPEN REPORT TABLE
    openReport: {
      columns: {
        section: 'Section',
        createdAt: 'Created At',
        numberOfQuestions: 'Number of Questions',
        completedAt: 'Completed At',
        score: 'Score',
        completedBy: 'Completed By',
      },
    },
    // RESOURCES TABLE
    resources: {
      resourceName: 'Resource Name',
      type: 'Type',
      size: 'Size',
      requiredSubscriptionType: 'Required Subscription Type',
      resourceCategory: 'Resource Category',
      price: 'Price',
      tooltips: {
        download: 'Download the resource',
      },
    },

    // CCR TABLE
    configurationChangeRequest: {
      columns: {
        CCRId: 'CCR Id',
        CCR: 'CCR',
        creationDTG: 'Creation DTG',
        service: 'Service',
        function: 'Function',
        criticality: 'Criticality',
        urgency: 'Urgency',
        status: 'Status',
      },
    },
    // TT TABLE
    troubleTicket: {
      columns: {
        troubleTicketId: 'Trouble Ticket Id',
        troubleTicket: 'Trouble Ticket',
        creationDTG: 'Creation DTG',
        service: 'Service',
        function: 'Function',
        criticality: 'Criticality',
        urgency: 'Urgency',
        status: 'Status',
      },
    },
    // CUSTOMER INVITATION TABLE
    customerInvitations: {
      columns: {
        customersCompanyName: 'Customers Company Name',
        receiverUserName: 'Receiver User Name',
        receiverUserEmail: 'Receiver User Email',
        deliveryStatus: 'Delivery Status',
        deliveryDate: 'Delivery Date',
        registerStatus: 'Register Status',
        registerDate: 'Register Date',
        senderUserName: 'Sender User Name',
        senderUserEmail: 'Sender User Email',
      },
    },
    userInvitations: {
      columns: {
        usersCompanyName: 'Users Company Name',
        receiverUserName: 'Receiver User Name',
        receiverUserEmail: 'Receiver User Email',
        deliveryStatus: 'Delivery Status',
        deliveryDate: 'Delivery Date',
        registerStatus: 'Register Status',
        registerDate: 'Register Date',
        senderUserName: 'Sender User Name',
        senderUserEmail: 'Sender User Email',
      },
    },
  },

  // GLOBAL VARIABLES
  global: {
    save: 'Save',
    upload: 'Upload',
    ok: 'OK',
    yes: 'Yes',
    no: 'No',
    download: 'Download',
    cancel: 'Cancel',
    all: 'All',
    reset: 'Reset',
    filter: 'Filter',
    edit: 'Edit',
    archive: 'Archive',
    delete: 'Delete',
    send: 'Send',
    proceed: 'Proceed',
    show: 'Show',
    actions: 'Actions',
    submit: 'Submit',
    enter: 'Enter',
    create: 'Create',
    archiveModal: {
      title: 'Archive',
      content: `{{content}} will be archived. Are you sure?`,
    },
    deleteModal: {
      title: 'Delete',
      content: `{{content}} will be deleted. Are you sure?`,
      formDeleteSentence: 'This form will be deleted. Are you sure?',
    },
    subscriptionTypes: {
      bronze: 'Bronze',
      silver: 'Silver',
      gold: 'Gold',
    },
    twoFATypes: {
      DISABLED: 'Disabled',
      GOOGLEAUTHENTICATION: 'Google Authentication',
      MAILAUTHENTICATION: 'Mail Authentication',
      SMSAUTHENTICATION: 'SMS Authentication',
      QRCODEAUTHENTICATION: 'QR Code Authentication',
    },
  },

  // LOGIN PAGE
  loginPage: {
    dontHaveAccount: "Don't you have an account yet?",
    register: 'Register',
    title: 'LOGIN',
    email: 'E-mail',
    customer: 'Customer',
    password: 'Password',
    submit: 'Login',
    forgotPassword: 'Forgot Password',
    needHelp: 'Need Help',
    selectCompany: 'Select Company',
    freezeModal: {
      titleUser: 'Frozen User',
      titleCustomer: 'Frozen Customer',
      contact1: 'Please contact us using ',
      contact2: ' address to get more information and unfreeze your account.',
      emailAddress: 'sc3.help@slavefreealliance.org',
      user: 'User',
      customer: 'Customer',
      close: 'Close',
    },
  },

  // GOOGLE AUTHENTICATION PAGE
  googleAuthenticationVerificationPage: {
    title: 'VERIFY GOOGLE AUTH',
    email: 'E-mail',
    password: 'Password',
    next: 'Next',
    submit: 'Login',
    authentication: 'QR-Code Verification Setup',
    authenticate: 'Authenticate',
    code: 'Code',
    placeholder: 'Retype your password',
    openApp: 'Open the Google Authenticator App on your phone or tablet',
    scan: 'Please scan the QR-Code on the screen and validate login operation with 6-digit code',
    modalPlaceholder: 'Enter the code',
    verifyGoogle: 'Please retype your password to proceed verification of Google Authentication',

    login: {
      email: 'email',
      customer: 'customerId',
      password: 'password',
    },
  },

  // MAIL AUTHENTICATION PAGE
  mailAuthenticationPage: {
    googleTitle: 'QR-Code Authentication',
    mailTitle: 'Mail Authentication',
    title: 'TWO FACTOR AUTH',
    subTitle: 'This extra step shows it’s really you trying to sign in',
    mailMessage: 'An e-mail with a 6-digit verification code was just sent to ',
    code: 'Code',
    submit: 'Submit',
    codeMessageGoogle: 'Please enter the 6-digit verification code of your QR-Code.',
    codeMessageMail: 'Please enter the one time 6-digit verification code.',
    openApp: 'Open the Google Authenticator App on your phone or tablet',
    openMail: 'Open your e-mail inbox',
    placeholder: 'Enter the code',
    lostQR: 'Lost QR-Code',
    authentication: {
      code: 'code',
    },
  },

  // REGISTER PAGE
  registerPage: {
    title: 'REGISTER',
    fullname: 'Full Name',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    submit: 'Register',
    star: '*',
    warning: 'WARNING',
    warningModal: {
      title: 'Warning',
      expireContent: 'Customer register invitation link expired.',
      registeredContent: 'You have already registered to the SC3 using the invitation link.',
      expireButtonText: 'Close',
      registeredButtonText: 'Login Page',
    },
  },
  // FORGOT PASSWORD PAGE
  forgotPasswordPage: {
    title: 'RESET PASSWORD',
    detail: 'Please enter your e-mail address to request a password reset',
    email: 'E-mail',
    buttonText: 'Reset Password',
    forgotPassword: {
      email: 'email',
    },
  },

  // FORGOT PASSWORD DONE PAGE
  forgotPasswordDonePage: {
    title: 'RESET PASSWORD',
    detail: 'An e-mail has been sent to your e-mail address.',
    moreDetail: 'You can use the link in your inbox and reset your password.',
    buttonText: 'Done',
  },

  // RESET PASSWORD PAGE
  resetPasswordPage: {
    title: 'CREATE PASSWORD',
    newPassword: 'New Password',
    newPasswordConfirmation: 'Retype New Password',
    submit: 'Create',
  },

  // CUSTOMER REGISTER PAGE
  customerRegisterPage: {
    title: 'CUSTOMER REGISTER',
    formLabels: {
      email: 'E-mail',
      fullname: 'Full Name',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      companyName: 'Company Name',
      website: 'Website',
      duns: 'DUNS',
      subscribeType: 'Select your subscribe type',
      registryId: 'Registry Id',
      companyNumber: 'Company Number',
      legalCompanyName: 'Legal Company Name',
      country: 'Country',
      industry: 'Industry',
      accountType: 'Subscription Type',
    },
    star: '*',
    submit: 'Create Customer',
    complete: 'Complete',
    next: 'Next',
    modalWarning1:
      ' is already added into system. If you are sure about the company information please contact with us using ',
    mailAddress: 'help@sc3.slavefreealliance.com',
    modalWarning2: 'address. You can try to change',
    modalWarning3: 'fields to complete this step.',
    or: 'or',
    ok: 'OK',
    fillWarning: 'Please fill the required fields.',
  },

  // ERROR MESSAGES
  errors: {
    cocDependency:
      'There is an assigned CoC related to this document. To continue; please delete related assigned CoC(s) in "Monitor CoC" tab first and the try again.',
    unknownError: 'Unknown Error',
    badRequest: 'Bad Request',
    NotFound: 'The attachment could not find',
    internalServerError: 'Internal Server Error',
    connectionError: 'Connection Error',
    sessionExpired: 'Session Expired',
    authorizationError: 'Authorization Error',
    dataNotFound: 'Data not found',
    unsuccessfull: 'Unsuccessful operation',
    missingValue: 'At least 1 value required',
    incorrectCredentials: 'Incorrect credentials',
    incorrectPassword: 'Incorrect password',
    emailAlreadyExists: 'E-mail already exists',
    emailDesNotExists: 'E-mail does not exist',
    fieldRequired: 'This field cannot be empty',
    invalidEmail: 'Should be a valid e-mail',
    min8: 'Should be more then 8 characters',
    max64: 'Should be 64 characters at maximum',
    max100: 'Should be 100 characters at maximum',
    max128: 'Should be 128 characters at maximum',
    max255: 'Should be less then 256 characters',
    max256: 'Should be 256 characters at maximum',
    max30: 'Should be less then 30 characters',
    max1000: 'Should be 1000 characters at maximum',
    max10000: 'Should be 10000 characters at maximum',
    email: 'Should be a valid e-mail',
    onlyLetters: 'Only letters allowed',
    required: 'This field cannot be empty',
    range: 'Should be between: ',
    invalidSignature: 'Register link is expired',
    passwordResetExpired: 'Reset password link is expired.',
    customerRegisterExpired: 'Customer register link is expired.',
    mailAuthenticationExpired: 'Mail authentication link is expired.',
    passwordsNotMatching: 'Passwords did not match',
    verificationCodeNotMatch: 'Verification code is invalid.',
    websiteFormat: 'Website must be in URL format',
    isPositive: 'This field must be a positive number',
    dateNotFound: 'Date not found',
    titleNotFound: 'Title not found',
    urlNotFound: 'URL not found',
    invalidPassword: 'At least one upper case, lower case and number required',
    sameEnterprise: 'Companies cannot be the same',
    interactionExists: 'This company was already added into your supply chain',
    missingInterests: 'Missing Interests',
    EXCEL_ERROR: 'Excel error',
    EXCEL_WORKSHEET_NAMES_DOES_NOT_MATCH: 'Excel worksheet names do not match',
    EXCEL_FIELD_NAMES_DOES_NOT_MATCH: 'Field names do not match',
    EXCEL_FIELD_VALIDATION_ERROR: 'Field validation error',
    EXCEL_CREATE_ERROR: 'Create warning',
    EXCEL_INVALID_COUNTRY_NAME_ERROR: 'Invalid country name',
    EXCEL_INVALID_COMPANY_NAME_ERROR: 'Invalid company name',
    EXCEL_INVALID_RELATION_TYPE_ERROR: 'Invalid relation type',
    EXCEL_DELETE_ERROR: 'Delete error',
    errorRow: 'Row',
    errorColumn: 'Column',
    errorHandled: 'Error Handled',
    companies: 'Companies',
    blacklists: 'Blacklists',
    interactions: 'Interactions',
    forgotPasswordOverflow: 'Forgot Password request limit has been reached.',
    lostQrCodeOverflow: 'Lost QR-Code request limit has been reached.',
    incorrectAuthenticationCode: 'The code is invalid',
    companyDeleteErrorMessage: 'The company could not be deleted because of the effects on other customers.',
    companyEditErrorMessage: 'The company could not be edited because of the effects on other customers.',
    companyAlreadyExists: 'A company already exists with given Company Name, Company Number and Country.',
    fileTypeError: 'Supported image types are only .jpg and .jpeg',
    fileTypeErrorTemplate: 'Please upload the template excel file',
    // fileTypeErrorRequest:
    //   'Supported file types are .jpg, .jpeg, .png, .jpg, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx and videos',
    fileTypeErrorRequest: 'Supported file types only .jpg, .jpeg, .png, .pdf, .docx, .xlsx, .pptx and videos',
    cocFileTypeErrorRequest: 'Supported file types are .pdf and .docx',
    nullAuthentication: 'Please select an authentication type',
    changeAuthentication: 'Please change your current authentication type',
    ppSizeError: 'File size limit exceeds. The maximum allowed file size is 1MB',
    requestSizeError: 'File size limit exceeds. The maximum allowed file size is 5MB in total',
    requestSizeErrorCoc: 'File size limit exceeds. The maximum allowed file size is 10MB in total',
    anErrorOccurred: 'An error occurred. Please try again.',
    incorrectPasswordGoogleAuth: 'Password is incorrect',
    customerAccountTypeLimitsReached: 'Customer account type limits reached',
    cannotDownloadResourceWithoutPayment: 'Cannot download resource without payment',
    notificationFeedbackError: 'A problem has been occurred while submitting your feedback.',
    mandatoryQuestionEmpty: 'Mandatory questions cannot be empty.',
    selectSAQ: 'Please select an SAQ to proceed.',
    selectEmail: 'Please select at least 1 e-mail address.',
    maxSupllierMonitorLimit:
      'You have reached the limit, please upgrade your subscription type to monitor more suppliers.',
    minCurrentDate: "Can't be smaller than Action Set Date",
    setupNotificationExpiresError:
      'Expire date should not be past date/time, your notification created without expiration date.',
    systemLabelEditWarning: 'This label is created by the system, it cannot be changed.',
    attachmentPathNotFound: 'The attachment could not be found',
  },

  success: {
    updated: 'Successfully updated',
    edited: 'Successfully edited',
    added: 'Successfully added',
    deleted: 'Successfully deleted',
    archived: 'Successfully archived',
    approved: 'Successfully approved',
    declined: 'Successfully declined',
    saved: 'Successfully saved',
    started: 'Successfully started',
    stoped: 'Successfully stopped',
    restarted: 'Successfully restarted',
    uploaded: 'Successfully uploaded',
    registered: 'Successfully registered',
    created: 'Successfully created',
    invitationSent: 'Invitation successfully sent',
    typeformIntegration: 'Your answers have been successfully registered.',
    report: 'Report is successfully generated',
    lostQRCode: 'Your QR-Code was just sent to your e-mail.',
    notificationFeedbackSuccess: 'Your feedback has been received successfully.',
    ruleEditExpiredDateSuccess:
      'The changes are successfully saved. However, the expire date of this notification has already passed.',
    ruleEditInactiveFeedbackSuccess:
      'The changes are successfully saved. However, the status of this notification is inactive.',
    saqSended: 'SAQ sent successfully',
    cocSended: 'CoC sent successfully ',
    download: 'File downloaded successfully',
  },

  // NETWORK PAGE
  networkPage: {
    valueChain: {
      tabName: 'My Value Chain',
      tableView: {
        tabName: 'Table View',
        generateRiskReport: 'Generate Risk Report',
        addSupplier: 'Add Supplier',
        generateReport: 'Generate Report',
      },
      networkView: {
        tabName: 'Network View',
      },

      filtered: 'Filtered',
      monitored: 'Monitored',
      totalSupplier: 'Total Supplier',
    },
    saq: {
      tabName: 'My SAQ',

      saqTemplates: {
        tabName: 'SAQ Templates',
      },
      emailTemplates: {
        tabName: 'Email Templates',
        createMailTemplate: 'Create Mail Template',
        createEmailTemplate: 'Create E-Mail Template',
      },
      sendSaq: {
        tabName: 'Send SAQ',
      },
      monitorSaq: {
        tabName: 'Monitor SAQ',
      },
      actionCenter: {
        tabName: 'Action Center',
      },
    },
    coc: {
      tabName: 'My COC',
      cocTemplates: {
        tabName: 'CoC Templates',
        uploadCoc: 'Upload CoC',
      },
      emailTemplates: {
        tabName: 'Email Templates',
        createMailTemplate: 'Create Mail Template',
      },
      sendCoc: {
        tabName: 'Send CoC',
      },
      monitorCoc: {
        tabName: 'Monitor CoC',
      },
    },
    media: {
      tabName: 'My Media',
      source: 'Source',
      company: 'Company',
      location: 'Location',
      industry: 'Industry',
      mediaInsight: 'Media Insight',
      mediaType: 'Media Type',
      date: 'Date',
    },
    locations: {
      tabName: 'My Locations',
      tableView: {
        tabName: 'Table View',
      },
      mapView: {
        tabName: 'Map View',
      },
    },
    industries: {
      tabName: 'My Industries',
    },
    details: {
      tabName: 'My Details',
    },
  },

  industries: {
    pageName: 'All Industries',
    tableColumns: {
      GICSIndustryCode: 'GICS Industry Code',
      GICSIndustryName: 'GICS Industry',
      GICSIndustryGroup: 'GICS Industry Group',
      GICSSector: 'GICS Sector',
    },
    tableColumns2: {
      parents: {
        GICSIndustrySector: 'GICS Industry Sector',
        e1: '',
        GICSIndustryGroup: 'GICS Industry Group',
        e2: '',
        GICSIndustry: 'GICS Industry',
      },
      children: {
        GICSSectorCode: 'Code',
        GICSSector: 'Name',
        GICSIndustryGroupCode: 'Code',
        GICSIndustryGroup: 'Name',
        GICSIndustryCode: 'Code',
        GICSIndustryName: 'Name',
      },
    },
    columnTooltips: {
      source: 'Source: ',
      sourceLink: 'https://www.msci.com/our-solutions/indexes/gics',
      GICSIndustrySector: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS sector list consists 11 sectors',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
      },
      GICSIndustryGroup: {
        para1:
          'Global Industry Classification Standard (GICS) - The GICS industry group list consists 24 industry groups',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
      },
      GICSIndustry: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS industry list consists 69 industries.',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3:
          'The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.',
      },
    },
    columnTooltipsTab: {
      source: 'Source: ',
      sourceLink: 'https://www.msci.com/our-solutions/indexes/gics',
      GICSIndustrySector: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS sector list consists 11 sectors',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3: 'The list below shows the GICS sector based classification of your suppliers.',
      },
      GICSIndustryGroup: {
        para1:
          'Global Industry Classification Standard (GICS) - The GICS industry group list consists 24 industry groups',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3: 'The list below shows the GICS sector based classification of your suppliers.',
      },
      GICSIndustry: {
        para1: 'Global Industry Classification Standard (GICS) - The GICS industry list consists 69 industries.',
        para2:
          "GICS is used as a basis for S&P (Standard and Poor's) and MSCI financial market indexes in which each company is assigned to a sub-industry, and to an industry, industry group, and sector, by its principal business activity. S&P and MSCI use these categorization for all major public companies.",
        para3:
          'The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.',
        para4:
          'The list below shows the GICS industry based classification of your suppliers. The number in () after each industry shows how many of your suppliers are having its principal business activity within the respective GICS Industry. The color coding refers to risks associated with the particular GICS industry, where color red refers to high, color yellow refers to moderate and color green refer to low risks respectively. These risks are related to modern slavery, child labour, human rights and workers rights.',
      },
    },
    industryEditPage: {
      tabs: {
        data: 'Data',
        network: 'Network',
      },
    },
  },

  resourcesPage: {
    pageName: 'Resources',
    tableColumns: {
      ResourceName: 'Resource Name',
      Type: 'Type',
      Size: 'Size',
      RequiredSubscriptionType: 'Required Subscription Type',
      ResourceCategory: 'Resource Category',
      Price: 'Price',
    },
  },

  companySettingsPage: {
    pageName: 'Company Settings',
    tableContent: {
      operationNames: {
        changeRootAdmin: 'Change Root Admin',
        freezeCompany: 'Freeze Company',
        deleteCompany: 'Delete Company',
        changeSubscriptionType: 'Change Subscription Type',
        change2FASettings: 'Change 2FA Settings',
      },
      descriptions: {
        changeRootAdmin:
          'The root admin of a company is able to change 2FA settings of the company. You can give the authority to any user.',
        freezeCompany:
          'Freezing a company blocks the users of this company to login SC3 system. You can undo this process.',
        deleteCompany:
          'Deleting a company deletes the company and its users from SC3 system. You cannot undo this process.',
        changeSubscriptionType:
          'The subscription type affects the company limitation such as number of users, number of notification rules, etc. You can change the subscription type to any level.',
        change2FASettings:
          '	If you change the 2FA, all end-users of this company will be affected from this action. And they need to authorize with this selection.',
      },
      actionTooltips: {
        changeRootAdmin: 'Change Root Admin',
        freezeCompany: 'Freeze',
        deleteCompany: 'Delete',
        changeSubscriptionType: 'Change Subscription Type',
        change2FASettings: 'Change 2FA Settings',
      },
    },
    modalContent: {
      change2FASettings: 'Two Factor Authentication Settings',
      freezeContent:
        'The company will be {{content}}. Its users will no longer be able to use the system anymore. Are you sure?',
      deleteContent: `The company and its users will be deleted permanently. This process cannot be undone. Are you sure?`,
      subscriptionTypeSentence: 'Select a subscription type.',
      subscriptionTypeLabel: 'Subscription Type',
      enable2FA: 'Enable Two Factor Authentication',
      authentication: 'Authentication Type',
    },
  },

  // PERMISSIONS
  permissions: {
    home: 'Home',
    network: 'Network',
    valueChain: 'Value Chain',
    tableView: 'Table View',
    networkView: 'Network View',
    saq: 'SAQ',
    saqTemplates: 'SAQ Templates',
    emailTemplates: 'Email Templates',
    sendSaq: 'Send SAQ',
    monitorSaq: 'Monitor SAQ',
    actionCenter: 'Action Center',
    coc: 'CoC',
    cocTemplates: 'CoC Templates',
    sendCoc: 'Send CoC',
    monitorCoc: 'Monitor CoC',
    media: 'Media',
    locations: 'Locations',
    industries: 'Industries',
    details: 'Details',
    notifications: 'Notifications',
    topic: 'Topic',
    location: 'Location',
    industry: 'Industry',
    reports: 'Reports',
    resources: 'Resources',
    customerAndUserInvitations: 'Customer & User Invitations',
    settings: 'Settings',
    theme: 'Theme',
    userSettings: 'User Settings',
    securitySettings: 'Security Settings',
    notificationsSettings: 'Notifications Settings',
    notificationRules: 'Notifications Rules',
    notificationSettings: 'Notification Settings',
    saqSettings: 'SAQ Settings',
    cocSettings: 'CoC Settings',
    monitorSupplierSettings: 'Monitor Supplier Settings',
    controlPanel: 'Control Panel',
    authenticationSettings: 'Authentication Settings',
    students: 'Student',
    customerAndUser: 'Customer And User',
    administration: 'Administration',
    profile: 'Profile',
    portal: 'Portal',
    importData: 'Import Data',
    exportData: 'Export Data',
    helpAndSupport: 'Help And Support',
    tabs: 'Help And Support',
    userGuide: 'User Guide',
    faq: 'FAQ',
    ccr: 'Consfiguration Change Request',
    tt: 'Trouble Ticket',
    ruleSettings: 'Rule Settings',
    interests: 'Interests',
    fileUpload: 'File Upload',
    nSearch: 'nSearch',
  },
  map: {
    low: 'Low Risk',
    high: 'High Risk',
    selectCountry: 'Select a country to show ',
    information: 'information.',
    enterpriseMapContent: {
      selectCountry1: 'Colored countries on the map represent the locations of the supplier companies of ',
      selectCountry2: 'Select a country to see suppliers.',
      supplyChainMap: 'Supply Chain Map',
      country: 'Country',
      supplierCompanies: 'Supplier companies from ',
    },
  },
  importData: {
    downloadTemplate: 'Download Template',
    explanationFirstLine: 'Companies and Suppliers can be added with an excel file.',
    explanationSecondLine: 'Use the Download Template button to access provided excel file.',
    justSelectFile: 'Select File',
  },
};
