import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import styles from 'src/app/pages/NotificationsPage/NotificationsPage.module.scss';
import { useAppDispatch } from 'src/app/redux/hook';
import { useGetLabelsQuery } from 'src/app/redux/labels/labelSlice';
import { addLabelToNotification } from 'src/app/redux/notifications/notificationsSlice';
import { NotificationResultType } from 'src/app/services/notifications/notifications-service.type';
import { LabelType } from 'src/types/labels.type';

interface IEditLabelProps {
  notification: NotificationResultType;
}
const EditLabel: React.FC<IEditLabelProps> = ({ notification }) => {
  const dispatch = useAppDispatch();
  const { data: labelList } = useGetLabelsQuery();
  const [checkedLabels, setCheckedLabels] = useState<LabelType[]>(notification.label);
  const [isAfterFirstCheck, setIsAfterFirstCheck] = useState<boolean>(false);

  useEffect(() => {
    if (isAfterFirstCheck) dispatch(addLabelToNotification({ id: notification.id, labels: checkedLabels }));
  }, [checkedLabels]);

  const checkLabel = (cond: boolean, label: LabelType) => {
    if (cond) setCheckedLabels((prev) => [...prev, label]);
    else setCheckedLabels((prev) => prev.filter((lbl) => lbl.id !== label.id));
    if (!isAfterFirstCheck) setIsAfterFirstCheck(true);
  };

  if (!labelList) return null;
  return (
    <div className={styles.labelsContainer}>
      {labelList.rows.map((label) => {
        return (
          <div className={styles.labelRow} key={label.id}>
            <Checkbox
              checked={checkedLabels.length > 0 && checkedLabels.some((obj) => obj.id === label.id)}
              value={checkedLabels}
              disabled={!label.isEditable}
              onChange={(e) => {
                checkLabel(e.target.checked, label);
              }}
            />
            <div
              className={styles.label}
              style={{
                backgroundColor: label.backgroundColor,
                cursor: !label.isEditable ? 'not-allowed' : undefined,
              }}
              onClick={() => {
                if (!label.isEditable) return;
                checkLabel(!checkedLabels.some((obj) => obj.id === label.id), label);
              }}
            >
              {label.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EditLabel;
