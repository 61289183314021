import React from 'react';

import { iconType } from 'src/types';

const PureCancelIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex' }}
    >
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        width="19.592"
        height="19.592"
        viewBox="0 0 19.592 19.592"
      >
        <path
          id="Path_37291"
          data-name="Path 37291"
          d="M13.114,9.8l5.791-5.791A2.346,2.346,0,0,0,15.588.688L9.8,6.48,4,.688A2.346,2.346,0,0,0,.688,4.006L6.478,9.8.688,15.588A2.346,2.346,0,0,0,4,18.905L9.8,13.114l5.791,5.791a2.346,2.346,0,0,0,3.317-3.318Z"
          transform="translate(0 -0.001)"
          fill={color || '#fff'}
        />
      </svg>
    </div>
  );
};

PureCancelIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default PureCancelIcon;
