import styled from 'styled-components';

export const Header = styled.header`
  header {
    // We give bg color to the header here...
    background-color: var(--header_bg) !important;

    .headerTheme {
      //border-bottom: 1px solid ${({ theme }) => theme.colors.border};
      #userMenu {
        button:hover {
          color: ${({ theme }) => theme.colors.quoteBgc} !important;
        }
        .headerDropdown {
          //border: 1px solid red !important;
          color: red;
          :hover {
            color: red !important;
          }
          button.ant-btn-compact-last-item {
            span {
              svg {
                path {
                  fill: 'red';
                }
              }
            }
          }
        }
        #notificationIcon {
          border-right: 1px solid ${({ theme }) => theme.colors.secondaryText};
        }

        #searchButton {
          border-top: 1px solid ${({ theme }) => theme.colors.secondaryText};
          border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryText};
          padding: 5px 0 5px 0;
          // span {
          //   //search input box ile iconu arasındaki çizgi
          //   span {
          //     :nth-child(2) {
          //       border-right: 1px solid ${({ theme }) => theme.colors.quoteBgc} !important;
          //     }
          //   }
          // }
          .ant-input-wrapper {
            border-left: 1px solid ${({ theme }) => theme.colors.quoteBgc};
          }
        }
      }
    }
  }
`;
