import { FormInstance, UploadFile } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { isDefined } from 'class-validator';
import { MutationType } from 'src/enums/operation.enum';

export const submitForm = (form: FormInstance, modal?: { destroy: () => void }) => {
  form
    .validateFields()
    .then(() => {
      form.submit();
      if (modal) modal.destroy();
    })
    .catch(() => {});
};

export const convertToSelectOptionsWithId = <T extends { id: string; name?: string }>(
  data: T[],
  labelField?: keyof T,
  title?: keyof T,
): BaseOptionType[] => {
  return data.map((obj) => {
    return {
      value: obj.id,
      label: labelField ? obj[labelField] : obj.name,
      title: title ? obj[title]?.toString() : undefined,
    };
  });
};

type DeleteRecordConfigType = {
  modal: { destroy: () => void };
  recordId: string;
  deleteFn: (recordId: string) => void;
};

export const deleteRecordFromTable = (config: DeleteRecordConfigType) => {
  config.deleteFn(config.recordId);
  config.modal.destroy();
};

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const getFormData = <T extends { [k: string]: any }>(
  object: T,
  fileFields?: (keyof T)[],
  mutationType?: MutationType,
) => {
  return Object.keys(object).reduce((formData, key) => {
    if (fileFields && fileFields.includes(key) && !!object[key]) {
      if (Array.isArray(object[key])) {
        object[key]?.forEach((file: UploadFile) => {
          if (file.originFileObj) formData.append(key, file?.originFileObj);
        });
      } else {
        formData.append(key, object[key]?.originFileObj);
      }
    } else if (Array.isArray(object[key])) {
      object[key]?.forEach((value: any) => {
        formData.append(key, value);
      });
    } else if (mutationType === MutationType.CREATE) {
      if (isDefined(object[key]) && object[key] !== '') {
        formData.append(key, object[key]);
      }
    } else if (isDefined(object[key])) {
      formData.append(key, object[key]);
    }
    return formData;
  }, new FormData());
};
