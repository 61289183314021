import { apiSlice } from 'src/app/redux/api/apiSlice';
import { surferApiSlice } from 'src/app/redux/api/surferApiSlice';
import { BlockResultType, NSearchResultType } from 'src/app/services/surfer-service/surfer.service.type';
import { urlsConfig } from 'src/configs';
import { NSearchRelevantNews, NSearchType } from 'src/types/query.type';

export const nSearchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getNSearchLogs: builder.query<NSearchLoggerGetAllType, void>({
    //   query: () => ({
    //     url: urlsConfig.nSearchLogger.nSearch,
    //   }),
    // }),

    exportNSearchExcell: builder.mutation<ArrayBuffer, { ids?: string[] }>({
      query: (data) => ({
        url: urlsConfig.exports.nsearch,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const nSearchSurferApiSlice = surferApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNSearch: builder.query<NSearchResultType, NSearchType>({
      query: (data) => ({
        method: 'POST',
        body: data,
        url: urlsConfig.surfer.mediaInsights,
      }),
      providesTags: [{ type: 'NSearch', id: 'LIST' }],
    }),
    getNSearchRelevantNews: builder.query<BlockResultType[], NSearchRelevantNews>({
      query: (data) => ({
        method: 'POST',
        body: data,
        url: urlsConfig.surfer.relevantNews,
      }),
      providesTags: [{ type: 'NSearch', id: 'LIST' }],
    }),
  }),
});

export const { useExportNSearchExcellMutation } = nSearchApiSlice;
export const { useGetNSearchQuery, useLazyGetNSearchQuery, useGetNSearchRelevantNewsQuery } = nSearchSurferApiSlice;
