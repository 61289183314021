import React from 'react';
import { iconType } from 'src/types';

const ModernSlaveryIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="50" height="34" viewBox="0 0 50 34">
        <path
          id="Rectangle_14946"
          data-name="Rectangle 14946"
          d="M5,0H50a0,0,0,0,1,0,0V34a0,0,0,0,1,0,0H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
          transform="translate(50 34) rotate(180)"
          fill={circleColor}
        />
        <g id="prison_2_" data-name="prison (2)" transform="translate(7 4)">
          <path
            id="Path_37330"
            data-name="Path 37330"
            d="M12.68,19.412h.349v5.263a.745.745,0,0,0,.745.745h2.979a.745.745,0,0,0,.745-.745V19.412h.745a2.233,2.233,0,0,0,1.664-3.724,2.23,2.23,0,0,0,0-2.979,2.23,2.23,0,0,0,0-2.979,2.233,2.233,0,0,0-1.664-3.724H17.5V.745A.745.745,0,0,0,16.753,0H13.774a.745.745,0,0,0-.745.745V6.007H12.11A2.238,2.238,0,0,0,9.894,7.964l-.908,7.263A3.725,3.725,0,0,0,12.68,19.412Zm3.328,4.518H14.519V19.412h1.489ZM14.519,1.489h1.489V6.007H14.519ZM10.464,15.412l.908-7.262A.746.746,0,0,1,12.11,7.5h6.132a.745.745,0,0,1,0,1.489H15.264a.745.745,0,0,0,0,1.489h2.979a.745.745,0,1,1,0,1.489H15.264a.745.745,0,0,0,0,1.489h2.979a.745.745,0,1,1,0,1.489H15.264a.745.745,0,0,0,0,1.489h2.979a.745.745,0,1,1,0,1.489H12.68A2.235,2.235,0,0,1,10.464,15.412Z"
            transform="translate(0 0)"
            fill={color}
          />
          <path
            id="Path_37331"
            data-name="Path 37331"
            d="M273.233,19.412h.745v5.263a.745.745,0,0,0,.745.745H277.7a.745.745,0,0,0,.745-.745V19.412h.349a3.725,3.725,0,0,0,3.695-4.185l-.908-7.262a2.238,2.238,0,0,0-2.217-1.957h-.919V.745A.745.745,0,0,0,277.7,0h-2.979a.745.745,0,0,0-.745.745V6.007h-.745a2.233,2.233,0,0,0-1.664,3.724,2.23,2.23,0,0,0,0,2.979,2.23,2.23,0,0,0,0,2.979,2.233,2.233,0,0,0,1.664,3.724Zm3.724,4.518h-1.489V19.412h1.489ZM275.467,1.489h1.489V6.007h-1.489Zm.745,13.455h-2.979a.745.745,0,0,1,0-1.489h2.979a.745.745,0,1,0,0-1.489h-2.979a.745.745,0,0,1,0-1.489h2.979a.745.745,0,1,0,0-1.489h-2.979a.745.745,0,0,1,0-1.489h6.132a.746.746,0,0,1,.739.652l.908,7.262a2.235,2.235,0,0,1-2.217,2.511h-5.562a.745.745,0,0,1,0-1.489h2.979a.745.745,0,1,0,0-1.489Z"
            transform="translate(-249.033)"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
};

ModernSlaveryIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default ModernSlaveryIcon;
