import { Badge, Form, message, Tooltip } from 'antd';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import ActionColumnComponent from 'src/app/components/ActionColumnComponent/ActionColumnComponent';
import showModal from 'src/app/components/ShowModalComponent/ShowModal';
import TableComponent, { CustomColumnsType } from 'src/app/components/TableComponent/TableComponent';
import CustomerAndUserEditForm from 'src/app/forms/CustomerAndUserEditForm/CustomerAndUserEditForm';
import { formHelper } from 'src/app/helpers';
import { checkPermission } from 'src/app/helpers/permission.helper';
import { compareDates } from 'src/app/helpers/time.helper';
import usePermissions from 'src/app/hooks/usePermissions';
import { CustomerAndUserTableDataType } from 'src/app/pages/PortalPage/CustomerAndUserPage/customer-and-user-page.config';
import UserDetailsContent from 'src/app/pages/PortalPage/CustomerAndUserPage/UserDetailsModalContent/UserDetailsModalContent';
import { useAppSelector } from 'src/app/redux/hook';
import { useDeleteUserMutation, useGetUsersQuery, useUpdateUserMutation } from 'src/app/redux/user/userSlice';
import { CustomerResultType } from 'src/app/services/customer/customer-service.type';

interface IUserTable {
  dataSource: CustomerAndUserTableDataType[];
  customer?: CustomerResultType;

  permissions: {
    editPermission: any;
    freezePermission: any;
    deletePermission: any;
  };
}

const UserTable: React.FC<IUserTable> = ({ dataSource, customer, permissions }) => {
  const [editUserForm] = Form.useForm();
  const { id: loggedInUserId } = useAppSelector((state) => state.user);
  const { permissions: userPermissions } = usePermissions();

  const { data: usersList } = useGetUsersQuery(undefined, { skip: !!customer });

  const [deleteUser] = useDeleteUserMutation();
  const [updateUser] = useUpdateUserMutation();

  const freezeComponentOptions = (record: CustomerAndUserTableDataType) => {
    const freezeModal = record.isFreezed ? 'unfreezeModal' : 'freezeModal';
    return {
      onClick: (event: any) =>
        showModal({
          event,
          content: t(`customerAndUserPage.modals.${freezeModal}.content`, {
            userName: record.username,
          }),
          onSave: (modal) => {
            updateUser({ id: record.id, isFreezed: !record.isFreezed })
              .unwrap()
              .then(() => message.success(`User is successfully ${record.isFreezed ? 'unfreezed' : 'freezed'}`))
              .catch((e) => message.error(e.data.message));
            modal.destroy();
          },
          title: t(`customerAndUserPage.modals.${freezeModal}.title`),
          okText: t(`customerAndUserPage.modals.${freezeModal}.okText`),
        }),
      title: record.isFreezed ? t('global.unfreeze') : t('global.freeze'),
    };
  };

  const columns: CustomColumnsType<CustomerAndUserTableDataType> = [
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      render: (_, record: CustomerAndUserTableDataType) => {
        return (
          <div>
            <Tooltip
              title={
                <span>{record.isOnline ? t('customerAndUserPage.online') : t('customerAndUserPage.offline')}</span>
              }
            >
              <Badge color={record.isOnline ? 'green' : 'red'} style={{ paddingRight: 10 }} />
            </Tooltip>
            {record.username}
          </div>
        );
      },
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      key: 'email',
    },
    {
      title: 'Creation date',
      dataIndex: 'creationDate',
      key: 'creationDate',
      sorter: (a, b) => compareDates(a.creationDate, b.creationDate),
    },
    {
      title: 'Last Login Date',
      dataIndex: 'lastLoginDate',
      key: 'lastLoginDate',
      sorter: (a, b) => compareDates(a.lastLoginDate, b.lastLoginDate),
    },
    {
      title: 'User Role',
      dataIndex: 'userRole',
      key: 'userRole',
      sorter: (a, b) => a.userRole.length - b.userRole.length,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record: CustomerAndUserTableDataType) => {
        // const foundCustomer = customerList?.rows?.find((row) => row.id === record.customerId);
        // if (!foundCustomer) return null;
        // const foundUser = usersList?.rows?.find((row) => row.id === record.id);
        // if (!foundUser) return null;
        let foundUser;

        if (customer) {
          foundUser = customer?.users?.find((row) => row.id === record.id);
        } else {
          foundUser = usersList?.rows?.find((row) => row.id === record.id);
        }
        if (!foundUser) return null;

        const isLoggedInUser = record.id === loggedInUserId;

        const isEditable = !!isLoggedInUser || !checkPermission(userPermissions, permissions.editPermission);

        const isDeletable = !!isLoggedInUser || !checkPermission(userPermissions, permissions.deletePermission);

        const isFreezable = !!isLoggedInUser || !checkPermission(userPermissions, permissions.freezePermission);

        return (
          <ActionColumnComponent
            showComponent={{
              onClick: () => {
                showModal({
                  content: (
                    <UserDetailsContent
                      data={foundUser}
                      customerName={customer ? customer.enterpriseIdentified[0].company.name : undefined}
                    />
                  ),
                  title: t('customerAndUserPage.userDetails'),
                  disableButtons: false,
                  hasFooter: false,
                  size: 'large',
                });
              },
              title: t('global.show'),
            }}
            editComponent={{
              onClick: (event) => {
                editUserForm.setFieldsValue({
                  fullname: foundUser.fullname,
                  userRole: foundUser.userRole?.map((role) => role.id),
                });
                showModal({
                  size: 'large',
                  event,
                  title: t('tables.userSettings.tooltips.editUser'),
                  content: <CustomerAndUserEditForm form={editUserForm} recordId={record.id} />,
                  onSave: (modal) => formHelper.submitForm(editUserForm, modal),
                });
              },
              title: t('global.edit'),
              disabled: isEditable,
            }}
            freezeComponent={
              record.isFreezed ? { ...freezeComponentOptions(record), disabled: isFreezable } : undefined
            }
            unFreezeComponent={
              !record.isFreezed ? { ...freezeComponentOptions(record), disabled: isFreezable } : undefined
            }
            deleteComponent={{
              onClick: (event) =>
                showModal({
                  event,
                  content: (
                    <Trans i18nKey="customerAndUserPage.modals.deleteModal.content">
                      {{ firstname: record.username }}
                    </Trans>
                  ),
                  title: t('customerAndUserPage.modals.deleteModal.title'),
                  okText: t('global.delete'),
                  onSave: (modal) =>
                    formHelper.deleteRecordFromTable({
                      deleteFn: deleteUser,
                      modal,
                      recordId: record.id,
                    }),
                }),
              title: t('global.delete'),
              disabled: isDeletable,
            }}
          />
        );
      },
    },
  ];
  return <TableComponent columns={columns} dataSource={dataSource} />;
};

export default UserTable;
