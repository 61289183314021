import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Upload,
  UploadFile,
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { isDefined } from 'class-validator';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import FormComponent from 'src/app/components/FormComponent/FormComponent';
import styles from 'src/app/forms/FileUploadForm/FileUploadForm.module.scss';
import { IFormProps } from 'src/app/forms/form.config';
import { FileUploadDataType } from 'src/app/redux/fileUpload/fileUpload-slice.type';
import { useUploadFileMutation } from 'src/app/redux/fileUpload/fileUploadSlice';
import { useAppSelector } from 'src/app/redux/hook';
import { useGetFunctionalAreasRecognizedQuery } from 'src/app/redux/interests/functional-areas/functionalAreaRecognizedSlice';
import { useGetLocationsIdentifiedQuery } from 'src/app/redux/locations/locationsIdentifiedSlice';
import { useGetSensorsIdentifiedQuery } from 'src/app/redux/sensors/sensorIdentifiedSlice';
import FileUploadIcon from 'src/icons/FileUploadIcon';

interface FormOptions {
  value: string;
  label: string;
}

const FileUploadForm: React.FC<IFormProps> = ({ form }) => {
  const [locationOptions, setLocationOptions] = useState<FormOptions[]>([]);
  const [sensorOptions, setSensorOptions] = useState<FormOptions[]>([]);
  const [functionalAreaOptions, setFunctionalAreasOptions] = useState<FormOptions[]>([]);
  const [time, setTime] = useState<string>('');
  const { theme } = useAppSelector((state) => state.configContent);
  const location = Form.useWatch('locations', form);
  const lat = Form.useWatch('lat', form);
  const lng = Form.useWatch('lng', form);
  const uploadedFiles = Form.useWatch('file', form);
  const locations = useGetLocationsIdentifiedQuery({ isInterested: true });
  const sensors = useGetSensorsIdentifiedQuery();
  const functionalAreas = useGetFunctionalAreasRecognizedQuery();
  const [uploadFile] = useUploadFileMutation();

  const readFileAsBase64 = (file: UploadFile): Promise<string> =>
    new Promise((resolve, _reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const b64 = reader.result as string;
        resolve(b64);
      };
      reader.readAsDataURL(file.originFileObj as Blob);
    });

  const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    let formattedDate;
    if (typeof dateString === 'string') {
      formattedDate = dateString.split(' ');
      const secondsFromFormattedDate = formattedDate.pop();
      const newSeconds = `${secondsFromFormattedDate}:00`;
      formattedDate.push(newSeconds);
      formattedDate = formattedDate.join('T');
    }
    if (formattedDate !== undefined) {
      setTime(formattedDate);
    }
  };

  const onFinish = async (values: any) => {
    const fileList: FileUploadDataType = [];

    for (let i = 0; i < values.file.length; i += 1) {
      const file = values.file[i];
      const base64File = await readFileAsBase64(file);
      fileList.push({
        data: base64File.split(',')[1],
        filename: file.originFileObj.name,
      });
    }
    const finalValues = {
      ...(values.lat && { lat: values.lat }),
      ...(values.lng && { lng: values.lng }),
      ...(values.locations && { locations: values.locations }),
      ...(values.sensors && { sensors: values.sensors }),
      ...(values.time && { time }),
      ...(values.title && { title: values.title }),
      ...(values.functionalAreas && values?.functionalAreas?.length > 0 && { functionalAreas: values.functionalAreas }),
    };
    uploadFile({ fileList, ...finalValues });
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const getLocationsList = async () => {
    if (locations.data) {
      const options = locations.data.rows.map((row) => ({
        value: row.id,
        label: `${row.name}`,
      }));

      setLocationOptions(options);
    }
  };

  const getSensorsList = async () => {
    if (sensors.data) {
      const options = sensors.data.rows.map((row) => ({
        value: row.id,
        label: `${row.name}`,
      }));
      setSensorOptions(options);
    }
  };

  const getFunctionalAreasList = async () => {
    if (functionalAreas.data) {
      const options = functionalAreas.data.rows.map((row) => ({
        value: row.id,
        label: `${row.name}`,
      }));
      setFunctionalAreasOptions(options);
    }
  };
  const generateSuccessfullyUploadedMessage = () => {
    if (isDefined(uploadedFiles) && uploadedFiles.length > 0) {
      const file = uploadedFiles[0].name;
      return (
        <div className={styles.uploaded}>
          <span>{file.length > 15 ? `${file.slice(0, 20)}...` : file} is successfully loaded.</span>
          <AiOutlineCheck size={21} />
        </div>
      );
    }
    return '';
  };
  // TODO: bu dataları useInterestFilters'taki gibi sadeleştir.
  useEffect(() => {
    getLocationsList();
    getSensorsList();
    getFunctionalAreasList();
  }, [locations, sensors, functionalAreas]);

  useEffect(() => {
    const locationIdentified = locations.data?.rows.find((row) => row.id === location);
    if (locationIdentified) {
      const locationLat = locationIdentified?.lat;
      const locationLng = locationIdentified?.lng;

      form.setFieldValue('lat', locationLat);
      form.setFieldValue('lng', locationLng);
    }
  }, [location]);

  useEffect(() => {
    const locationIdentified = locations.data?.rows.find((row) => row.id === location);
    const locationLat = locationIdentified?.lat;
    const locationLng = locationIdentified?.lng;
    if (locationIdentified && (locationLat !== lat || locationLng !== lng)) {
      form.resetFields(['locations']);
    }
  }, [lat, lng]);

  return (
    <FormComponent options={{ form, onFinish }}>
      <Row>
        <Col span={12} style={{ display: 'flex' }}>
          <Form.Item
            name="file"
            getValueFromEvent={normFile}
            style={{ marginBottom: '12px', justifyContent: 'right !important', display: 'block' }}
            className="borderless custom-error"
            rules={[{ required: true }]}
          >
            <Dragger
              openFileDialogOnClick={false}
              className={styles.fileDragger}
              style={{ display: 'block', width: '800px' }}
              beforeUpload={() => false}
              maxCount={1}
              itemRender={(_node, _file, _fileList, _actions) => undefined}
            >
              <div className={styles.fileUploadContainer}>
                <FileUploadIcon style={{ height: '60px', width: '60px' }} color="white" />
                <span style={{ color: 'white' }}>Drag your file here</span>
                {/* <div className={styles.uploadButtonsContainer}> */}
                <Row style={{ width: '100%', alignContent: 'space-evenly' }}>
                  <Col span={12}>
                    <Form.Item
                      name="file"
                      getValueFromEvent={normFile}
                      style={{ marginBottom: '12px', justifyContent: 'right !important' }}
                      className="borderless"
                    >
                      <Upload
                        beforeUpload={() => false}
                        style={{ padding: 0 }}
                        maxCount={1}
                        itemRender={(_node, _file, _fileList, _actions) => undefined}
                      >
                        <Button
                          style={{ backgroundColor: theme.colors.quoteBgc, maxWidth: 'unset', marginLeft: '4em' }}
                        >
                          {t('forms.fileUploadForm.fields.selectFile')}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="file" getValueFromEvent={normFile} className="borderless">
                      <Upload
                        maxCount={1}
                        beforeUpload={() => false}
                        directory
                        itemRender={(_node, _file, _fileList, _actions) => undefined}
                      >
                        <Button style={{ backgroundColor: theme.colors.quoteBgc }} className={styles.fileUploadBtn}>
                          {t('forms.fileUploadForm.fields.selectFolder')}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                {/* </div> */}
              </div>
            </Dragger>
          </Form.Item>
          {generateSuccessfullyUploadedMessage()}
        </Col>
        <Col span={12}>
          <Form.Item label={t('forms.fileUploadForm.fields.locations')} name="locations">
            <Select variant="borderless" options={locationOptions} allowClear showSearch />
          </Form.Item>
          <Form.Item label={t('forms.fileUploadForm.fields.title')} name="title">
            <Input variant="borderless" />
          </Form.Item>
          <Form.Item label={t('forms.fileUploadForm.fields.time')} htmlFor="time" name="time">
            <DatePicker showTime variant="borderless" id="time" format="YYYY-MM-DD HH:mm" onChange={onDateChange} />
          </Form.Item>
          <Form.Item label={t('forms.fileUploadForm.fields.latitude')} name="lat">
            <InputNumber variant="borderless" />
          </Form.Item>
          <Form.Item label={t('forms.fileUploadForm.fields.longtitude')} name="lng">
            <InputNumber variant="borderless" />
          </Form.Item>
          <Form.Item label={t('forms.fileUploadForm.fields.sensors')} name="sensors">
            <Select variant="borderless" options={sensorOptions} allowClear showSearch />
          </Form.Item>
          <Form.Item label={t('forms.fileUploadForm.fields.functionalAreas')} name="functionalAreas">
            <Select mode="multiple" variant="borderless" options={functionalAreaOptions} />
          </Form.Item>
        </Col>
      </Row>
    </FormComponent>
  );
};

export default FileUploadForm;
