import React from 'react';

import { iconType } from 'src/types';

const UploadIcon: React.FC<iconType.PropsType> = ({ style, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex' }}
    >
      <svg
        style={{ ...style }}
        id="Upload_v1"
        xmlns="http://www.w3.org/2000/svg"
        width="22.431"
        height="22.032"
        viewBox="0 0 29.431 22.032"
      >
        <path
          id="Path_37358"
          data-name="Path 37358"
          d="M34.646,40.28l-6.2,6.2h3.628v3.81h5.136v-3.81h3.631Z"
          transform="translate(-19.934 -28.254)"
          fill="#fff"
        />
        <path
          id="Path_37359"
          data-name="Path 37359"
          d="M394.8,265.5a9.107,9.107,0,0,0-15.911,0,6.774,6.774,0,1,0,.036,13.548h.036l2.389-2.389h-2.421a4.386,4.386,0,0,1-.982-8.659,4.273,4.273,0,0,1,.982-.116,4.354,4.354,0,0,1,1.442.248,6.724,6.724,0,0,1,12.955,0,4.355,4.355,0,0,1,1.442-.248,4.273,4.273,0,0,1,.982.116,4.386,4.386,0,0,1-.982,8.659h-2.421l2.389,2.389h.036A6.774,6.774,0,1,0,394.8,265.5Z"
          transform="translate(-372.13 -260.822)"
          fill="#fff"
        />
      </svg>
    </div>
  );
};

UploadIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '12px' },
  // color: 'var(--primary-color)',
  onClick: undefined,
  className: undefined,
};

export default UploadIcon;
