import { isDefined } from 'class-validator';
import { SystemConfigType } from 'src/configs/system.config';
import { InterestsTypes } from 'src/enums/interests.enum';
import { SensorCategoryType, SensorType } from 'src/enums/nginr-type.enum';
import { MediaType } from 'src/enums/surfer.enums';
import { LayoutViewType } from 'src/enums/user.enum';

export const isConfigDisabled = (cfg: string) => {
  if (!isDefined(cfg)) return true;
  if (cfg.startsWith('{{')) return true;
  if (cfg === 'true') return false;
  return true;
};

export const getInterestWhitelist = (config: SystemConfigType['interests']): InterestsTypes[] => {
  const result: InterestsTypes[] = [];
  Object.entries(config).forEach(([key, value]) => {
    if (value) result.push(InterestsTypes[key as keyof typeof InterestsTypes]);
  });
  return result;
};

export const getMediaTypesWhitelist = (config: SystemConfigType['mediaTypes']): MediaType[] => {
  const result: MediaType[] = [];
  Object.entries(config).forEach(([key, value]) => {
    if (value) result.push(MediaType[key as keyof typeof MediaType]);
  });
  return result;
};

export const getSensorTypesWhitelist = (config: SystemConfigType['sensorTypes']): SensorType[] => {
  const result: SensorType[] = [];
  Object.entries(config).forEach(([key, value]) => {
    if (value) result.push(SensorType[key as keyof typeof SensorType]);
  });
  return result;
};

export const getSensorCategoryTypesWhitelist = (
  config: SystemConfigType['sensorCategoryTypes'],
): SensorCategoryType[] => {
  const result: SensorCategoryType[] = [];
  Object.entries(config).forEach(([key, value]) => {
    if (value) result.push(SensorCategoryType[key as keyof typeof SensorCategoryType]);
  });
  return result;
};

export const getProductName = (layout: LayoutViewType) => {
  switch (layout) {
    case LayoutViewType.LAYOUT_1:
      return 'SC3';
    case LayoutViewType.LAYOUT_2:
      return 'SAMA';
    case LayoutViewType.LAYOUT_3:
      return 'NGINR';
    default:
      return '';
  }
};

export const getFontFamily = (layout: LayoutViewType) => {
  switch (layout) {
    case LayoutViewType.LAYOUT_1:
      return 'Urbanist';
    // case LayoutViewType.LAYOUT_2:
    //   return "Lusail";

    default:
      return 'Urbanist';
  }
};
