import { useNavigate } from 'react-router';
import { routeHelper } from 'src/app/helpers';
import { InterestPropertiesType } from 'src/app/pages/InterestsPage/interests-page.config';
import { InterestClassType, InterestComponents, InterestsTypes } from 'src/enums/interests.enum';
import { RoutesType } from 'src/types/routes.type';

const useInterestTableClick = () => {
  const navigate = useNavigate();

  const onInterestClick = (
    record: {
      id: string;
      name: string;
      type: InterestsTypes;
      classType: InterestClassType;
      properties?: InterestPropertiesType | null;
    },
    componentType: InterestComponents,
    route: RoutesType = '/Interests',
    state?: any,
  ) => {
    routeHelper.handleRoute(
      navigate,
      route,
      { id: record.id.toString() },
      {
        interest: {
          id: record.id,
          type: record.type,
          classType: record.classType,
          name: record.name,
          properties: record?.properties,
        },
        componentType,
        ...state,
      },
    );
  };

  return { onInterestClick };
};

export default useInterestTableClick;
