import React from 'react';
import { iconType } from 'src/types';

const ModernSlaveryPureIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        width="37.047"
        height="38.39"
        viewBox="0 0 37.047 38.39"
      >
        <g id="prison_2_" data-name="prison (2)" transform="translate(-8.956)">
          <path
            id="Path_37330"
            data-name="Path 37330"
            d="M14.581,29.317h.527v7.948a1.125,1.125,0,0,0,1.125,1.125h4.5a1.125,1.125,0,0,0,1.125-1.125V29.317h1.125a3.372,3.372,0,0,0,2.513-5.624,3.368,3.368,0,0,0,0-4.5,3.368,3.368,0,0,0,0-4.5,3.372,3.372,0,0,0-2.513-5.624H21.856V1.125A1.125,1.125,0,0,0,20.732,0h-4.5a1.125,1.125,0,0,0-1.125,1.125V9.073H13.72a3.379,3.379,0,0,0-3.348,2.956L9,23A5.626,5.626,0,0,0,14.581,29.317Zm5.026,6.823H17.357V29.317h2.249ZM17.357,2.249h2.249V9.073H17.357ZM11.233,23.275,12.6,12.307a1.126,1.126,0,0,1,1.116-.985h9.261a1.125,1.125,0,1,1,0,2.249h-4.5a1.125,1.125,0,0,0,0,2.249h4.5a1.125,1.125,0,1,1,0,2.249h-4.5a1.125,1.125,0,0,0,0,2.249h4.5a1.125,1.125,0,1,1,0,2.249h-4.5a1.125,1.125,0,0,0,0,2.249h4.5a1.125,1.125,0,1,1,0,2.249h-8.4A3.375,3.375,0,0,1,11.233,23.275Z"
            transform="translate(0 0)"
            fill={color}
          />
          <path
            id="Path_37331"
            data-name="Path 37331"
            d="M274.373,29.317H275.5v7.948a1.125,1.125,0,0,0,1.125,1.125h4.5a1.125,1.125,0,0,0,1.125-1.125V29.317h.527A5.626,5.626,0,0,0,288.354,23l-1.371-10.968a3.379,3.379,0,0,0-3.348-2.956h-1.388V1.125A1.125,1.125,0,0,0,281.121,0h-4.5A1.125,1.125,0,0,0,275.5,1.125V9.073h-1.125A3.372,3.372,0,0,0,271.86,14.7a3.368,3.368,0,0,0,0,4.5,3.368,3.368,0,0,0,0,4.5,3.372,3.372,0,0,0,2.513,5.624ZM280,36.141h-2.249V29.317H280ZM277.747,2.249H280V9.073h-2.249Zm1.125,20.32h-4.5a1.125,1.125,0,0,1,0-2.249h4.5a1.125,1.125,0,1,0,0-2.249h-4.5a1.125,1.125,0,0,1,0-2.249h4.5a1.125,1.125,0,1,0,0-2.249h-4.5a1.125,1.125,0,0,1,0-2.249h9.261a1.126,1.126,0,0,1,1.116.985l1.371,10.968a3.375,3.375,0,0,1-3.348,3.793h-8.4a1.125,1.125,0,0,1,0-2.249h4.5a1.125,1.125,0,1,0,0-2.249Z"
            transform="translate(-242.394)"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
};

ModernSlaveryPureIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default ModernSlaveryPureIcon;
