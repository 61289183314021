import { Layout } from 'antd';
import { t } from 'i18next';
import styles from 'src/app/components/FooterComponent/FooterComponent.module.scss';
import { LAYOUTVIEW } from 'src/configs/system.config';
import { LayoutViewType } from 'src/enums/user.enum';
import { Footer as FooterTheme } from 'src/theme/Footer.styled';

const { Footer } = Layout;

const FooterComponent = () => {
  return (
    <FooterTheme>
      <Footer className={styles.footerContainer}>
        <div className={styles.footerText}>
          {LAYOUTVIEW === LayoutViewType.LAYOUT_1 ? (
            t('sidebar.footer.SC3text')
          ) : (
            <>
              <div className={styles.footerTextLogo}>
                <p>{t('sidebar.footer.SAMAtextOne')}</p>
                <img src="/logo_nginr_metalik_amblem_64x64.png" alt="Sama Logo" className={styles.nginrLogo} />
                <p className={styles.andText}>and</p>
              </div>
              <p className={styles.samaSecondText}>{t('sidebar.footer.SAMAtextTwo')}</p>
            </>
          )}
        </div>
        {LAYOUTVIEW === LayoutViewType.LAYOUT_1 && (
          <div className={styles.footerButtons}>
            <div className={styles.footerButton}>
              <span className={styles.footerButtonText}>{t('sidebar.footer.credits')}</span>
            </div>
            <div className={styles.footerButton}>
              <span className={styles.footerButtonText}>{t('sidebar.footer.termsOfUse')}</span>
            </div>
          </div>
        )}
      </Footer>
    </FooterTheme>
  );
};

export default FooterComponent;
