import React from 'react';

import { iconType } from 'src/types';

const ReportIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
        <circle id="Ellipse_65" data-name="Ellipse 65" cx="15.5" cy="15.5" r="15.5" fill={circleColor} />
        <g id="Group_40467" data-name="Group 40467" transform="translate(5 -689)">
          <g id="Report_v2" transform="translate(-3.228 693)">
            <path
              id="Path_37279"
              data-name="Path 37279"
              d="M306.58,536.72l-4.06-4.06a.64.64,0,0,0-.43-.18h-9.22a1.36,1.36,0,0,0-1.36,1.36v2a8,8,0,0,0,0,15.91v2a1.37,1.37,0,0,0,1.36,1.36H305.4a1.37,1.37,0,0,0,1.35-1.36v-16.6A.63.63,0,0,0,306.58,536.72Zm-3.88-.3V534.6l1.95,1.94h-1.83a.12.12,0,0,1-.12-.12Zm-9.95,15.33a8,8,0,0,0,5.66-3,3.47,3.47,0,0,0-.26,1.33,3.57,3.57,0,1,0,1-2.49,8,8,0,0,0,.95-3.76.62.62,0,0,0-.62-.62h-6.75v-9.34a.12.12,0,0,1,.12-.12h8.6v2.7a1.35,1.35,0,0,0,1.35,1.35h2.7v16a.13.13,0,0,1-.12.12H292.87a.12.12,0,0,1-.12-.12Zm6.63-1.69a2.4,2.4,0,1,1,.05.42l1.11,1.12a.62.62,0,0,0,.44.18.57.57,0,0,0,.43-.19l2.39-2.38-.87-.88L301,550.28l-.85-.84-.73.73a.429.429,0,0,1-.04-.11Zm-.52-5.65a6.74,6.74,0,0,1-11,4.56l4.57-4.56Zm-7.35-7.34v6.46L287,548.1a6.67,6.67,0,0,1-1.57-4.31,6.75,6.75,0,0,1,6.08-6.72Z"
              transform="translate(-284.13 -532.48)"
              fill={color}
            />
            <path
              id="Path_37280"
              data-name="Path 37280"
              d="M293.61,534.32h0a.62.62,0,0,0-.62.62v7.37a.61.61,0,0,0,.62.62H301a.62.62,0,0,0,.62-.62A8,8,0,0,0,293.61,534.32Zm.61,1.27a6.75,6.75,0,0,1,6.11,6.11h-6.11Z"
              transform="translate(-284.13 -532.48)"
              fill={color}
            />
            <rect
              id="Rectangle_14929"
              data-name="Rectangle 14929"
              width="3.45"
              height="1.24"
              transform="translate(17.7 6.27)"
              fill={color}
            />
            <rect
              id="Rectangle_14930"
              data-name="Rectangle 14930"
              width="3.45"
              height="1.24"
              transform="translate(17.7 8.48)"
              fill={color}
            />
            <rect
              id="Rectangle_14931"
              data-name="Rectangle 14931"
              width="3.45"
              height="1.24"
              transform="translate(17.7 10.69)"
              fill={color}
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

ReportIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  style: { margin: '5px 0 -8px 0' },
  color: 'black',
  onClick: undefined,
  className: undefined,
};

export default ReportIcon;
