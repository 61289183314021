import { LAYOUTVIEW } from 'src/configs/system.config';
import { LayoutViewType } from 'src/enums/user.enum';
import { ThemeType } from 'src/types/configContent.type';

const getLayoutBgColor = (layout: LayoutViewType) => {
  switch (layout) {
    case LayoutViewType.LAYOUT_1:
      return '#d6673f';
    case LayoutViewType.LAYOUT_2:
      return '#267060';
    case LayoutViewType.LAYOUT_3:
      return '#267060';
    default:
      return '#d6673f';
  }
};

export const light: ThemeType = {
  // deafult olarak bu tema üzerinden ilerleniyor
  name: 'light',
  colors: {
    header: '#ffffff',
    background: '#e8f5fe',
    text: '#f2f2f2',
    // quoteBgc: "#8B1739", //marun
    quoteBgc: getLayoutBgColor(LAYOUTVIEW), // sc3 turuncu
    quoteTitle: '#616161', // icon renkleri
    secondaryText: '#92aabb',
    quoteBorder: '#ffffff', // headerdropdown li border-bottom
    border: 'rgb(26, 52, 65)', // header border-bottom
    loginBackground: '#393f36',
  },
};

export const gray = {
  name: 'gray',
  colors: {
    header: '#ffffff',
    background: 'hsl(0, 0%, 100%)',
    text: 'hsl(0, 1%, 16%)',
    quoteBgc: 'gray',
    quoteTitle: 'hsl(0, 1%, 38%)',
    secondaryText: '#92aabb',
    quoteBorder: 'hsl(0, 0%, 87%)',
    border: 'hsl(0, 0%, 87%)',
    loginBackground: '#393f36',
  },
};
// export const dark = { // dark theme
//   name: "dark",
//   colors: {
//     header: "hsl(0, 0%, 20%)",
//     background: "hsl(0, 1%, 16%)",
//     text: "hsl(0, 0%, 100%)",
//     quoteBgc: "hsl(0, 0%, 35%)",
//     quoteTitle: "hsl(0, 0%, 100%)",
//     secondaryText: "hsl(0, 0%, 100%)",
//     quoteBorder: "hsl(0, 0%, 59%)",
//     border: "hsl(0, 0%, 78%)",
//   },
// };
export const dark = {
  name: 'dark',
  colors: {
    header: '#ffffff',
    background: '#fff',
    text: '#f2f2f2',
    quoteBgc: 'black',
    quoteTitle: '#616161',
    secondaryText: '#92aabb',
    quoteBorder: '#ffffff', // headerdropdown li border-bottom
    border: '#1a3441', // header border-bottom
    loginBackground: '#393f36',
  },
};

export const blue = {
  name: 'blue',
  colors: {
    header: '#ffffff',
    background: '#fff',
    text: '#f2f2f2',
    quoteBgc: 'hsl(195, 52%, 28%)',
    quoteTitle: '#616161',
    secondaryText: '#92aabb',
    quoteBorder: '#ffffff', // headerdropdown li border-bottom
    border: '#1a3441', // header border-bottom
    loginBackground: '#393f36',
  },
};

export const green = {
  name: 'green',
  colors: {
    header: '#ffffff',
    background: '#fff',
    text: '#f2f2f2',
    quoteBgc: 'hsl(150, 80%, 15%)',
    quoteTitle: '#616161',
    secondaryText: '#92aabb',
    quoteBorder: '#ffffff', // headerdropdown li border-bottom
    border: '#1a3441', // header border-bottom
    loginBackground: '#393f36',
  },
};

export const brown = {
  name: 'brown',
  colors: {
    header: '#ffffff',
    background: '#fff',
    text: '#f2f2f2',
    quoteBgc: 'hsl(37, 83%, 54%)',
    quoteTitle: '#616161',
    secondaryText: '#92aabb',
    quoteBorder: '#ffffff', // headerdropdown li border-bottom
    border: '#1a3441', // header border-bottom
    loginBackground: '#393f36',
  },
};

export const pink = {
  name: 'pink',
  colors: {
    header: '#ffffff',
    background: '#fff',
    text: '#f2f2f2',
    quoteBgc: 'hsl(350, 50%, 78%)',
    quoteTitle: '#616161',
    secondaryText: '#92aabb',
    quoteBorder: '#ffffff', // headerdropdown li border-bottom
    border: '#1a3441', // header border-bottom
    loginBackground: '#393f36',
  },
};

export const purple = {
  name: 'purple',
  colors: {
    header: '#ffffff',
    background: '#fff',
    text: '#f2f2f2',
    quoteBgc: 'hsl(300, 50%, 28%)',
    quoteTitle: '#616161',
    secondaryText: '#92aabb',
    quoteBorder: '#ffffff', // headerdropdown li border-bottom
    border: '#1a3441', // header border-bottom
    loginBackground: '#393f36',
  },
};

export const orange = {
  name: 'orange',
  colors: {
    header: '#ffffff',
    background: '#fff',
    text: '#f2f2f2',
    quoteBgc: '#EF6930',
    quoteTitle: '#616161',
    secondaryText: '#92aabb',
    quoteBorder: '#ffffff', // headerdropdown li border-bottom
    border: '#1a3441', // header border-bottom
    loginBackground: '#393f36',
  },
};
