import React from 'react';

import { iconType } from 'src/types';

const TopicIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
        <circle id="Ellipse_57" data-name="Ellipse 57" cx="15.5" cy="15.5" r="15.5" fill={circleColor} />
        <g id="topic_v2" transform="translate(6 6)">
          <path
            id="Path_37262"
            data-name="Path 37262"
            d="M294.72,533.57a9.55,9.55,0,0,0-8.28,14.29l-1.22,3.81a.74.74,0,0,0,.48.94.72.72,0,0,0,.46,0l3.81-1.23a9.53,9.53,0,1,0,4.75-17.81Zm0,17.59a8,8,0,0,1-4.26-1.22.72.72,0,0,0-.62-.08l-2.76.89L288,548a.75.75,0,0,0-.08-.62,8,8,0,1,1,6.83,3.79Z"
            transform="translate(-285.18 -533.57)"
            fill={color}
          />
          <path
            id="Path_37263"
            data-name="Path 37263"
            d="M300.56,541.08a.54.54,0,0,0-.4-.37l-3.25-.75-1.71-2.89a.56.56,0,0,0-1,0L292.52,540l-3.24.75a.55.55,0,0,0-.42.66.47.47,0,0,0,.13.25l2.18,2.51-.29,3.32a.56.56,0,0,0,.5.61.589.589,0,0,0,.27,0l3.07-1.3,3.07,1.3a.55.55,0,0,0,.73-.3.61.61,0,0,0,0-.27l-.29-3.32,2.18-2.51a.56.56,0,0,0,.12-.54Zm-3.3,2.5a.56.56,0,0,0-.13.42l.23,2.62-2.43-1a.54.54,0,0,0-.43,0l-2.43,1,.23-2.62a.52.52,0,0,0-.13-.42l-1.73-2L293,541a.58.58,0,0,0,.35-.26l1.36-2.29,1.35,2.29a.6.6,0,0,0,.36.26l2.57.59Z"
            transform="translate(-285.18 -533.57)"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
};

TopicIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  style: { margin: '5px 0 -8px 0' },
  color: 'black',
  onClick: undefined,
  className: undefined,
};

export default TopicIcon;
