import { LoadingOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Progress,
  Radio,
  Select,
  Spin,
  Tooltip,
  message,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { t } from 'i18next';
import fileDownload from 'js-file-download';
import React, { useState } from 'react';
import ExtraTab from 'src/app/components/ExtraTabcomponent/ExtraTab';
import FormComponent from 'src/app/components/FormComponent/FormComponent';
import { useNumberMutation, useStartMutation } from 'src/app/redux/blackSystem/blackSystem.slice';
import { useAppSelector } from 'src/app/redux/hook';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';
import { SENSOR_URL_LIST } from 'src/configs/system.config';
import { UploadIcon } from 'src/icons';

function NewsApiComponent() {
  // States
  const [topicsArr, setTopicsArr] = useState<any[]>([]);
  const [submitType, setSubmitType] = useState<string>('');
  const [toDate, setToDate] = useState<string | undefined>();
  const [fromDate, setFromDate] = useState<string | undefined>();
  const [downloadState, setDownloadState] = useState<{
    newsApi: {
      state: boolean;
      size: string;
      done: boolean;
    };
    OSS: {
      state: boolean;
      size: string;
      done: boolean;
    };
  }>({
    newsApi: {
      state: false,
      size: '0.00',
      done: false,
    },
    OSS: {
      state: false,
      size: '0.00',
      done: false,
    },
  });
  const [numberMessages, setNumberMessages] = useState<{
    newsApi: string;
    OSS: string;
  }>({
    newsApi: '',
    OSS: '',
  });

  // AntDState
  const [form] = useForm();
  const [ossForm] = useForm();
  const topic = Form.useWatch('addTopic', form);

  const selector = Form.useWatch('radioBtns', form);
  const site = Form.useWatch('site', ossForm);

  const [start] = useStartMutation();
  const [number] = useNumberMutation();
  const { theme } = useAppSelector((state) => state.configContent);

  const onFinishForm = async (url: string) => {
    const data =
      url === 'OSS'
        ? { site }
        : {
            topics: topicsArr,
            selector,
            fromDate,
            toDate,
          };
    if (submitType === 'Start') {
      message.success(t('blackSystemPage.startOperationSuccess'), 5);
      start({ data: { ...data }, url })
        .unwrap()
        .catch(() => message.error(t('blackSystemPage.startOperationFailed')));
    } else if (submitType === 'Export') {
      try {
        message.success(t('blackSystemPage.exportOperationSuccess'), 5);
        await axios({
          url: urlsConfig.blackSystem(url).export,
          method: 'POST',
          data,
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const bytesDownloaded = progressEvent.loaded;
            const megabytesDownloaded = bytesDownloaded / (1024 * 1024);
            if (megabytesDownloaded) {
              setDownloadState((prevState) => ({
                ...prevState,
                [url]: {
                  state: true,
                  size: `${megabytesDownloaded.toFixed(2)}`,
                  done: false,
                },
              }));
            }
          },
          blockSpinner: true,
        })
          .then((res: any) => {
            setDownloadState((prevState) => ({
              ...prevState,
              [url]: { size: '0.00', state: false, done: true },
            }));
            fileDownload(res.data, `${url}'s.zip`);
          })
          .catch(() => {
            message.error(t('blackSystemPage.exportOperationFailed'), 5);
            setDownloadState((prevState) => ({
              ...prevState,
              [url]: { done: false, size: '0.00', state: false },
            }));
          });
      } catch (error) {
        console.log('err', error);
      }
    } else if (submitType === 'Number') {
      number({ data: { ...data }, url })
        .unwrap()
        .then((res) =>
          setNumberMessages((prevNumberMessages) => ({
            ...prevNumberMessages,
            [url]: res,
          })),
        )
        .catch(() => {
          setNumberMessages((prevNumberMessages) => ({
            ...prevNumberMessages,
            [url]: '',
          }));
          message.error(t('blackSystemPage.numberOperationFailed'), 5);
        });
    }
  };

  // Functions
  const handleAddTopic = (topicAdded: string) => {
    if (!topicsArr.includes(topicAdded) && topicAdded !== undefined && topicAdded !== '') {
      setTopicsArr((prevState) => [...prevState, topicAdded]);
      form.setFieldValue('addTopic', '');
    }
  };

  const handleDeleteTopic = (e: React.MouseEvent<HTMLButtonElement>) => {
    const x = e.currentTarget.value;
    setTopicsArr((prevState) => prevState.filter((eachTopic) => eachTopic !== x));
  };

  const handleAddTopicEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      handleAddTopic(topic);
    }
  };
  const onFromDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    let formattedDate;
    if (typeof dateString === 'string') {
      formattedDate = dateString.split(' ');
      const secondsFromFormattedDate = formattedDate.pop();
      const newSeconds = `${secondsFromFormattedDate}:00`;
      formattedDate.push(newSeconds);
      formattedDate = formattedDate.join('T');
    }
    setFromDate(formattedDate);
  };

  const onToDateChange: DatePickerProps['onChange'] = (_date, dateString) => {
    let formattedDate;
    if (typeof dateString === 'string') {
      formattedDate = dateString.split(' ');
      const secondsFromFormattedDate = formattedDate.pop();
      const newSeconds = `${secondsFromFormattedDate}:00`;
      formattedDate.push(newSeconds);
      formattedDate = formattedDate.join('T');
    }
    setToDate(formattedDate);
  };

  const topicsList = topicsArr.map((eachTopic) => (
    <div
      key={eachTopic}
      className="topic-item"
      style={{
        ...(theme.colors.quoteBgc && {
          backgroundColor: theme.colors.quoteBgc,
          boxShadow: `2px 2px 2px ${theme.colors.quoteBgc}`,
        }),
      }}
    >
      <p>{eachTopic}</p>
      <button type="button" onClick={(e) => handleDeleteTopic(e)} value={eachTopic} className="delete-topic-btn">
        x
      </button>
    </div>
  ));

  // TODO: daha sonra permissionları ekle
  // const startPermission = checkPermission(permissions, [routes['/Portal'], 'OSSConfig', 'NewsSite', 'start']);
  // const exportPermission = checkPermission(permissions, [routes['/Portal'], 'OSSConfig', 'NewsSite', 'export']);
  // const generateNumberPermission = checkPermission(permissions, [
  //   routes['/Portal'],
  //   'OSSConfig',
  //   'NewsSite',
  //   'generateNumber',
  // ]);

  return (
    <div className="news-api">
      <div className="first-form">
        <div className="news-api-form">
          <FormComponent
            extraButton={
              <div className="news-api-extra-btns">
                <ExtraTab
                  onClick={() => {
                    setSubmitType('Start');
                    form.submit();
                  }}
                  icon={null}
                  text={t('blackSystemPage.commonBtns.start')}
                  bgTheme
                  disabled={topicsArr.length === 0 || !fromDate || !toDate}
                />
                <ExtraTab
                  onClick={() => {
                    setSubmitType('Export');
                    form.submit();
                  }}
                  icon={<UploadIcon />}
                  text={t('blackSystemPage.commonBtns.exportData')}
                  bgTheme
                  disabled={topicsArr.length === 0}
                />
                <ExtraTab
                  onClick={() => {
                    setSubmitType('Number');
                    form.submit();
                  }}
                  icon={null}
                  text={t('blackSystemPage.commonBtns.number')}
                  bgTheme
                  disabled={topicsArr.length === 0}
                />
              </div>
            }
            options={{
              labelCol: { span: 9 },
              form,
              size: 'middle',
              onFinish: () => onFinishForm('newsApi'),
            }}
          >
            <div className="topic">
              <Form.Item
                className="form-with-btn"
                name="addTopic"
                label={
                  <div style={{ display: 'flex' }}>
                    {t('blackSystemPage.newsApi.form.addNewKeyword')}
                    <Tooltip placement="topRight" title={t('AddTopic')}>
                      <Badge offset={[10, 0]} />
                    </Tooltip>
                  </div>
                }
                initialValue=""
              >
                <Input bordered={false} onKeyDown={(e) => handleAddTopicEnterKey(e)} type="text" />
              </Form.Item>
              <Button
                onClick={() => handleAddTopic(topic)}
                className="topic-add-btn"
                style={{
                  ...(theme.colors.quoteBgc && {
                    backgroundColor: theme.colors.quoteBgc,
                  }),
                }}
              >
                +
              </Button>
            </div>
            <Form.Item
              name="radioBtns"
              label={
                <div style={{ display: 'flex' }}>
                  {t('blackSystemPage.newsApi.form.chooseSelector')}
                  <Tooltip placement="topRight" title="Choose Selector">
                    <Badge offset={[10, 0]} />
                  </Tooltip>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: t('errors.required'),
                },
              ]}
              initialValue="and"
            >
              <Radio.Group name="radioBtns">
                <Radio value="and">And</Radio>
                <Radio value="or">Or</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label={t('blackSystemPage.newsApi.form.fromDate')} htmlFor="fromDate" name="fromDate">
              <DatePicker
                showTime
                variant="borderless"
                id="time"
                format="YYYY-MM-DD HH:mm"
                onChange={onFromDateChange}
              />
            </Form.Item>
            <Form.Item label={t('blackSystemPage.newsApi.form.toDate')} htmlFor="toDate" name="toDate">
              <DatePicker showTime variant="borderless" id="time" format="YYYY-MM-DD HH:mm" onChange={onToDateChange} />
            </Form.Item>
          </FormComponent>
          {topicsArr.length === 0 && <p>{t('blackSystemPage.atLeastOneTopicNeeded')}</p>}
          <div className="resultAreas">
            {(downloadState.newsApi.done || downloadState.newsApi.state) && (
              <div
                className="resultArea"
                style={{
                  ...(theme.colors.quoteBgc && {
                    borderTop: `1px solid ${theme.colors.quoteBgc}`,
                    borderBottom: `1px solid ${theme.colors.quoteBgc}`,
                    color: `${theme.colors.quoteBgc}`,
                  }),
                }}
              >
                {downloadState.newsApi.state ? (
                  <>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    <p>{downloadState.newsApi.size}mb</p>
                  </>
                ) : (
                  downloadState.newsApi.done && (
                    <>
                      <Progress type="circle" percent={100} size={30} />
                      <p>Successfully Downloaded...</p>
                    </>
                  )
                )}
              </div>
            )}
            {numberMessages.newsApi !== '' && (
              <div
                className="resultArea"
                style={{
                  ...(theme.colors.quoteBgc && {
                    borderTop: `1px solid ${theme.colors.quoteBgc}`,
                    borderBottom: `1px solid ${theme.colors.quoteBgc}`,
                    color: `${theme.colors.quoteBgc}`,
                  }),
                }}
              >
                <p>
                  {t('blackSystemPage.newsApi.numberMessage')}
                  {numberMessages.newsApi}
                </p>
              </div>
            )}
          </div>
        </div>
        {topicsArr.length > 0 ? (
          <div
            className="topics-area"
            style={{
              ...(theme.colors.quoteBgc && {
                border: `1px solid ${theme.colors.quoteBgc}`,
              }),
            }}
          >
            {topicsList}
          </div>
        ) : (
          <div
            className="topics-area"
            style={{
              ...(theme.colors.quoteBgc && {
                border: `1px solid ${theme.colors.quoteBgc}`,
              }),
            }}
          >
            <h1
              style={{
                marginLeft: '1em',
                opacity: '0.3',
                ...(theme.colors.quoteBgc && {
                  color: theme.colors.quoteBgc,
                }),
              }}
            >
              {t('blackSystemPage.newsApi.form.noKeywordsAdded')}
            </h1>
          </div>
        )}
      </div>
      <div className="second-form">
        <div className="news-api-form">
          <FormComponent
            extraButton={
              <div className="extra-btns">
                <ExtraTab
                  onClick={() => {
                    setSubmitType('Start');
                    ossForm.submit();
                  }}
                  icon={null}
                  text={t('blackSystemPage.commonBtns.start')}
                  bgTheme
                />

                <ExtraTab
                  onClick={() => {
                    setSubmitType('Export');
                    ossForm.submit();
                  }}
                  icon={<UploadIcon />}
                  text={t('blackSystemPage.commonBtns.exportData')}
                  bgTheme
                />
                <ExtraTab
                  onClick={() => {
                    setSubmitType('Number');
                    ossForm.submit();
                  }}
                  icon={null}
                  text={t('blackSystemPage.commonBtns.number')}
                  bgTheme
                />
              </div>
            }
            options={{
              labelCol: { span: 9 },
              form: ossForm,
              size: 'middle',
              onFinish: () => onFinishForm('OSS'),
            }}
          >
            <Form.Item
              name="site"
              label={
                <div style={{ display: 'flex' }}>
                  {t('blackSystemPage.OSS.selectSite')}
                  <Tooltip placement="topRight" title="Select Site">
                    <Badge offset={[10, 0]} />
                  </Tooltip>
                </div>
              }
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={SENSOR_URL_LIST.newsSiteUrls[0]}
            >
              <Select bordered={false} defaultValue="Label One">
                {SENSOR_URL_LIST.newsSiteUrls.map((sensor) => (
                  <Select.Option value={sensor} key={sensor}>
                    {sensor}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </FormComponent>{' '}
          <div className="resultAreas">
            {(downloadState.OSS.done || downloadState.OSS.state) && (
              <div
                className="resultArea"
                style={{
                  ...(theme.colors.quoteBgc && {
                    borderTop: `1px solid ${theme.colors.quoteBgc}`,
                    borderBottom: `1px solid ${theme.colors.quoteBgc}`,
                    color: `${theme.colors.quoteBgc}`,
                  }),
                }}
              >
                {downloadState.OSS.state ? (
                  <>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    <p>{downloadState.OSS.size}mb</p>
                  </>
                ) : (
                  downloadState.OSS.done && (
                    <>
                      <Progress type="circle" percent={100} size={30} />
                      <p>Successfully Downloaded...</p>
                    </>
                  )
                )}
              </div>
            )}
            {numberMessages.OSS !== '' && (
              <div
                className="resultArea"
                style={{
                  ...(theme.colors.quoteBgc && {
                    borderTop: `1px solid ${theme.colors.quoteBgc}`,
                    borderBottom: `1px solid ${theme.colors.quoteBgc}`,
                    color: `${theme.colors.quoteBgc}`,
                  }),
                }}
              >
                <p>
                  {t('blackSystemPage.newsApi.numberMessageOss')}
                  {numberMessages.OSS}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsApiComponent;
