import { useForm } from 'antd/es/form/Form';
import { t } from 'i18next';
import ExtraTab from 'src/app/components/ExtraTabcomponent/ExtraTab';
import showModal from 'src/app/components/ShowModalComponent/ShowModal';
import { ICustomTabs } from 'src/app/components/TabLayoutComponent/tab-layout.config';
import TabLayout from 'src/app/components/TabLayoutComponent/TabLayout';
import UserTable from 'src/app/components/UserTableComponent/UserTable';
import ChangeClassificationAndDbForm from 'src/app/forms/CompanySettingsForms/ChangeClassificationAndDbForm';
import { formHelper } from 'src/app/helpers';
import useGetTableData from 'src/app/hooks/useGetTableData';
import { convertUsersToTableData } from 'src/app/pages/PortalPage/Users/users.config';
import { useAppSelector } from 'src/app/redux/hook';
import { useGetUsersQuery } from 'src/app/redux/user/userSlice';
import { routes } from 'src/configs/permissions.dictionary';
import ChangeIcon from 'src/icons/ChangeIcon';

const Users = () => {
  const { customerId } = useAppSelector((state) => state.user);
  const [form] = useForm();

  const { data: usersList } = useGetUsersQuery({ customerIds: customerId ?? undefined });

  const { tableDataSource } = useGetTableData({
    convertFn: convertUsersToTableData,
    data: usersList?.rows || [],
  });

  const changeClassificationBtn = (
    <ExtraTab
      icon={<ChangeIcon color="white" />}
      bgTheme
      text={t('companySettingsPage.tableContent.operationNames.changeClassificationAndDbSettings')}
      onClick={(event) => onChangeClassificationBtnClick(event)}
    />
  );

  const onChangeClassificationBtnClick = (event: any) => {
    showModal({
      event,
      content: <ChangeClassificationAndDbForm form={form} recordId={customerId} />,
      title: t('companySettingsPage.modalContent.changeClassificationAndDbSettings'),
      onSave: (modal) => formHelper.submitForm(form, modal),
      size: 'large',
    });
  };

  const tabItems: ICustomTabs[] = [
    {
      key: '1',
      label: '',
      children: (
        <UserTable
          dataSource={tableDataSource}
          permissions={{
            editPermission: [routes['/Portal'], 'Users', 'edit'],
            deletePermission: [routes['/Portal'], 'Users', 'delete'],
            freezePermission: [routes['/Portal'], 'Users', 'freeze'],
          }}
        />
      ),
      extendedContents: changeClassificationBtn,
    },
  ];

  return <TabLayout paramName="tab" items={tabItems} className="tabs-with-border-container" />;
};

export default Users;
