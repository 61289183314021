import { message } from 'antd';
import { axios } from 'src/app/utils';

export interface ExportFileSendMailInterface {
  emails: string[];
  type: string;
  excel?: any;
  excelName?: string;
  pdf?: any;
  pdfName?: string;
}
export const exportFileSendMail = ({ emails, type, excel, pdf, pdfName, excelName }: ExportFileSendMailInterface) => {
  const formData = new FormData();
  let size: number = 0;
  if (excel) {
    if (Number(excel?.size)) {
      size += excel?.size as number;
    }
    formData.append('excel', excel, excelName ?? 'Reports.xlsx');
  }
  if (pdf) {
    if (Number(pdf?.size)) {
      size += pdf?.size as number;
    }
    formData.append('pdf', pdf, pdfName ?? 'Report.pdf');
  }

  const sizeInMB = size / (1024 * 1024);
  if (sizeInMB > 6.9) {
    message.error('Failled to send email: Attachment size is too big..!');
    return;
  }

  emails.forEach((email) => {
    formData.append('emails', email);
  });

  formData.append('type', type);

  axios.post('exports/sendMail', formData);
};

export const convertDateToReadableText = (timeStamp: Date) => {
  const date = new Date(timeStamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
