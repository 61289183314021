import React from 'react';
import { iconType } from 'src/types';

const ArrowRightIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg
        style={{ ...style, overflow: 'visible' }}
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="27"
        viewBox="0 0 31 31"
      >
        <line
          id="Line_19"
          data-name="Line 19"
          y2="30"
          transform="translate(15.5 0.5)"
          fill="none"
          stroke="#c66d49"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <circle id="Ellipse_10" data-name="Ellipse 10" cx="15.5" cy="15.5" r="15.5" fill={color} />
        <path
          id="yon"
          d="M273.044,386.986a1.56,1.56,0,0,0,0,2.208l4.052,4.055h-8.725a1.561,1.561,0,1,0,0,3.123h8.658l-3.985,3.988a1.561,1.561,0,1,0,2.206,2.208l5.214-5.214a3.646,3.646,0,0,0,0-5.154l-5.214-5.214A1.56,1.56,0,0,0,273.044,386.986Z"
          transform="translate(-258.81 -379.968)"
          fill="#fff"
        />
      </svg>
    </div>
  );
};

ArrowRightIcon.defaultProps = {
  // style: { width: '32px', height: '32px' },
  color: 'black',
  onClick: undefined,
  className: undefined,
};

export default ArrowRightIcon;
