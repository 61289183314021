import { DataNode } from 'antd/es/tree';
import { t } from 'i18next';
import { permissionsConfig } from 'src/configs';
import { PermissionOptions } from 'src/configs/permissions.config';
import { LAYOUTVIEW } from 'src/configs/system.config';

export interface CustomDataNode extends DataNode {
  isAtomic?: boolean;
  isView?: boolean;
}

export const generateDefaultPermissionsTree = (permissions: string[]) => {
  const tree: { [key: string]: CustomDataNode[] } = {};
  const viewKeys: { key: string; checked: boolean }[] = [];

  Object.entries(permissionsConfig.options).forEach((routes) => {
    tree[t(routes[1].key)] = [];
  });

  Object.entries(permissionsConfig.options).forEach((routes) => {
    let totalValue: string = '';
    let circleEnd: boolean = false;
    let degree: number = 0;

    function iterate(obj: any) {
      if (obj?.excludeLayouts && obj.excludeLayouts.includes(LAYOUTVIEW)) return;
      totalValue += obj.value;

      const foundPermission = permissions.find((per) => per === totalValue);

      if (foundPermission) {
        tree[t(routes[1].key)].push({
          key: foundPermission.match(/.{1,2}/g)!.join('-'),
          isAtomic: true,
          isView: !!obj.options,
        });

        if (obj.options) viewKeys.push({ key: foundPermission, checked: true });
      } else if (obj.options) viewKeys.push({ key: totalValue, checked: false });

      if (obj.options) {
        degree += 1;

        Object.entries(obj.options).forEach(([_key, value], index) => {
          circleEnd = false;
          if (index === Object.values(obj.options).length - 1 && degree > 1) {
            circleEnd = true;
            degree -= 1;
          }

          iterate(value);
        });
      } else if (!circleEnd) totalValue = totalValue.substring(0, totalValue.length - 2);
      else {
        totalValue = totalValue.slice(0, degree * 2);
        circleEnd = false;
      }
    }

    iterate(routes[1]);
    degree = 0;
  });

  return { tree, viewKeys };
};

export const getPermissionKey = (arr: [string, ...string[]]): string => {
  let currentRole: any = permissionsConfig.options[arr[0]];
  let totalValue: string = '';
  for (let i = 1; i <= arr.length; i += 1) {
    totalValue += currentRole.value;
    // if (!hasPart(userPermissions, totalValue, i * 2)) {
    //   return false;
    // }
    if (i >= arr.length) break;
    currentRole = currentRole?.options[arr[i]];
  }
  return totalValue;
};

export const checkPermission = (userPermissions: string[], arr: [string, ...string[]]) => {
  // arr = ['NetworkPage', 'ValueChain', 'TableView', 'Delete']
  // @ts-ignore

  let currentRole: any = permissionsConfig.options[arr[0]];
  let totalValue: string = '';
  for (let i = 1; i <= arr.length; i += 1) {
    totalValue += currentRole.value;
    if (!hasPart(userPermissions, totalValue, i * 2)) {
      return false;
    }
    if (i >= arr.length) break;
    currentRole = currentRole?.options[arr[i]];
  }
  return true;
};

const hasPart = (arr: string[], value: string, index: any): boolean => {
  return arr.some((obj) => {
    return obj.substring(0, index) === value;
  });
};

const preOrderTraversal = (obj: any, rootKey: string): CustomDataNode | undefined => {
  if (obj[1].excludeLayouts) {
    if (!genericExcludeFilter(obj[1].excludeLayouts, LAYOUTVIEW)) return;
  }

  const totalRootKey = `${rootKey}-${obj[1].value}`;

  const start: CustomDataNode = {
    title: t(obj[1].key),
    key: obj?.options?.length ? `${totalRootKey}all` : totalRootKey,
    children: [],
    isView: !!obj?.options?.length,
  };

  if (!obj[1]?.options) return { ...start, isAtomic: true };

  Object.entries(obj[1]?.options).forEach((item) => {
    const node = preOrderTraversal(item, totalRootKey);
    if (node) start.children?.push(node);
  });

  return start;
};

export const generatePermissionsTree = (route: [string, PermissionOptions]): CustomDataNode | undefined => {
  const root = permissionsConfig.options[route[0]];

  if (root.excludeLayouts) {
    if (!genericExcludeFilter(root.excludeLayouts, LAYOUTVIEW)) return;
  }

  const start: CustomDataNode = {
    title: t(route[1].key),
    key: root?.options?.length ? `${root.value}all` : root.value,
    children: [],
    isView: !!root?.options?.length,
  };
  if (!root.options) return { ...start, isAtomic: true };
  Object.entries(root.options).forEach((item) => {
    if (item[1].options) {
      const node = preOrderTraversal(item, root?.value);
      if (node) start.children?.push(node);
    } else {
      if (item[1].excludeLayouts) {
        if (!genericExcludeFilter(item[1].excludeLayouts, LAYOUTVIEW)) return;
      }
      start.children?.push({
        title: t(item[1].key),
        key: `${root.value}-${item[1].value}`,
        children: [],
        isAtomic: true,
      } as CustomDataNode);
    }
  });

  return start;
};

export const genericExcludeFilter = <T>(excludeList: T[], value: T) => {
  if (excludeList.includes(value)) {
    return false;
  }
  return true;
};
