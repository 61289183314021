import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'src/app/redux/store';
import { urlsConfig } from 'src/configs';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: urlsConfig.webServerBaseURL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).user;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      // headers.set("Access-Control-Allow-Origin", "*");
      return headers;
    },
  }),

  tagTypes: [
    'UserRoles',
    'UserRolePermissions',
    'Interactions',
    'AddSupplier',
    'Relations',
    'SaqMailTemplates',
    'CocMailTemplates',
    'Notifications',
    'CocTemplates',
    'SaqTemplates',
    'Enterprises',
    'CocInvitations',
    'Forms',
    'LocationRecognized',
    'Labels',
    'TopicsIdentified',
    'IndustryRecognized',
    'SensorIdentified',
    'Interests',
    'Companies',
    'Rules',
    'Users',
    'RegisterInvitations',
    'Students',
    'VehicleIdentified',
    'PersonIdentified',
    'Seeds',
    'Customers',
    'Resources',
    'Currencies',
    'AccountTypes',
    'States',
    'Transactions',
    'Actions',
    'CCR',
    'TT',
    'Sections',
    'LocationIdentified',
    'Genders',
    'ControlPanel',
    'EnterpriseInteractions',
    'FAQ',
    'IncidentRecognized',
  ],
  endpoints: () => ({}),
});

export const apiStatusSlice = createSlice({
  name: 'apiStatus',
  initialState: { anyPendingRequest: false },
  reducers: {
    setAnyPendingRequest: (state, action: PayloadAction<boolean>) => {
      state.anyPendingRequest = action.payload;
    },
  },
});

export const { setAnyPendingRequest } = apiStatusSlice.actions;
export const apiStatusReducer = apiStatusSlice.reducer;
