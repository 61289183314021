import { ConfigProvider, message, Modal } from 'antd';
import React, { createContext, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import 'src/App.scss';
import { LayoutDefault, LoginLayout } from 'src/app/layouts';
import { ConfigContentStateType } from 'src/app/redux/configContent/configContentSlice';
import { GlobalStyles } from 'src/theme/GlobalStyles';
import { light } from 'src/theme/Theme.styled';
import { ThemeProvider } from 'styled-components';

import { AxiosError } from 'axios';
import i18next from 'i18next';
import ProgressSpinner from 'src/app/components/ProgressSpinner/ProgressSpinner';
import SessionTimeout from 'src/app/components/SessionTimeoutComponent/SessionTimeout';
import { errorHelper, storageHelper } from 'src/app/helpers';
import { convertColorToRgbString } from 'src/app/helpers/color.helper';
import GuestUserLayout from 'src/app/layouts/guestUserLayout/GuestUserLayout';
import AdministrationPage from 'src/app/pages/AdministrationPage/AdministrationPage';
import PortalPage from 'src/app/pages/PortalPage/PortalPage';
import StudentsPage from 'src/app/pages/StudentsPage/StudentsPage';
import { useAppDispatch, useAppSelector } from 'src/app/redux/hook';
import * as userReduxType from 'src/app/redux/user/user.redux.type';
import { logout, reset } from 'src/app/redux/user/userSlice';
import { axiosUtils } from 'src/app/utils';
import AuthorizedRoute from 'src/app/utils/AuthorizedRoute';
import { setToken } from 'src/app/utils/axios.instance';
import ProtectedRoute from 'src/app/utils/ProtectedRoute';
import { urlsConfig } from 'src/configs';
import systemConfig, { FONT_FAMILY, LAYOUTVIEW, LOGIN_URL } from 'src/configs/system.config';
import { LanguagePreferenceEnum } from 'src/enums/language.enum';
import { LayoutViewType } from 'src/enums/user.enum';

const LazyHomePage = React.lazy(() => import('./pages/HomePage/HomePage'));

const LazyProfilePage = React.lazy(() => import('./pages/ProfilePage/ProfilePage'));

const LazySC3LoginPage = React.lazy(() => import('src/app/pages/LoginPage/SC3/LoginPageSC3'));

const LazyGoogleAuth = React.lazy(
  () => import('./pages/LoginPage/SC3/GoogleAuthenticationVerificationPage/GoogleAuthenticationVerificationPage'),
);
const LazyEmailAuthentication = React.lazy(() => import('./pages/LoginPage/SC3/AuthenticationPage/AuthenticationPage'));
const LazyRegisterPage = React.lazy(() => import('src/app/pages/LoginPage/SC3/RegisterPage/RegisterPage'));

const LazyForgotPasswordPage = React.lazy(
  () => import('src/app/pages/LoginPage/SC3/ForgotPasswordPage/ForgotPasswordPage'),
);
const LazyResetPasswordPage = React.lazy(
  () => import('src/app/pages/LoginPage/SC3/ResetPasswordPage/ResetPasswordPage'),
);

const LazyNeedHelpPage = React.lazy(() => import('src/app/pages/LoginPage/SC3/NeedHelpPage/NeedHelpPage'));

const LazyRedirect = React.lazy(() => import('./components/RedirectComponent/Redirect'));
const LazySettingsPage = React.lazy(() => import('./pages/SettingsPage/SettingsPage'));
const LazyHelpAndSupportPage = React.lazy(() => import('./pages/HelpAndSupportPage/HelpAndSupportPage'));

const LazyNotificationsPage = React.lazy(() => import('./pages/NotificationsPage/NotificationsPage'));

const LazyNetworkPage = React.lazy(() => import('./pages/NetworkPage/NetworkPage'));

const LazyIndustryPage = React.lazy(() => import('./pages/IndustryPage/IndustryPage'));

const LazyReportsPage = React.lazy(() => import('./pages/ReportsPage/ReportsPage'));

const LazyTopicPage = React.lazy(() => import('./pages/TopicPage/TopicPage'));

const LazyLocationPage = React.lazy(() => import('./pages/LocationPage/LocationPage'));
const LazyResourcesPage = React.lazy(() => import('./pages/ResourcesPage/ResourcesPage'));

const LazyCustomerRegisterPage = React.lazy(() => import('./pages/LoginPage/SC3/CustomerRegister/CustomerRegister'));

const LazySAQFormPage = React.lazy(() => import('./pages/SAQFormPage/SAQFormPage'));
const LazyAccountTypePage = React.lazy(() => import('./pages/AccountTypePage/AccountTypePage'));

const LazyCustomerRegisterWithCompanyDetailsPage = React.lazy(
  () => import('./pages/LoginPage/SC3/CustomerRegisterWithCompanyDetails/CustomerRegisterWithCompanyDetails'),
);

const LazyCompanySettingsPage = React.lazy(
  () => import('./pages/PortalPage/CustomerAndUserPage/CompanySettingsPage/CompanySettingsPage'),
);

const LazyInterestsPage = React.lazy(() => import('./pages/InterestsPage/InterestsPage'));

const LazyNSearchPage = React.lazy(() => import('./pages/InterestEditPage/InterestEdit'));

const LazyPage404 = React.lazy(() => import('src/app/pages/Page404/Page404'));

const LazyCocResponsePage = React.lazy(() => import('./pages/CocResponsePage/CoCResponsePage'));

const LazyReminderMailPage = React.lazy(() => import('./pages/ReminderMailPage/ReminderMailPage'));

export const ThemeModeContext = createContext<any>({
  mainTheme: localStorage.getItem('mainTheme') || '',
  setMainTheme: () => {},
});

const App = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [mainTheme, setMainTheme] = useState<string>(localStorage.getItem('mainTheme') || '');

  const isSomeQueryPending = useAppSelector((state) => {
    const queries = [...Object.values(state.api.queries), ...Object.values(state.surferApi.queries)]
      // @ts-ignore
      .filter((query) => !query?.originalArgs?.blockSpinner)
      .filter((query) => query?.endpointName !== 'getModulesAndMachines');
    return queries;
  }).some((query) => query?.status === 'pending');

  const loggedInUser: userReduxType.StateType = useAppSelector((state) => state.user);
  const currentTheme: ConfigContentStateType = useAppSelector((state) => state.configContent);

  const { anyPendingRequest } = useAppSelector((state) => state.apiStatus);

  const currentLanguage = storageHelper.getValueWithKey('language');

  useEffect(() => {
    const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;

    if (LAYOUTVIEW === LayoutViewType.LAYOUT_1) {
      if (favicon) {
        favicon.href = './company_logo_small.svg';
      }
      document.title = 'SC3®';
    } else if (LAYOUTVIEW === LayoutViewType.LAYOUT_2) {
      document.title = 'SAMA®';
      favicon.href = './headerLogos/SAMA_RED.png';
      // if (systemConfig.system.type === SystemType.RED) {
      //   favicon.href = './headerLogos/SAMA_RED.png';
      // } else if (systemConfig.system.type === SystemType.BLACK) {
      //   favicon.href = './headerLogos/Sama_Black.png';
      // }
    } else if (LAYOUTVIEW === LayoutViewType.LAYOUT_3) {
      document.title = 'Securete';
      favicon.href = './logo_securETE.png';
    } else if (LAYOUTVIEW === LayoutViewType.LAYOUT_4) {
      document.title = 'SAMA®';
      favicon.href = './headerLogos/Sama_Black.png';
    } else if (LAYOUTVIEW === LayoutViewType.FULL) {
      document.title = 'Nginr®';
      favicon.href = './logo_nginr_metalik.png';
    }
  }, []);

  useEffect(() => {
    const handleChangeLanguage = (lng: string) => {
      // i18n.changeLanguage(lng);
      storageHelper.setValueWithKey('language', lng);
      document.body.dir = i18next.language === LanguagePreferenceEnum.AR ? 'rtl' : 'ltr';
    };
    handleChangeLanguage(loggedInUser.preferredLanguage);
  }, [loggedInUser.preferredLanguage]);

  useEffect(() => {
    axiosUtils.configureInterceptors(dispatch, (error: AxiosError) =>
      errorHelper.handleResponseErrors(
        error,
        (textMessage: any) => message.error(textMessage),
        // () => dispatch(reset()),
        // () => sessionStorage.removeItem("token")
        () => {},
        () => {},
      ),
    );
  }, []);

  useEffect(() => {
    if (loggedInUser.token) {
      setToken(loggedInUser.token);
      // storageHelper.setValueWithKey("token", loggedInUser.token);
      sessionStorage.setItem('token', loggedInUser.token);
      // storageHelper.setValueWithKey("user", JSON.stringify(loggedInUser));
      sessionStorage.setItem('user', JSON.stringify(loggedInUser));
    }
  }, [loggedInUser]);

  // useEffect(() => {
  //   if (currentLanguage === null) {
  //     storageHelper.setValueWithKey('language', 'en');
  //   }
  //   // sayfa ilk yüklendiğinde seçili olan dile göre direction belirlenir
  //   document.body.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
  // }, [currentLanguage]);

  useEffect(() => {
    const elem = document.getElementById('favicon');
    elem?.setAttribute('href', `${urlsConfig.storageServerURL}/public?path=${window.FAVICON_PATH}`);
  }, []);

  useEffect(() => {
    Modal.destroyAll();
  }, [location]);

  const { theme } = useAppSelector((state) => state.configContent);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ThemeModeContext.Provider value={{ mainTheme, setMainTheme }}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: currentTheme.theme.colors.quoteBgc,
            // now if the theme is dark, color text is equal to white,
            // otherwise it is black
            colorText: 'var(--primary_text_color)',
            fontFamily: FONT_FAMILY,
            // This is for placeholder texts in select parts or in inputs
            // I.E Search part in header...
            colorTextPlaceholder: 'var(--place_holder_text_color)',
          },
          components: {
            Drawer: {
              // this is applied for the right side of the Main Dashboard Page,
              colorBgElevated: 'var(--right_content_drawer_color)',
            },
            Table: {
              // With this, we are controlling the bg of our tables...
              headerBg: 'var(--table_header_bg)',
              rowHoverBg: '',
              bodySortBg: 'var(--table_body_sort_active_bg)',
              headerSortHoverBg: 'var(--header_bg)',
              colorBgContainer: 'var(--page_content_bg)',
              rowSelectedHoverBg:
                mainTheme === 'light'
                  ? `rgba(${convertColorToRgbString(theme.colors.quoteBgc)},0.1)`
                  : 'var(--header_bg)',
              rowSelectedBg:
                mainTheme === 'light'
                  ? `rgba(${convertColorToRgbString(theme.colors.quoteBgc)},0.1)`
                  : 'var(--header_bg)',
              // shadow color of the "Action Content" of tables
              // colorSplit: "gray"
              headerSortActiveBg: `var(--table_header_sort_active_bg)`,
            },
            Button: {
              // We did this, becouse buttons such ass Add Student i.e...
              // They had white bg but we do not want this default...
              colorBgContainer: 'var(--page_content_bg)',
              colorBgContainerDisabled: 'var(--btn_disabled_bg)',
              colorLink: 'var(--primary_text_color)',
              colorLinkHover: 'var(--primary_text_color)',
            },
            Collapse: {
              // NSearch collapse sections are all now applying this bg color...
              colorBgContainer: 'var(--page_content_bg)',
            },
            Select: {
              // Such as collapse we give Select bg, and bg of the selected items...
              colorBgContainer: 'var(--page_content_bg)',
              multipleItemBg: `${theme.colors.quoteBgc}40`,

              // Here we give the color of the "X" CLOSE btns
              colorIcon: 'var(--primary_text_color)',

              // Here we give the bg color for the children dropdown or
              // any other children components of select component
              // go check NSearch Again...
              colorBgElevated: 'var(--page_content_bg)',

              // Hover Effect for each row on dropdown
              // optionActiveBg: "var(--dropdown_hover_bg)",

              // when an option is selected, we give this bg
              // On the dropdown
              optionSelectedBg: `${theme.colors.quoteBgc}33`,

              // Disabled options of selector inputs color...
              colorTextDisabled: 'var(--color_text_disabled)',
            },
            Input: {
              // Not So sure why wee needed this...
              colorBgContainer: 'var(--page_content_bg)',
            },
            Card: {
              // Here we control the bg of the Cards, specialy for in NSearch
              // Select an Interest part is a card
              headerBg: 'var(--page_content_bg)',
              colorBgContainer: 'var(--page_content_bg)',
            },
            DatePicker: {
              // this is done for date picker in NSearch part...
              colorBgContainer: 'var(--page_content_bg)',
              colorBgElevated: 'var(--page_content_bg)',

              // Colors For the arrow Iconst... On Date Picker Spesificly you can again go to
              // Date Picker part on NSearch...
              colorIcon: 'var(--primary_text_color)',
              colorIconHover: theme.colors.quoteBgc,
              // Here we give bg color to the dates or hours
              // That are selected by the user, "80" at the end
              // helps us to give opacity to our hex value for 50%
              controlItemBgActive: `${theme.colors.quoteBgc}66`,
            },
            Dropdown: {
              // With this, We have fixed the problem of dropdown when user filters
              // on the notification page...
              colorBgElevated: 'var(--page_content_bg)',

              // We have added this just becouse dropdown filters on
              // tables had a terriable styling if a user was hovering
              // over it, or selects it...
              controlItemBgActive: `${theme.colors.quoteBgc}66`,
              controlItemBgActiveHover: `${theme.colors.quoteBgc}80`,
            },
            Tree: {
              // We did this, so now Administration page Tree Components are
              // Getting Affected from this change...
              colorBgContainer: 'var(--page_content_bg)',
            },
            Tabs: {
              // We have added this for the tabs on the interest page
              // or other pages...
              colorText: 'var(--secondary_text_color)',
            },
            Checkbox: {
              colorText: 'var(--secondary_text_color)',
            },
            Segmented: {
              // This Part is for NSearch, Interest tab header,
              // Selection for Identified or Recognized Part...
              colorBgElevated: 'var(--page_content_bg)',
              colorBgLayout: 'var(--segment_bg_color)',
              colorTextLabel: 'var(--primary_text_color)',
            },
          },
        }}
      >
        <ThemeProvider theme={currentTheme.theme ? currentTheme.theme : light}>
          <SessionTimeout
            onIdle={async () => {
              message.error('Session timeout');
              await dispatch(logout()).unwrap();
              dispatch(reset());
              window.location.replace(LOGIN_URL);
            }}
            idleTime={loggedInUser.idleTime}
            onActive={() => {}}
          />
          <div className="App">
            <GlobalStyles />
            <Suspense fallback={<ProgressSpinner anyPendingRequest />}>
              <Routes>
                {/* PROTECTED ROUTES */}
                <Route element={<ProtectedRoute />}>
                  <Route element={<LayoutDefault />}>
                    <Route element={<AuthorizedRoute requiredPermissions={['01']} />}>
                      <Route path="/" element={<LazyHomePage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['02']} />}>
                      <Route path="/Network" element={<LazyNetworkPage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['03']} />}>
                      <Route path="/Notifications" element={<LazyNotificationsPage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['06']} />}>
                      <Route path="/Industry" element={<LazyIndustryPage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['04']} />}>
                      <Route path="/Topic" element={<LazyTopicPage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['05']} />}>
                      <Route path="/Location" element={<LazyLocationPage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['07']} />}>
                      <Route path="/Reports" element={<LazyReportsPage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['08']} />}>
                      <Route path="/Resources" element={<LazyResourcesPage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['11']} />}>
                      <Route path="/Students" element={<StudentsPage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['10']} />}>
                      <Route path="/Settings" element={<LazySettingsPage />} />{' '}
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['18']} />}>
                      <Route path="/HelpAndSupport" element={<LazyHelpAndSupportPage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['14']} />}>
                      <Route path="/Administration" element={<AdministrationPage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['15']} />}>
                      <Route path="/Portal" element={<PortalPage />} />
                    </Route>

                    <Route element={<AuthorizedRoute requiredPermissions={['12']} />}>
                      <Route path="/Profile" element={<LazyProfilePage />} />
                    </Route>

                    {/* <Route
                    element={<AuthorizedRoute requiredPermissions={["12"]} />}
                  > */}
                    <Route path="/CompanySettings" element={<LazyCompanySettingsPage />} />
                    {/* </Route> */}

                    <Route element={<AuthorizedRoute requiredPermissions={['21']} />}>
                      <Route path="/Interests" element={<LazyInterestsPage />} />
                    </Route>

                    <Route path="/NSearch" element={<LazyNSearchPage interest={location.state?.interest} />} />
                  </Route>
                </Route>

                {/* PUBLIC ROUTES */}

                <Route element={<LayoutDefault />}>
                  <Route path="/SAQForm" element={<LazySAQFormPage />} />
                  <Route path="/CoCResponse" element={<LazyCocResponsePage />} />
                  <Route element={<GuestUserLayout />}>
                    <Route path="/Redirect" element={<LazyRedirect />} />
                  </Route>
                  <Route path="/ChangeSaqEmailSendType" element={<LazyReminderMailPage type="SAQ" />} />
                  <Route path="/ChangeEmailSendType" element={<LazyReminderMailPage type="COC" />} />
                </Route>

                {/* LOGIN LAYOUT ROUTES */}

                <Route element={<LoginLayout />}>
                  <Route path="/CustomerRegister" element={<LazyCustomerRegisterPage />} />

                  <Route path="/GoogleAuthenticationVerification" element={<LazyGoogleAuth />} />
                  <Route element={<GuestUserLayout />}>
                    {!systemConfig.systemLogin.active ? <Route path="/Login" element={<LazySC3LoginPage />} /> : null}

                    <Route path="/Authentication" element={<LazyEmailAuthentication />} />
                    <Route path="/Register" element={<LazyRegisterPage />} />
                    <Route path="/ForgotPassword" element={<LazyForgotPasswordPage />} />
                    <Route path="/ResetPassword" element={<LazyResetPasswordPage />} />
                    <Route path="/NeedHelp" element={<LazyNeedHelpPage />} />
                    <Route path="/AccountType" element={<LazyAccountTypePage />} />
                    <Route
                      path="/CustomerRegisterWithCompanyDetails"
                      element={<LazyCustomerRegisterWithCompanyDetailsPage />}
                    />
                  </Route>
                </Route>
                <Route path="/*" element={<LazyPage404 />} />
              </Routes>
            </Suspense>
          </div>
        </ThemeProvider>
      </ConfigProvider>
      <ProgressSpinner anyPendingRequest={isSomeQueryPending || anyPendingRequest} />
    </ThemeModeContext.Provider>
  );
};

export default App;
