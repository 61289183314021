import React from 'react';
import { useAppSelector } from 'src/app/redux/hook';
import TableFooter from 'src/app/components/TableFooter/TableFooter';

interface IPageLayoutProps {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  hasFooter?: boolean;
  id?: string;
  subTitle?: string;
  titleExtra?: React.ReactNode;
  subTitleExtra?: React.ReactNode;
}
const PageLayout: React.FC<IPageLayoutProps> = ({
  title,
  children,
  hasFooter,
  id,
  subTitle,
  titleExtra,
  subTitleExtra,
}) => {
  const { theme } = useAppSelector((state) => state.configContent);

  return (
    <>
      <div className="tab-container" id={id}>
        <div className="tab-top">
          {title && (
            <div
              className="tab-header"
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ color: `${theme.colors.quoteBgc}` }}>{title}</div>
              {titleExtra}
            </div>
          )}
        </div>
        {subTitle ? (
          <>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <p className="page-sub-title">{subTitle}</p>
              {subTitleExtra}
            </div>
            <div className="page-sub-title-border" />
          </>
        ) : null}
        {children}
      </div>
      {hasFooter && <TableFooter />}
    </>
  );
};
PageLayout.defaultProps = {
  hasFooter: true,
};
export default PageLayout;
