import React from 'react';
import { iconType } from 'src/types';

const PaymentVerifiedIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex' }}
    >
      {/* <svg
        style={{ ...style }}
        id="Group_40619"
        data-name="Group 40619"
        xmlns="http://www.w3.org/2000/svg"
        width="16.457"
        height="21.926"
        viewBox="0 0 16.457 21.926"
      >
        <path
          id="Path_37315"
          data-name="Path 37315"
          d="M223.042,242.376a.642.642,0,0,0,.642-.642v-7.691a.642.642,0,1,0-1.285,0v7.691A.641.641,0,0,0,223.042,242.376Z"
          transform="translate(-215.608 -223.405)"
          fill={color !== undefined ? color : '#92aabb'}
        />
        <path
          id="Path_37316"
          data-name="Path 37316"
          d="M289.842,242.376a.642.642,0,0,0,.642-.642v-7.691a.642.642,0,0,0-1.285,0v7.691A.642.642,0,0,0,289.842,242.376Z"
          transform="translate(-279.547 -223.405)"
          fill={color !== undefined ? color : '#92aabb'}
        />
        <path
          id="Path_37317"
          data-name="Path 37317"
          d="M356.542,242.376a.642.642,0,0,0,.642-.642v-7.691a.642.642,0,1,0-1.285,0v7.691A.641.641,0,0,0,356.542,242.376Z"
          transform="translate(-343.391 -223.405)"
          fill={color !== undefined ? color : '#92aabb'}
        />
        <path
          id="Path_37314"
          data-name="Path 37314"
          d="M74.1,7.04H70.121L74.66,2.5a.638.638,0,0,0,0-.908L73.508.437a1.5,1.5,0,0,0-2.12,0L70.207,1.619l-.6-.6a1.716,1.716,0,0,0-2.424,0L64.815,3.379a1.716,1.716,0,0,0,0,2.424l.6.6L64.237,7.589a1.5,1.5,0,0,0,0,2.12l1.152,1.152a.638.638,0,0,0,.908,0l1.627-1.627v9.91a2.789,2.789,0,0,0,2.784,2.784h6.766a2.789,2.789,0,0,0,2.784-2.784V7.049m-5.43-2.193-9.1-.57,2.364-2.364a.426.426,0,0,1,.6,0l.6.6L66.331,5.494l-.6-.6a.426.426,0,0,1,0-.6ZM65.843,9.5l-.7-.7a.22.22,0,0,1,0-.3L72.3,1.345a.212.212,0,0,1,.3,0l.7.7Zm13.134,9.644a1.5,1.5,0,0,1-1.5,1.5H70.712a1.5,1.5,0,0,1-1.5-1.5V8.325h9.764Z"
          transform="translate(-63.8 0)"
          fill={color !== undefined ? color : '#92aabb'}
        />
      </svg> */}

      {/* payment verified */}
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill={color || 'var(--primary-color)'}
      >
        <g>
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
        <g>
          <path d="M20,4H4C2.89,4,2.01,4.89,2.01,6L2,18c0,1.11,0.89,2,2,2h5v-2H4v-6h18V6C22,4.89,21.11,4,20,4z M20,8H4V6h16V8z M14.93,19.17l-2.83-2.83l-1.41,1.41L14.93,22L22,14.93l-1.41-1.41L14.93,19.17z" />
        </g>
      </svg>

      {/* download */}
      {/* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16 13h-3V3h-2v10H8l4 4 4-4zM4 19v2h16v-2H4z" />
      </svg> */}

      {/* clone icon for typeform */}
      {/* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#1a3441">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zM8 21V7h6v5h5v9H8z" />
      </svg> */}
    </div>
  );
};

PaymentVerifiedIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '12px' },
  // color: 'var(--primary-color)',
  onClick: undefined,
  className: undefined,
};

export default PaymentVerifiedIcon;
