export enum NotificationReadStatusEnum {
  Unread = 0,
  Read = 1,
}
export enum NotificationStarStatusEnum {
  Unstar = 0,
  Star = 1,
}

export enum NotificationArchiveStatusEnum {
  UNARCHIVE = 0,
  ARCHIVE = 1,
}
export enum NotificationRelevantStatusEnum {
  UNRELEVANT = 0,
  RELEVANT = 1,
}

export enum NotificationTypeEnum {
  RULE = 0,
  SAQ = 1,
}
