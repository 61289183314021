import { AxiosPromise } from 'axios';
import { NSearchLoggerGetAllType } from 'src/app/redux/nSearch/nSearch-slice-type';
import { urlHelper } from 'src/app/helpers';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';

export const nSearchGenerateLog = (data: any): AxiosPromise<any> => {
  return axios.post(urlsConfig.nSearchLogger.nSearch, data);
};

export interface NSearchLoggerGetAllLogsQueryParams {
  customerIds?: string[];
}

export const getNSearchLogs = async (
  queryParams?: NSearchLoggerGetAllLogsQueryParams,
): AxiosPromise<NSearchLoggerGetAllType> => {
  return axios.get(urlsConfig.nSearchLogger.nSearch, { params: urlHelper.getLookupQuery(queryParams) });
};

export default {
  nSearchGenerateLog,
};
