import { Col, Row } from 'antd';
import React from 'react';
import InfoTooltip from 'src/app/components/InfoTooltipComponent/InfoTooltip';
import { STANDARD_USER_ROLE_ID } from 'src/app/constants/constants';
import { timeHelper } from 'src/app/helpers';
import styles from 'src/app/pages/PortalPage/CustomerAndUserPage/UserDetailsModalContent/UserDetailsModalContent.module.scss';
import { TwoFactorAuthenticationType } from 'src/enums/authentication.enum';
import { User } from 'src/types/user.type';

interface ContentProps {
  data: User | undefined;
  customerName?: string;
}
const UserDetailsContent: React.FC<ContentProps> = ({ data, customerName }) => {
  return (
    <div className={styles.content} id="customerAndUser_modalShowContents">
      <div className={styles.username} id="customerAndUser_modalShowUsername">
        {data?.fullname}
      </div>
      {customerName ? (
        <Row className={styles.contentRow} id="customerAndUser_showRow">
          <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
            Company
          </Col>
          <Col span={16} className={styles.contentValueCol}>
            {customerName}
          </Col>
        </Row>
      ) : null}
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          E-mail Address
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {data?.email}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          User Role
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {data?.userRole[0].name}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Last Login Dates
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {data?.lastLoginDates.length ? (
            <ul>
              {data.lastLoginDates.map((date, idx) => (
                <li key={idx.toString()}>{timeHelper.formatDate(date)}</li>
              ))}
            </ul>
          ) : (
            'Has not logged in yet'
          )}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          User Creation Date
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {timeHelper.formatDate(data?.createdAt)}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Authentication Type
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {TwoFactorAuthenticationType[data?.authentication[0]!].toString()}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Is Authorized to change MFA
          <InfoTooltip
            style={{ marginTop: '.2rem' }}
            props={{ placement: 'topLeft' }}
            tooltipContent="This setting can be changed on Company Settings Page."
          />
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {data?.userRole.every((role) => role.id === STANDARD_USER_ROLE_ID) ? 'Not Authorized' : 'Authorized'}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          User Status
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {data?.isOnline ? 'Online' : 'Offline'}
        </Col>
      </Row>
    </div>
  );
};

export default UserDetailsContent;
