import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'src/app/redux/store';
import { urlsConfig } from 'src/configs';

export const surferApiSlice = createApi({
  reducerPath: 'surferApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urlsConfig.surferURL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).user;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['NSearch'],
  endpoints: () => ({}),
});
