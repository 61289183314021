import {
  UserRoleCreateDataType,
  UserRoleLookupResultType,
  UserRoleUpdateDataType,
  UserRoleUpdateResultType,
} from 'src/app/services/user-role/user-role-service.type';
import { urlsConfig } from 'src/configs';
import { USER_ROLE_BLACKLIST } from 'src/app/constants/constants';
import { apiSlice } from 'src/app/redux/api/apiSlice';

export const userRolesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserRoles: builder.query<UserRoleLookupResultType, { includeSuperUser?: boolean } | void>({
      query: () => urlsConfig.userRole.lookup,
      transformResponse: (data: UserRoleLookupResultType, _meta, arg) => {
        return {
          rows: arg?.includeSuperUser ? data.rows : data.rows.filter((row) => !USER_ROLE_BLACKLIST.includes(row.id)),
          count: arg?.includeSuperUser ? data.count : data.count - USER_ROLE_BLACKLIST.length,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'UserRoles' as const,
                id,
              })),
              { type: 'UserRoles', id: 'LIST' },
            ]
          : [{ type: 'UserRoles', id: 'LIST' }],
    }),
    addUserRole: builder.mutation({
      query: (data: UserRoleCreateDataType) => ({
        url: urlsConfig.userRole.create,
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: [{ type: 'UserRoles', id: 'LIST' }],
    }),
    updateUserRole: builder.mutation({
      query: (data: UserRoleUpdateDataType) => ({
        url: urlsConfig.userRole.update,
        method: 'PATCH',
        body: { ...data },
      }),
      invalidatesTags: (_result, _error, data: UserRoleUpdateResultType) => [{ type: 'UserRoles', id: data.id }],
    }),
    deleteUserRole: builder.mutation({
      query: (id: string) => ({
        url: `${urlsConfig.userRole.destroy}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'UserRoles', id }],
    }),
  }),
});

export const { useAddUserRoleMutation, useDeleteUserRoleMutation, useGetUserRolesQuery, useUpdateUserRoleMutation } =
  userRolesApiSlice;
