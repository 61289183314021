import { Form, FormProps } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { formHelper } from 'src/app/helpers';
import { SaveIcon } from 'src/icons';
import ExtraTab from 'src/app/components/ExtraTabcomponent/ExtraTab';
import styles from 'src/app/components/FormComponent/FormComponent.module.scss';

interface IFormComponentProps {
  children: React.ReactNode;
  options?: FormProps;
  hasSaveButton?: boolean;
  onClick?: () => void;
  onFinish?: () => void;
  onFinishFailed?: () => void;
  extraButton?: React.ReactNode;
  saveButtonText?: string;
  saveButtonDisabled?: boolean;
}
const FormComponent: React.FC<IFormComponentProps> = ({
  children,
  options,
  hasSaveButton,
  extraButton,
  saveButtonText,
  saveButtonDisabled,
}) => {
  const handleFormSubmit = (e: any) => {
    e.stopPropagation();
    if (options?.form) {
      formHelper.submitForm(options.form);
    }
  };

  return (
    <>
      <Form
        preserve={false}
        id="form-component"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
        colon={false}
        scrollToFirstError
        // style={{ maxHeight: "80vh", overflowY: "auto", overflowX: "hidden" }}
        {...options}
      >
        {children}
      </Form>
      <div className={styles.buttonContainer}>
        {hasSaveButton && (
          <div className={styles.saveBtnContainer}>
            <ExtraTab
              onClick={handleFormSubmit}
              text={saveButtonText || t('global.save')}
              icon={<SaveIcon />}
              disabled={saveButtonDisabled}
            />
          </div>
        )}
        {extraButton && <div>{extraButton}</div>}
      </div>
    </>
  );
};

export default FormComponent;
