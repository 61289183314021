import React from 'react';
import { iconType } from 'src/types';

const HeaderIcon: React.FC<iconType.PropsType> = ({ style, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex' }}
    >
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="191.023"
        height="70.038"
        viewBox="0 0 191.023 70.038"
        fill="var(--sc3_header_icon_color)"
      >
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_7" data-name="Rectangle 7" width="191.023" height="70.038" />
          </clipPath>
        </defs>
        <path
          id="Path_1"
          data-name="Path 1"
          d="M83.138,51.44l1.9-4.7,1.9,4.7,1.541-4.4h.709l-2.25,6.175-1.9-4.726-1.9,4.726-2.25-6.175H81.6Z"
          transform="translate(4.596 2.655)"
        />
        <g id="Group_2" data-name="Group 2">
          <g id="Group_1" data-name="Group 1" clipPath="url(#clip-path)">
            <path
              id="Path_2"
              data-name="Path 2"
              d="M89.518,50.952a1.3,1.3,0,1,0,1.3-1.354,1.3,1.3,0,0,0-1.3,1.354m-.622.008a1.923,1.923,0,1,1,1.923,1.922A1.92,1.92,0,0,1,88.9,50.96"
              transform="translate(5.051 2.785)"
            />
            <path
              id="Path_3"
              data-name="Path 3"
              d="M94.494,49.52h.015a.941.941,0,0,1,.811-.5,1.105,1.105,0,0,1,.56.171l-.3.569a.608.608,0,0,0-.374-.164c-.685,0-.715.879-.715,1.354v1.83h-.625V49.123h.625Z"
              transform="translate(5.334 2.785)"
            />
            <path
              id="Path_4"
              data-name="Path 4"
              d="M97.514,50.62l1.331-1.355h.834l-1.565,1.558,1.86,2.1h-.825l-1.471-1.667-.163.164v1.5H96.89V46.5h.624Z"
              transform="translate(5.505 2.642)"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M101.675,52.875h-.622v-3.66h.622Zm.133-5.038a.444.444,0,1,1-.888,0,.444.444,0,0,1,.888,0"
              transform="translate(5.734 2.693)"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M104.04,49.566h.016a1.227,1.227,0,0,1,1.043-.544c1.012,0,1.246.731,1.246,1.587v2.173h-.624V50.719c0-.592-.046-1.121-.77-1.121-.856,0-.911.794-.911,1.448v1.736h-.622V49.123h.622Z"
              transform="translate(5.876 2.785)"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M108.167,50.913a1.263,1.263,0,0,0,1.2,1.394,1.248,1.248,0,0,0,1.223-1.385,1.223,1.223,0,0,0-1.23-1.323,1.24,1.24,0,0,0-1.191,1.315m3.013,1.69a2.319,2.319,0,0,1-.374,1.573,1.77,1.77,0,0,1-1.416.647,1.675,1.675,0,0,1-1.8-1.682h.622a1.088,1.088,0,0,0,1.168,1.105c.965,0,1.176-.707,1.176-1.532v-.438h-.016a1.622,1.622,0,0,1-1.269.608,1.793,1.793,0,0,1-1.745-1.915,1.825,1.825,0,0,1,1.768-1.947,1.524,1.524,0,0,1,1.246.624h.016v-.522h.622Z"
              transform="translate(6.11 2.785)"
            />
            <path
              id="Path_8"
              data-name="Path 8"
              d="M117.092,52.9h-.654V47.633h-1.416v-.608h3.5v.608h-1.432Z"
              transform="translate(6.536 2.672)"
            />
            <path
              id="Path_9"
              data-name="Path 9"
              d="M119.1,50.952a1.3,1.3,0,1,0,1.3-1.354,1.3,1.3,0,0,0-1.3,1.354m-.621.008a1.923,1.923,0,1,1,1.923,1.922,1.919,1.919,0,0,1-1.923-1.922"
              transform="translate(6.732 2.785)"
            />
            <path
              id="Path_10"
              data-name="Path 10"
              d="M124.732,51.608l1.137-2.756,1.143,2.756,1.09-2.476h.709L127,53.049l-1.129-2.726-1.13,2.726-1.8-3.917h.708Z"
              transform="translate(6.986 2.776)"
            />
            <path
              id="Path_11"
              data-name="Path 11"
              d="M129.973,50.913a1.264,1.264,0,0,0,1.2,1.394,1.248,1.248,0,0,0,1.221-1.385,1.223,1.223,0,0,0-1.23-1.323,1.24,1.24,0,0,0-1.192,1.315m3.015,1.87h-.624v-.506h-.015a1.541,1.541,0,0,1-1.262.607,1.94,1.94,0,0,1,.007-3.862,1.583,1.583,0,0,1,1.254.624h.015v-.522h.624Z"
              transform="translate(7.349 2.785)"
            />
            <path
              id="Path_12"
              data-name="Path 12"
              d="M135.029,49.52h.015a.944.944,0,0,1,.811-.5,1.1,1.1,0,0,1,.56.171l-.3.569a.6.6,0,0,0-.374-.164c-.685,0-.715.879-.715,1.354v1.83H134.4V49.123h.624Z"
              transform="translate(7.637 2.785)"
            />
            <path
              id="Path_13"
              data-name="Path 13"
              d="M137.8,51.057a1.264,1.264,0,0,0,1.2,1.394,1.248,1.248,0,0,0,1.222-1.387,1.223,1.223,0,0,0-1.23-1.323,1.241,1.241,0,0,0-1.191,1.316m2.391,1.362h-.016a1.558,1.558,0,0,1-1.261.608,1.94,1.94,0,0,1,.007-3.862,1.581,1.581,0,0,1,1.253.622h.016V46.5h.622v6.423h-.622Z"
              transform="translate(7.794 2.642)"
            />
            <path
              id="Path_14"
              data-name="Path 14"
              d="M143.755,49.971a.584.584,0,0,0-.5-.373.443.443,0,0,0-.45.4c0,.335.42.46.848.638a1.113,1.113,0,0,1,.849,1.05,1.2,1.2,0,0,1-1.238,1.191,1.315,1.315,0,0,1-1.293-.926l.553-.234c.155.342.335.583.747.583a.557.557,0,0,0,.593-.56c0-.818-1.651-.538-1.651-1.69a1.042,1.042,0,0,1,1.09-1.035,1.106,1.106,0,0,1,.973.676Z"
              transform="translate(8.067 2.785)"
            />
            <path
              id="Path_15"
              data-name="Path 15"
              d="M148.916,50.913a1.264,1.264,0,0,0,1.2,1.394,1.248,1.248,0,0,0,1.222-1.385,1.223,1.223,0,0,0-1.23-1.323,1.24,1.24,0,0,0-1.191,1.315m3.013,1.87h-.622v-.506h-.016a1.537,1.537,0,0,1-1.261.607,1.94,1.94,0,0,1,.007-3.862,1.581,1.581,0,0,1,1.253.624h.016v-.522h.622Z"
              transform="translate(8.425 2.785)"
            />
            <path
              id="Path_16"
              data-name="Path 16"
              d="M84.03,58.449a1.078,1.078,0,0,0-1.012-.638.93.93,0,0,0-.972.91c0,.507.521.741.918.919l.389.171c.763.334,1.41.717,1.41,1.651a1.853,1.853,0,0,1-3.676.3l.639-.18A1.21,1.21,0,0,0,82.9,62.669a1.18,1.18,0,0,0,1.207-1.145c0-.686-.537-.919-1.082-1.168l-.358-.156c-.685-.312-1.277-.662-1.277-1.5A1.541,1.541,0,0,1,83.034,57.2a1.685,1.685,0,0,1,1.519.911Z"
              transform="translate(4.608 3.25)"
            />
            <rect
              id="Rectangle_1"
              data-name="Rectangle 1"
              width="0.622"
              height="6.423"
              transform="translate(90.895 60.002)"
            />
            <path
              id="Path_17"
              data-name="Path 17"
              d="M88.591,61.188a1.263,1.263,0,0,0,1.2,1.393A1.247,1.247,0,0,0,91.012,61.2a1.222,1.222,0,0,0-1.229-1.323,1.241,1.241,0,0,0-1.192,1.316M91.6,63.056h-.621V62.55h-.016a1.54,1.54,0,0,1-1.262.608,1.94,1.94,0,0,1,.007-3.863,1.586,1.586,0,0,1,1.254.624h.016V59.4H91.6Z"
              transform="translate(4.998 3.369)"
            />
            <path
              id="Path_18"
              data-name="Path 18"
              d="M94.3,61.852l1.09-2.461h.7l-1.79,3.9-1.8-3.9h.708Z"
              transform="translate(5.256 3.374)"
            />
            <path
              id="Path_19"
              data-name="Path 19"
              d="M99.564,60.829a1.116,1.116,0,0,0-1.075-.957,1.156,1.156,0,0,0-1.113.957Zm-2.218.529a1.183,1.183,0,0,0,1.2,1.222,1.252,1.252,0,0,0,1.114-.756l.529.3a1.849,1.849,0,0,1-1.69,1.028,1.772,1.772,0,0,1-1.79-1.884,1.773,1.773,0,1,1,3.511.086Z"
              transform="translate(5.495 3.369)"
            />
            <rect
              id="Rectangle_2"
              data-name="Rectangle 2"
              width="1.596"
              height="0.576"
              transform="translate(106.576 64.261)"
            />
            <path
              id="Path_20"
              data-name="Path 20"
              d="M104.17,63.2h-.622V60.122H103.2v-.577h.342V58.09a1.886,1.886,0,0,1,.163-.95,1.065,1.065,0,0,1,.943-.46,1.454,1.454,0,0,1,.444.078v.631a1.244,1.244,0,0,0-.421-.086c-.506,0-.506.358-.506,1v1.238h.927v.577h-.927Z"
              transform="translate(5.864 3.22)"
            />
            <path
              id="Path_21"
              data-name="Path 21"
              d="M106.641,59.794h.015a.944.944,0,0,1,.811-.5,1.106,1.106,0,0,1,.561.171l-.3.569a.606.606,0,0,0-.373-.164c-.686,0-.717.88-.717,1.355v1.829h-.622V59.4h.622Z"
              transform="translate(6.024 3.369)"
            />
            <path
              id="Path_22"
              data-name="Path 22"
              d="M111.617,60.829a1.116,1.116,0,0,0-1.075-.957,1.157,1.157,0,0,0-1.113.957Zm-2.219.529a1.184,1.184,0,0,0,1.2,1.222,1.251,1.251,0,0,0,1.114-.756l.529.3a1.846,1.846,0,0,1-1.689,1.028,1.772,1.772,0,0,1-1.791-1.884,1.773,1.773,0,1,1,3.511.086Z"
              transform="translate(6.18 3.369)"
            />
            <path
              id="Path_23"
              data-name="Path 23"
              d="M115.993,60.829a1.115,1.115,0,0,0-1.074-.957,1.157,1.157,0,0,0-1.114.957Zm-2.218.529a1.184,1.184,0,0,0,1.2,1.222,1.254,1.254,0,0,0,1.114-.756l.528.3a1.846,1.846,0,0,1-1.689,1.028,1.773,1.773,0,0,1-1.791-1.884,1.774,1.774,0,1,1,3.512.086Z"
              transform="translate(6.429 3.369)"
            />
            <path
              id="Path_24"
              data-name="Path 24"
              d="M123.321,58.449a1.079,1.079,0,0,0-1.012-.638.93.93,0,0,0-.972.91c0,.507.521.741.918.919l.389.171c.763.334,1.409.717,1.409,1.651a1.853,1.853,0,0,1-3.675.3l.638-.18a1.211,1.211,0,0,0,1.176,1.091,1.181,1.181,0,0,0,1.207-1.145c0-.686-.538-.919-1.083-1.168l-.358-.156c-.685-.312-1.277-.662-1.277-1.5a1.541,1.541,0,0,1,1.643-1.494,1.683,1.683,0,0,1,1.518.911Z"
              transform="translate(6.84 3.25)"
            />
            <path
              id="Path_25"
              data-name="Path 25"
              d="M125.893,61.432c0,.615.07,1.145.824,1.145s.825-.529.825-1.145V59.391h.624V61.5c0,.973-.367,1.651-1.449,1.651s-1.448-.677-1.448-1.651v-2.11h.624Z"
              transform="translate(7.118 3.375)"
            />
            <path
              id="Path_26"
              data-name="Path 26"
              d="M130.2,61.188a1.264,1.264,0,0,0,1.2,1.393,1.248,1.248,0,0,0,1.223-1.386,1.223,1.223,0,0,0-1.231-1.323,1.241,1.241,0,0,0-1.191,1.316m.032-1.269h.016a1.537,1.537,0,0,1,1.261-.624,1.819,1.819,0,0,1,1.752,1.939,1.779,1.779,0,0,1-1.76,1.922,1.539,1.539,0,0,1-1.253-.615h-.016v2.57h-.622V59.4h.622Z"
              transform="translate(7.365 3.369)"
            />
            <path
              id="Path_27"
              data-name="Path 27"
              d="M135,61.188a1.264,1.264,0,0,0,1.2,1.393,1.247,1.247,0,0,0,1.222-1.386,1.222,1.222,0,0,0-1.23-1.323A1.24,1.24,0,0,0,135,61.188m.032-1.269h.015a1.54,1.54,0,0,1,1.262-.624,1.819,1.819,0,0,1,1.751,1.939,1.779,1.779,0,0,1-1.76,1.922,1.542,1.542,0,0,1-1.253-.615h-.015v2.57h-.624V59.4h.624Z"
              transform="translate(7.638 3.369)"
            />
            <rect
              id="Rectangle_3"
              data-name="Rectangle 3"
              width="0.622"
              height="6.423"
              transform="translate(147.142 60.002)"
            />
            <path
              id="Path_28"
              data-name="Path 28"
              d="M140.9,59.391h.725l1.291,2.624,1.184-2.624h.709l-2.7,5.715h-.709l1.184-2.4Z"
              transform="translate(8.006 3.375)"
            />
            <path
              id="Path_29"
              data-name="Path 29"
              d="M153.009,58.589a2.513,2.513,0,0,0-1.791-.779,2.43,2.43,0,0,0,.016,4.859,2.576,2.576,0,0,0,1.775-.779v.81a3.033,3.033,0,0,1-4.812-2.437,3.07,3.07,0,0,1,3.059-3.059,2.838,2.838,0,0,1,1.752.576Z"
              transform="translate(8.421 3.25)"
            />
            <path
              id="Path_30"
              data-name="Path 30"
              d="M154.972,56.776v3.193l.015.016a1.228,1.228,0,0,1,1.043-.545c1.012,0,1.246.731,1.246,1.587V63.2h-.622V61.136c0-.593-.047-1.121-.772-1.121-.856,0-.91.794-.91,1.449V63.2h-.624V56.776Z"
              transform="translate(8.77 3.226)"
            />
            <path
              id="Path_31"
              data-name="Path 31"
              d="M159.134,61.188a1.263,1.263,0,0,0,1.2,1.393,1.248,1.248,0,0,0,1.223-1.386,1.223,1.223,0,0,0-1.231-1.323,1.24,1.24,0,0,0-1.191,1.316m3.013,1.868h-.622V62.55h-.016a1.538,1.538,0,0,1-1.261.608,1.94,1.94,0,0,1,.007-3.863,1.582,1.582,0,0,1,1.253.624h.016V59.4h.622Z"
              transform="translate(9.006 3.369)"
            />
            <path
              id="Path_32"
              data-name="Path 32"
              d="M164.335,63.149h-.621v-3.66h.621Zm.133-5.038a.444.444,0,0,1-.888,0,.444.444,0,1,1,.888,0"
              transform="translate(9.295 3.277)"
            />
            <path
              id="Path_33"
              data-name="Path 33"
              d="M166.7,59.84h.016a1.227,1.227,0,0,1,1.043-.545c1.011,0,1.246.731,1.246,1.588v2.172h-.624V60.992c0-.592-.046-1.121-.77-1.121-.857,0-.911.795-.911,1.449v1.735h-.624V59.4h.624Z"
              transform="translate(9.437 3.369)"
            />
            <path
              id="Path_34"
              data-name="Path 34"
              d="M27.067,33.546c-.092-.054-.2-.113-.31-.175l-.074-.04c-.15-.081-.329-.174-.525-.269l-.068-.033q-.243-.116-.518-.235l-.165-.069c-.127-.053-.26-.1-.4-.157-.111-.042-.223-.081-.335-.122l-.287-.1c-.106-.035-.213-.068-.32-.1l-.175-.051c-.272-.076-.506-.135-.743-.189L23,31.976c-.113-.024-.223-.044-.333-.064s-.2-.038-.29-.053c-.112-.018-.223-.035-.334-.051s-.211-.027-.32-.041-.211-.024-.3-.033c-.133-.014-.268-.022-.4-.032l-.242-.016c-.221-.011-.444-.016-.671-.016A8.4,8.4,0,0,1,12.5,26.933a19.88,19.88,0,0,1,7.606-1.493A20.114,20.114,0,1,1,.156,48.11H.44a8.952,8.952,0,0,1,7.7,4.52,13.9,13.9,0,0,0,25.848-7.075,13.521,13.521,0,0,0-5.342-10.933c-.386-.282-1.58-1.075-1.58-1.075"
              transform="translate(0.009 1.445)"
            />
            <path
              id="Path_35"
              data-name="Path 35"
              d="M20.114,44.622A20.114,20.114,0,1,1,40.148,22.786,8.761,8.761,0,0,1,32.4,18.008,13.891,13.891,0,1,0,20.114,38.391c.262,0,.521-.013.778-.027a8.388,8.388,0,0,1,6.832,4.761,19.881,19.881,0,0,1-7.61,1.5"
              transform="translate(0 0.249)"
            />
            <line id="Line_1" data-name="Line 1" y2="70.038" transform="translate(62.494 0)" fill="#fff" />
            <rect
              id="Rectangle_4"
              data-name="Rectangle 4"
              width="1.557"
              height="70.038"
              transform="translate(61.716)"
              fill="#cc502e"
            />
            <path
              id="Path_36"
              data-name="Path 36"
              d="M87.165,6.64a2,2,0,0,0-1.875-1.1A1.843,1.843,0,0,0,83.339,7.2c0,.914.9,1.394,1.626,1.673L85.8,9.2c1.658.651,2.9,1.564,2.9,3.517a3.6,3.6,0,0,1-3.733,3.639,3.638,3.638,0,0,1-3.609-3.268l1.471-.31a2.208,2.208,0,1,0,4.415-.061c0-1.256-1.007-1.8-2.013-2.216l-.806-.34c-1.332-.573-2.54-1.332-2.54-2.958a3.185,3.185,0,0,1,3.438-3.02,3.332,3.332,0,0,1,3.006,1.766Z"
              transform="translate(4.623 0.237)"
            />
            <path
              id="Path_37"
              data-name="Path 37"
              d="M94.2,14.759h3.547v1.333h-5V4.413H94.2Z"
              transform="translate(5.27 0.251)"
            />
            <path
              id="Path_38"
              data-name="Path 38"
              d="M105.725,7.135l-2.076,4.724H107.8Zm-2.68,6.056-1.332,2.927h-1.564l5.576-12.175L111.3,16.118h-1.564L108.4,13.19Z"
              transform="translate(5.691 0.224)"
            />
            <path
              id="Path_39"
              data-name="Path 39"
              d="M116.721,13.055l3.47-8.643h1.595l-5.064,12.3-5.081-12.3h1.6Z"
              transform="translate(6.344 0.251)"
            />
            <path
              id="Path_40"
              data-name="Path 40"
              d="M125.111,4.413h6.273V5.744h-4.817V9.013h4.677v1.332h-4.677v4.414h4.817v1.333h-6.273Z"
              transform="translate(7.109 0.251)"
            />
            <rect
              id="Rectangle_5"
              data-name="Rectangle 5"
              width="3.391"
              height="1.163"
              transform="translate(143.157 10.796)"
            />
            <path
              id="Path_41"
              data-name="Path 41"
              d="M144.918,5.744V9.028h4.058V10.36h-4.058v5.731h-1.456V4.413h5.654V5.744Z"
              transform="translate(8.152 0.251)"
            />
            <path
              id="Path_42"
              data-name="Path 42"
              d="M154.812,9.942h.465c1.488,0,3.1-.28,3.1-2.138,0-1.827-1.735-2.09-3.176-2.09h-.388Zm5.809,6.149h-1.766l-3.439-4.941h-.6v4.941h-1.455V4.412h1.766a6.176,6.176,0,0,1,3.082.542,3.235,3.235,0,0,1,1.534,2.789,3.181,3.181,0,0,1-2.8,3.268Z"
              transform="translate(8.714 0.25)"
            />
            <path
              id="Path_43"
              data-name="Path 43"
              d="M164.248,4.413h6.273V5.744H165.7V9.013h4.677v1.332H165.7v4.414h4.817v1.333h-6.273Z"
              transform="translate(9.333 0.251)"
            />
            <path
              id="Path_44"
              data-name="Path 44"
              d="M174.816,4.413h6.273V5.744h-4.817V9.013h4.677v1.332h-4.677v4.414h4.817v1.333h-6.273Z"
              transform="translate(9.933 0.251)"
            />
            <path
              id="Path_45"
              data-name="Path 45"
              d="M86.347,27.273,84.27,32h4.152Zm-2.68,6.056-1.332,2.927H80.77l5.577-12.175,5.576,12.175H90.357l-1.332-2.927Z"
              transform="translate(4.589 1.368)"
            />
            <path
              id="Path_46"
              data-name="Path 46"
              d="M96.635,34.9h3.547v1.332h-5V24.55h1.456Z"
              transform="translate(5.408 1.395)"
            />
            <path
              id="Path_47"
              data-name="Path 47"
              d="M105.21,34.9h3.547v1.332h-5V24.55h1.456Z"
              transform="translate(5.896 1.395)"
            />
            <rect
              id="Rectangle_6"
              data-name="Rectangle 6"
              width="1.455"
              height="11.679"
              transform="translate(118.665 25.945)"
            />
            <path
              id="Path_48"
              data-name="Path 48"
              d="M123.065,27.273,120.989,32h4.151Zm-2.68,6.056-1.332,2.927h-1.564l5.576-12.175,5.576,12.175h-1.564l-1.332-2.927Z"
              transform="translate(6.676 1.368)"
            />
            <path
              id="Path_49"
              data-name="Path 49"
              d="M131.766,24.023l8.766,9.185V24.58h1.456V36.74l-8.766-9.186v8.705h-1.456Z"
              transform="translate(7.487 1.365)"
            />
            <path
              id="Path_50"
              data-name="Path 50"
              d="M154.873,26.794a4.644,4.644,0,0,0-7.7,3.624,4.595,4.595,0,0,0,7.7,3.532v1.718a6.308,6.308,0,0,1-3.067.821,6.045,6.045,0,0,1-6.087-6.025,6.139,6.139,0,0,1,6.212-6.149,5.633,5.633,0,0,1,2.942.789Z"
              transform="translate(8.28 1.381)"
            />
            <path
              id="Path_51"
              data-name="Path 51"
              d="M158.956,24.55h6.273v1.333h-4.818v3.268h4.679v1.333h-4.679V34.9h4.818v1.332h-6.273Z"
              transform="translate(9.032 1.395)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default HeaderIcon;
