import { Col, Row } from 'antd';
import React from 'react';
import { CustomerFromLogger } from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyDetailsModal/companyDetails-modal.config';
import styles from 'src/app/pages/PortalPage/CustomerAndUserPage/UserInfoTabModalComponent/UserInfoTabModalContent.module.scss';

interface ContentProps {
  userInfo: CustomerFromLogger;
}
const UserInfoTabModalContent: React.FC<ContentProps> = ({ userInfo }) => {
  const users = userInfo?.data[0];

  const standardUserCount = users?.standardUserCount ? users?.standardUserCount : 0;
  const adminUserCount = users?.adminUserCount ? users?.adminUserCount : 0;
  const superuserUserCount = users?.superuserUserCount ? users?.superuserUserCount : 0;

  return (
    <div className={styles.content} id="customerAndUser_modalShowContents">
      <br />
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Number of Standard User {`(${standardUserCount})`}
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {users?.standardUser ? users?.standardUser?.map((user) => <div key={user}>{user}</div>) : <div>- </div>}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Number of Admin User {`(${adminUserCount})`}
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {users?.adminUser ? users?.adminUser?.map((user) => <div key={user}>{user}</div>) : <div>-</div>}
        </Col>
      </Row>
      <Row className={styles.contentRow} id="customerAndUser_showRow">
        <Col span={8} className={styles.contentKeyCol} id="customerAndUser_showCol">
          Number of Super User {`(${superuserUserCount})`}
        </Col>
        <Col span={16} className={styles.contentValueCol}>
          {users?.superuserUser ? users?.superuserUser?.map((user) => <div key={user}>{user}</div>) : <div>-</div>}
        </Col>
      </Row>
    </div>
  );
};

export default UserInfoTabModalContent;
