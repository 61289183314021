import React from 'react';
import { iconType } from 'src/types';

const ArrowUpwardIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill={color || '#FFFFFF'}
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
      </svg>
    </div>
  );
};

ArrowUpwardIcon.defaultProps = {
  style: { width: '36px', height: '36px' },
  // color: 'var(--primary-color)',
  onClick: undefined,
  className: undefined,
};

export default ArrowUpwardIcon;
