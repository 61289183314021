import { AxiosPromise } from 'axios';
import { urlHelper } from 'src/app/helpers';
import { isDefined } from 'class-validator';
import queryString from 'query-string';
import { headerPageSize } from 'src/app/pages/NotificationsPage/notification-list.config';
import {
  GetNotificationInfoDataType,
  GetNotificationInfoResultType,
} from 'src/app/services/notifications/notifications-service.type';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';
import * as servicesNotificationTypes from 'src/app/services/notifications/notifications-service.type';

export const getNotifications = (
  query: servicesNotificationTypes.GetNotificationsDataType,
): AxiosPromise<servicesNotificationTypes.GetNotificationsResultType> => {
  return axios.get(urlsConfig.notifications.lookup, {
    params: query,
    paramsSerializer: (params) => {
      return queryString.stringify(params);
    },
    blockSpinner: query.limit === headerPageSize,
  });
};

export const selectedSetStatus = (
  data: servicesNotificationTypes.ChangeSelectedNotificationsStatus,
): AxiosPromise<servicesNotificationTypes.NotificationResultType[]> => {
  return axios.patch(urlsConfig.notifications.selectUpdateStatus, data);
};

export const allSetStatus = (
  data: servicesNotificationTypes.ChangeAllNotificationStatus,
): AxiosPromise<servicesNotificationTypes.GetNotificationsResultType> => {
  return axios.patch(urlsConfig.notifications.allUpdateStatus, data);
};

export const multipleDelete = (ids: string[]) => {
  const query = {
    ids: ids.join(','),
  };
  return axios.delete(urlHelper.addQueryParameters(query, urlsConfig.notifications.deleteNotification));
};

export const deleteAll = (data: servicesNotificationTypes.DeleteAllCheckboxNotificationsType) => {
  const query: any = {};
  query.filterArchiveStatus = data.filterArchiveStatus;
  if (data.filterLabelIds) {
    query.filterLabelIds = data.filterLabelIds.join(',');
  }
  if (data.filterPriorities) {
    query.filterPriorities = data.filterPriorities.join(',');
  }
  if (isDefined(data.filterReadStatus)) {
    query.filterReadStatus = data.filterReadStatus;
  }
  if (isDefined(data.filterStarStatus)) {
    query.filterStarStatus = data.filterStarStatus;
  }
  return axios.delete(urlHelper.addQueryParameters(query, urlsConfig.notifications.deleteAllNotification));
};

// export const addLabelToNotification = (
//   data: AddLabelToNotificationType
// ): AxiosPromise<NotificationResultType> => {
//   return axios.post(urlsConfig.notifications.addLabel, data);
// };

// export const removeLabelToNotification = (
//   data: RemoveLabelToNotificationType
// ): AxiosPromise<NotificationResultType> => {
//   return axios.post(urlsConfig.notifications.removeLabel, data);
// };

// export const getUnreadNotificationsCount = () => {
//   const query = {};
//   return axios.get(
//     urlHelper.addQueryParameters(
//       query,
//       urlsConfig.notifications.getUnreadNotificationsCount
//     )
//   );
// };

export const getNotificationInfo = (data: GetNotificationInfoDataType): AxiosPromise<GetNotificationInfoResultType> => {
  return axios.get(urlHelper.addQueryParameters(data, urlsConfig.notifications.getNotificationInfo));
};

export const getUnreadNotificationsCount = () => {
  return axios.get(urlsConfig.notifications.getUnreadNotificationsCount, {
    blockSpinner: true,
  });
};
