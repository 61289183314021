import '@ant-design/flowchart/dist/index.css';
import ReactDOM from 'react-dom/client';
import 'react-grid-layout/css/styles.css';
import { Provider } from 'react-redux';
import 'react-resizable/css/styles.css';
import { BrowserRouter } from 'react-router-dom';
import App from 'src/app/App';
import { store } from 'src/app/redux/store';
import 'src/i18n';
import 'src/index.scss';
import 'src/leaflet.css';
import reportWebVitals from 'src/reportWebVitals';
import 'src/vectorMap.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
