// export const ROOT_USER_ID = "c44801e0-6e37-430c-8c2d-255b4f700357";
// export const ROOT_USER_ROLE_ID = "2244e95c-2706-4ac8-a451-6090be28a8b2";
// export const GUEST_USER_ROLE_ID = "c382e90b-d595-42cc-a58a-26c4a2fa0277";
export const STANDARD_USER_ROLE_ID = '359349e7-d362-4c57-ad65-cefc1a1a1656';
export const SUPER_USER_USER_ROLE_ID = '1a13a041-404b-45bf-b200-7f3b24451df9';

export const BRONZE_ACCOUNT_ID = '1d5eda4a-850a-4ed2-9fea-bf4f50da1973';
export const SILVER_ACCOUNT_ID = '5a0f1735-332e-4a1a-8a07-786a7f60425b';
export const GOLD_ACCOUNT_ID = '84280f6a-557d-40b7-b445-fa2dc9278e71';

export const USER_ROLE_BLACKLIST: string[] = [
  // ROOT_USER_ROLE_ID,
  // GUEST_USER_ROLE_ID,
  SUPER_USER_USER_ROLE_ID,
];

export const AGENT_CATEGORY_CIVILIAN_ID = '01e5caf1-bce6-43eb-a666-33f024ff230a';
export const AGENT_CATEGORY_FIRE_ID = '994759f7-8979-4fd7-8ffc-170fd98bdf3b';
export const AGENT_CATEGORY_HEALTH_ID = 'c723f278-e19a-4f74-8241-baadbd0490e5';
export const AGENT_CATEGORY_MILITARY_ID = '8cd37510-7202-4ca5-bece-e5445255a99a';
export const AGENT_CATEGORY_POLICE_ID = 'd2d3a4d5-a325-468c-a90d-7f0fd51842a5';
