import { Rule } from 'antd/es/form';
import { urlHelper } from 'src/app/helpers';
import { GetRulesDataQueryType, RulesResultType } from 'src/app/services/rule/rule-service.type';
import { urlsConfig } from 'src/configs';
import { CreateRule, UpdateRule } from 'src/types/rule.type';
import { apiSlice } from 'src/app/redux/api/apiSlice';

export const rulesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRules: builder.query<RulesResultType, GetRulesDataQueryType | void>({
      query: (params?: GetRulesDataQueryType) => ({
        url: urlsConfig.rules.lookup,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'Rules' as const,
                id,
              })),
              { type: 'Rules', id: 'LIST' },
            ]
          : [{ type: 'Rules', id: 'LIST' }],
    }),
    createRule: builder.mutation<Rule, CreateRule>({
      query: (data) => ({
        url: urlsConfig.rules.create,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Rules', id: 'LIST' }],
    }),
    updateRule: builder.mutation<Rule, UpdateRule>({
      query: (data) => ({
        url: urlsConfig.rules.update,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [{ type: 'Rules', id: data.id }],
    }),
    deleteRule: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `${urlsConfig.rules.delete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [{ type: 'Rules', id }],
    }),
  }),
});

export const {
  useGetRulesQuery,
  useLazyGetRulesQuery,
  useCreateRuleMutation,
  useDeleteRuleMutation,
  useUpdateRuleMutation,
} = rulesSlice;
