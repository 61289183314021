import React from 'react';

import { iconType } from 'src/types';

const FilterIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        width="18.753"
        height="21.194"
        viewBox="0 0 18.753 21.194"
      >
        <g id="_4" data-name="4" transform="translate(0 0)">
          <path
            id="Path_37021"
            data-name="Path 37021"
            d="M380.113,389h-6.771a.185.185,0,0,0-.179.294l1.168,2.258a.6.6,0,0,0,.483.293h3.821a.6.6,0,0,0,.482-.293l1.169-2.258A.184.184,0,0,0,380.113,389Z"
            transform="translate(-367.347 -375.631)"
            fill={color || '#92aabb'}
          />
          <path
            id="Path_37022"
            data-name="Path 37022"
            d="M352.763,349.9l1.168,2.258a.594.594,0,0,0,.482.293h7.677a.6.6,0,0,0,.482-.293l1.168-2.258a.186.186,0,0,0-.179-.294H352.944C352.755,349.61,352.679,349.741,352.763,349.9Z"
            transform="translate(-348.874 -340.695)"
            fill={color || '#92aabb'}
          />
          <path
            id="Path_37023"
            data-name="Path 37023"
            d="M393.564,428.744l1.481,2.863c.084.163.22.163.3,0l1.481-2.863a.186.186,0,0,0-.179-.294h-2.909A.185.185,0,0,0,393.564,428.744Z"
            transform="translate(-385.819 -410.535)"
            fill={color || '#92aabb'}
          />
          <path
            id="Path_37024"
            data-name="Path 37024"
            d="M311.973,271.064l1.168,2.258a.6.6,0,0,0,.483.293h15.388a.6.6,0,0,0,.482-.293l1.166-2.258a.186.186,0,0,0-.179-.294H312.145C311.969,270.77,311.89,270.9,311.973,271.064Z"
            transform="translate(-311.94 -270.77)"
            fill={color || '#92aabb'}
          />
          <path
            id="Path_37025"
            data-name="Path 37025"
            d="M332.37,310.484l1.168,2.258a.6.6,0,0,0,.482.293h11.532a.6.6,0,0,0,.482-.293l1.168-2.258a.186.186,0,0,0-.179-.294H332.549A.185.185,0,0,0,332.37,310.484Z"
            transform="translate(-330.409 -305.732)"
            fill={color || '#92aabb'}
          />
        </g>
      </svg>
    </div>
  );
};

FilterIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  style: { margin: '5px 0 -5px 0' },
  onClick: undefined,
  className: undefined,
};

export default FilterIcon;
