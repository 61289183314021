import React from 'react';

import { iconType } from 'src/types';

const PrevalenceIconPure: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg
        style={{ ...style }}
        id="XMLID_493_"
        xmlns="http://www.w3.org/2000/svg"
        width="37.893"
        height="37.892"
        viewBox="0 0 37.893 37.892"
      >
        <g id="Group_40498" data-name="Group 40498">
          <path
            id="Path_37336"
            data-name="Path 37336"
            d="M37.893,37.893,35.756,23.14,32.28,26.616l-2.665-2.665a11.839,11.839,0,0,0,0-10.007l2.665-2.665,3.477,3.476L37.892,0,23.138,2.137l3.476,3.476L23.949,8.279a11.839,11.839,0,0,0-10.007,0L11.277,5.614l3.476-3.476L0,0,2.136,14.755l3.476-3.476,2.67,2.67a11.837,11.837,0,0,0,0,10l-2.67,2.67L2.136,23.14,0,37.893l14.754-2.136-3.476-3.476,2.665-2.665a11.839,11.839,0,0,0,10.007,0l2.665,2.665-3.476,3.476ZM29.927,5.614,28.1,3.785l7.027-1.018L34.108,9.8,32.28,7.967l-3.912,3.912a11.933,11.933,0,0,0-2.353-2.353ZM5.613,7.967,3.784,9.8,2.767,2.768,9.794,3.785,7.966,5.614l3.912,3.912a11.962,11.962,0,0,0-1.259,1.094,11.811,11.811,0,0,0-1.093,1.26ZM7.966,32.281l1.828,1.828L2.767,35.127,3.784,28.1l1.828,1.828,3.913-3.913a11.85,11.85,0,0,0,2.352,2.354Zm10.98-3.9a9.436,9.436,0,1,1,6.672-2.764A9.374,9.374,0,0,1,18.946,28.383Zm7.069-.014a11.933,11.933,0,0,0,2.353-2.353l3.912,3.912L34.108,28.1l1.018,7.027L28.1,34.109l1.828-1.828Z"
            transform="translate(0 -0.001)"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
};

PrevalenceIconPure.defaultProps = {
  style: { width: '38px', height: '38px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default PrevalenceIconPure;
