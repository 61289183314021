import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { getLoginImages, LoginLayoutPropsType } from 'src/app/layouts/loginLayout/login-layout.config';
import styles from 'src/app/layouts/loginLayout/LoginLayout.module.scss';
import { LAYOUTVIEW } from 'src/configs/system.config';
import { LayoutViewType } from 'src/enums/user.enum';

const LoginLayout: React.FC<LoginLayoutPropsType> = () => {
  const location = useLocation();

  const [customerRegisterState, setCustomerRegisterState] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname.toLocaleUpperCase() === '/customerRegisterForm'.toLocaleUpperCase()) {
      setCustomerRegisterState(true);
    } else {
      setCustomerRegisterState(false);
    }
  }, [location]);

  return (
    <div
      id="login-layout"
      className={styles.container}
      style={{
        backgroundImage: `url(${getLoginImages(LAYOUTVIEW).background})`,
        backgroundSize: 'cover',
        paddingLeft: LAYOUTVIEW === LayoutViewType.LAYOUT_1 ? '11%' : '0px',
        justifyContent: LAYOUTVIEW === LayoutViewType.LAYOUT_1 ? 'start' : 'center',
      }}
    >
      <div className={styles.formContainer}>
        <img
          className={styles.logo}
          src={getLoginImages(LAYOUTVIEW).logo}
          alt="company-logo"
          style={customerRegisterState ? { marginTop: '5vh', cursor: 'pointer' } : { cursor: 'pointer' }}
        />
        <div
          className={styles.formWrapper}
          style={customerRegisterState ? { marginTop: '4rem' } : { marginTop: '2rem' }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
