import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as mapReduxType from 'src/app/redux/map/map.redux.type';
import { MapInstance } from 'src/app/redux/map/map.redux.type';
import { getLayers } from 'src/app/services/layersService/layers-server.service';
import { TLayers, layers } from 'src/app/services/layersService/layers-server.service.config';

const defaultState: mapReduxType.StateType = {
  activeLayer: undefined,
  availableLayers: [],
  mapInstance: MapInstance.DEFAULT,
};

const initialState: mapReduxType.StateType = defaultState;

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setActiveLayer: (state, action: PayloadAction<string>) => {
      state.activeLayer = action.payload;
    },
    setMapInstance: (state, action: PayloadAction<MapInstance>) => {
      state.mapInstance = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getLayersRedux.fulfilled, (state, action: PayloadAction<TLayers>) => {
      state.availableLayers = action.payload;
    });
  },
});

export const getLayersRedux = createAsyncThunk('/map/getLayers', async (_, { rejectWithValue }) => {
  try {
    const response = await getLayers();
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    rejectWithValue(message);
    return layers;
  }
});

export const { setActiveLayer, setMapInstance } = mapSlice.actions;
export default mapSlice.reducer;
