import { useMemo } from 'react';
import { useAppSelector } from 'src/app/redux/hook';

const usePermissions = () => {
  const { roles } = useAppSelector((state) => state.user);

  const permissions = useMemo(() => {
    const mergedPermissions: string[] = [];
    roles?.forEach((role) => {
      role.permissions.forEach((per) => {
        if (mergedPermissions.indexOf(per.permissionId) === -1) mergedPermissions.push(per.permissionId);
      });
    });
    return mergedPermissions;
  }, [roles]);
  return { permissions };
};

export default usePermissions;
