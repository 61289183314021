import JsPDF, { jsPDFOptions } from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { callAddFont as callBoldFont } from 'src/fonts/Vazir/static/Vazirmatn-Bold-normal';
import { callAddFont as callRegularFont } from 'src/fonts/Vazir/static/Vazirmatn-Regular-normal';

export interface PdfExportInterface {
  headers: string[];
  tableBodyValues: (string | number | any)[][];
  interestName?: string;
  options?: jsPDFOptions;
  docStyle?: UserOptions;
  isReturnFunc?: boolean;
}

export function pdfExport({
  headers,
  tableBodyValues,
  interestName,
  options,
  docStyle,
  isReturnFunc,
}: PdfExportInterface) {
  JsPDF.API.events.push(['addFonts', callRegularFont]);

  JsPDF.API.events.push(['addFonts', callBoldFont]);

  const doc = new JsPDF({ orientation: 'landscape', ...(options ? { ...options } : undefined) });

  doc.setFont('Vazirmatn-Regular');

  autoTable(doc, {
    head: [headers],
    body: tableBodyValues,
    styles: {
      font: 'Vazirmatn-Regular',
    },
    headStyles: {
      font: 'Vazirmatn-Bold',
      fontStyle: 'bold',
    },
    ...docStyle,
  });

  doc.save(`${interestName}.pdf`);
  if (isReturnFunc) {
    return doc.output('blob');
  }
}
