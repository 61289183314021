import { getLookupQuery } from 'src/app/helpers/url.helper';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  FunctionalAreaRecognizedLookupDataType,
  FunctionalAreaRecognizedLookupResultType,
} from 'src/app/redux/interests/functional-areas/functional-area-slice.type';
import { urlsConfig } from 'src/configs';

export const functionalAreaRecognizedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFunctionalAreasRecognized: builder.query<
      FunctionalAreaRecognizedLookupResultType,
      FunctionalAreaRecognizedLookupDataType | void
    >({
      query: (params: FunctionalAreaRecognizedLookupDataType) => ({
        url: urlsConfig.interest.recognized.funtionalArea,
        params: getLookupQuery(params),
      }),
    }),
  }),
});

export const { useGetFunctionalAreasRecognizedQuery } = functionalAreaRecognizedSlice;
