import { AxiosPromise } from 'axios';
import { urlHelper } from 'src/app/helpers';
import { convertUserUpdateToFormData } from 'src/app/redux/user/userSlice';
import * as servicesUserTypes from 'src/app/services/user/user-service.type';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';
import { User } from 'src/types/user.type';

// This endpoint is created to login process
export const login = (
  loginInformation: servicesUserTypes.LoginDataType,
  options: { blockErrorHandler: boolean },
): AxiosPromise<servicesUserTypes.LoginResultType> => {
  return axios.post(urlsConfig.user.login, loginInformation, options);
};

// This endpoint is created to retrieve user information
export const getMe = (): AxiosPromise<servicesUserTypes.GetInformationResultType> => {
  return axios.get(urlsConfig.user.getInformation);
};

// This endpoint created for the QR code check first time
export const getQrCode = (
  qrCodeData: servicesUserTypes.GetQrCodeDataType,
): AxiosPromise<servicesUserTypes.GetQrCodeResultType> => {
  return axios.post(urlsConfig.user.googleAuthGetQr, qrCodeData, {
    blockErrorHandler: true,
  });
};

// This endpoint created for the google authentication verification process
export const verifyGoogleAuthentication = (
  information: servicesUserTypes.VerifyGoogleAuthenticationDataType,
  options?: { blockErrorHandler: boolean },
): AxiosPromise<servicesUserTypes.VerifyGoogleAuthenticationResultType> => {
  return axios.post(urlsConfig.user.googleAuthLogin, information, options);
};

// This endpoint created for the mail authentication verification signature process
export const verifyMailAuthenticationSignature = (
  signatureInformation: servicesUserTypes.VerifyMailAuthenticationSignatureDataType,
  options?: { blockErrorHandler: boolean },
): AxiosPromise<servicesUserTypes.VerifyMailAuthenticationSignatureResultType> => {
  return axios.post(urlsConfig.user.verifyMailAuthenticationToken, signatureInformation, options);
};

export const verifyResetPasswordSignature = (
  signatureInformation: servicesUserTypes.VerifyMailAuthenticationSignatureDataType,
  options?: { blockErrorHandler: boolean },
): AxiosPromise<servicesUserTypes.VerifyMailAuthenticationSignatureResultType> => {
  return axios.post(urlsConfig.user.verifyResetToken, signatureInformation, options);
};

// This endpoint created for the user's mail authentication process
export const authenticateUser = (
  mailAuthenticationInformation: servicesUserTypes.MailAuthenticationDataType,
  options?: { blockErrorHandler: boolean },
): AxiosPromise<servicesUserTypes.MailAuthenticationResultType> => {
  return axios.post(urlsConfig.user.authenticateUser, mailAuthenticationInformation, options);
};

// This endpoint created for the user's register process
export const register = (
  registerInformation: servicesUserTypes.RegisterDataType,
  options: { blockErrorHandler: boolean },
): AxiosPromise<servicesUserTypes.RegisterResultType> => {
  return axios.post(urlsConfig.user.register, registerInformation, options);
};

// This endpoint created for the user's multiple account register process
export const multipleAccountRegister = (
  registerInformation: servicesUserTypes.MultipleAccountRegisterDataType,
  options: { blockErrorHandler: boolean },
): AxiosPromise<servicesUserTypes.MultipleAccountRegisterResultType> => {
  return axios.post(urlsConfig.user.multipleAccountRegister, registerInformation, options);
};

// This endpoint created for the user's verify register signature process
export const verifyRegisterSignature = (
  signatureInformation: servicesUserTypes.VerifyRegisterSignatureDataType,
  options?: { blockErrorHandler: boolean },
): AxiosPromise<servicesUserTypes.VerifyRegisterSignatureResultType> => {
  return axios.post(urlsConfig.user.verifyRegisterToken, signatureInformation, options);
};

// This endpoint created for the user forgot password process
export const forgotPassword = (
  forgotPasswordInformation: servicesUserTypes.ForgotPasswordDataType,
  options?: { blockErrorHandler: boolean },
): AxiosPromise<servicesUserTypes.ForgotPasswordResultType> => {
  return axios.post(urlsConfig.user.forgotPassword, forgotPasswordInformation, options);
};

export const getUsers = (data?: {
  showSelf?: boolean;
  customerId?: string;
}): AxiosPromise<servicesUserTypes.GetUsersResultType> => {
  // userstate customer id parametre olarak verilecek
  // if (data === undefined) {
  //   return axios.get(urlsConfig.user.getUsers);
  // }

  const query: servicesUserTypes.GetUsersDataQueryType = {
    customerIds: '',
  };
  if (data?.customerId) {
    query.customerIds = data.customerId;
  }
  return axios.get(urlHelper.addQueryParameters(urlHelper.getLookupQuery(query), urlsConfig.user.getUsers));
  // return axios.get(`${urlsConfig.user.getUsers}?customerIds=${data.customerId}`);
};

// this service enpoint will update for UserSettings list
export const updateUser = (
  payload: servicesUserTypes.UpdateUserDataType,
): AxiosPromise<servicesUserTypes.UpdateUserResultType> => {
  // TODO: kalan fieldları daha sonra form dataya ekle
  const formData = convertUserUpdateToFormData(payload);

  const data = axios.patch(`${urlsConfig.user.updateUser}/${payload.id}`, formData);

  return data;
};

export const updateProfilePassword = (
  userPassword: servicesUserTypes.UpdateProfilePasswordDataType,
): AxiosPromise<servicesUserTypes.UpdateProfilePasswordResultType> => {
  return axios.patch(urlsConfig.user.updatePassword, userPassword);
};

export const deleteUser = (id: string): AxiosPromise<servicesUserTypes.DeleteUserResultType> => {
  return axios.delete(`${urlsConfig.user.deleteUser}/${id}`);
};

export const updateIsFirstAdmin = (data: servicesUserTypes.UpdateIsFirstAdminDataType): AxiosPromise<User> => {
  return axios.patch(urlsConfig.user.updateIsFirstAdmin, data);
};

export const updateTwoFactorAuthentication = (
  data: servicesUserTypes.UpdateCustomerTwoFADataType,
): AxiosPromise<User> => {
  return axios.patch(urlsConfig.user.updateCustomerTwoFA, data);
};

export const resetPassword = (data: servicesUserTypes.ResetPasswordType) => {
  return axios.post(urlsConfig.user.resetPassword, data);
};

export const lostQrCode = (lostQrCodeInformation: servicesUserTypes.LostQrCodeRequestType): AxiosPromise<void> => {
  return axios.post(urlsConfig.user.lostQrCodeMail, lostQrCodeInformation);
};

export const logout = (): AxiosPromise<void> => {
  return axios.post(urlsConfig.user.logout);
};

export default {
  login,
  getMe,
  getQrCode,
  verifyGoogleAuthentication,
  verifyMailAuthenticationSignature,
  verifyResetPasswordSignature,
  authenticateUser,
  register,
  multipleAccountRegister,
  verifyRegisterSignature,
  forgotPassword,
  resetPassword,
  getUsers,
  updateUser,
  deleteUser,
  updateProfilePassword,
  updateIsFirstAdmin,
  updateTwoFactorAuthentication,
  lostQrCode,
  logout,
};
