import React from 'react';

import { iconType } from 'src/types';

const NetworkIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : {}}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
        <circle id="Ellipse_56" data-name="Ellipse 56" cx="15.5" cy="15.5" r="15.5" fill={circleColor} />
        <g id="Network_v2" transform="translate(3.001 3.001)">
          <path
            id="Path_37269"
            data-name="Path 37269"
            d="M296.8,545.32a.72.72,0,0,0,0,1,.7.7,0,0,0,.51.22.74.74,0,0,0,.53-.22.72.72,0,0,0,.21-.52.73.73,0,0,0-1.25-.51Z"
            transform="translate(-282.67 -531.19)"
            fill={color}
          />
          <path
            id="Path_37270"
            data-name="Path 37270"
            d="M295.85,547.37a.71.71,0,0,0,.47-.3.69.69,0,0,0,.13-.54.74.74,0,0,0-.83-.61,2.16,2.16,0,0,1-2.1-3.4,2.167,2.167,0,0,1,3.89.93,2.22,2.22,0,0,1,0,.77.73.73,0,0,0,.58.86.72.72,0,0,0,.55-.11.76.76,0,0,0,.31-.47,3.6,3.6,0,1,0-3,2.87Z"
            transform="translate(-282.67 -531.19)"
            fill={color}
          />
          <path
            id="Path_37271"
            data-name="Path 37271"
            d="M294.54,535.12v3.46a.73.73,0,0,0,1.46,0v-3.46a2.043,2.043,0,0,0-.82-3.93h0a2.06,2.06,0,0,0-1.43.6,2,2,0,0,0-.6,1.44A2.06,2.06,0,0,0,294.54,535.12Zm.74-1.08v-.25a.57.57,0,0,1-.57-.57.58.58,0,0,1,.57-.57h0a.57.57,0,0,1,.56.58.56.56,0,0,1-.56.56V534Z"
            transform="translate(-282.67 -531.19)"
            fill={color}
          />
          <path
            id="Path_37272"
            data-name="Path 37272"
            d="M296,552.45V549a.73.73,0,0,0-1.46,0v3.45a2.048,2.048,0,0,0,.81,3.93,2.081,2.081,0,0,0,.55-.07,2,2,0,0,0,.18-3.86Zm-.16,1.9a.57.57,0,0,1-.57.57.58.58,0,0,1-.57-.57.57.57,0,0,1,.57-.57.56.56,0,0,1,.57.57Z"
            transform="translate(-282.67 -531.19)"
            fill={color}
          />
          <path
            id="Path_37273"
            data-name="Path 37273"
            d="M286.61,544.52h3.45a.74.74,0,1,0,0-1.47h-3.45a2.08,2.08,0,0,0-.9-1,2.036,2.036,0,1,0-.96,3.81,1.748,1.748,0,0,0,.55-.08A2,2,0,0,0,286.61,544.52Zm-1.33-.74a.57.57,0,0,1-1.14,0,.58.58,0,0,1,.57-.57A.57.57,0,0,1,285.28,543.78Z"
            transform="translate(-282.67 -531.19)"
            fill={color}
          />
          <path
            id="Path_37274"
            data-name="Path 37274"
            d="M307.8,543.24a2.05,2.05,0,0,0-2-1.49h0a2,2,0,0,0-1.89,1.3h-3.46a.74.74,0,0,0,0,1.47h3.46a2,2,0,0,0,.9,1,2.06,2.06,0,0,0,1,.26,2,2,0,0,0,.55-.07A2.05,2.05,0,0,0,307.8,543.24Zm-1.4.55a.56.56,0,0,1-.57.56h0a.571.571,0,0,1,.04-1.14h0a.57.57,0,0,1,.53.58Z"
            transform="translate(-282.67 -531.19)"
            fill={color}
          />
          <path
            id="Path_37275"
            data-name="Path 37275"
            d="M291.59,546.74h0a.72.72,0,0,0-.52.21l-2.44,2.45a2.064,2.064,0,1,0,1.04,1.03l2.45-2.44a.75.75,0,0,0,0-1A.72.72,0,0,0,291.59,546.74ZM291.93,547.13Zm-3.72,4.53a.573.573,0,0,1-.81-.81.55.55,0,0,1,.4-.16.56.56,0,0,1,.41.16.58.58,0,0,1,0,.81Z"
            transform="translate(-282.67 -531.19)"
            fill={color}
          />
          <path
            id="Path_37276"
            data-name="Path 37276"
            d="M299,540.84a.7.7,0,0,0,.52-.22l2.44-2.44a2.05,2.05,0,0,0,1.38.1,2,2,0,1,0-2.41-1.14l-2.44,2.45a.72.72,0,0,0,0,1,.7.7,0,0,0,.51.25Zm3.39-4.93a.59.59,0,0,1,.4-.16h0a.56.56,0,0,1,.4.17.57.57,0,0,1,0,.76h-.05a.56.56,0,0,1-.73.06v-.13h-.13a.58.58,0,0,1,.06-.7Z"
            transform="translate(-282.67 -531.19)"
            fill={color}
          />
          <path
            id="Path_37277"
            data-name="Path 37277"
            d="M301.92,549.4l-2.45-2.4a.73.73,0,0,0-1,1l2.44,2.44a1.959,1.959,0,1,0,1.04-1.03Zm.36,1.53h.13v-.13a.51.51,0,0,1,.33-.09.53.53,0,0,1,.39.18.56.56,0,0,1,0,.77.57.57,0,0,1-.86-.73Z"
            transform="translate(-282.67 -531.19)"
            fill={color}
          />
          <path
            id="Path_37278"
            data-name="Path 37278"
            d="M286.81,538.1a2,2,0,0,0,1.82.08l2.44,2.44a.73.73,0,0,0,.52.22h0a.73.73,0,0,0,.52-.22.74.74,0,0,0,0-1l-2.45-2.44a2.043,2.043,0,1,0-2.85.92Zm.43-1.78a.59.59,0,0,1,.16-.4.6.6,0,0,1,.41-.17.58.58,0,0,1,.4.17.57.57,0,0,1,0,.8.56.56,0,0,1-.81,0,.58.58,0,0,1-.16-.4Z"
            transform="translate(-282.67 -531.19)"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
};

NetworkIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  color: 'black',
  onClick: undefined,
  className: undefined,
};

export default NetworkIcon;
