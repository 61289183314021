import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/app/redux/hook';
import { LOGIN_URL } from 'src/configs/system.config';

const ProtectedRoute = () => {
  const user = useAppSelector((state) => state.user);
  if (!user.token || !user.roles.length) {
    window.location.replace(LOGIN_URL);
    return null;

    // return <Navigate to={LOGIN_URL} state={{ from: location }} replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
