import { DefaultOptionType } from 'antd/es/select';

export function getEnumKeyByValue<T extends Record<string | number, string | number>>(
  enumClass: T,
  enumValue: string | number | undefined,
  raw: boolean = false,
): keyof T {
  if (enumValue === undefined || enumValue === null) {
    return '';
  }
  const key = Object.keys(enumClass).filter((obj) => enumClass[obj].toString() === enumValue.toString())[0];

  if (!key) return '';

  return !raw ? key.replaceAll('_', ' ') : key;
}

export function getEnumEntries<T extends {}>(enumClass: T): [string, any][] {
  return Object.entries(enumClass).filter((x) => Number.isNaN(parseInt(x[0], 10)));
}

export const getEnumSelectOptions = <T extends {}>(enumClass: T, filter?: T[keyof T][]): DefaultOptionType[] => {
  const result = getEnumEntries(enumClass).map(([label, value]) => ({
    label: label.replaceAll('_', ' '),
    value,
  }));
  if (filter) {
    return result.filter((obj) => filter.includes(obj.value));
  }
  return result;
};

export const getEnumFilterOptions = <T extends {}>(
  enumClass: T,
): {
  text: string;
  value: string | number;
}[] => {
  return getEnumEntries(enumClass).map(([label, _value]) => ({
    text: label.replaceAll('_', ' '),
    value: label.replaceAll('_', ' '),
  }));
};
