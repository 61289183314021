import React from 'react';

import { iconType } from 'src/types';

const NotificationMuted: React.FC<iconType.PropsType> = ({ style, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex', height: style?.height }}
    >
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        width="14.526"
        height="13.799"
        viewBox="0 0 14.526 13.799"
      >
        <g id="Group_40615" data-name="Group 40615" transform="translate(0.725)">
          <path
            id="Path_15283"
            data-name="Path 15283"
            d="M135.488,418.588a2.159,2.159,0,0,1-2.156-2.156.431.431,0,0,1,.863,0,1.294,1.294,0,0,0,2.588,0,.431.431,0,0,1,.863,0A2.159,2.159,0,0,1,135.488,418.588Zm0,0"
            transform="translate(-129.738 -404.789)"
            fill="#92aabb"
          />
          <path
            id="Path_15284"
            data-name="Path 15284"
            d="M10.495,78.095H1.006a1.007,1.007,0,0,1-.654-1.771A.4.4,0,0,1,.4,76.289a3.865,3.865,0,0,0,1.327-2.915v-1.6a4,4,0,0,1,3.086-3.91.431.431,0,0,1,.2.84,3.14,3.14,0,0,0-2.423,3.071v1.6A4.729,4.729,0,0,1,.918,76.98L.893,77a.142.142,0,0,0-.03.089.145.145,0,0,0,.144.144h9.488a.145.145,0,0,0,.144-.144A.137.137,0,0,0,10.607,77l-.025-.02A4.69,4.69,0,0,1,9.374,75.4a.431.431,0,1,1,.779-.369,3.844,3.844,0,0,0,.95,1.256.5.5,0,0,1,.044.033,1.006,1.006,0,0,1-.653,1.771Zm0,0"
            transform="translate(0 -66.018)"
            fill="#92aabb"
          />
          <path
            id="Path_15285"
            data-name="Path 15285"
            d="M238.406,7.475a3.738,3.738,0,1,1,3.738-3.738A3.742,3.742,0,0,1,238.406,7.475Zm0-6.613a2.875,2.875,0,1,0,2.875,2.875A2.878,2.878,0,0,0,238.406.863Zm0,0"
            transform="translate(-228.342)"
            fill="#92aabb"
          />
          <line
            id="Line_298"
            data-name="Line 298"
            y1="9.347"
            x2="11.216"
            transform="translate(0.684 2.092)"
            fill="none"
            stroke="#c66d49"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </div>
  );
};

NotificationMuted.defaultProps = {
  // style: { width: '40px', height: '40px' },
  // color: 'var(--sidebar-icon-color)',
  onClick: undefined,
  className: undefined,
};

export default NotificationMuted;
