import { Form, message, Radio, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { isDefined } from 'class-validator';
import fileDownload from 'js-file-download';
import { useEffect, useState } from 'react';
import FormComponent from 'src/app/components/FormComponent/FormComponent';
import {
  convertDateToReadableText,
  exportFileSendMail,
} from 'src/app/forms/ExportInterestForm/export-interest-form.config';
import styles from 'src/app/forms/ExportInterestForm/exportInterest.module.scss';
import { IFormProps } from 'src/app/forms/form.config';
import { pdfExport } from 'src/app/helpers/pdf.helper';
import { convertInteractionsToTableData } from 'src/app/pages/InterestsPage/RelationPage/relation-interest-config';
import {
  useExportEnterpriseIdentifiedExcellMutation,
  useLazyGetEnterprisesQuery,
} from 'src/app/redux/company/companySlice';
import { useAppSelector } from 'src/app/redux/hook';
import { useLazyGetInteractionsQuery } from 'src/app/redux/interaction/interactionSlice';
import {
  useExportPersonIdentifiedExcellMutation,
  useLazyGetPersonsIdentifiedQuery,
} from 'src/app/redux/interests/persons/personIdentifiedSlice';
import {
  useExportVehicleIdentifiedExcellMutation,
  useLazyGetVehiclesIdentifiedQuery,
} from 'src/app/redux/interests/vehicles/vehicleIdentifiedSlice';
import {
  useExportLocationIdentifiedExcellMutation,
  useLazyGetLocationsIdentifiedQuery,
} from 'src/app/redux/locations/locationsIdentifiedSlice';
import { NSearchLoggerType } from 'src/app/redux/nSearch/nSearch-slice-type';
import { useExportNSearchExcellMutation } from 'src/app/redux/nSearch/nSearchSlice';
import { useExportRelationExcellMutation } from 'src/app/redux/relation/relationSlice';
import {
  useExportSensorIdentifiedExcellMutation,
  useLazyGetSensorsIdentifiedQuery,
} from 'src/app/redux/sensors/sensorIdentifiedSlice';
import { useExportStudentExcellMutation, useLazyGetStudentsQuery } from 'src/app/redux/students/studentsSlice';
import {
  useExportTopicIdentifiedExcellMutation,
  useLazyGetTopicsIdentifiedQuery,
} from 'src/app/redux/topics/topicIdentifiedSlice';
import * as userReduxType from 'src/app/redux/user/user.redux.type';
import { useGetUsersQuery } from 'src/app/redux/user/userSlice';
import { EnterpriseIdentifiedDataType } from 'src/app/services/interest/Enterprise/enterprise-service.type';
import { LocationIdentifiedDataType } from 'src/app/services/interest/Location/location-service.type';
import { TopicIdentifiedDataType } from 'src/app/services/interest/Topic/topic-service.type';
import { getNSearchLogs } from 'src/app/services/nSearchBackend/nSearchLogger-service';
import { StudentDataType } from 'src/app/services/student/student.service.type';
import { SensorCategoryType } from 'src/enums/nginr-type.enum';
import { PersonIdentified } from 'src/types/person.type';
import { SensorIdentified } from 'src/types/sensor.type';
import { VehicleIdentified } from 'src/types/vehicle.type';

export type InterestExportFormType = {
  filteredInterestIds: string[];
  interestName:
    | 'person'
    | 'vehicle'
    | 'location'
    | 'sensor'
    | 'enterprise'
    | 'topic'
    | 'nSearch'
    | 'relation'
    | 'student';
};

const allInterestTableValues = (dataValues: any, interestName: string) => {
  if (interestName === 'person') {
    const interest: PersonIdentified[] = dataValues;
    const values = interest.map((person) => [
      person.name,
      person.surname,
      person?.age?.name,
      person.height,
      person?.nationality?.name,
      person.weight,
      person?.gender?.name,
    ]);
    const headers = ['Name', 'Surname', 'Age', 'Height', 'Nationality', 'Weight', 'Gender'];
    return { values, headers };
  }
  if (interestName === 'vehicle') {
    const interest: VehicleIdentified[] = dataValues;
    const values = interest.map((vehicle) => [vehicle.name, vehicle?.brand?.name, vehicle.color?.name, vehicle.plate]);
    const headers = ['Name', 'Brand', 'Color', 'Plate'];
    return { values, headers };
  }
  if (interestName === 'location') {
    const interest: LocationIdentifiedDataType[] = dataValues;

    const values = interest.map((location) => [location.name, location.lat, location.lng]);
    const headers = ['Name', 'Lat', 'Lng'];
    return { values, headers };
  }
  if (interestName === 'sensor') {
    const interest: SensorIdentified[] = dataValues;

    const values = interest.map((sensor) => [
      sensor.name,
      SensorCategoryType[sensor.categoryType],
      sensor.deviceName,
      sensor.ipAddress,
      sensor.macAddress,
    ]);
    const headers = ['Name', 'Category', 'Sensor Name', 'IP Address', 'MAC Address'];
    return { values, headers };
  }
  if (interestName === 'enterprise') {
    const interest: EnterpriseIdentifiedDataType[] = dataValues;

    const values = interest.map((enterprise) => [
      enterprise?.company?.name,
      enterprise?.country?.name,
      enterprise?.industry.name,
    ]);
    const headers = ['Company Name', 'Country', 'Industry'];
    return { values, headers };
  }
  if (interestName === 'topic') {
    const interest: TopicIdentifiedDataType[] = dataValues;
    const values = interest.map((topic) => [topic?.name]);
    const headers = ['Name'];
    return { values, headers };
  }
  if (interestName === 'relation') {
    const tableDataSource = convertInteractionsToTableData(dataValues);

    const values = tableDataSource?.map((relation) => [
      relation.name,
      relation.fromInterestType,
      relation.fromInterest,
      relation.relation,
      relation.toInterestType,
      relation.toInterest,
    ]);
    const headers = [
      'Name',
      'Source Interest Type',
      'Source Interest',
      'Relation',
      'Target Interest Type',
      'Target Interest',
    ];
    return { values, headers };
  }

  if (interestName === 'nSearch') {
    const nSearch: NSearchLoggerType[] = dataValues;
    const headers = [
      'User',
      'Source Category',
      'Source Type',
      'Source',
      'Interests',
      'Media Type',
      // "Location",
      'Action Date',
    ];

    const values = nSearch.map((logData) => [
      logData.username,
      logData.dataSourceCategory?.join('\n'),
      logData.dataSourceType?.join('\n'),
      logData.dataSource?.join('\n'),
      logData.interests?.join('\n'),
      logData.mediaTypes?.join('\n'),
      // logData.coordinates?.map(coordinate => [coordinate.lat.toFixed(6), coordinate.lng.toFixed(6)].join(", ")).join("\n"),
      convertDateToReadableText(logData.createdAt),
    ]);

    return { values, headers };
  }
  if (interestName === 'student') {
    const students: StudentDataType[] = dataValues;
    const headers = [
      'Student Name',
      'Rank',
      'Branch',
      'University Name',
      'Year Of Program',
      'Start Year',
      'Expected Graduation Year',
      'Graduation Year',
    ];

    const values = students.map((student) => [
      student?.studentName,
      student?.rank,
      student?.branch,
      student?.universityName,
      student?.yearOfProgram,
      student?.startYear,
      student?.expectedGraduationYear,
      student?.graduationYear,
    ]);

    return { values, headers };
  }
};

export const ExportInterestForm: React.FC<IFormProps & InterestExportFormType> = ({
  form,
  filteredInterestIds,
  interestName,
}) => {
  const [usersToMail, setUsersToMail] = useState<{ label: string; value: string; key: number }[]>([]);
  const mailList = useWatch('mails', form);
  const sendAttachment = useWatch('sendAttachment', form);
  const studentType = useWatch('studentType', form);
  const [query, setQuery] = useState<any>();
  const { customerId }: userReduxType.StateType = useAppSelector((state) => state.user);

  const [getPersons] = useLazyGetPersonsIdentifiedQuery();
  const [getVehicles] = useLazyGetVehiclesIdentifiedQuery();
  const [getLocations] = useLazyGetLocationsIdentifiedQuery();
  const [getEnterprises] = useLazyGetEnterprisesQuery();
  const [getSensors] = useLazyGetSensorsIdentifiedQuery();
  const [getTopics] = useLazyGetTopicsIdentifiedQuery();
  const [getRelations] = useLazyGetInteractionsQuery();
  const [getStudents] = useLazyGetStudentsQuery();

  const { data: userList } = useGetUsersQuery({
    customerIds: customerId ?? undefined,
  });

  const getNSearches = async () => {
    try {
      const { data } = await getNSearchLogs();
      return data;
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    if (isDefined(userList)) {
      const mailsAndNames = userList.rows.map((user, _) => ({
        label: user.fullname,
        value: user.email,
        key: _,
      }));
      setUsersToMail(mailsAndNames);
    }
  }, [userList]);

  const mutations = {
    person: useExportPersonIdentifiedExcellMutation(),
    vehicle: useExportVehicleIdentifiedExcellMutation(),
    location: useExportLocationIdentifiedExcellMutation(),
    sensor: useExportSensorIdentifiedExcellMutation(),
    enterprise: useExportEnterpriseIdentifiedExcellMutation(),
    topic: useExportTopicIdentifiedExcellMutation(),
    nSearch: useExportNSearchExcellMutation(),
    relation: useExportRelationExcellMutation(),
    student: useExportStudentExcellMutation(),
  };

  const queries = {
    person: getPersons,
    vehicle: getVehicles,
    location: getLocations,
    sensor: getSensors,
    enterprise: getEnterprises,
    topic: getTopics,
    relation: getRelations,
    student: getStudents,
  };

  useEffect(() => {
    const getQuery = async () => {
      if (interestName !== 'nSearch') {
        if (interestName === 'student') {
          setQuery(
            (
              await queries[interestName]({
                graduationApproved: studentType === 'archived',
              })
            ).data,
          );
        } else {
          setQuery((await queries[interestName]()).data);
        }
      } else {
        setQuery(await getNSearches());
      }
    };

    getQuery();
  }, [interestName, studentType]);

  const [exportExcell] = mutations[interestName];

  const onFinish = async (values: any) => {
    try {
      if (values.format === 'pdf' && query) {
        const filtered =
          values.filters === 'yes' && filteredInterestIds.length > 0
            ? query.rows.filter((interest: any) => filteredInterestIds.includes(interest.id))
            : query.rows;

        const tableDataValues = allInterestTableValues(filtered, interestName);
        if (tableDataValues) {
          const pdf = pdfExport({
            headers: tableDataValues.headers,
            tableBodyValues: tableDataValues.values,
            interestName,
            options: {
              orientation: 'p',
              format: 'a4',
            },
            isReturnFunc: true,
          });
          if (mailList?.length > 0) {
            exportFileSendMail({
              emails: mailList,
              type: 'pdf',
              ...(sendAttachment && { pdf, pdfName: interestName }),
            });
          }
        }
      }
      if (values.format === 'xlsx' && query) {
        const data = await exportExcell({
          ...(filteredInterestIds.length > 0 && values.filters === 'yes' && { ids: filteredInterestIds }),
          ...(studentType && { graduatedOrNot: studentType === 'archived' }),
        })
          .unwrap()
          .catch((err) => message.error(err));
        if (data && data !== true) {
          fileDownload(data, `${interestName}.${values.format}`);
        }
        if (mailList?.length > 0) {
          exportFileSendMail({
            emails: mailList,
            type: 'xlsx',
            ...(sendAttachment && { excel: data, excelName: interestName }),
          });
        }
      }
    } catch (error) {
      console.log('err: ', error);
    }
  };

  return (
    <FormComponent options={{ form, onFinish }}>
      <Form.Item label="Export Type" name="format" rules={[{ required: true }]}>
        <Radio.Group rootClassName={styles.radioGroup}>
          <Radio rootClassName={styles.radio} value="xlsx">
            .xlsx
          </Radio>
          <Radio value="pdf" rootClassName={styles.radio}>
            .pdf
          </Radio>
        </Radio.Group>
      </Form.Item>
      {interestName === 'student' && (
        <Form.Item label="Student Type" name="studentType" rules={[{ required: true }]}>
          <Radio.Group rootClassName={styles.radioGroup}>
            <Radio rootClassName={styles.radio} value="archived">
              Archived
            </Radio>
            <Radio value="student" rootClassName={styles.radio} style={{ marginLeft: '1em' }}>
              Students
            </Radio>
          </Radio.Group>
        </Form.Item>
      )}
      {filteredInterestIds?.length > 0 && (
        <Form.Item label="Use Current Filters" name="filters" rules={[{ required: true }]}>
          <Radio.Group rootClassName={styles.radioGroup}>
            <Radio rootClassName={styles.radio} value="yes">
              Yes
            </Radio>
            <Radio value="no" rootClassName={styles.radio} style={{ marginLeft: '1em' }}>
              No
            </Radio>
          </Radio.Group>
        </Form.Item>
      )}
      <Form.Item label="Email Users" name="mails">
        <Select
          style={{ background: 'transparent !important' }}
          mode="multiple"
          variant="borderless"
          options={usersToMail}
          placeholder="Select Users to Mail"
        />
      </Form.Item>
      <Form.Item label="Send Attachment" name="sendAttachment" initialValue>
        <Radio.Group rootClassName={styles.radioGroup}>
          <Radio rootClassName={styles.radio} value>
            Yes
          </Radio>
          <Radio value={false} rootClassName={styles.radio}>
            No
          </Radio>
        </Radio.Group>
      </Form.Item>
    </FormComponent>
  );
};
