import { t } from 'i18next';
import PageLayout from 'src/app/components/PageLayoutComponent/PageLayout';
import { ICustomTabs } from 'src/app/components/TabLayoutComponent/tab-layout.config';
import TabLayout from 'src/app/components/TabLayoutComponent/TabLayout';
import { getPermissionKey } from 'src/app/helpers/permission.helper';
import UserRights from 'src/app/pages/AdministrationPage/UserRights/UserRights';
import UserRoles from 'src/app/pages/AdministrationPage/UserRoles/UserRoles';
import { routes } from 'src/configs/permissions.dictionary';

const tabItems: ICustomTabs[] = [
  {
    key: '1',
    label: 'User Roles',
    children: <UserRoles />,
    permission: getPermissionKey([routes['/Administration'], 'UserRoles']),
  },
  {
    key: '2',
    label: 'User Rights',
    children: <UserRights />,
    permission: getPermissionKey([routes['/Administration'], 'UserRights']),
  },
];
const AdministrationPage = () => {
  return (
    <PageLayout title={t('header.menu.administration')}>
      <TabLayout paramName="subTab" items={tabItems} />
    </PageLayout>
  );
};

export default AdministrationPage;
