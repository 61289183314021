import { Form, message, Space, Tag, Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineEye, AiOutlineSetting } from 'react-icons/ai';
import { MdOutlineNoteAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import ExtraTab from 'src/app/components/ExtraTabcomponent/ExtraTab';
import SelectAll from 'src/app/components/SelectWithAllComponent/SelectAll';
import showModal from 'src/app/components/ShowModalComponent/ShowModal';
import { ICustomTabs } from 'src/app/components/TabLayoutComponent/tab-layout.config';
import TabLayout from 'src/app/components/TabLayoutComponent/TabLayout';
import UserTable from 'src/app/components/UserTableComponent/UserTable';
import { routeHelper, timeHelper } from 'src/app/helpers';
import usePermissions from 'src/app/hooks/usePermissions';
import CompanyDetailsModal from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyDetailsModal/CompanyDetailsModal';
import { CustomerAndUserTableDataType } from 'src/app/pages/PortalPage/CustomerAndUserPage/customer-and-user-page.config';
import styles from 'src/app/pages/PortalPage/CustomerAndUserPage/CustomerAndUserPage.module.scss';
import TakeNoteModalContent from 'src/app/pages/PortalPage/CustomerAndUserPage/TakeNoteModalContent/TakeNoteModalContent';
import { useGetCustomersQuery } from 'src/app/redux/customers/customersSlice';
import { useAppSelector } from 'src/app/redux/hook';
import { useDeleteUserMutation, useUpdateUserMutation } from 'src/app/redux/user/userSlice';
import { CustomerResultType } from 'src/app/services/customer/customer-service.type';
import notesService from 'src/app/services/notes/notes.service';
import { routes } from 'src/configs/permissions.dictionary';
import { LAYOUTVIEW } from 'src/configs/system.config';
import { LayoutViewType } from 'src/enums/user.enum';
import AddInteractionIcon from 'src/icons/AddInteractionIcon';
import { NoteType } from 'src/types/notes.type';
import { User } from 'src/types/user.type';

const CustomerAndUserPage = () => {
  const { permissions } = usePermissions();
  const { id: loggedInUserId } = useAppSelector((state) => state.user);
  const labelColorCodes = [
    { label: 'Gold', color: 'rgb(255, 215, 0)' },
    { label: 'Silver', color: 'rgb(192, 192, 192)' },
    { label: 'Bronze', color: 'rgb(205, 127, 50)' },
  ];

  const navigate = useNavigate();
  const noteRef = useRef<NoteType>();
  const defaultNoteRef = useRef('');
  const [editUserFrom] = Form.useForm();
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [tableUsers, setTableUsers] = useState<{ [id: string]: User[] }>({});

  const [selectOptions, setSelectOptions] = useState<DefaultOptionType[]>([]);

  const [deleteUser] = useDeleteUserMutation();

  const { data: customerList } = useGetCustomersQuery({ isFirstCompany: true });

  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (customerList) {
      const tempOptions: DefaultOptionType[] = [];

      customerList.rows.forEach((customer) => {
        tempOptions.push({
          label: customer.enterpriseIdentified[0].company.name,
          value: customer.id,
        });
      });
      setSelectOptions([
        {
          label: 'All',
          value: 'all',
        },
        ...tempOptions,
      ]);
    }
  }, [customerList]);

  useEffect(() => {
    const tableData: { [key: string]: User[] } = {};
    if (selectedList.length && customerList) {
      selectedList.forEach((id) => {
        const foundCustomer = customerList.rows.find((cus) => cus.id === id);
        if (!foundCustomer) return;
        tableData[id] = foundCustomer.users;
      });
    }
    setTableUsers(tableData);
  }, [selectedList, customerList]);

  const freezeComponentOptions = (record: CustomerAndUserTableDataType) => {
    const freezeModal = record.isFreezed ? 'unfreezeModal' : 'freezeModal';
    return {
      onClick: (event: any) =>
        showModal({
          event,
          content: t(`customerAndUserPage.modals.${freezeModal}.content`, {
            userName: record.username,
          }),
          onSave: (modal) => {
            updateUser({ id: record.id, isFreezed: !record.isFreezed })
              .unwrap()
              .then(() => message.success(`User is successfully ${record.isFreezed ? 'unfreezed' : 'freezed'}`))
              .catch((e) => message.error(e.data.message));
            modal.destroy();
          },
          title: t(`customerAndUserPage.modals.${freezeModal}.title`),
          okText: t(`customerAndUserPage.modals.${freezeModal}.okText`),
        }),
      title: record.isFreezed ? t('global.unfreeze') : t('global.freeze'),
    };
  };

  // const columns: CustomColumnsType<CustomerAndUserTableDataType> = [
  //   {
  //     title: 'User Name',
  //     dataIndex: 'username',
  //     key: 'username',
  //     sorter: (a: any, b: any) => a.username.localeCompare(b.username),
  //     render: (_: any, record: CustomerAndUserTableDataType) => {
  //       return (
  //         <div>
  //           <Tooltip
  //             title={
  //               <span>{record.isOnline ? t('customerAndUserPage.online') : t('customerAndUserPage.offline')}</span>
  //             }
  //           >
  //             <Badge color={record.isOnline ? 'green' : 'red'} style={{ paddingRight: 10 }} />
  //           </Tooltip>
  //           {record.username}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: 'E-mail',
  //     dataIndex: 'email',
  //     sorter: (a, b) => a.email.localeCompare(b.email),
  //     key: 'email',
  //   },
  //   {
  //     title: 'Creation date',
  //     dataIndex: 'creationDate',
  //     key: 'creationDate',
  //     sorter: (a, b) => compareDates(a.creationDate, b.creationDate),
  //   },
  //   {
  //     title: 'Last Login Date',
  //     dataIndex: 'lastLoginDate',
  //     key: 'lastLoginDate',
  //     sorter: (a, b) => compareDates(a.lastLoginDate, b.lastLoginDate),
  //   },
  //   {
  //     title: 'User Role',
  //     dataIndex: 'userRole',
  //     key: 'userRole',
  //     sorter: (a, b) => a.userRole.length - b.userRole.length,
  //   },
  //   {
  //     title: 'Action',
  //     key: 'action',
  //     render: (_, record: CustomerAndUserTableDataType) => {
  //       const foundCustomer = customerList?.rows?.find((row) => row.id === record.customerId);
  //       if (!foundCustomer) return null;

  //       // const foundUser = foundCustomer.users.find(user => user.id === record.id);
  //       // if(!foundUser) return <></>;

  //       // const isSuperUser = foundUser.userRole.find(
  //       //   (role) => role.id === SUPER_USER_USER_ROLE_ID
  //       // );
  //       const isLoggedInUser = record.id === loggedInUserId;

  //       const isEditable =
  //         !!isLoggedInUser || !checkPermission(permissions, [routes['/Portal'], 'CustomerAndUser', 'edit']);

  //       const isDeletable =
  //         !!isLoggedInUser || !checkPermission(permissions, [routes['/Portal'], 'CustomerAndUser', 'delete']);

  //       const isFreezable =
  //         !!isLoggedInUser || !checkPermission(permissions, [routes['/Portal'], 'CustomerAndUser', 'freeze']);

  //       return (
  //         <ActionColumnComponent
  //           showComponent={{
  //             onClick: () => {
  //               showModal({
  //                 content: (
  //                   <UserDetailsContent
  //                     data={tableUsers[record.customerId].find((user) => user.id === record.id)}
  //                     customerName={record.customerName}
  //                   />
  //                 ),
  //                 title: t('customerAndUserPage.userDetails'),
  //                 disableButtons: false,
  //                 hasFooter: false,
  //                 size: 'large',
  //               });
  //             },
  //             title: t('global.show'),
  //           }}
  //           editComponent={{
  //             onClick: (event) => {
  //               if (!customerList) return;
  //               editUserFrom.resetFields();
  //               editUserFrom.setFieldsValue(convertUserOfCustomerToEditFormData(record.id, customerList?.rows));
  //               showModal({
  //                 size: 'large',
  //                 event,
  //                 title: t('tables.userSettings.tooltips.editUser'),
  //                 content: <CustomerAndUserEditForm form={editUserFrom} recordId={record.id} />,
  //                 onSave: (modal) => formHelper.submitForm(editUserFrom, modal),
  //               });
  //             },
  //             title: t('global.edit'),
  //             disabled: isEditable,
  //           }}
  //           freezeComponent={
  //             record.isFreezed ? { ...freezeComponentOptions(record), disabled: isFreezable } : undefined
  //           }
  //           unFreezeComponent={
  //             !record.isFreezed ? { ...freezeComponentOptions(record), disabled: isFreezable } : undefined
  //           }
  //           deleteComponent={{
  //             onClick: (event) =>
  //               showModal({
  //                 event,
  //                 content: (
  //                   <Trans i18nKey="customerAndUserPage.modals.deleteModal.content">
  //                     {{ firstname: record.username }}
  //                   </Trans>
  //                 ),
  //                 title: t('customerAndUserPage.modals.deleteModal.title'),
  //                 okText: t('global.delete'),
  //                 onSave: (modal) =>
  //                   formHelper.deleteRecordFromTable({
  //                     deleteFn: deleteUser,
  //                     modal,
  //                     recordId: record.id,
  //                   }),
  //               }),
  //             title: t('global.delete'),
  //             disabled: isDeletable,
  //           }}
  //         />
  //       );
  //     },
  //   },
  // ];

  const extraTabs = () => (
    <div>
      <div className="flex-center" style={{ columnGap: '50px' }}>
        <ExtraTab
          onClick={() => {
            routeHelper.handleRoute(navigate, '/CustomerRegister');
          }}
          icon={<AddInteractionIcon />}
          text={t('customerAndUserPage.inviteCustomer')}
          iconTheme
        />
      </div>
    </div>
  );
  const toCompanySettings = (customer: CustomerResultType) => {
    routeHelper.handleRoute(navigate, '/CompanySettings', undefined, {
      customerId: customer.id,
    });
  };

  const findAccountTypeColor = (accountType: string) => {
    const resultColor = labelColorCodes.filter((type) => type.label === accountType);
    return resultColor[0].color;
  };

  const pullNote = (data: NoteType, defaultNote: string) => {
    defaultNoteRef.current = defaultNote;
    noteRef.current = data;
  };

  const takeNote = async (id: string) => {
    try {
      // if (defaultNoteRef.current) {
      //   await notesService.update({
      //     customerId: id,
      //     note: noteRef.current?.note!,
      //     id: noteRef.current?.id!,
      //   });
      // } else {
      await notesService.create({
        customerId: id,
        note: noteRef.current?.note!,
      });
      // }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getUsersTable = (item: CustomerResultType) => {
    const dataSource: CustomerAndUserTableDataType[] = [];
    item.users?.forEach((user) => {
      dataSource.push({
        id: user.id,
        username: user.fullname,
        email: user.email,
        creationDate: timeHelper.formatDate(user.createdAt),
        lastLoginDate: timeHelper.formatDate(user.lastLoginDates[user.lastLoginDates.length - 1]),
        userRole: user.userRole?.map((role) => role.name).join(','),
        customerId: item.id,
        customerName: item.enterpriseIdentified[0].company.name,
        isFreezed: user.isFreezed,
        isOnline: user.isOnline,
      });
    });

    return (
      <div key={item.id}>
        <br />
        <div className={styles.companyNameContainer}>
          <div className={styles.companyName}>{item.enterpriseIdentified[0].company.name}</div>
          <div className={styles.companyTagAndIcons}>
            <Space size={[0, 8]} wrap>
              <Tag color={findAccountTypeColor(item.customerAccountType.name)}>
                {item.customerAccountType.name} ({item.users.length} / {item.customerAccountType.userLimit})
              </Tag>
            </Space>
            <img
              src={`/countryFlags/${item.enterpriseIdentified[0].country.alphaCode.toLowerCase()}.png`}
              height={20}
              alt=""
            />
            {/* {isClickedModal && ( */}
            <Tooltip title="Company Details">
              <div
                tabIndex={0}
                className={styles.companyIcons}
                role="button"
                onClick={() => {
                  showModal({
                    content: <CompanyDetailsModal customerTableData={item} />,
                    title: t('customerAndUserPage.modals.companyDetails'),
                    hasFooter: false,
                    size: 'xlarge',
                  });
                }}
              >
                <AiOutlineEye />
              </div>
              {/* <CompanyDetailsModal customerTableData={item} /> */}
            </Tooltip>
            <Tooltip title="Take Note">
              <div
                tabIndex={0}
                className={styles.companyIcons}
                role="button"
                onClick={() => {
                  showModal({
                    content: <TakeNoteModalContent customerId={item.id} sendNote={pullNote} />,
                    title: t('customerAndUserPage.modals.companyDetails'),
                    hasFooter: true,
                    onSave: (modal) => {
                      takeNote(item.id).then(() => modal.destroy());
                    },
                    size: 'large',
                  });
                }}
              >
                <MdOutlineNoteAdd />
              </div>
            </Tooltip>
            <Tooltip title="Company Settings">
              <div className={styles.companyIcons} onClick={() => toCompanySettings(item)}>
                <AiOutlineSetting />
              </div>
            </Tooltip>
          </div>
        </div>
        {/* <TableComponent scroll={{ y: 'calc(100vh - 32rem)' }} columns={columns} dataSource={dataSource} /> */}
        <UserTable
          customer={item}
          dataSource={dataSource}
          permissions={{
            editPermission: [routes['/Portal'], 'CustomerAndUser', 'edit'],
            deletePermission: [routes['/Portal'], 'CustomerAndUser', 'delete'],
            freezePermission: [routes['/Portal'], 'CustomerAndUser', 'freeze'],
          }}
        />
        <br />
        <hr />
      </div>
    );
  };

  const tabItems: ICustomTabs[] = [
    {
      key: '1',
      label: (
        <div className="select_space">
          <SelectAll
            setSelectedOptions={setSelectedList}
            variant="borderless"
            style={{ width: '100%', height: '100%' }}
            mode="multiple"
            showSearch
            value={selectedList}
            maxTagTextLength={15}
            maxTagCount={6}
            placeholder="Select Customer(s)"
            options={selectOptions.slice(1)}
          />
        </div>
      ),
      children: (
        <div className={styles.customerAndUser_tables}>
          {Object.entries(tableUsers).map(([key, _value]) => {
            const foundCustomer = customerList?.rows.find((cus) => cus.id === key);
            if (!foundCustomer) return null;
            return getUsersTable(foundCustomer);
          })}
        </div>
      ),
      extendedContents: LAYOUTVIEW === LayoutViewType.LAYOUT_1 ? extraTabs() : '',
    },
  ];

  return <TabLayout paramName="subTab" items={tabItems} className="tabs-with-border-container" />;
};

export default CustomerAndUserPage;
