import React from 'react';

import { iconType } from 'src/types';

const LocationIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
        <circle id="Ellipse_58" data-name="Ellipse 58" cx="15.5" cy="15.5" r="15.5" fill={circleColor} />
        <g id="Location_v2" transform="translate(6.99 4)">
          <path
            id="Path_37267"
            data-name="Path 37267"
            d="M295.35,532.43h0a8.48,8.48,0,0,0-8.45,8.47,9.579,9.579,0,0,0,.72,3.25,14.33,14.33,0,0,0,1,1.81l5.62,8.51a1.29,1.29,0,0,0,.79.62,1.13,1.13,0,0,0,.36.06A1.33,1.33,0,0,0,296,555a1.27,1.27,0,0,0,.5-.5l5.63-8.53a12.092,12.092,0,0,0,1-1.81,9.48,9.48,0,0,0,.72-3.25,8.47,8.47,0,0,0-8.5-8.48Zm0,1.47a7,7,0,0,1,7,7,8.6,8.6,0,0,1-.6,2.7,12.6,12.6,0,0,1-.85,1.58l-5.55,8.41-5.54-8.4a12,12,0,0,1-.84-1.58,8.471,8.471,0,0,1-.61-2.69,7,7,0,0,1,6.98-7.02Z"
            transform="translate(-286.89 -532.43)"
            fill={color}
          />
          <path
            id="Path_37268"
            data-name="Path 37268"
            d="M295.35,545.48a4.6,4.6,0,0,0,0-9.19h0a4.6,4.6,0,1,0,0,9.19Zm-3.13-4.59a3.13,3.13,0,1,1,3.13,3.13,3.13,3.13,0,0,1-3.13-3.13Z"
            transform="translate(-286.89 -532.43)"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
};

LocationIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  style: { margin: '5px 0 -8px 0' },
  color: 'black',
  onClick: undefined,
  className: undefined,
};

export default LocationIcon;
