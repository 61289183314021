import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { urlHelper } from 'src/app/helpers';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import { UserRoleDataType } from 'src/app/services/user-role/user-role-service.type';
import {
  GetUsersDataQueryType,
  GetUsersResultType,
  UpdatePortalUserType,
  UpdateUserDataType,
  UpdateUserResultType,
} from 'src/app/services/user/user-service.type';
import * as axiosInstance from 'src/app/utils/axios.instance';
import { urlsConfig } from 'src/configs';
// import { ROOT_USER_ID } from "src/app/constants/constants";
import { isDefined } from 'class-validator';
import { surferApiSlice } from 'src/app/redux/api/surferApiSlice';
import * as userReduxType from 'src/app/redux/user/user.redux.type';
import { CustomersOfUserDataType } from 'src/app/services/customer/customer-service.type';
import * as userServiceType from 'src/app/services/user/user-service.type';
import userService from 'src/app/services/user/user.service';
import { LanguagePreferenceEnum } from 'src/enums/language.enum';

// const localStorageUser = storageHelper.getValueWithKey("user");
const localStorageUser = sessionStorage.getItem('user');

const userCached = localStorageUser ? JSON.parse(localStorageUser) : null;

const defaultState: userReduxType.StateType = {
  id: undefined,
  email: undefined,
  fullname: undefined,
  token: undefined,
  profilePicture: undefined,
  isVerified: undefined,
  authentication: undefined,
  authenticated: false,
  phoneNumber: undefined,
  preferredLanguage: LanguagePreferenceEnum.EN,
  roles: [],
  // For trigger navbar profile image
  mockImageChangedBoolean: false,
  customerId: undefined,
  customer: undefined,
  enterpriseIdentifyClass: {
    company: {
      id: undefined,
      name: undefined,
    },
    id: undefined,
  },
  idleTime: undefined,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: undefined,
  isOnline: false,
  isFormSubmitted: false,
  isFreezed: false,
  systemLabelShow: false,
  userLabelShow: false,
  createdAt: undefined,
  numberOfAccounts: undefined,
};

const initialState: userReduxType.StateType = userCached || defaultState;

// login user
export const login = createAsyncThunk('/auth/login', async (user: userServiceType.LoginDataType, thunkAPI) => {
  try {
    const response = await userService.login(user, {
      blockErrorHandler: true,
    });
    thunkAPI.dispatch(apiSlice.util.invalidateTags(['Customers']));
    return response.data;
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    // errorHelper.displayErrorMessage('incorrectCredentials', message);
    return thunkAPI.rejectWithValue(message);
  }
});

// user profile update
export const updateUser = createAsyncThunk(
  '/auth/updateUser',
  async (user: userServiceType.UpdateUserDataType, thunkAPI) => {
    try {
      const response = await userService.updateUser(user);
      thunkAPI.dispatch(apiSlice.util.invalidateTags(['Users']));
      return response.data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      // errorHelper.displayErrorMessage('incorrectCredentials', message);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// user logout
export const logout = createAsyncThunk('auth/logout', async (_, { dispatch, rejectWithValue }) => {
  try {
    await userService.logout();
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    rejectWithValue(message);
  }
  dispatch(apiSlice.util.resetApiState());
  dispatch(surferApiSlice.util.resetApiState());
  axiosInstance.setToken(undefined);
  // storageHelper.removeKeyPair("token");
  sessionStorage.removeItem('token');

  // localStorage.removeItem("user");
  sessionStorage.removeItem('user');
});

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    reset: () => {
      return defaultState;
    },
    // redirectToken: (state: userReduxType.StateType, action) => {
    //   state.token = action.payload;
    // },
    redirectUser: (state, action) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.fullname = action.payload.fullname;
      state.token = action.payload.token;
      state.authentication = action.payload.authentication;
      state.customer = action.payload.customer;
      state.customerId = action.payload.customerId;
      state.roles = action.payload.userRole;
      state.idleTime = action.payload.idleTime;
      state.phoneNumber = action.payload.phoneNumber;

      if (state.customer?.enterpriseIdentified && state.customer?.enterpriseIdentified.length) {
        state.enterpriseIdentifyClass = state.enterpriseIdentifyClass || defaultState.enterpriseIdentifyClass;
        state.enterpriseIdentifyClass.id = action.payload.customer?.enterpriseIdentified[0].id;
        state.enterpriseIdentifyClass.company.id = action.payload.customer.enterpriseIdentified[0]?.company?.id;
        state.enterpriseIdentifyClass.company.name = action.payload.customer.enterpriseIdentified[0]?.company?.name;
      }
    },
    setToken: (state: userReduxType.StateType, action) => {
      state.token = action.payload.token;
    },
    setNumberOfAccounts: (state: userReduxType.StateType, action: PayloadAction<number>) => {
      state.numberOfAccounts = action.payload;
    },
    updateUserRole: (state, action: PayloadAction<UserRoleDataType>) => {
      const index = state.roles.findIndex((role) => role.id === action.payload.id);
      state.roles[index] = action.payload;
    },
    setUserRoles: (state, action: PayloadAction<UserRoleDataType[]>) => {
      state.roles = [...action.payload];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state: userReduxType.StateType) => {
        state.isLoading = true;
      })
      .addCase(
        login.fulfilled,
        (state: userReduxType.StateType, action: PayloadAction<userServiceType.LoginResultType>) => {
          const { user } = action.payload;
          const enterprise = action.payload.user.customer?.enterpriseIdentified[0];
          const companyName = `${enterprise?.isProxy ? '#' : ''}${enterprise?.company.name}`;

          state.isLoading = false;
          state.isSuccess = true;
          state.idleTime = user.idleTime;
          state.id = user.id;
          state.email = user.email;
          state.fullname = user.fullname;
          state.profilePicture = user.profilePicture;
          state.authentication = user.authentication;
          state.token = action.payload.token;
          state.isVerified = user.isVerified;
          state.roles = user.userRole;
          state.customerId = user.customerId;
          state.customer = user.customer;
          state.phoneNumber = user.phoneNumber;
          state.preferredLanguage = user.preferredLanguage;

          if (state.customer?.enterpriseIdentified && state.customer?.enterpriseIdentified.length) {
            state.customer.enterpriseIdentified[0].company.name = companyName;
            state.enterpriseIdentifyClass.id = enterprise?.id;
            state.enterpriseIdentifyClass.company.id = enterprise?.company.id;
            state.enterpriseIdentifyClass.company.name = companyName;
          }

          state.systemLabelShow = user.systemLabelShow;
          state.userLabelShow = user.userLabelShow;
          state.createdAt = user.createdAt;
          state.isOnline = user.isOnline;
        },
      )
      .addCase(login.rejected, (state: userReduxType.StateType) => {
        state.isLoading = false;
        state.isError = true;

        state.isSuccess = false;
        state.message = t('pleaseTryAgain'); // "Please try again!";
        state.id = undefined;
        state.email = undefined;
        state.idleTime = undefined;
        state.fullname = undefined;
        state.profilePicture = undefined;
        state.authentication = undefined;
        state.roles = [];
        state.customerId = undefined;
        state.phoneNumber = undefined;
        state.preferredLanguage = LanguagePreferenceEnum.EN;
        state.createdAt = undefined;
      })
      .addCase(logout.fulfilled, (state: userReduxType.StateType) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.idleTime = undefined;
        state.message = '';
        state.id = undefined;
        state.email = undefined;
        state.fullname = undefined;
        state.profilePicture = undefined;
        state.authentication = undefined;
        state.token = undefined;
        state.roles = [];
        state.customerId = undefined;
        state.customer = undefined;
        state.enterpriseIdentifyClass.company.id = undefined;
        state.enterpriseIdentifyClass.company.name = undefined;
        state.createdAt = undefined;
        state.phoneNumber = undefined;
        state.preferredLanguage = LanguagePreferenceEnum.EN;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.fullname = action.payload.fullname;
        state.email = action.payload.email;
        state.profilePicture = action.payload.profilePicture;
        state.systemLabelShow = action.payload.systemLabelShow;
        state.userLabelShow = action.payload.userLabelShow;
        state.idleTime = action.payload.idleTime;
        state.phoneNumber = action.payload.phoneNumber;
        state.preferredLanguage = action.payload.preferredLanguage;
      });
  },
});

export const convertUserUpdateToFormData = (data: UpdateUserDataType) => {
  const formData = new FormData();
  if (data.profilePicture) formData.append('profilePicture', data.profilePicture);
  if (data.id) formData.append('id', data.id);
  if (data.userRole) {
    data.userRole.forEach((role) => {
      formData.append('userRole', role);
    });
  }
  if (data.authentication) {
    data.authentication.forEach((auth) => {
      formData.append('authentication', auth?.toString());
    });
  }
  if (data.currentPassword) {
    formData.append('currentPassword', data.currentPassword);
  }
  if (data.idleTime) {
    formData.append('idleTime', data.idleTime.toString());
  }
  if (isDefined(data.isFreezed)) {
    formData.append('isFreezed', data.isFreezed!.toString());
  }
  if (isDefined(data.email)) {
    formData.append('email', data.email);
  }
  if (isDefined(data.phoneNumber)) {
    formData.append('phoneNumber', data.phoneNumber);
  }
  if (isDefined(data.deleteProfilePicture)) {
    formData.append('deleteProfilePicture', 'true');
  }
  if (isDefined(data.preferredLanguage)) {
    formData.append('preferredLanguage', data.preferredLanguage);
  }
  if (isDefined(data.systemLabelShow)) {
    formData.append('systemLabelShow', data.systemLabelShow ? 'true' : 'false');
  }
  if (isDefined(data.userLabelShow)) {
    formData.append('userLabelShow', data.userLabelShow ? 'true' : 'false');
  }
  if (isDefined(data.fullname)) {
    formData.append('fullname', data.fullname);
  }

  return formData;
};

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsersResultType, GetUsersDataQueryType | void>({
      query: (params?: GetUsersDataQueryType) => ({
        url: urlsConfig.user.getUsers,
        params: urlHelper.getLookupQuery(params),
      }),
      // transformResponse: (data: GetUsersResultType) => {
      //   return {
      //     rows: data.rows.filter((row) => row.id !== ROOT_USER_ID),
      //     count: data.count - 1,
      //   };
      // },
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'Users' as const,
                id,
              })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),
    updateUser: builder.mutation<UpdateUserResultType, UpdateUserDataType>({
      query: (data) => ({
        url: `${urlsConfig.user.updateUser}/${data.id}`,
        method: 'PATCH',
        body: convertUserUpdateToFormData(data),
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'Users', id: data.id },
        { type: 'Customers', id: 'LIST' },
      ],
    }),
    deleteUser: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `${urlsConfig.user.deleteUser}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: 'Users', id },
        { type: 'Customers', id: 'LIST' },
      ],
    }),
    getCustomersOfUser: builder.mutation<GetUsersResultType, CustomersOfUserDataType>({
      query: (data) => ({
        url: urlsConfig.user.customers,
        method: 'POST',
        body: data,
      }),
    }),
    updatePortalUser: builder.mutation<UpdateUserResultType, UpdatePortalUserType>({
      query: (data) => ({
        url: `${urlsConfig.user.updatePortalUser}/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: 'Users', id: data.id },
        { type: 'Customers', id: 'LIST' },
      ],
    }),
  }),
});

export const { reset, redirectUser, setToken, updateUserRole, setNumberOfAccounts, setUserRoles } = userSlice.actions;

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetCustomersOfUserMutation,
  useUpdatePortalUserMutation,
} = usersApiSlice;

export default userSlice.reducer;
