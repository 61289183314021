import ReactGridLayout from 'react-grid-layout';
import { CardType, HomeCardType } from 'src/app/pages/HomePage/Card/home-card.config';
import { IndustryRecognizedDataType } from 'src/app/services/interest/Industry/industry-service.type';
import { LocationRecognizedDataType } from 'src/app/services/interest/Location/location-service.type';
import { LAYOUTVIEW } from 'src/configs/system.config';
import { LanguagePreferenceEnum } from 'src/enums/language.enum';
import { EnterpriseRelationsResultType } from 'src/types/enterprise.type';

export type DashboardState = {
  layout: ReactGridLayout.Layout[];
  toolbox: string[];
};

export const filterCards = (cards: CardType[]) => {
  return cards.filter((card) => card.isDefault && card.layoutModes.includes(LAYOUTVIEW));
};

// LAYOUT OPTIONS ACCORDING TO CARD TYPE
export const getLayoutOptions = (type: HomeCardType) => {
  let options: Partial<ReactGridLayout.Layout> = {
    isResizable: false,
    maxH: 1,
    maxW: 1,
  };

  switch (type) {
    case HomeCardType.Chart:
      options = { isResizable: true, maxH: 2, maxW: 2 };
      break;
  }
  return options;
};

export const generateLayout = (items: CardType[], lang: LanguagePreferenceEnum): DashboardState => {
  const layout: ReactGridLayout.Layout[] = [];

  for (let index = 0; index < items.length; index += 1) {
    const card = lang === LanguagePreferenceEnum.AR ? items[items.length - 1 - index] : items[index];
    layout.push({
      i: card.id,
      x: index % 4,
      y: Math.floor(index / 4),
      w: 1,
      h: 1,
      ...getLayoutOptions(card.type),
    });
  }
  return { layout, toolbox: [] };
};

export const getUniqueCountriesFromSuppliers = (
  suppliers: EnterpriseRelationsResultType['from']['rows'],
): LocationRecognizedDataType[] => {
  const result: LocationRecognizedDataType[] = [];

  suppliers.forEach((supplier) => {
    const { country } = supplier.fromInterest.enterpriseIdentified;
    const foundCountry = result.find((obj) => obj.id === country.id);
    if (!foundCountry) {
      result.push(country);
    }
  });

  return result;
};

export const getUniqueIndustriesFromSuppliers = (
  suppliers: EnterpriseRelationsResultType['from']['rows'],
): IndustryRecognizedDataType[] => {
  const result: IndustryRecognizedDataType[] = [];

  suppliers.forEach((supplier) => {
    const { industry } = supplier.fromInterest.enterpriseIdentified;
    const foundIndustry = result.find((obj) => obj.id === industry.id);
    if (!foundIndustry) {
      result.push(industry);
    }
  });

  return result;
};
