import { Card } from 'antd';
import Tree, { DataNode, TreeProps } from 'antd/es/tree';
import React, { Dispatch, SetStateAction } from 'react';
import { CustomDataNode } from 'src/app/helpers/permission.helper';
import styles from 'src/app/pages/AdministrationPage/UserRights/PermissionCard/PermissionCard.module.scss';
import { useAppSelector } from 'src/app/redux/hook';

interface IPermissionCardProps {
  route: string;
  treeData: DataNode[];
  setTreeState: Dispatch<
    SetStateAction<{
      treeNodes: {
        [key: string]: CustomDataNode[];
      };
      checkboxes: {
        [key: string]: boolean;
      };
    }>
  >;
  checkedKeys: string[];
}
const PermissionCard: React.FC<IPermissionCardProps> = ({ route, treeData, setTreeState, checkedKeys }) => {
  const { theme } = useAppSelector((state) => state.configContent);
  const onCheck: TreeProps['onCheck'] = (_checkedKeys, info) => {
    setTreeState((prev) => {
      const newCheckboxes = {
        ...prev.checkboxes,
      };

      Object.entries(prev.checkboxes).forEach(([key, _value]) => {
        const splittedKey = info.node.key.toString().split('-').join('');
        if ((splittedKey.startsWith(key) && info.checked) || key.startsWith(splittedKey)) {
          newCheckboxes[key] = info.checked;
        }
      });

      return {
        checkboxes: newCheckboxes,
        treeNodes: {
          ...prev.treeNodes,
          [route]: (info.checkedNodes as CustomDataNode[])
            .filter((node) => node.isAtomic === true)
            .map((node) => ({
              key: node.key,
              isAtomic: node.isAtomic,
              isView: node.isView,
            })),
        },
      };
    });
  };

  if (treeData.length === 0) return null;
  return (
    <Card
      rootClassName={styles.card}
      title={route}
      id="permission-card"
      style={{ borderBottom: `1px solid ${theme.colors.quoteBgc}` }}
      styles={{
        header: {
          borderBottom: `1px solid ${theme.colors.quoteBgc}`,
          borderTop: `1px solid ${theme.colors.quoteBgc}`,
          borderRadius: '0px',
        },
      }}
    >
      <Tree
        treeData={treeData}
        checkable
        checkedKeys={checkedKeys.map((item) =>
          item
            .toString()
            .match(/.{1,2}/g)!
            .join('-'),
        )}
        defaultExpandedKeys={[treeData[0].key]}
        rootClassName={styles.permissionTree}
        style={{ color: `${theme.colors.quoteBgc}` }}
        selectable={false}
        onCheck={onCheck}
      />
    </Card>
  );
};

export default PermissionCard;
