import socketIO, { Socket } from 'socket.io-client';

import { urlsConfig } from 'src/configs';

let socket: Socket | undefined;

export const createConnection = () => {
  socket = socketIO(urlsConfig.socketURL, {
    transports: ['websocket', 'polling'],
    upgrade: true,
    path: '/socket',
  });
};

export const listenSocket = (id: string, callback: (data: any) => void) => {
  socket?.on(id, callback);
};

export const closeSocket = () => {
  socket?.close();
};
