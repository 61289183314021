import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isDefined } from 'class-validator';
import { DEFAULT_FILTERS } from 'src/app/pages/NotificationsPage/Dropdowns/notification-filter.config';
import { defaultPageSize } from 'src/app/pages/NotificationsPage/notification-list.config';
import { getNotifications } from 'src/app/services/notifications/notfications.service';
import {
  ChangeSelectedNotificationsStatus,
  DeleteNotificationsType,
  GetNotificationsDataType,
  NotificationAllUpdateType,
  NotificationResultType,
  UpdateLabelsOfNotificationsType,
} from 'src/app/services/notifications/notifications-service.type';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';
import { NotificationArchiveStatusEnum } from 'src/enums/notifications.enum';
import { LabelType } from 'src/types/labels.type';

export {};

export const fetchNotifications = createAsyncThunk(
  'testNotifications/getNotifications',
  async (query: GetNotificationsDataType, thunkAPI) => {
    thunkAPI.dispatch(setSelectedNotificationStatus(query.archiveStatus!));
    thunkAPI.dispatch(setNotificationQuery({ ...query }));
    try {
      const { data } = await getNotifications({
        ...query,
      });
      return data;
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      // errorHelper.displayErrorMessage('incorrectCredentials', message);
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateNotifications = createAsyncThunk(
  'testNotifications/updateNotifications',
  async (body: ChangeSelectedNotificationsStatus) => {
    await axios.patch(urlsConfig.notifications.selectUpdateStatus, body);
    return body;
  },
);

export const updateAllNotifications = createAsyncThunk(
  'testNotifications/updateAllNotifications',
  async (body: NotificationAllUpdateType) => {
    await axios.patch(urlsConfig.notifications.allUpdateStatus, body);
    return body;
  },
);

export const deleteNotifications = createAsyncThunk(
  'testNotifications/deleteNotifications',
  async (body: DeleteNotificationsType) => {
    await axios.delete(urlsConfig.notifications.deleteNotification, {
      data: body,
    });
    return body;
  },
);

export const deleteAllNotifications = createAsyncThunk('testNotifications/deleteAllNotifications', async () => {
  await axios.delete(urlsConfig.notifications.deleteAllNotification);
});

export const addLabelToNotification = createAsyncThunk(
  'testNotifications/addLabelToNotification',
  async (body: UpdateLabelsOfNotificationsType) => {
    await axios.post(urlsConfig.notifications.addLabel, {
      id: body.id,
      labelIds: body.labels.map((label) => label.id),
    });
    return body;
  },
);

export type NotificationMetadata = {
  data: NotificationResultType[];
  hasMore: boolean;
  query: GetNotificationsDataType;
  count: number;
};

export type NotificationsStateType = {
  notifications: {
    [key in NotificationArchiveStatusEnum]: NotificationMetadata;
  };
  headerNotifications: NotificationResultType[];
  selectedNotification: NotificationResultType | undefined;
  checkedNotifications: string[];
  unreadInboxCount: number;
  selectedStatus: NotificationArchiveStatusEnum;
  isMuted: boolean;
};

const defaultQuery: GetNotificationsDataType = {
  offset: 0,
  limit: defaultPageSize,
  ...DEFAULT_FILTERS,
};

export const initialState: NotificationsStateType = {
  notifications: {
    '0': {
      data: [],
      hasMore: true,
      query: {
        ...defaultQuery,
        archiveStatus: NotificationArchiveStatusEnum.UNARCHIVE,
      },
      count: 0,
    },
    '1': {
      data: [],
      hasMore: true,
      query: {
        ...defaultQuery,
        archiveStatus: NotificationArchiveStatusEnum.ARCHIVE,
      },
      count: 0,
    },
  },
  headerNotifications: [],
  selectedNotification: undefined,
  unreadInboxCount: 0,
  checkedNotifications: [],
  selectedStatus: NotificationArchiveStatusEnum.UNARCHIVE,
  isMuted: true,
};

export const notificationTestSlice = createSlice({
  initialState,
  name: 'testNotifications',
  reducers: {
    addNewNotification: (state, action: PayloadAction<NotificationResultType>) => {
      const status = action.payload.archiveStatus;
      state.notifications[status].data.push(action.payload);
      state.notifications[status].count += 1;
      const { query } = state.notifications[state.selectedStatus];
      query.offset += 1;
    },

    setUnreadInboxCount: (state, action: PayloadAction<number>) => {
      state.unreadInboxCount = action.payload;
    },

    checkNotification: (state, action: PayloadAction<string>) => {
      state.checkedNotifications.push(action.payload);
    },
    uncheckNotification: (state, action: PayloadAction<string>) => {
      state.checkedNotifications = state.checkedNotifications.filter(
        (notificationId) => notificationId !== action.payload,
      );
    },
    setSelectedNotification: (state, action: PayloadAction<NotificationResultType | undefined>) => {
      state.selectedNotification = action.payload;
    },
    resetCheckedNotifications: (state) => {
      state.checkedNotifications = [];
    },
    checkAllNotifications: (state, action: PayloadAction<NotificationResultType[]>) => {
      state.checkedNotifications = action.payload.map((notification) => notification.id);
    },
    setSelectedNotificationStatus: (state, action) => {
      state.selectedStatus = action.payload;
    },
    setNotificationQuery: (state, action) => {
      const notifications = state.notifications[state.selectedStatus];
      notifications.query = {
        ...notifications.query,
        ...action.payload,
      };
    },
    setHeaderNotifications: (state, action: PayloadAction<NotificationResultType[]>) => {
      state.headerNotifications = action.payload;
    },
    setNotificationsMuted: (state, action: PayloadAction<boolean>) => {
      state.isMuted = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        const notifications = state.notifications[state.selectedStatus];
        notifications.count = action.payload.count;

        if (notifications.query.offset === 0) {
          notifications.data = [...action.payload.rows];
        } else {
          notifications.data = [...notifications.data, ...action.payload.rows];
        }

        if (
          action.payload.rows.length < notifications.query.limit! ||
          action.payload.rows.length === notifications.count
        ) {
          notifications.hasMore = false;
        }
      })

      .addCase(updateNotifications.fulfilled, (state, action) => {
        const notifications = state.notifications[state.selectedStatus!];

        action.payload.ids.forEach((id) => {
          const found = notifications.data.find((not) => not.id === id);
          if (!found) return;

          if (isDefined(action.payload.readStatus)) found.readStatus = action.payload.readStatus;

          if (isDefined(action.payload.relevantStatus)) found.relevantStatus = action.payload.relevantStatus;

          if (isDefined(action.payload.archiveStatus)) {
            const archivedState = state.notifications[action.payload.archiveStatus];

            found.archiveStatus = action.payload.archiveStatus;

            if (archivedState.data.length) {
              archivedState.data.push(found);
              archivedState.count += 1;
            } else {
              archivedState.hasMore = true;
            }
          }

          if (isDefined(action.payload.starStatus)) found.starStatus = action.payload.starStatus;

          state.checkedNotifications = [];
        });

        if (isDefined(action.payload.archiveStatus)) {
          notifications.data = [...notifications.data.filter((row) => !action.payload.ids.includes(row.id))];
          notifications.count -= action.payload.ids.length;
          state.checkedNotifications = [];
        }
      })
      .addCase(updateAllNotifications.fulfilled, (state, action) => {
        const notifications = state.notifications[state.selectedStatus!];

        if (isDefined(action.payload.readStatus))
          notifications.data = [
            ...notifications.data.map((row) => ({
              ...row,
              readStatus: action.payload.readStatus!,
            })),
          ];

        if (isDefined(action.payload.relevantStatus))
          notifications.data = [
            ...notifications.data.map((row) => ({
              ...row,
              relevantStatus: action.payload.relevantStatus!,
            })),
          ];

        if (isDefined(action.payload.archiveStatus)) {
          if (action.payload.filteredTab === action.payload.archiveStatus) return;

          const archivedState = state.notifications[action.payload.archiveStatus];

          if (archivedState.data.length) {
            archivedState.data.push(...notifications.data);
            archivedState.count += notifications.count;
          } else {
            archivedState.hasMore = true;
          }

          notifications.data = [];
          notifications.count = 0;
        }
      })
      .addCase(deleteNotifications.fulfilled, (state, action) => {
        const notifications = state.notifications[state.selectedStatus!];

        notifications.data = [...notifications.data.filter((row) => !action.payload.ids.includes(row.id))];

        state.selectedNotification = undefined;
        state.checkedNotifications = [];
        notifications.count -= action.payload.ids.length;
      })
      .addCase(deleteAllNotifications.fulfilled, (state) => {
        const notifications = state.notifications[state.selectedStatus!];
        notifications.data = [];
      })
      .addCase(addLabelToNotification.fulfilled, (state, action) => {
        const notifications = state.notifications[state.selectedStatus!];

        const found = notifications.data.find((row) => row.id === action.payload.id);

        if (!found) return;
        const allLabels: LabelType[] = [];

        action.payload.labels.forEach((label) => {
          allLabels.push(label);
        });

        // action.payload.labelIds.forEach((labelId) => {
        //   allLabels.push({
        //     id: action.payload.id,
        //     labelId,
        //   });
        // });
        found.label = [...allLabels];
      });
  },
});

export const {
  setNotificationsMuted,
  checkAllNotifications,
  checkNotification,
  resetCheckedNotifications,
  setNotificationQuery,
  uncheckNotification,
  setSelectedNotification,
  addNewNotification,
  setUnreadInboxCount,
  setSelectedNotificationStatus,
  setHeaderNotifications,
} = notificationTestSlice.actions;
export default notificationTestSlice.reducer;
