import React from 'react';
import { iconType } from 'src/types';

const CopyIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex' }}
    >
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill={color || '#1a3441'}
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z" />
      </svg>
    </div>
  );
};

CopyIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '12px' },
  // color: 'var(--primary-color)',
  onClick: undefined,
  className: undefined,
};

export default CopyIcon;
