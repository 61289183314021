import { urlsConfig } from 'src/configs';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import { FileUploadDataType } from 'src/app/redux/fileUpload/fileUpload-slice.type';

export const fileUploadSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<void, FileUploadDataType>({
      query: (data) => ({
        url: urlsConfig.fileUpload,
        body: data,
        method: 'POST',
      }),
    }),
  }),
});

export const { useUploadFileMutation } = fileUploadSlice;
