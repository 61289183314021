import { LayoutViewType } from 'src/enums/user.enum';

export type LoginLayoutPropsType = {
  // children: React.ReactNode;
};

export const getLoginImages = (layout: LayoutViewType): { background: string; logo: string } => {
  switch (layout) {
    case LayoutViewType.LAYOUT_1:
      return {
        background: '/loginTemplate/login_bg.jpg',
        logo: '/loginTemplate/company_logo_new.svg',
      };

    default:
      return {
        background: '/loginBackground.png',
        logo: '/logo_nginr_metalik.png',
      };
  }
};
