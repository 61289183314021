import React from 'react';

import { iconType } from 'src/types';

const GovernmentIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="50" height="34" viewBox="0 0 50 34">
        <path
          id="Rectangle_14976"
          data-name="Rectangle 14976"
          d="M5,0H50a0,0,0,0,1,0,0V34a0,0,0,0,1,0,0H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
          transform="translate(50 34) rotate(180)"
          fill={circleColor}
        />
        <g id="government" transform="translate(15 4)">
          <path
            id="Path_37326"
            data-name="Path 37326"
            d="M111.241,355.71h-.006a.763.763,0,0,0-.761.763v7.186H112v-7.186A.763.763,0,0,0,111.241,355.71Z"
            transform="translate(-104.856 -337.62)"
            fill={color}
          />
          <path
            id="Path_37327"
            data-name="Path 37327"
            d="M241.238,335.41h-.006a.763.763,0,0,0-.761.763v8.218H242v-8.218A.763.763,0,0,0,241.238,335.41Z"
            transform="translate(-228.24 -318.352)"
            fill={color}
          />
          <path
            id="Path_37328"
            data-name="Path 37328"
            d="M372.232,355.71h-.006a.763.763,0,0,0-.761.763v7.186h1.529v-7.186A.763.763,0,0,0,372.232,355.71Z"
            transform="translate(-352.572 -337.62)"
            fill={color}
          />
          <ellipse
            id="Ellipse_68"
            data-name="Ellipse 68"
            cx="0.764"
            cy="0.763"
            rx="0.764"
            ry="0.763"
            transform="translate(12.255 9.08)"
            fill={color}
          />
          <path
            id="Path_37329"
            data-name="Path 37329"
            d="M17.391,13.437h8.648V11.613a.763.763,0,0,0-.494-.715L13.781,6.483V4.577h4.181a.762.762,0,0,0,.761-.763V1.271a.762.762,0,0,0-.761-.763H13.737a.761.761,0,0,0-1.478.254v5.72L.511,10.893.494,10.9A.762.762,0,0,0,0,11.613v1.823H8.648a.851.851,0,0,1,0,1.7H1.069V25.276a.762.762,0,0,0,.761.763H4.1V18.853A2.289,2.289,0,0,1,6.38,16.565h.006A2.289,2.289,0,0,1,8.67,18.853v7.186h2.037V17.821a2.289,2.289,0,0,1,2.284-2.289H13a2.289,2.289,0,0,1,2.284,2.289v8.218h2.088V18.853a2.289,2.289,0,0,1,2.284-2.289h.006a2.288,2.288,0,0,1,2.284,2.289v7.186h2.265a.762.762,0,0,0,.761-.763V15.138H17.391a.851.851,0,0,1,0-1.7ZM13.02,12.132a2.289,2.289,0,1,1,2.287-2.289,2.29,2.29,0,0,1-2.287,2.289Z"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
};

GovernmentIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default GovernmentIcon;
