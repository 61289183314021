import { urlHelper } from 'src/app/helpers';
import { getFormData } from 'src/app/helpers/form.helper';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  VehicleIdentifiedCreateDataType,
  VehicleIdentifiedLookupDataType,
  VehicleIdentifiedLookupResultType,
  VehicleIdentifiedUpdateDataType,
} from 'src/app/redux/interests/vehicles/vehicle-slice.type';
import { VehicleIdentifiedDataType } from 'src/app/services/interest/Vehicle/vehicle-service.type';
import { urlsConfig } from 'src/configs';
import { MutationType } from 'src/enums/operation.enum';

export const vehicleIdentifiedSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVehiclesIdentified: builder.query<VehicleIdentifiedLookupResultType, VehicleIdentifiedLookupDataType | void>({
      query: (params?: VehicleIdentifiedLookupDataType) => ({
        url: urlsConfig.interest.identified.vehicle.lookup,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'VehicleIdentified' as const,
                id,
              })),
              { type: 'VehicleIdentified', id: 'LIST' },
            ]
          : [{ type: 'VehicleIdentified', id: 'LIST' }],
    }),
    createVehicle: builder.mutation<VehicleIdentifiedDataType, VehicleIdentifiedCreateDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.vehicle.create,
        method: 'POST',
        body: getFormData(data, ['pictures'], MutationType.CREATE),
      }),
      invalidatesTags: [
        { type: 'VehicleIdentified', id: 'LIST' },
        { type: 'Interests', id: 'LIST' },
      ],
    }),
    updateVehicle: builder.mutation<VehicleIdentifiedDataType, VehicleIdentifiedUpdateDataType>({
      query: (data) => ({
        url: urlsConfig.interest.identified.vehicle.update,
        body: getFormData(data, ['pictures']),
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, data) => [{ type: 'VehicleIdentified', id: data.id }],
    }),
    deleteVehicle: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `${urlsConfig.interest.identified.vehicle.delete}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: 'VehicleIdentified', id },
        { type: 'Interests', id: 'LIST' },
      ],
    }),
    exportVehicleIdentifiedExcell: builder.mutation<ArrayBuffer, { ids?: string[] }>({
      query: (data) => ({
        url: urlsConfig.exports.vehicle,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetVehiclesIdentifiedQuery,
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
  useDeleteVehicleMutation,
  useExportVehicleIdentifiedExcellMutation,
  useLazyGetVehiclesIdentifiedQuery,
} = vehicleIdentifiedSlice;
