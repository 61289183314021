import { urlHelper } from 'src/app/helpers';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import { RelationInterestLookupDataType } from 'src/app/services/interest/interest.service.type';
import { RelationRecognizedResultType } from 'src/app/services/interest/Relation/relation-service.type';
import { urlsConfig } from 'src/configs';

export const relationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRelations: builder.query<RelationRecognizedResultType, RelationInterestLookupDataType | void>({
      query: (params?: RelationInterestLookupDataType) => ({
        url: urlsConfig.interest.identified.relation.types,
        params: urlHelper.getLookupQuery(params),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'Relations' as const,
                id,
              })),
              { type: 'Relations', id: 'LIST' },
            ]
          : [{ type: 'Relations', id: 'LIST' }],
    }),
    exportRelationExcell: builder.mutation<ArrayBuffer, { ids?: string[] }>({
      query: (data) => ({
        url: urlsConfig.exports.relation,
        method: 'POST',
        body: data,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const { useExportRelationExcellMutation, useGetRelationsQuery, useLazyGetRelationsQuery } = relationSlice;
