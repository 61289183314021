import { isURL } from 'class-validator';
/* eslint-disable @typescript-eslint/naming-convention */
import L from 'leaflet';

export type TLayer = TLayerTileLayer | TLayerGeojsonLayer | TLayerWMSLayer;
export type TLayerForm = TLayerTileLayer | TLayerGeojsonLayerForm | TLayerWMSLayer;
export type TLayerTypeOptions = 'tile' | 'geojson' | 'wms';
export type TLayerGeometryType = 'Point' | 'Polyline' | 'Polygon';

// export const landDefaultURL =
//   'https://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}';

export type TLayerBase = {
  id?: string;
  layerType: TLayerTypeOptions;
  label: string;
  isBaseLayer: boolean;
  attribution?: string;
  isActive: boolean;
};

export type TLayerTileLayer = {
  layerType: 'tile';
  url: string;
} & TLayerBase;

export type TLayerGeojsonLayer = {
  layerType: 'geojson';
  url?: string;
  geometryColor: string;
  geometryType: TLayerGeometryType;
  filePath?: string;
} & TLayerBase;

export type TLayerGeojsonLayerForm = TLayerGeojsonLayer & {
  geometryColor: any;
  media: any;
};

export type TLayerWMSLayer = {
  layerType: 'wms';
  url: string;
  wmsLayer: string;
  format: string;
  crs?: string;
  version: string;
  transparent: boolean;
  tiled: boolean;
} & TLayerBase;

export const landDefaultURL = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';

export const satelliteDefaultURL =
  'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';

// export const satelliteDefaultURL = 'http://[::]:8080/data/satellite/{z}/{x}/{y}.png';

export enum ELayerType {
  'TileLayer',
  'WMS',
  'Vector',
}

export interface ILayer {
  id?: string;
  label: string;
  layerType: TLayerTypeOptions;
  attribution?: string;
  isBaseLayer: boolean;
  isActive: boolean;
  // iconUrl: string;
}

export interface ITileLayer extends ILayer {
  layerType: 'tile';
  url: string;
}

export interface IWMSLayer extends ILayer {
  layerType: 'wms';
  url: string;
  wmsLayer?: string;
  format?: string;
  transparent?: boolean;
  version?: string;
  crs?: string;
  tiled?: boolean;
}

export interface IVectorLayer extends ILayer {
  layerType: 'geojson';
  url?: string;
  geometryColor: string;
  geometryType: TLayerGeometryType;
  filePath?: string;
}

export type TLayers = (ITileLayer | IWMSLayer | IVectorLayer)[];

export const layers: TLayers = [
  {
    id: '0',
    label: 'Land',
    layerType: 'tile',
    url: isURL(window.LAND_MAP_URL) ? window.LAND_MAP_URL : landDefaultURL,
    isBaseLayer: true,
    attribution: '',
    isActive: true,
    // key: 0,
    // iconUrl: '/assets/img1.png',
  },
  {
    id: '1',
    label: 'Satellite',
    layerType: 'tile',
    url: isURL(window.SATELLITE_MAP_URL) ? window.SATELLITE_MAP_URL : satelliteDefaultURL,
    isBaseLayer: true,
    attribution: '',
    isActive: true,
    // key: 1,
    // iconUrl: '/assets/img2.png',
  },
  {
    id: '2',
    label: 'Vector',
    layerType: 'geojson',
    url: 'countries.geo.json',
    isBaseLayer: true,
    geometryColor: '#999999',
    geometryType: 'Polygon',
    filePath: '',
    attribution: '',
    isActive: true,
    // key: 2,
    // iconUrl: '/assets/img2.png',
  },
  // test: {
  //   iconUrl: '/assets/img2.png',
  //   label: 'Nordic',
  //   layerType: 'WMS',
  //   url: 'http://localhost/cgi-bin/test/qgis_mapserv.fcgi?',
  //   key: 2,
  //   wmsLayer: 'worldLow15mTest',
  //   format: 'image/png',
  //   transparent: true,
  //   version: '1.3.0',
  //   crs: L.CRS.EPSG4326,
  // },
];

export const vectorLayers = [
  {
    label: 'Vector',
    url: 'countries',
  },
];

export const defaultWeight = 1;
export const highlightedWeight = 2;

export const defaultStyle = (isHighlighted: boolean = false, fillColor?: string): L.PathOptions => {
  if (isHighlighted) {
    return {
      weight: highlightedWeight,
      fillColor: '#347768',

      fillOpacity: 1,
      color: '#ffffff',
    };
  }
  return {
    weight: defaultWeight,
    fillColor: fillColor || '#999999',
    fillOpacity: 1,
    color: '#ffffff',
  };
};

export const s57LayerPolygonStyle = {
  color: '#000',
  weight: 1,
  fillOpacity: 0.3,
};

export const s57LayerStyle = {
  color: '#000',
  weight: 1,
  fillOpacity: 0.5,
};

export const getCRSFromString = (crs: string | undefined) => {
  switch (crs) {
    case 'EPSG:3395':
      return L.CRS.EPSG3395;
    case 'EPSG:3857':
      return L.CRS.EPSG3857;
    case 'EPSG:4326':
      return L.CRS.EPSG4326;
    case 'EPSG:900913':
      return L.CRS.EPSG900913;
    case 'Earth':
      return L.CRS.Earth;
    case 'Simple':
      return L.CRS.Simple;
    default:
      return undefined;
  }
};
