import React from 'react';

import { iconType } from 'src/types';

const GovernmentPureIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg
        style={{ ...style }}
        id="government"
        xmlns="http://www.w3.org/2000/svg"
        width="38.887"
        height="38.887"
        viewBox="0 0 38.887 38.887"
      >
        <path
          id="Path_37326"
          data-name="Path 37326"
          d="M111.62,355.71h-.009a1.139,1.139,0,0,0-1.137,1.139V367.58h2.283V356.849A1.139,1.139,0,0,0,111.62,355.71Z"
          transform="translate(-102.083 -328.694)"
          fill={color}
        />
        <path
          id="Path_37327"
          data-name="Path 37327"
          d="M241.616,335.41h-.009a1.14,1.14,0,0,0-1.137,1.139v12.273h2.283V336.549A1.14,1.14,0,0,0,241.616,335.41Z"
          transform="translate(-222.206 -309.935)"
          fill={color}
        />
        <path
          id="Path_37328"
          data-name="Path 37328"
          d="M372.61,355.71H372.6a1.14,1.14,0,0,0-1.137,1.139V367.58h2.283V356.849A1.139,1.139,0,0,0,372.61,355.71Z"
          transform="translate(-343.251 -328.694)"
          fill={color}
        />
        <ellipse
          id="Ellipse_68"
          data-name="Ellipse 68"
          cx="1.142"
          cy="1.139"
          rx="1.142"
          ry="1.139"
          transform="translate(18.302 13.56)"
          fill={color}
        />
        <path
          id="Path_37329"
          data-name="Path 37329"
          d="M25.972,20.066H38.887V17.343a1.14,1.14,0,0,0-.738-1.067L20.58,9.682V6.836h6.245A1.139,1.139,0,0,0,27.962,5.7V1.9A1.139,1.139,0,0,0,26.825.76H20.514a1.136,1.136,0,0,0-2.208.38V9.681L.763,16.267l-.025.01A1.139,1.139,0,0,0,0,17.343v2.723H12.914a1.27,1.27,0,0,1,0,2.541H1.6V37.747a1.139,1.139,0,0,0,1.137,1.139H6.117V28.156a3.418,3.418,0,0,1,3.411-3.418h.009a3.418,3.418,0,0,1,3.411,3.418V38.887H15.99V26.614A3.418,3.418,0,0,1,19.4,23.2h.009a3.418,3.418,0,0,1,3.411,3.418V38.887h3.118V28.156a3.418,3.418,0,0,1,3.411-3.418h.009a3.418,3.418,0,0,1,3.411,3.418V38.887h3.383a1.139,1.139,0,0,0,1.137-1.139V22.607H25.972a1.27,1.27,0,0,1,0-2.541Zm-6.529-1.949A3.418,3.418,0,1,1,22.859,14.7a3.421,3.421,0,0,1-3.416,3.418Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

GovernmentPureIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default GovernmentPureIcon;
