import { iconType } from 'src/types';

const AddInteractionIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <path
          id="Rectangle_14651"
          data-name="Rectangle 14651"
          d="M5,0H30a0,0,0,0,1,0,0V30a0,0,0,0,1,0,0H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
          fill={color || '#8B1739'}
        />
        <path
          id="Path_37292"
          data-name="Path 37292"
          d="M11.582,8.653,16.7,3.538a2.072,2.072,0,1,0-2.93-2.93L8.652,5.723,3.537.608a2.072,2.072,0,0,0-2.93,2.93L5.722,8.653.607,13.767a2.072,2.072,0,0,0,2.93,2.93l5.115-5.115L13.767,16.7a2.072,2.072,0,1,0,2.93-2.93Z"
          transform="translate(2.999 15.234) rotate(-45)"
          fill="#fff"
        />
      </svg>
    </div>
  );
};

AddInteractionIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default AddInteractionIcon;
