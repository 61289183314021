import { Form } from 'antd';
import ExportDataForm from 'src/app/forms/ExportData/ExportDataForm';
import { checkPermission } from 'src/app/helpers/permission.helper';
import usePermissions from 'src/app/hooks/usePermissions';
import { routes } from 'src/configs/permissions.dictionary';
import { FormContainer as FormTheme } from 'src/theme/Form.styled';

function SystemLogsPage() {
  const [form] = Form.useForm();
  const { permissions } = usePermissions();

  return (
    <div style={{ width: '35vw' }}>
      <FormTheme className="buttonTheme" id="sender-email-settings-form">
        <ExportDataForm
          form={form}
          saveButtonDisabled={!checkPermission(permissions, [routes['/Portal'], 'SystemLogs', 'export'])}
        />
      </FormTheme>
    </div>
  );
}

export default SystemLogsPage;
