import { configureStore } from '@reduxjs/toolkit';
import { apiSlice, apiStatusReducer } from 'src/app/redux/api/apiSlice';
import configContentReducer from 'src/app/redux/configContent/configContentSlice';
import dashboardReducer from 'src/app/redux/dashboard/dashboardSlice';
import userReducer from 'src/app/redux/user/userSlice';
// import timelineGraphReducer from './timelineGraph/timelineGraph'
import { surferApiSlice } from 'src/app/redux/api/surferApiSlice';
import mapReducer from 'src/app/redux/map/mapSlice';
import notificationReducer from 'src/app/redux/notifications/notificationsSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    configContent: configContentReducer,
    apiStatus: apiStatusReducer,
    dashboard: dashboardReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [surferApiSlice.reducerPath]: surferApiSlice.reducer,
    // timelineGraph: timelineGraphReducer
    notificationsState: notificationReducer,
    map: mapReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware).concat(surferApiSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
