import React from 'react';

import { iconType } from 'src/types';

const LabelImportantIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={
        onClick
          ? {
              cursor: 'pointer',

              width: '20px',
              height: '20px',
            }
          : { width: '20px', height: '20px' }
      }
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill={color || '#92aabb'}>
        <path d="m3 19 4.5-7L3 5h12q.5 0 .938.205.437.205.712.625L21 12l-4.35 6.15q-.275.4-.712.625Q15.5 19 15 19Z" />
      </svg>
    </div>
  );
};

export default LabelImportantIcon;
