import React from 'react';

import { iconType } from 'src/types';

const PrevalenceIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className, circleColor }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg style={{ ...style }} xmlns="http://www.w3.org/2000/svg" width="50" height="34" viewBox="0 0 50 34">
        <path
          id="Rectangle_14946"
          data-name="Rectangle 14946"
          d="M5,0H50a0,0,0,0,1,0,0V34a0,0,0,0,1,0,0H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
          transform="translate(50 34) rotate(180)"
          fill={circleColor}
        />
        <g id="XMLID_493_" transform="translate(16 5.096)">
          <g id="Group_40498" data-name="Group 40498" transform="translate(0 0.001)">
            <path
              id="Path_37336"
              data-name="Path 37336"
              d="M24.691,24.692,23.3,15.078l-2.265,2.265L19.3,15.607a7.715,7.715,0,0,0,0-6.52L21.034,7.35,23.3,9.615,24.691,0,15.077,1.393l2.265,2.265L15.606,5.4a7.715,7.715,0,0,0-6.52,0L7.349,3.658,9.614,1.393,0,0,1.392,9.615,3.657,7.35,5.4,9.089a7.713,7.713,0,0,0,0,6.515l-1.74,1.74L1.392,15.079,0,24.692,9.614,23.3,7.349,21.035,9.085,19.3a7.715,7.715,0,0,0,6.52,0l1.737,1.737L15.077,23.3ZM19.5,3.658,18.309,2.467,22.888,1.8l-.663,4.579L21.034,5.192,18.485,7.741a7.776,7.776,0,0,0-1.533-1.533ZM3.657,5.192,2.466,6.383,1.8,1.8l4.579.663L5.191,3.658,7.74,6.207a7.8,7.8,0,0,0-.82.713,7.7,7.7,0,0,0-.712.821ZM5.191,21.035l1.191,1.191L1.8,22.889l.663-4.579L3.657,19.5l2.55-2.55A7.721,7.721,0,0,0,7.74,18.486Zm7.155-2.54a6.149,6.149,0,1,1,4.348-1.8A6.108,6.108,0,0,1,12.346,18.495Zm4.606-.009a7.776,7.776,0,0,0,1.533-1.533L21.034,19.5l1.191-1.191.663,4.579-4.579-.663L19.5,21.035Z"
              transform="translate(0 -0.001)"
              fill={color}
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

PrevalenceIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default PrevalenceIcon;
