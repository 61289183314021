import { getEnumKeyByValue } from 'src/app/helpers/enum.helper';
import { getInterestNameFromLookup } from 'src/app/helpers/service.helper';
import { InteractionData } from 'src/app/redux/interaction/interaction-slice.type';
import { InterestsTypes } from 'src/enums/interests.enum';
import { InteractionColumnType } from 'src/types/table.type';

export const convertInteractionsToTableData = (data: InteractionData[]): InteractionColumnType[] => {
  const result: InteractionColumnType[] = [];

  data.forEach((interaction) => {
    const fromType = interaction.fromInterest.type;
    const toType = interaction.toInterest.type;

    result.push({
      id: interaction.id,
      name: interaction.name,
      fromInterestType: getEnumKeyByValue(InterestsTypes, fromType),
      fromInterest: getInterestNameFromLookup(interaction.fromInterest),
      relation: interaction.interactionType.name,
      toInterestType: getEnumKeyByValue(InterestsTypes, toType),
      toInterest: getInterestNameFromLookup(interaction.toInterest),
    });
  });

  return result;
};
