import { urlHelper } from 'src/app/helpers';
import { urlsConfig } from 'src/configs';
import { apiSlice } from 'src/app/redux/api/apiSlice';
import {
  UserRolePermissionCreateDataType,
  UserRolePermissionLookupResultType,
  UserRolePermissionParamsType,
} from 'src/app/redux/userRolePermissions/userRolePermission.type';

export const userRolePermissionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserRolePermissions: builder.query<UserRolePermissionLookupResultType, UserRolePermissionParamsType | void>({
      query: (params?: UserRolePermissionParamsType) => {
        return {
          url: urlsConfig.userRolePermission.lookup,
          params: urlHelper.getLookupQuery(params),
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.rows.map(({ id }) => ({
                type: 'UserRolePermissions' as const,
                id,
              })),
              { type: 'UserRolePermissions', id: 'LIST' },
            ]
          : [{ type: 'UserRolePermissions', id: 'LIST' }],
    }),
    updateUserRolePermission: builder.mutation({
      query: (data: UserRolePermissionCreateDataType) => ({
        url: urlsConfig.userRolePermission.update,
        method: 'PATCH',
        body: { ...data },
      }),
      invalidatesTags: [{ type: 'UserRoles', id: 'LIST' }],
      // async onQueryStarted({userRoleId, permissionId}, { getState, dispatch, queryFulfilled }) {
      //   try {
      //     //@ts-ignore
      //     const roles: UserRoleDataType[] = getState().user.roles;
      //     const { data } = await queryFulfilled;
      //     const updatedRole = roles.find(role => role.id === userRoleId );

      //     // const newRoles: UserRoleDataType[] = [];
      //     // data.rows.forEach((row) => {
      //     //   roles?.forEach((role) => {
      //     //     if (role.id === row.id) {
      //     //       newRoles.push(row);
      //     //     }
      //     //   });
      //     // });
      //     // dispatch(setUserRoles(newRoles));
      //   } catch (err) {
      //     console.log("err", err);
      //   }
      // },
    }),
  }),
});

export const { useGetUserRolePermissionsQuery, useUpdateUserRolePermissionMutation } = userRolePermissionsSlice;
