export enum Directorates {
  Human_Resources = 1,
  Intelligence = 2,
  Operations = 3,
  Logistics = 4,
  Plans_And_Policies = 5,
  Signals = 6,
  Training_And_Exercise = 7,
  Programs = 8,
  Civil_Military_Interactions = 9,
}

export enum FunctionalAreaRecognitionType {
  JOINT = 0,
  LAND = 1,
  MARITIME = 2,
  AIR_FORCE = 3,
  UNITED_NATIONS = 4,
  BATTALION = 5,
}

// export enum IncidentType {
//   Unknown = 0,
//   Riot = 1,
//   Fire_Explosion = 2,
//   Protest = 3,
//   Homicide = 4,
//   Cyber = 5,
//   Logon = 6,
//   Crowded = 7,
//   Card_Reader = 8,
//   Proximity_Warning = 9,
//   Proximity_Alarm = 10,
//   Inertia_Alarm = 11,
//   Inertia_Warning = 12,
//   Steady = 14,
//   Connection_Lost = 15,
//   Area_Restriction = 16,
//   Abandoned_Object = 17,
//   Battery_Low = 18,
//   Alarm = 19,
//   Technical_Service_Code = 20,
//   Power_Source_Battery = 21,
//   High_Temperature = 22,
// }

export enum VehicleTypeBitValue {
  Bus = 32,
  Car = 128,
  Motorcycle = 64,
  Truck = 16,
}

export enum ColorBitValue {
  BEIGE = 128,
  BLACK = 128,
  BLUE = 8,
  BORDEAUX = 32,
  BROWN = 1,
  GOLDEN = 64,
  GREEN = 2,
  GREY = 32,
  ORANGE = 8,
  RED = 4,
  SILVER = 16,
  VIOLET = 4,
  WHITE = 64,
  YELLOW = 16,
}

export enum AgeBitValue {
  Adult = 32,
  // CHILD = 128,
  // MIDDLE_AGE = 16,
  Senior = 8,
  Young = 64,
  Young_Adult = 16,
  Adult_Senior = 128,
  Face_Covered = 256,
}
export enum GenderBitValue {
  Female = 64,
  Male = 128,
}
export enum PersonStatus {
  Single = 0,
  Married = 1,
}
export enum CollarType {
  White_Collar,
  Blue_Collar,
}
export enum CollarBitValue {
  BLUE_COLLAR_CHEF = 16,
  Blue_Collar_Chef = 16,
  BLUE_COLLAR_INTERN = 4,
  BLUE_COLLAR_SECURITY = 128,
  BLUE_COLLAR_SECURITY_MANAGER = 64,
  BLUE_COLLAR_TEACHER = 2,
  BLUE_COLLAR_TECHNICIAN = 32,
  BLUE_COLLAR_WAREHOUSE_SUPERVISOR = 8,
  WHITE_COLLAR_ASST_GENERAL_MANAGER = 4,
  WHITE_COLLAR_ASST_MANAGER = 32,
  WHITE_COLLAR_ASST_SPECIALIST = 128,
  WHITE_COLLAR_DIRECTOR = 8,
  WHITE_COLLAR_GENERAL_MANAGER = 2,
  WHITE_COLLAR_MANAGER = 16,
  WHITE_COLLAR_SPECIALIST = 64,
}

export enum SensorType {
  Camera = 0,
  Card_Reader = 1,
  CB_Manual = 2,
  Web_Manual = 3,
  Other = 4,
  Web_Site = 5,
  News_Site = 6,
  Blacklist = 7,
  Court_Record = 8,
  Social_Media = 9,
  Fence_Node = 10,
}

export enum SensorCategoryType {
  OSS = 0,
  File_Upload = 1,
  Streaming = 2,
}

export enum FenceSensorStatus {
  Alarm,
  Online,
  Steady,
}

export enum FenceBatteryStatus {
  Normal,
  Low,
}

export enum FencePowerSource {
  'Power Line',
  Battery,
}

export enum SensorLocationBitValue {
  ELEVATOR = 64,
  ENTRANCE = 8,
  EXIT = 16,
  FIRE_LADDER = 128,
  MEETING_POINT = 32,
}

export enum EquipmentBitValue {
  BALACLAVA = 128,
  GLASSES = 64,
  HANDGUN = 32,
  HELMET = 128,
  KNIFE = 64,
  MASK = 16,
  OVERALLS = 32,
  RIFLE = 16,
  SHOTGUN = 8,
  BAG = 16,
}

export enum VehicleBrandBitValue {
  AUDI = 128,
  BMW = 64,
  CHEVROLET = 32,
  CITROEN = 16,
  DACIA = 8,
  FERRARI = 4,
  FIAT = 2,
  FORD = 1,
  HONDA = 128,
  HYUNDAI = 64,
  JAGUAR = 32,
  JEEP = 16,
  KIA = 8,
  LAND_ROVER = 4,
  LEXUS = 2,
  MASERATI = 1,
  MAZDA = 128,
  MERCEDES = 64,
  MITSUBISHI = 32,
  NISSAN = 16,
  OPEL = 8,
  PEUGEOT = 4,
  PORSCHE = 2,
  RENAULT = 1,
  SEAT = 128,
  SKODA = 64,
  SUBARU = 32,
  SUZUKI = 16,
  TESLA = 8,
  TOYOTA = 4,
  VOLKSWAGEN = 2,
  VOLVO = 1,
}
