import { SubscriptionFromLogger } from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyDetailsModal/companyDetails-modal.config';

export type SubscriptionInfoTableDataType = {
  fromSubscriptionType?: string;
  toSubscriptionType?: string;
  operationType: string;
  date: string;
};

export const convertSubsJSONtoTableValue = (subscriptionData: SubscriptionFromLogger) => {
  const dataSource = subscriptionData?.data?.map((subs) => {
    return {
      fromSubscriptionType: subs?.fromCustomerAccountType,
      toSubscriptionType: subs?.toCustomerAccountType,
      operationType: subs?.operationType,
      date: subs?.date,
    };
  });
  return dataSource;
};
