import React, { useEffect, useState } from 'react';
import TableComponent from 'src/app/components/TableComponent/TableComponent';
import {
  ResourceInfoTableDataType,
  convertResourceJSONtoTableValue,
} from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyDetailsModal/ResourceInfoModalContent/resource-info-modal-content';
import { ResourceFromLogger } from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyDetailsModal/companyDetails-modal.config';

type ResourceInfoProps = {
  resourceInfo: ResourceFromLogger;
};

const ResourceInfoModalContent: React.FC<ResourceInfoProps> = ({ resourceInfo }) => {
  const [resourceInfoList, setResourceInfoList] = useState<ResourceInfoTableDataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getLogs = () => {
    try {
      const data = convertResourceJSONtoTableValue(resourceInfo);
      if (data) {
        setResourceInfoList(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    getLogs();
  }, []);

  const columns = [
    {
      title: 'Operation Date',
      dataIndex: 'operationDate',
      key: 'operationDate',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Resource Name',
      dataIndex: 'resourceName',
      key: 'resourceName',
    },
    {
      title: 'Operation Type',
      dataIndex: 'operationType',
      key: 'operationType',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
  ];

  return (
    <TableComponent
      loading={loading}
      dataSource={resourceInfoList}
      columns={columns}
      extraContentWidth={400}
      isModalTable
      scroll={{ y: 'calc(100vh - 47rem)' }}
    />
  );
};

export default ResourceInfoModalContent;
