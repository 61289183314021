import { AxiosPromise } from 'axios';
import { urlHelper } from 'src/app/helpers';
import {
  BlackListFileUploadResultType,
  FileUploadDataType,
  FileUploadResultType,
  StorageParams,
  formDataHeader,
} from 'src/app/services/storage-server-service/storage.service.type';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';

export const getFile = (storageParams: StorageParams) => {
  return axios.get(urlsConfig.storage.file, {
    params: storageParams,
    responseType: 'arraybuffer',
  });
};

export const getConvertedHTMLFile = (body: { productName: string }) => {
  return axios.post(urlsConfig.storage.convertDoc, body, {
    responseType: 'text',
  });
};

export const fileUpload = ({
  excelFile,
  userId,
  customerId,
  cancelToken,
  blockErrorHandler,
}: FileUploadDataType): AxiosPromise<FileUploadResultType> => {
  const fileFormData = new FormData();

  fileFormData.append('excelFile', excelFile);
  const query: any = {};
  if (userId && customerId) {
    query.customerId = customerId;
    query.userId = userId;
  }
  return axios.post(
    urlHelper.addQueryParameters(query, urlsConfig.interest.identified.relation.bulkImport),
    fileFormData,
    {
      headers: formDataHeader,
      // onUploadProgress: onProgress,
      cancelToken,
      blockErrorHandler,
      baseURL: urlsConfig.excelImportUrl,
    },
  );
};

export const fileUploadBlacklist = ({
  excelFile,
  cancelToken,
  blockErrorHandler,
}: FileUploadDataType): AxiosPromise<BlackListFileUploadResultType> => {
  const fileFormData = new FormData();

  fileFormData.append('excelFile', excelFile);

  return axios.post(urlsConfig.data.blacklistFileUpload, fileFormData, {
    headers: formDataHeader,
    // onUploadProgress: onProgress,
    cancelToken,
    blockErrorHandler,
    baseURL: 'need-implementation',
  });
};
