import React from 'react';

import { iconType } from 'src/types';

const TipTierPureIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'inline-block' } : { display: 'inline-block' }}
    >
      <svg
        style={{ ...style }}
        xmlns="http://www.w3.org/2000/svg"
        width="26.789"
        height="38.1"
        viewBox="0 0 26.789 38.1"
      >
        <path
          id="Path_37332"
          data-name="Path 37332"
          d="M92.371,32.147H86.418a4.47,4.47,0,0,1-4.465-4.465c0-6.624-5.953-6.226-5.953-14.287a13.395,13.395,0,1,1,26.789,0c0,8-5.953,7.715-5.953,14.287A4.47,4.47,0,0,1,92.371,32.147ZM89.395,2.977A10.43,10.43,0,0,0,78.977,13.395c0,6.624,5.953,6.226,5.953,14.287a1.49,1.49,0,0,0,1.488,1.488h5.953a1.49,1.49,0,0,0,1.488-1.488c0-8,5.953-7.715,5.953-14.287A10.43,10.43,0,0,0,89.395,2.977Z"
          transform="translate(-76)"
          fill={color}
        />
        <path
          id="Path_37333"
          data-name="Path 37333"
          d="M203.441,474.977h-5.953a1.488,1.488,0,1,1,0-2.977h5.953a1.488,1.488,0,1,1,0,2.977Z"
          transform="translate(-187.07 -436.877)"
          fill={color}
        />
        <path
          id="Path_37334"
          data-name="Path 37334"
          d="M200.465,205.953a1.484,1.484,0,0,1-1.052-.436l-2.977-2.977a1.488,1.488,0,0,1,2.1-2.1l1.924,1.924,1.924-1.924a1.488,1.488,0,0,1,2.1,2.1l-2.977,2.977A1.484,1.484,0,0,1,200.465,205.953Z"
          transform="translate(-187.07 -185.117)"
          fill={color}
        />
        <path
          id="Path_37335"
          data-name="Path 37335"
          d="M237.488,245.953A1.488,1.488,0,0,1,236,244.465v-2.977a1.488,1.488,0,1,1,2.977,0v2.977A1.488,1.488,0,0,1,237.488,245.953Z"
          transform="translate(-224.094 -222.141)"
          fill={color}
        />
      </svg>
    </div>
  );
};

TipTierPureIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '8px' },
  // color: 'white',
  onClick: undefined,
  className: undefined,
};

export default TipTierPureIcon;
