import { Button } from 'antd';
import React, { ReactElement } from 'react';
import { useAppSelector } from 'src/app/redux/hook';
import { CSSProperties } from 'styled-components';

interface IExtraTabProps {
  onClick?: (event?: any) => void;
  icon: ReactElement<any> | null;
  text: string;

  iconPosition?: 'left' | 'right';
  offset?: number;

  bgTheme?: boolean;
  iconTheme?: boolean;
  disabled?: boolean;
  btnStyle?: CSSProperties;
}
const ExtraTab: React.FC<IExtraTabProps> = ({
  iconPosition,
  offset,
  disabled,
  onClick,
  bgTheme,
  iconTheme,
  icon,
  text,
  btnStyle,
}) => {
  const { theme } = useAppSelector((state) => state.configContent);

  const flexDirection = iconPosition === 'left' ? 'row' : 'row-reverse';
  const columnGap = offset || 0;
  return disabled ? null : (
    <div className="extra-tab-content" onClick={onClick} style={{ flexDirection, columnGap: `${columnGap}px` }}>
      <div className="extra-tab-icon" style={bgTheme ? { background: theme.colors.quoteBgc } : undefined}>
        {icon
          ? React.cloneElement(icon, {
              color: iconTheme ? theme.colors.quoteBgc : icon?.props?.color ? icon?.props?.color : undefined,
            })
          : null}
      </div>
      <Button className="bordered-tab-button" style={btnStyle}>
        <span className="bordered-button-text">{text}</span>
      </Button>
    </div>
  );
};
ExtraTab.defaultProps = {
  iconPosition: 'left',

  offset: 0,

  iconTheme: false,
  bgTheme: false,
};
export default ExtraTab;
