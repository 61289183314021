import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { storageHelper } from 'src/app/helpers';
import { LanguagePreferenceEnum } from 'src/enums/language.enum';
import * as ar from 'src/locals/languages/language.ar';
import * as eng from 'src/locals/languages/language.eng';
import * as tr from 'src/locals/languages/language.tr';

const currentLanguage = storageHelper.getValueWithKey('language'); // JSON.stringify(localStorageLanguage)

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources: { [key in keyof typeof LanguagePreferenceEnum]: any } = {
  EN: {
    translation: eng.languageEn,
  },
  TR: {
    translation: tr.languageTr,
  },
  AR: {
    translation: ar.languageAr,
  },
};

// declare custom type options so the return is always a string.

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: currentLanguage || LanguagePreferenceEnum.EN, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    fallbackLng: LanguagePreferenceEnum.EN,
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    returnNull: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: false,
    keySeparator: '.',
    ns: ['translation'],
    defaultNS: 'translation',
    react: { useSuspense: false },
    compatibilityJSON: 'v3',
  });

const t = i18n.t.bind(i18n);

export { t };

export default i18n;
