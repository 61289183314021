export enum LayoutViewType {
  LAYOUT_1 = 1, // SC3
  LAYOUT_2 = 2, // SAMA RED
  LAYOUT_3 = 3, // NGINR

  LAYOUT_4 = 4, // SAMA BLACK
  FULL = 5,
}

export enum SystemType {
  RED = 'Red',
  BLACK = 'Black',
}

export enum AuthenticationEnum {
  DISABLED = 0,
  PALMREADERAUTHENTICATION = 1,
}

export enum ResetPasswordStatus {
  Active = 0,
  Inactive = 1,
}
