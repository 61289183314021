import styled from 'styled-components';

export const Sidebar = styled.aside`
  .ant-layout-sider {
    #menu {
      #menuContainer {
        border-inline-end: 1px solid ${({ theme }) => theme.colors.quoteBgc};

        ul {
          li {
            span:nth-child(2) {
              border-inline-start: 1px solid ${({ theme }) => theme.colors.quoteBgc};
            }
          }
        }
      }
      #menuFooter {
        background-color: ${({ theme }) => theme.colors.quoteBgc};
      }
    }
    ul.ant-menu.ant-menu-root {
      .ant-menu-title-content {
        padding-inline-start: 24px;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        opacity: 1;
      }

      .ant-menu-item {
        padding-inline-end: 0px !important;
      }

      .ant-menu-item-selected {
        .ant-menu-title-content {
          font-weight: 700;
          font-size: 16px;
          color: ${({ theme }) => theme.colors.quoteBgc};
        }

        background: none;
        div {
          svg {
            circle {
              fill: ${({ theme }) => theme.colors.quoteBgc} !important;
            }
            path {
              fill: ${({ theme }) => theme.colors.header} !important;
            }
          }
        }
      }
    }
  }
`;
