import { RoutesType } from 'src/types/routes.type';

export type PermissionDict<T extends string> = {
  [key in T]: T extends `/` ? 'Home' : T extends `/${infer Route}` ? Route : T;
};

export const routes: PermissionDict<RoutesType> = {
  '/': 'Home',
  '/Network': 'Network',
  '/Authentication': 'Authentication',
  '/ForgotPasswordDone': 'ForgotPasswordDone',
  '/GoogleAuthenticationVerification': 'GoogleAuthenticationVerification',
  '/Industry': 'Industry',
  '/Location': 'Location',
  '/Login': 'Login',
  '/Notifications': 'Notifications',
  '/Profile': 'Profile',
  '/Reports': 'Reports',
  '/Resources': 'Resources',
  '/Settings': 'Settings',
  '/Topic': 'Topic',
  '/SAQForm': 'SAQForm',
  '/Students': 'Students',
  '/HelpAndSupport': 'HelpAndSupport',
  '/CustomerRegister': 'CustomerRegister',
  '/AccountType': 'AccountType',
  '/CustomerRegisterWithCompanyDetails': 'CustomerRegisterWithCompanyDetails',
  '/Register': 'Register',
  '/CompanySettings': 'CompanySettings',
  '/Administration': 'Administration',
  '/Portal': 'Portal',
  '/ImportData': 'ImportData',
  '/ExportData': 'ExportData',
  '/Interests': 'Interests',
  '/NSearch': 'NSearch',
  '/FileUpload': 'FileUpload',
};
