import moment from 'moment';
import { ResourceFromLogger } from 'src/app/pages/PortalPage/CustomerAndUserPage/CompanyDetailsModal/companyDetails-modal.config';

export type ResourceInfoTableDataType = {
  operationDate: string;
  price: string;
  resourceName: string;
  operationType: string;
  username: string;
};

export const ConvertedContentToTableData = (data: any[]): ResourceInfoTableDataType[] => {
  const result: ResourceInfoTableDataType[] = [];

  data.forEach((item) => {
    item.data.forEach((log: any) => {
      result.push({
        operationDate: moment(log.content.date).format('DD/MM/YYYY - HH:mm'),
        price:
          log.content.obj[0].entity.resource.price && log.content.obj[0].entity.resource.price !== null
            ? (log.content.obj[0].entity.resource.price / 100).toString()
            : log.content.obj[0].entity.resource.price,
        resourceName: log.content.obj[0].entity.resource.name,
        operationType: log.event?.type === 'GET' ? 'DOWNLOAD' : log.event?.type === 'CREATE' ? 'PAID' : log.event.type,
        username: log.content.obj[0].entity.fullName,
      });
    });
  });
  return result;
};

export const convertResourceJSONtoTableValue = (subscriptionData: ResourceFromLogger) => {
  const dataSource = subscriptionData.data.map((res) => {
    return {
      operationDate: res.paymentDate,
      price: `${res.resourcePrice}`,
      resourceName: res.resourceName,
      operationType: res.operationType,
      username: res.buyerName,
    };
  });
  return dataSource;
};
