import { TLayers } from 'src/app/services/layersService/layers-server.service.config';

export type StateType = {
  activeLayer: string | undefined;
  availableLayers: TLayers;
  mapInstance: MapInstance | undefined;
};

export enum MapInstance {
  DEFAULT = 'Default',
  MODAL = 'Modal',
  COUNTRIES = 'Country',
}
