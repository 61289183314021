import { LoadingOutlined } from '@ant-design/icons';
import { Badge, Form, Input, Progress, Spin, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { t } from 'i18next';
import fileDownload from 'js-file-download';
import { useEffect, useState } from 'react';
import ExtraTab from 'src/app/components/ExtraTabcomponent/ExtraTab';
import FormComponent from 'src/app/components/FormComponent/FormComponent';
import { useNumberMutation, useStartMutation } from 'src/app/redux/blackSystem/blackSystem.slice';
import { useAppSelector } from 'src/app/redux/hook';
import { axios } from 'src/app/utils';
import { urlsConfig } from 'src/configs';
import { UploadIcon } from 'src/icons';

function CourtRecordComponent() {
  // States
  const [dto, setDto] = useState<string>('');
  const [numberMessage, setNumberMessage] = useState<string>('');
  const [downloadState, setDownloadState] = useState<{
    state: boolean;
    done: boolean;
    size: string;
  }>({
    state: false,
    done: false,
    size: '0.00',
  });

  // AntDState
  const [form] = useForm();
  const keyword = Form.useWatch('keyword', form);

  const [start] = useStartMutation();
  const [number] = useNumberMutation();
  const { theme } = useAppSelector((state) => state.configContent);

  useEffect(() => {
    setDto(keyword);
  }, [keyword]);

  return (
    <div className="social-media">
      <div className="first-form">
        <div className="news-api-form">
          <FormComponent
            extraButton={
              <div className="extra-btns">
                <ExtraTab
                  onClick={() => {
                    message.success(t('blackSystemPage.startOperationSuccess'), 5);
                    start({ data: { query: dto }, url: 'Court_Record' })
                      .unwrap()
                      .catch(() => message.error(t('blackSystemPage.startOperationFailed'), 5));
                  }}
                  icon={null}
                  text={t('blackSystemPage.commonBtns.start')}
                  bgTheme
                  disabled={!dto}
                />

                <ExtraTab
                  onClick={async () => {
                    try {
                      message.success(t('blackSystemPage.exportOperationSuccess'), 5);
                      await axios({
                        url: urlsConfig.blackSystem('Court_Record').export,
                        method: 'POST',
                        data: { query: dto },
                        responseType: 'blob',
                        onDownloadProgress: (progressEvent) => {
                          const bytesDownloaded = progressEvent.loaded;
                          const megabytesDownloaded = bytesDownloaded / (1024 * 1024);
                          if (megabytesDownloaded) {
                            setDownloadState((prevState) => ({
                              ...prevState,
                              state: true,
                              size: `${megabytesDownloaded.toFixed(2)}`,
                            }));
                          }
                        },
                        blockSpinner: true,
                      })
                        .then((res: any) => {
                          setDownloadState({
                            size: '0.00',
                            state: false,
                            done: true,
                          });
                          const fileName = dto || 'Court_Record';
                          fileDownload(res.data, `${fileName}.zip`);
                        })
                        .catch(() => {
                          message.error(t('blackSystemPage.exportOperationFailed'), 5);
                          setDownloadState({
                            done: false,
                            size: '0.00',
                            state: false,
                          });
                        });
                    } catch (error) {
                      console.log('error', error);
                    }
                  }}
                  icon={<UploadIcon />}
                  text={t('blackSystemPage.commonBtns.exportData')}
                  bgTheme
                  disabled={!dto}
                />
                <ExtraTab
                  onClick={() => {
                    number({ data: { query: dto }, url: 'Court_Record' })
                      .unwrap()
                      .then((res) => {
                        setNumberMessage(res);
                      })
                      .catch(() => {
                        setNumberMessage('');
                        message.success(t('blackSystemPage.numberOperationFailed'), 5);
                      });
                  }}
                  icon={null}
                  text={t('blackSystemPage.commonBtns.number')}
                  bgTheme
                  disabled={!dto}
                />
              </div>
            }
            options={{ labelCol: { span: 9 }, form, size: 'middle' }}
          >
            <Form.Item
              className="form-with-btn"
              name="keyword"
              label={
                <div style={{ display: 'flex' }}>
                  {t('blackSystemPage.newsApi.form.addNewKeyword')}
                  <Badge offset={[10, 0]} />
                </div>
              }
              rules={[
                {
                  required: true,
                  message: t('errors.required'),
                },
              ]}
              initialValue=""
            >
              <Input bordered={false} type="text" />
            </Form.Item>
          </FormComponent>
          {!dto && <p>{t('blackSystemPage.atLeastOneTopicNeeded')}</p>}
          <div className="resultAreas">
            {(downloadState.done || downloadState.state) && (
              <div
                className="resultArea"
                style={{
                  ...(theme.colors.quoteBgc && {
                    borderTop: `1px solid ${theme.colors.quoteBgc}`,
                    borderBottom: `1px solid ${theme.colors.quoteBgc}`,
                    color: `${theme.colors.quoteBgc}`,
                  }),
                }}
              >
                {downloadState.state ? (
                  <>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    <p>{downloadState.size}mb</p>
                  </>
                ) : (
                  downloadState.done && (
                    <>
                      <Progress type="circle" percent={100} size={30} />
                      <p>Successfully Downloaded...</p>
                    </>
                  )
                )}
              </div>
            )}
            {numberMessage !== '' && (
              <div
                className="resultArea"
                style={{
                  ...(theme.colors.quoteBgc && {
                    borderTop: `1px solid ${theme.colors.quoteBgc}`,
                    borderBottom: `1px solid ${theme.colors.quoteBgc}`,
                    color: `${theme.colors.quoteBgc}`,
                  }),
                }}
              >
                <p>
                  {t('blackSystemPage.newsApi.numberMessage')}
                  {numberMessage}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourtRecordComponent;
