import InfoTooltipWithLabel from 'src/app/components/InfoTooltipComponent/InfoTooltipWithLabel';
import { ICustomTabs } from 'src/app/components/TabLayoutComponent/tab-layout.config';
import { IColumnType } from 'src/app/components/TableComponent/TableComponent';

interface IColumnHighlightProps<T> {
  col?: IColumnType<T>;
  tabs?: ICustomTabs;
}

const ColumnHighlight = <T extends object>({ col, tabs }: IColumnHighlightProps<T>) => {
  const name = col && col.title ? col.title : tabs ? tabs.label : null;

  const tooltipContent = col && col.tooltipText ? col.tooltipText : tabs ? tabs.tooltipText : null;
  return <InfoTooltipWithLabel label={name} tooltipContent={tooltipContent} />;
};

export default ColumnHighlight;
