import { routes } from 'src/configs/permissions.dictionary';
import { LayoutViewType } from 'src/enums/user.enum';

export type PermissionOptions = {
  options?: { [key: string]: PermissionOptions };
  value: string;
  key: string;
  excludeLayouts?: LayoutViewType[];
};

export type Permission = {
  options: {
    [key: string]: PermissionOptions;
  };
};

const atomics = {
  add: { value: '01', key: 'global.add' },
  edit: { value: '02', key: 'global.edit' },
  delete: { value: '03', key: 'global.delete' },
  archive: { value: '04', key: 'global.archive' },
  download: { value: '05', key: 'global.download' },
  show: { value: '06', key: 'global.show' },
  send: { value: '07', key: 'global.send' },
  openSaq: { value: '08', key: 'global.openSaq' },
  upload: { value: '09', key: 'global.upload' },
  save: { value: '10', key: 'global.save' },
  export: { value: '11', key: 'global.export' },
  inviteUser: { value: '12', key: 'global.inviteUser' },
  inviteCustomer: { value: '13', key: 'global.inviteCustomer' },
  freeze: { value: '14', key: 'global.freeze' },
  start: { value: '15', key: 'global.start' },
  generateNumber: { value: '16', key: 'global.number' },
  network: { value: '17', key: 'global.network' },
  deleteAll: { value: '18', key: 'global.deleteAll' },
  readAll: { value: '19', key: 'global.readAll' },
  relevantAll: { value: '20', key: 'global.relevantAll' },
  archiveAll: { value: '21', key: 'global.archiveAll' },
  read: { value: '22', key: 'global.read' },
  relevant: { value: '23', key: 'global.relevant' },
  star: { value: '24', key: 'global.star' },
  editLabel: { value: '25', key: 'global.editLabel' },
  editMonitoredList: { value: '25', key: 'global.editMonitoredList' },
};

export const permissions: Permission = {
  options: {
    [routes['/']]: {
      value: '01',
      key: 'permissions.home',
      excludeLayouts: [LayoutViewType.LAYOUT_4],
      options: {
        add: atomics.add,
        edit: atomics.edit,
        delete: atomics.delete,
      },
    },
    [routes['/Network']]: {
      excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],
      value: '02',
      key: 'permissions.network',
      options: {
        ValueChain: {
          value: '01',
          key: 'permissions.valueChain',
          options: {
            TableView: {
              value: '01',
              key: 'permissions.tableView',
              options: {
                add: atomics.add,
                edit: atomics.edit,
                delete: atomics.delete,
                download: atomics.download,
              },
            },
            NetworkView: {
              value: '02',
              key: 'permissions.networkView',
            },
          },
        },
        Saq: {
          value: '02',
          key: 'permissions.saq',
          options: {
            SaqTemplates: {
              value: '01',
              key: 'permissions.saqTemplates',
              options: {
                edit: atomics.edit,
                archive: atomics.archive,
                delete: atomics.delete,
              },
            },
            EmailTemplates: {
              value: '02',
              key: 'permissions.emailTemplates',
              options: {
                edit: atomics.edit,
                delete: atomics.delete,
                add: atomics.add,
                show: atomics.show,
              },
            },
            SendSaq: {
              value: '03',
              key: 'permissions.sendSaq',
              options: {
                send: atomics.send,
              },
            },
            MonitorSaq: {
              value: '04',
              key: 'permissions.monitorSaq',
              options: {
                openSaq: atomics.openSaq,
                delete: atomics.delete,
              },
            },
            ActionCenter: {
              value: '05',
              key: 'permissions.actionCenter',
              options: {
                edit: atomics.edit,
                delete: atomics.delete,
              },
            },
          },
        },
        Coc: {
          value: '03',
          key: 'permissions.coc',
          options: {
            CocTemplates: {
              value: '01',
              key: 'permissions.cocTemplates',
              options: {
                upload: atomics.upload,
                edit: atomics.edit,
                archive: atomics.archive,
                delete: atomics.delete,
              },
            },
            EmailTemplates: {
              value: '02',
              key: 'permissions.emailTemplates',
              options: {
                add: atomics.add,
                edit: atomics.edit,
                show: atomics.show,
                delete: atomics.delete,
              },
            },
            SendCoc: {
              value: '03',
              key: 'permissions.sendCoc',
              options: {
                send: atomics.send,
              },
            },
            MonitorCoc: {
              value: '04',
              key: 'permissions.monitorCoc',
              options: {
                show: atomics.show,
                delete: atomics.delete,
              },
            },
          },
        },
        Media: {
          value: '04',
          key: 'permissions.media',
          // options: {
          //   filter: atomics.filter,
          // },
        },
        Locations: {
          key: 'permissions.locations',
          value: '05',
        },
        Industries: {
          key: 'permissions.industries',
          value: '06',
        },
        Details: {
          key: 'permissions.details',
          value: '07',
          options: {
            save: atomics.save,
          },
        },
        RelevantNews: {
          key: 'permissions.relevantNews',
          value: '08',
        },
      },
    },
    [routes['/Notifications']]: {
      key: 'permissions.notifications',
      excludeLayouts: [LayoutViewType.LAYOUT_4],
      value: '03',
      options: {
        delete: atomics.delete,
        deleteAll: atomics.deleteAll,
        relevantAll: atomics.relevantAll,
        archiveAll: atomics.archiveAll,
        readAll: atomics.readAll,
        read: atomics.read,
        star: atomics.star,
        editLabel: atomics.editLabel,
        archive: atomics.archive,
        relevant: atomics.relevant,
      },
    },
    [routes['/Topic']]: {
      excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],
      key: 'permissions.topic',
      value: '04',
    },
    [routes['/Location']]: {
      key: 'permissions.location',
      value: '05',
      excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],
    },
    [routes['/Industry']]: {
      key: 'permissions.industry',
      value: '06',
      excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],
    },
    [routes['/Reports']]: {
      excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],
      key: 'permissions.reports',
      value: '07',
      options: {
        Reports: {
          value: '01',
          key: 'permissions.reports',
          options: {
            add: atomics.add,
          },
        },
      },
    },
    [routes['/Resources']]: {
      excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_4],
      key: 'permissions.resources',
      value: '08',
      options: {
        // add: atomics.add,
        // edit: atomics.edit,
        // delete: atomics.delete,
        download: atomics.download,
      },
    },
    [routes['/Settings']]: {
      value: '10',
      key: 'permissions.settings',
      options: {
        Theme: {
          value: '01',
          key: 'permissions.theme',
        },
        UserSettings: {
          value: '02',
          key: 'permissions.userSettings',
          options: {
            inviteUser: {
              ...atomics.inviteUser,
              excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],
            },
            edit: atomics.edit,
            delete: atomics.delete,
          },
        },
        SecuritySettings: {
          value: '03',
          key: 'permissions.securitySettings',
          options: {
            save: atomics.save,
          },
        },
        NotificationSettings: {
          excludeLayouts: [LayoutViewType.LAYOUT_4],
          value: '04',
          key: 'permissions.notificationsSettings',
          options: {
            RuleSettings: {
              value: '01',
              key: 'permissions.ruleSettings',
              options: {
                add: atomics.add,
                edit: atomics.edit,
                delete: atomics.delete,
              },
            },
            NotificationLabelSettings: {
              value: '02',
              key: 'permissions.notificationLabelSettings',
              options: {
                add: atomics.add,
                edit: atomics.edit,
                delete: atomics.delete,
              },
            },
          },
        },
        SAQSettings: {
          excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],
          value: '05',
          key: 'permissions.saqSettings',
          options: {
            save: atomics.save,
          },
        },
        CoCSettings: {
          excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],

          value: '06',
          key: 'permissions.cocSettings',
          options: {
            save: atomics.save,
          },
        },
        MonitoredSupplierSettings: {
          excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],

          value: '07',
          key: 'permissions.monitorSupplierSettings',
          options: {
            edit: atomics.edit,
            delete: atomics.delete,
            editMonitoredList: atomics.editMonitoredList,
          },
        },
        MapSettings: {
          excludeLayouts: [LayoutViewType.LAYOUT_4],
          value: '08',
          key: 'permissions.mapSettings',
          options: {
            add: atomics.add,
            edit: atomics.edit,
            delete: atomics.delete,
          },
        },
      },
    },
    [routes['/Students']]: {
      excludeLayouts: [LayoutViewType.LAYOUT_1, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],
      value: '11',
      key: 'permissions.students',
      options: {
        AllStudent: {
          value: '01',
          key: 'permissions.students',
          options: {
            add: atomics.add,
            edit: atomics.edit,
            delete: atomics.delete,
            archive: atomics.archive,
            upload: atomics.upload,
            export: atomics.export,
          },
        },
        Archive: {
          value: '02',
          key: 'permissions.archive',
          options: {
            add: atomics.add,
            edit: atomics.edit,
            delete: atomics.delete,
            archive: atomics.archive,
            upload: atomics.upload,
            export: atomics.export,
          },
        },
      },
    },
    [routes['/Profile']]: {
      excludeLayouts: [LayoutViewType.LAYOUT_4],

      value: '12',
      key: 'permissions.profile',
      options: {
        UserProfile: {
          value: '01',
          key: 'permissions.userProfile',
          options: {
            save: atomics.save,
            upload: atomics.upload,
          },
        },
        UserPassword: {
          value: '02',
          key: 'permissions.userPassword',
          options: {
            save: atomics.save,
          },
        },
      },
    },
    [routes['/Administration']]: {
      value: '14',
      key: 'permissions.administration',
      options: {
        UserRoles: {
          value: '01',
          key: 'permissions.userRoles',
          options: {
            add: atomics.add,
            edit: atomics.edit,
            delete: atomics.delete,
          },
        },
        UserRights: {
          value: '02',
          key: 'permissions.userRights',
          options: {
            save: atomics.save,
          },
        },
      },
    },
    [routes['/Portal']]: {
      value: '15',
      key: 'permissions.portal',
      options: {
        CustomerInvitations: {
          value: '01',
          key: 'permissions.customerInvitations',
          excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],
        },
        UserInvitations: {
          excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],
          value: '02',
          key: 'permissions.userInvitations',
        },
        CustomerAndUser: {
          excludeLayouts: [LayoutViewType.LAYOUT_3],

          value: '03',
          key: 'permissions.customerAndUser',
          options: {
            edit: atomics.edit,
            delete: atomics.delete,
            show: atomics.show,
            cutomerInvite: atomics.inviteCustomer,
            inviteUser: { ...atomics.inviteUser, excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3] },
            export: atomics.export,
            freeze: atomics.freeze,
          },
        },
        SenderEmailSettings: {
          excludeLayouts: [LayoutViewType.LAYOUT_4],
          value: '04',
          key: 'permissions.senderEmailSettings',
          options: {
            save: atomics.save,
          },
        },
        Resources: {
          excludeLayouts: [LayoutViewType.LAYOUT_4],
          value: '05',
          key: 'permissions.portalResources',
          options: {
            add: atomics.add,
            edit: atomics.edit,
            delete: atomics.delete,
            download: atomics.download,
          },
        },
        ControlPanel: {
          value: '06',
          key: 'permissions.controlPanel',
          options: {
            RunningModules: {
              value: '01',
              key: 'permissions.runningModules',
            },
            Machines: {
              value: '02',
              key: 'permissions.machines',
            },
          },
        },
        OSSConfig: {
          value: '07',
          key: 'permissions.ossConfig',
          options: {
            NewsSite: {
              value: '01',
              key: 'permissions.newsSite',
              options: {
                start: atomics.start,
                export: atomics.export,
                generateNumber: atomics.generateNumber,
              },
            },
            SocialMedia: {
              value: '02',
              key: 'permissions.socialMedia',
              options: {
                start: atomics.start,
                export: atomics.export,
                generateNumber: atomics.generateNumber,
              },
            },
            Blacklist: {
              value: '03',
              key: 'permissions.blacklist',
            },
            CourtRecord: {
              value: '04',
              key: 'permissions.courtRecord',
            },
            WebSite: {
              value: '05',
              key: 'permissions.webSite',
            },
          },
        },
        SystemLogs: {
          value: '08',
          key: 'permissions.systemLogs',
          options: {
            export: atomics.export,
          },
        },
        Users: {
          excludeLayouts: [LayoutViewType.LAYOUT_1, LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_4],
          value: '09',
          key: 'permissions.users',
          options: {
            show: atomics.show,
            freeze: atomics.freeze,
            edit: atomics.edit,
            delete: atomics.delete,
          },
        },
      },
    },
    [routes['/ImportData']]: {
      excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_3, LayoutViewType.LAYOUT_4],
      value: '16',
      key: 'permissions.importData',
    },
    // [routes["/ExportData"]]: {
    //   value: "17",
    //   key: "permissions.exportData",""
    // },
    [routes['/HelpAndSupport']]: {
      value: '18',
      key: 'permissions.helpAndSupport',
      options: {
        UserGuide: {
          value: '01',
          key: 'permissions.userGuide',
          options: {
            download: atomics.download,
          },
        },
        FAQ: {
          value: '02',
          key: 'permissions.faq',
          options: {
            add: atomics.add,
            edit: atomics.edit,
            delete: atomics.delete,
          },
        },
        CCR: {
          value: '03',
          key: 'permissions.ccr',
          excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_4],
          options: {
            add: atomics.add,
            edit: atomics.edit,
            delete: atomics.delete,
          },
        },
        TT: {
          excludeLayouts: [LayoutViewType.LAYOUT_2, LayoutViewType.LAYOUT_4],
          value: '04',
          key: 'permissions.tt',
          options: {
            add: atomics.add,
            edit: atomics.edit,
            delete: atomics.delete,
          },
        },
      },
    },
    [routes['/FileUpload']]: {
      excludeLayouts: [LayoutViewType.LAYOUT_4],
      value: '19',
      key: 'permissions.fileUpload',
    },
    [routes['/NSearch']]: {
      excludeLayouts: [LayoutViewType.LAYOUT_4],
      value: '20',
      key: 'permissions.nSearch',
      options: {
        TableView: {
          value: '01',
          key: 'permissions.tableView',
          options: {
            export: atomics.export,
          },
        },
        MapView: {
          value: '02',
          key: 'permissions.mapView',
        },
      },
    },
    [routes['/Interests']]: {
      excludeLayouts: [LayoutViewType.LAYOUT_1, LayoutViewType.LAYOUT_4],
      value: '21',
      key: 'permissions.interests',
      options: {
        Person: {
          value: '01',
          key: 'permissions.person',
          options: {
            Identified: {
              value: '01',
              key: 'permissions.identified',
              options: {
                add: atomics.add,
                edit: atomics.edit,
                delete: atomics.delete,
                export: atomics.export,
                network: atomics.network,
              },
            },
            Recognized: {
              value: '02',
              key: 'permissions.recognized',
            },
          },
        },
        Vehicle: {
          value: '02',
          key: 'permissions.vehicle',
          options: {
            Identified: {
              value: '01',
              key: 'permissions.identified',
              options: {
                add: atomics.add,
                edit: atomics.edit,
                delete: atomics.delete,
                export: atomics.export,
                network: atomics.network,
              },
            },
            Recognized: {
              value: '02',
              key: 'permissions.recognized',
            },
          },
        },
        Location: {
          value: '03',
          key: 'permissions.location',
          options: {
            Identified: {
              value: '01',
              key: 'permissions.identified',
              options: {
                add: atomics.add,
                edit: atomics.edit,
                delete: atomics.delete,
                export: atomics.export,
                network: atomics.network,
              },
            },
            Recognized: {
              value: '02',
              key: 'permissions.recognized',
            },
          },
        },
        Enterprise: {
          value: '04',
          key: 'permissions.enterprise',
          options: {
            Identified: {
              value: '01',
              key: 'permissions.identified',
              options: {
                add: atomics.add,
                edit: atomics.edit,
                delete: atomics.delete,
                export: atomics.export,
                network: atomics.network,
              },
            },
            MapView: {
              value: '02',
              key: 'permissions.mapView',
            },
          },
        },
        Industry: {
          value: '05',
          key: 'permissions.industry',
          options: {
            // Identified: {
            //   value: "01",
            //   key: "permissions.identified",
            //   options: {
            //     add: atomics.add,
            //     edit: atomics.edit,
            //     delete: atomics.delete,
            //     export: atomics.export,
            //     network: atomics.network,
            //   },
            // },
            Recognized: {
              value: '02',
              key: 'permissions.recognized',
            },
          },
        },
        Topic: {
          value: '06',
          key: 'permissions.topic',
          options: {
            Identified: {
              value: '01',
              key: 'permissions.identified',
              options: {
                add: atomics.add,
                edit: atomics.edit,
                delete: atomics.delete,
                export: atomics.export,
                network: atomics.network,
              },
            },
            // Recognized: {
            //   value: "02",
            //   key: "permissions.recognized",
            // },
          },
        },
        FunctionalArea: {
          value: '07',
          key: 'permissions.functionalArea',
          options: {
            // Identified: {
            //   value: "01",
            //   key: "permissions.identified",
            //   options: {
            //     add: atomics.add,
            //     edit: atomics.edit,
            //     delete: atomics.delete,
            //     export: atomics.export,
            //     network: atomics.network,
            //   },
            // },
            Recognized: {
              value: '02',
              key: 'permissions.recognized',
            },
          },
        },
        Sensor: {
          value: '08',
          key: 'permissions.sensor',
          options: {
            Identified: {
              value: '01',
              key: 'permissions.identified',
              options: {
                add: atomics.add,
                edit: atomics.edit,
                delete: atomics.delete,
                export: atomics.export,
              },
            },
            // Recognized: {
            //   value: "02",
            //   key: "permissions.recognized",
            // },
          },
        },
        Incident: {
          value: '09',
          key: 'permissions.incident',
          options: {
            // Identified: {
            //   value: "01",
            //   key: "permissions.identified",
            //   options: {
            //     add: atomics.add,
            //     edit: atomics.edit,
            //     delete: atomics.delete,
            //     export: atomics.export,
            //     network: atomics.network,
            //   },
            // },
            Recognized: {
              value: '02',
              key: 'permissions.recognized',
              options: {
                edit: atomics.edit,
              },
            },
          },
        },
        Communication: {
          value: '10',
          key: 'permissions.communication',
          options: {
            // Identified: {
            //   value: "01",
            //   key: "permissions.identified",
            //   options: {
            //     add: atomics.add,
            //     edit: atomics.edit,
            //     delete: atomics.delete,
            //     export: atomics.export,
            //     network: atomics.network,
            //   },
            // },
            Recognized: {
              value: '02',
              key: 'permissions.recognized',
            },
          },
        },
        Object: {
          value: '11',
          key: 'permissions.object',
          options: {
            Recognized: {
              value: '02',
              key: 'permissions.recognized',
            },
          },
        },
        Unit: {
          value: '12',
          key: 'permissions.unit',
          options: {
            Recognized: {
              value: '02',
              key: 'permissions.recognized',
            },
          },
        },

        Relation: {
          value: '13',
          key: 'permissions.relation',
          options: {
            Identified: {
              value: '01',
              key: 'permissions.identified',
              options: {
                add: atomics.add,
                edit: atomics.edit,
                delete: atomics.delete,
                export: atomics.export,
                network: atomics.network,
              },
            },
          },
        },
      },
    },
  },
};

export default permissions;
