import { Form, Input, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { t } from 'i18next';
import React from 'react';
import FormComponent from 'src/app/components/FormComponent/FormComponent';
import { IFormProps } from 'src/app/forms/form.config';
import { useAddUserRoleMutation, useUpdateUserRoleMutation } from 'src/app/redux/userRoles/userRolesSlice';

const UserRoleForm: React.FC<IFormProps> = ({ form, mode }) => {
  const [addUserRole] = useAddUserRoleMutation();
  const [updateUserRole] = useUpdateUserRoleMutation();

  const onFinish = async (values: any) => {
    try {
      if (mode === 'add') {
        addUserRole(values)
          .unwrap()
          .then(() => message.success(t('success.added')))
          .catch(() => message.error(t('errors.unknownError')));
      } else if (mode === 'edit') {
        updateUserRole(values)
          .unwrap()
          .then(() => message.success(t('success.edited')))
          .catch(() => message.error(t('errors.unknownError')));
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <FormComponent options={{ form, onFinish }}>
      <Form.Item name="id" hidden />
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input variant="borderless" />
      </Form.Item>
      <Form.Item name="abbreviation" label="Abbreviation" rules={[{ required: true }]}>
        <Input variant="borderless" />
      </Form.Item>
      <Form.Item name="description" label="Description" rules={[{ required: true }]}>
        <TextArea variant="borderless" />
      </Form.Item>
    </FormComponent>
  );
};

export default UserRoleForm;
