import { timeHelper } from 'src/app/helpers';
import { CustomerAndUserTableDataType } from 'src/app/pages/PortalPage/CustomerAndUserPage/customer-and-user-page.config';
import { User } from 'src/types/user.type';

export const convertUsersToTableData = (userList: User[]): CustomerAndUserTableDataType[] => {
  const result: CustomerAndUserTableDataType[] = [];

  userList.forEach((user) => {
    result.push({
      id: user.id,
      username: user.fullname,
      email: user.email,
      creationDate: timeHelper.formatDate(user.createdAt),
      lastLoginDate: timeHelper.formatDate(user.lastLoginDates[user.lastLoginDates.length - 1]),
      userRole: user.userRole?.map((role) => role.name).join(','),
      customerId: '',
      customerName: '',
      isFreezed: user.isFreezed,
      isOnline: user.isOnline,
    });
  });
  return result;
};
