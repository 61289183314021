import React from 'react';

import { iconType } from 'src/types';

const DownloadIcon: React.FC<iconType.PropsType> = ({ style, color, onClick, className }) => {
  return (
    <div
      className={className && className}
      role="button"
      onClick={onClick && onClick}
      onKeyDown={onClick && onClick}
      tabIndex={onClick && -1}
      style={onClick ? { cursor: 'pointer', display: 'flex' } : { display: 'flex' }}
    >
      <svg
        style={{ ...style }}
        id="download_v1"
        xmlns="http://www.w3.org/2000/svg"
        width="29.422"
        height="22.032"
        viewBox="0 0 29.422 22.032"
        fill={color || '#1a3441'}
      >
        <path
          id="Path_37356"
          data-name="Path 37356"
          d="M32.078,40.28v3.81H28.45l6.2,6.2,6.2-6.2H37.213V40.28Z"
          transform="translate(-19.934 -28.254)"
          fill={color || '#1a3441'}
          // fill="none"
        />
        <path
          id="Path_37357"
          data-name="Path 37357"
          d="M394.8,265.5a9.107,9.107,0,0,0-15.911,0,6.774,6.774,0,1,0,.036,13.548h2.424l-2.389-2.389h-.036A4.386,4.386,0,0,1,377.94,268a4.272,4.272,0,0,1,.982-.116,4.354,4.354,0,0,1,1.442.248,6.724,6.724,0,0,1,12.955,0,4.353,4.353,0,0,1,1.442-.248,4.272,4.272,0,0,1,.982.116,4.386,4.386,0,0,1-.982,8.659h-.036l-2.389,2.389h2.421A6.774,6.774,0,1,0,394.8,265.5Z"
          transform="translate(-372.13 -260.822)"
          // fill={color || "#FFF"}
        />
      </svg>
    </div>
  );
};

DownloadIcon.defaultProps = {
  // style: { width: '18px', height: '18px', marginRight: '12px' },
  // color: 'var(--primary-color)',
  onClick: undefined,
  className: undefined,
};

export default DownloadIcon;
