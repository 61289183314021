import { LookupQueryParametersType } from 'src/types/query.type';

export const addQueryParameters = (queryParameters: Record<string, string | number | undefined>, url: string) => {
  let newUrl: string;

  if (url.charAt(url.length - 1) === '?') {
    newUrl = url.substring(0, url.length - 1);
  } else {
    newUrl = url;
  }

  if (Object.keys(queryParameters).length === 0) {
    return newUrl;
  }

  Object.keys(queryParameters).forEach((parameter, i) => {
    if (i === 0) {
      newUrl += `?${parameter}=${queryParameters[parameter]}&`;
    } else {
      newUrl += `${parameter}=${queryParameters[parameter]}&`;
    }
  });

  return newUrl.substring(0, newUrl.length - 1);
};

export const getLookupQuery = <T extends {}>(params?: T) => {
  if (!params) return {};

  const query: LookupQueryParametersType = {};
  Object.entries(params).forEach(([key, value]) => {
    let res: any;
    if (Array.isArray(value)) {
      res = value.join(',');
    } else {
      res = value;
    }
    query[key as keyof LookupQueryParametersType] = res;
  });

  return query;
};

export function isValidHttpUrl(string: string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
