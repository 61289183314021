import { message as msgAntd } from 'antd';
import { AxiosError } from 'axios';
import { setToken } from 'src/app/utils/axios.instance';
import { languageTypes } from 'src/types';

/**
 * global error handler for axios
 * @param error
 * @param handleMessage
 * @param resetReducerStatesToDefault
 * @param getBaseURLs
 * @returns
 */

export const handleResponseErrors = (
  error: AxiosError,
  handleMessage: (textMessage: languageTypes.ErrorTextsKeysType) => void,
  resetReducerStatesToDefault: () => void,
  removeCookie: () => void,
): boolean => {
  if (error.config && error.config.blockErrorHandler) {
    return false;
  }

  switch (error.response?.status) {
    case 500:
      // todo: mustafa -> fix here
      if (
        error.response.data ===
        'update or delete on table "COCTemplates" violates foreign key constraint "COCInvitations_cocTemplateId_fkey" on table "COCInvitations"'
      ) {
        return true;
      }
      handleMessage('internalServerError');
      return true;
    case 404:
      if (error.response.data === 'The attachment could not be found') {
        handleMessage('attachmentPathNotFound');
      } else {
        handleMessage('internalServerError');
      }
      return true;
    case 401:
      handleMessage('sessionExpired');
      setToken(undefined);
      // storageHelper.removeKeyPair("token");
      sessionStorage.removeItem('token');
      removeCookie();
      resetReducerStatesToDefault();
      return true;
    case 400:
      handleMessage('badRequest');
      return true;
    case 403:
      handleMessage('authorizationError');
      return true;
    default: {
      if (error.message === 'Network Error') {
        handleMessage('connectionError');
        return true;
      }
      return false;
    }
  }
};

/**
 * gets error message from the errorList
 * @param messageKey
 * @param errorTexts
 */
export const displayErrorMessage = (
  // messageKey: languageTypes.ErrorTextsKeysType,
  errorTexts: string,
) => {
  msgAntd.error(errorTexts);
};
